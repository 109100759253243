import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import Error from '../../../common/global-error/Error';
import * as Yup from 'yup';

class UpdateProductionUnitBaseInfo extends React.Component {

  constructor(props) {
    super(props);
  }

  updateProductionUnitBasicInfo = (data) => {
    this.props.updateProductionUnitBasicInfoRequest(
      this.props.productionUnit.id,
      data.name,
      data.description
    );
  }

  render = () => {
    const editProductionUnitSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    });

    return (
      <Modal show={this.props.productionUnitShowUpdateBasicInfoWindow}
             onHide={this.props.showHideUpdateProductionUnitBasicInfoWindow.bind(this, false)}
             size="lg">
        <Formik
          initialValues={{
            name: this.props.productionUnit.name,
            description: this.props.productionUnit.description
          }}
          validationSchema={editProductionUnitSchema}
          onSubmit={this.updateProductionUnitBasicInfo.bind(this)}
          render={({errors, status, touched, isSubmitting}) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Edit Production Unit</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="production-unit-name">Production unit name:</label>
                  <Field type="text" name="name" className="form-control" id="production-unit-name"/>
                  <ErrorMessage name="name" component="div"/>
                </div>

                <div className="form-group">
                  <label htmlFor="production-unit-description">Production unit description:</label>
                  <Field name="description" className="form-control" component="textarea"/>
                  <ErrorMessage name="description" component="div"/>
                </div>
                {this.props.productionUnitUpdateBasicInfoError &&
                <Error error={this.props.productionUnitUpdateBasicInfoError}/>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={this.props.productionUnitBasicInfoUpdating} variant="secondary"
                        onClick={this.props.showHideUpdateProductionUnitBasicInfoWindow.bind(this, false)}>
                  Cancel
                </Button>
                <Button disabled={this.props.productionUnitBasicInfoUpdating} type="submit" variant="primary">
                  Save
                </Button>

              </Modal.Footer>
            </Form>
          )}
        >
        </Formik>
      </Modal>
    )
  };
}

UpdateProductionUnitBaseInfo.propTypes = {
  productionUnit: PropTypes.object.isRequired,
  productionUnitShowUpdateBasicInfoWindow: PropTypes.bool.isRequired,
  showHideUpdateProductionUnitBasicInfoWindow: PropTypes.func.isRequired,
  productionUnitUpdateBasicInfoError: PropTypes.object,
  productionUnitBasicInfoUpdating: PropTypes.bool.isRequired,
  updateProductionUnitBasicInfoRequest: PropTypes.func.isRequired
};

export default UpdateProductionUnitBaseInfo;
