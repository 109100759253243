import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from './GoogleMaps';

const UVAddressToCoordinates = (props) => {
  const { values, name, zoomLevel } = props;
  return (
    <React.Fragment>
      <GoogleMap
        location={{
          address: '',
          ...values[name],
        }}
        zoomLevel={zoomLevel}
        {...props}
      />
    </React.Fragment>
  );
};

UVAddressToCoordinates.propTypes = {
  values: PropTypes.object.isRequired,
  name: PropTypes.object.isRequired,
};

export default UVAddressToCoordinates;
