import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Modal} from 'react-bootstrap';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';

class AddAds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAddAddWindow: false,
      selectedAdIds: {}
    }
  }

  showHideAddAddWindow = (show) => {
    this.setState({
      showAddAddWindow: show
    });
  };

  cancelAddAdd = () => {
    this.showHideAddAddWindow(false)
  }

  addAds = () => {
    this.props.addAdsToProductionRequest(this.props.production.id, Object.keys(this.state.selectedAdIds));
    this.setState({
      selectedAdIds: {}
    });
    this.showHideAddAddWindow(false);
  }

  /**
   * Get all Ads except ones which are already selected or already in production.
   */
  getAllAdOptions = () => {
    const adIdsOfProduction = Object.fromEntries(this.props.production.adIds.map(adId => [adId, true]));
    const nonSelectedAds = this.props.allAds.filter(ad => !(ad.id in this.state.selectedAdIds || ad.id in adIdsOfProduction));
    return nonSelectedAds.map(ad => ({
      value: ad.id,
      label: `${ad.firstName} ${ad.lastName} (${ad.username}, ${ad.email})`
    }))
  };

  handleOnSelectChange = option => {
    this.setState(prevState => ({
      selectedAdIds: {...prevState.selectedAdIds, [option.value]: true}
    }));
  };

  removeAd = adId => {
    this.setState(prevState => {
      let selectedAdIds = {...prevState.selectedAdIds};
      delete selectedAdIds[adId];
      return {
        selectedAdIds: selectedAdIds
      };
    })
  }

  render = () => {
    const allAdsOptions = this.getAllAdOptions();
    const addedADs = Object.keys(this.state.selectedAdIds).map(adId => {
      const selectedAd = this.props.allAds.find(ad => ad.id === adId)
      return (
        <Row key={selectedAd.id} className="selected-ads-row">
          <Col md={10}>{selectedAd.firstName} {selectedAd.lastName} ({selectedAd.username}, {selectedAd.email})</Col>
          <Col md={2} onClick={this.removeAd.bind(this, selectedAd.id)} style={{textAlign: 'right'}}>
            <Button size="sm" variant="danger">Remove</Button>
          </Col>
        </Row>
      )
    });

    return (
      <div>
        <div style={{textAlign: 'right'}}>
          <Button onClick={this.showHideAddAddWindow.bind(this, true)} size="sm">Add ADs to Production</Button>
        </div>
        <Modal show={this.state.showAddAddWindow} onHide={this.cancelAddAdd.bind(this)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add ADs to Production</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.selectedAdIds).length > 0 &&
            <div className="selected-ads">
              <h5>Selected ADs:</h5>
              <div className="selected-ads-content">
                {addedADs}
              </div>
            </div>
            }
            <div className="add-ads-select">
              <Select
                options={allAdsOptions}
                placeholder="Select AD to add..."
                value={null}
                onChange={this.handleOnSelectChange.bind(this)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.cancelAddAdd.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.addAds.bind(this)}>
              Add ADs
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

AddAds.propTypes = {
  allAds: PropTypes.array.isRequired,
  production: PropTypes.object.isRequired,
  addAdsToProductionRequest: PropTypes.func.isRequired
};

export default AddAds;

