import React from 'react';
import PropTypes from 'prop-types';
import {dateTimeToUveFormat} from '../../../../lib/dateTimeUtils';
import * as getters from '../../../getters';
import ConfirmationDialog from '../../../../common/confimration-dialog/ConfirmationDialog';

class DocumentRecord extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmationDialog: false
    };
  }

  showHideDeleteConfirmationDialog = show => {
    this.setState({
      showDeleteConfirmationDialog: show
    })
  }

  deleteProductionDocument = (document) => {
    this.props.deleteProductionDocumentRequest(document.id, this.props.production.id)
  }

  render = () => {
    const { document } = this.props;
    return (
      <React.Fragment>
        <tr>
          <td>{document.title}</td>
          <td>{getters.getProductionDocumentTypeLabel(document.type)}</td>
          <td>{getters.getProductionRequiredActionLabel(document.requiredAction)}</td>
          <td>{getters.getProductionActionFrequencyLabel(document.actionFrequency)}</td>
          <td>{dateTimeToUveFormat(document.createdAt)}</td>
          <td className="production-document-record-actions">
            <a
              href={document.url}
              target="_blank"
              className="btn btn-outline-primary btn-xs"
            >
              View
            </a>
            <button
              style={{marginLeft: '5px'}}
              className="btn btn-outline-secondary btn-xs"
              onClick={this.props.showHideUpdateProductionDocumentWindow.bind(this, true, document)}
            >
              Edit
            </button>
            <button
              style={{marginLeft: '5px'}}
              className="btn btn-outline-danger btn-xs"
              onClick={this.showHideDeleteConfirmationDialog.bind(this, true)}
            >
              Delete
            </button>
            <ConfirmationDialog
              show={this.state.showDeleteConfirmationDialog}
              title="Delete Production Document"
              message={
                <span>
              Are you sure you want to delete production document <strong>{document.title}</strong>?
            </span>
              }
              onConfirm={this.deleteProductionDocument.bind(this, document)}
              onCancel={this.showHideDeleteConfirmationDialog.bind(this, false)}
            />
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

DocumentRecord.propTypes = {
  production: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  showHideUpdateProductionDocumentWindow: PropTypes.func.isRequired,
  deleteProductionDocumentRequest: PropTypes.func.isRequired
}

export default DocumentRecord;