import * as actionTypes from './actionTypes';

export const sendPasswordResetLinkRequest = (email, sourceApp) => ({
  type: actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST,
  email,
  sourceApp
});

export const sendPasswordResetLinkSuccess = () => ({
  type: actionTypes.SEND_PASSWORD_RESET_LINK_SUCCESS
});

export const sendPasswordResetLinkFailure = (error) => ({
  type: actionTypes.SEND_PASSWORD_RESET_LINK_FAILURE,
  error
});

export const setPasswordRequest = (oobCode, password) => ({
  type: actionTypes.SET_PASSWORD_REQUEST,
  oobCode,
  password
});

export const setPasswordSuccess = () => ({
  type: actionTypes.SET_PASSWORD_SUCCESS
});

export const setPasswordFailure = (error) => ({
  type: actionTypes.SET_PASSWORD_FAILURE,
  error
});

export const setStep = (step, data) => ({
  type: actionTypes.SET_STEP,
  step,
  data
});
