import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../actions';
import store from '../../store';
import Productions from './Productions';
import * as getters from '../getters';
import {importC5ProductionsRequest} from '../actions';

const filterProductions = (productionRecords, filter) => {
  // TODO:: add filters
  return productionRecords.sort((record1, record2) => {
    let result = 0;
    if (record1.production.name.toUpperCase() > record2.production.name.toUpperCase()) result = 1;
    if (record2.production.name.toUpperCase() > record1.production.name.toUpperCase()) result = -1;
    return result;
  });
};

class ProductionsContainer extends React.Component {

  componentDidMount() {
    store.dispatch(actions.loadProductionRecordsRequest());
  }

  handleProductionDayClick = (productionId, date) => {
    const url = `/productions/${productionId}/date/${date}`
    this.props.history.push(url);
  };

  render = () => {
    const {isLoaded} = this.props;
    if (!isLoaded) return null;
    return <Productions {...this.props} onProductionDayClick={this.handleProductionDayClick.bind(this)}/>
  }
}

const mapStateToProps = state => {
  const productionsState = state.productions;
  return {
    isLoaded: productionsState.isLoaded,
    isLoading: productionsState.isLoading,
    productionRecords: filterProductions(getters.getProductionRecords(state)),
    productionsImportResults: productionsState.productionsImportResults
  }
};

const actionCreators = {
  importC5ProductionsRequest
};

export default withRouter(connect(mapStateToProps, actionCreators)(ProductionsContainer));

