import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

const ConfirmationDialog = ({show, title, message, onConfirm, onCancel, confirmLabel, cancelLabel, size="lg"}) => {
  return (
    <Modal show={show} onHide={onCancel.bind(this)} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel.bind(this)}>
          {cancelLabel || 'No'}
        </Button>
        <Button variant="primary" onClick={onConfirm.bind(this)}>
          {confirmLabel || 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string
};

export default ConfirmationDialog;
