import React from 'react';
import ChitSection3LeftSection1 from './ChitSection3LeftSection1';
import ChitSection3LeftSection2 from './ChitSection3LeftSection2';

class ChitSection3 extends React.Component {
  render() {
    return (
      <div className="card p-4">
        <ChitSection3LeftSection1 {...this.props} />
        <ChitSection3LeftSection2 {...this.props} />
      </div>
    )
  }
}

export default ChitSection3;