import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Error from '../global-error/Error';
import FormFields from './FormFields';
import Loader from 'react-loader-spinner';
import FormField from './FormField';

function FormWithModal(props) {
  const {
    show,
    onSubmit,
    onCancel,
    isDisabled,
    onHide,
    initialState,
    validationSchema,
    title,
    cancelBtnNmae,
    submitBtnName,
    showLoader,
    showError,
    fields,
    modalSize = 'md',
    children
  } = props;

  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} size={modalSize}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          render={(rest) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {fields.map((field) => (
                    <FormField key={'field-container-'+field.name} fieldInfo={field} {...rest} {...props} />
                ))}
              </Modal.Body>
              {showError && <Error {...props} error={showError} />}
              {showLoader && <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />}
              <Modal.Footer>
                {children}
                <Button disabled={isDisabled} variant="secondary" onClick={onCancel}>
                  {cancelBtnNmae || 'Cancel'}
                </Button>
                <Button id={title} disabled={isDisabled} type="submit" variant="primary">
                  {submitBtnName || 'Submit'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        />
      </Modal>
    </React.Fragment>
  );
}


FormWithModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  initialState: PropTypes.object.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showError: PropTypes.object.isRequired,
};

export default FormWithModal;
