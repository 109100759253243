import React from 'react';
import PropTypes from 'prop-types';

class ChitSection3LeftSection2ChitTotal extends React.Component {
  render() {
    const { chit } = this.props.chitData;
    return (
      <React.Fragment>
        <tr className="bg-lighter">
          <td><b>TOTAL</b></td>
          <td className="text-right">
            £{parseFloat(chit.chitTotal).toFixed(2)}
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

ChitSection3LeftSection2ChitTotal.propTypes = {
  chitData: PropTypes.object.isRequired
}

export default ChitSection3LeftSection2ChitTotal;