import * as actionTypes from './actionTypes';

export const loadProductionRequest = (productionId) => ({
  type: actionTypes.LOAD_PRODUCTION_REQUEST,
  productionId
});

export const loadProductionSuccess = (productionData) => ({
  type: actionTypes.LOAD_PRODUCTION_SUCCESS,
  productionData
});

export const loadProductionFailure = (error) => ({
  type: actionTypes.LOAD_PRODUCTION_FAILURE,
  error
});

export const setActiveTab = (tab) => ({
  type: actionTypes.SET_ACTIVE_TAB,
  tab
});

export const assignPrimaryAdRequest = (productionId, adId) => ({
  type: actionTypes.ASSIGN_PRIMARY_AD_REQUEST,
  productionId,
  adId
});

export const assignPrimaryAdSuccess = (production) => ({
  type: actionTypes.ASSIGN_PRIMARY_AD_SUCCESS,
  production
});

export const assignPrimaryAdFailure = (error) => ({
  type: actionTypes.ASSIGN_PRIMARY_AD_FAILURE,
  error
});

export const assignAdminRequest = (productionId, adminId) => ({
  type: actionTypes.ASSIGN_ADMIN_REQUEST,
  productionId,
  adminId
});

export const assignAdminSuccess = (production) => ({
  type: actionTypes.ASSIGN_ADMIN_SUCCESS,
  production
});

export const assignAdminFailure = (error) => ({
  type: actionTypes.ASSIGN_ADMIN_FAILURE,
  error
});

export const setDefaultPaySchemeRequest = (productionId, paySchemeId) => ({
  type: actionTypes.SET_DEFAULT_PAY_SCHEME_REQUEST,
  productionId,
  paySchemeId
});

export const setDefaultPaySchemeSuccess = (production) => ({
  type: actionTypes.SET_DEFAULT_PAY_SCHEME_SUCCESS,
  production
});

export const setDefaultPaySchemeFailure = (error) => ({
  type: actionTypes.SET_DEFAULT_PAY_SCHEME_FAILURE,
  error
});

export const importProductionDaysRequest = (productionId, startDate, endDate, importType, successMessage) => ({
  type: actionTypes.IMPORT_PRODUCTION_DAYS_REQUEST,
  productionId,
  startDate,
  endDate,
  importType,
  successMessage
});

export const importProductionDaysSuccess = () => ({
  type: actionTypes.IMPORT_PRODUCTION_DAYS_SUCCESS
});

export const importProductionDaysFailure = (error) => ({
  type: actionTypes.IMPORT_PRODUCTION_DAYS_FAILURE,
  error
});

export const showHideCreateProductionUnitWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_CREATE_PRODUCTION_UNIT_WINDOW,
  show
});

export const createProductionUnitRequest = (productionId, name, description, secondaryAdIds, paySchemeId) => ({
  type: actionTypes.CREATE_PRODUCTION_UNIT_REQUEST,
  productionId,
  name,
  description,
  secondaryAdIds,
  paySchemeId
});

export const createProductionUnitSuccess = (productionUnit) => ({
  type: actionTypes.CREATE_PRODUCTION_UNIT_SUCCESS,
  productionUnit
});

export const createProductionUnitFailure = (error) => ({
  type: actionTypes.CREATE_PRODUCTION_UNIT_FAILURE,
  error
});

export const showHideUpdateProductionUnitBasicInfoWindow = (show, productionUnit) => ({
  type: actionTypes.SHOW_HIDE_UPDATE_PRODUCTION_UNIT_BASIC_INFO_WINDOW,
  show,
  productionUnit
});

export const updateProductionUnitBasicInfoRequest = (productionUnitId, name, description) => ({
  type: actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_REQUEST,
  productionUnitId,
  name,
  description,
});

export const updateProductionUnitBaseInfoSuccess = (productionUnit) => ({
  type: actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_SUCCESS,
  productionUnit
});

export const updateProductionUnitBasicInfoFailure = (error) => ({
  type: actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_FAILURE,
  error
});

export const deleteProductionUnitRequest = (productionUnitId) => ({
  type: actionTypes.DELETE_PRODUCTION_UNIT_REQUEST,
  productionUnitId,
});

export const deleteProductionUnitSuccess = (productionUnit) => ({
  type: actionTypes.DELETE_PRODUCTION_UNIT_SUCCESS,
  productionUnit
});

export const deleteProductionUnitFailure = (error) => ({
  type: actionTypes.DELETE_PRODUCTION_UNIT_FAILURE,
  error
});

export const setProductionUnitPaySchemeRequest = (productionUnitId, paySchemeId) => ({
  type: actionTypes.SET_PRODUCTION_UNIT_PAY_SCHEME_REQUEST,
  productionUnitId,
  paySchemeId,
});

export const setProductionUnitPaySchemeSuccess = (productionUnit) => ({
  type: actionTypes.SET_PRODUCTION_UNIT_PAY_SCHEME_SUCCESS,
  productionUnit
});

export const setProductionUnitPaySchemeFailure = (error) => ({
  type: actionTypes.SET_PRODUCTION_UNIT_PAY_SCHEME_FAILURE,
  error
});

export const assignSecondaryAdsToProductionUnitRequest = (productionUnitId, secondaryAdIds) => ({
  type: actionTypes.ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_REQUEST,
  productionUnitId,
  secondaryAdIds
});

export const assignSecondaryAdsToProductionUnitSuccess = (productionUnit) => ({
  type: actionTypes.ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_SUCCESS,
  productionUnit
});

export const assignSecondaryAdsToProductionUnitFailure = (error) => ({
  type: actionTypes.ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_FAILURE,
  error
});

export const unassignSecondaryAdFromProductionUnitRequest = (productionUnitId, secondaryAdId) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_REQUEST,
  productionUnitId,
  secondaryAdId
});

export const unassignSecondaryAdFromProductionUnitSuccess = (productionUnit) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_SUCCESS,
  productionUnit
});

export const unassignSecondaryAdFromProductionUnitFailure = (error) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_FAILURE,
  error
});

export const unassignSecondaryAdFromAllProductionUnitsRequest = (productionId, secondaryAdId) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_REQUEST,
  productionId,
  secondaryAdId
});

export const unassignSecondaryAdFromAllProductionUnitsSuccess = (productionUnitIds) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_SUCCESS,
  productionUnitIds
});

export const unassignSecondaryAdFromAllProductionUnitsFailure = (error) => ({
  type: actionTypes.UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_FAILURE,
  error
});

export const addAdsToProductionRequest = (productionId, adIds) => ({
  type: actionTypes.ADD_ADS_TO_PRODUCTION_REQUEST,
  productionId,
  adIds
});

export const addAdsToProductionSuccess = (production) => ({
  type: actionTypes.ADD_ADS_TO_PRODUCTION_SUCCESS,
  production
});

export const addAdsToProductionFailure = (error) => ({
  type: actionTypes.ADD_ADS_TO_PRODUCTION_FAILURE,
  error
});

export const removeAdFromProductionRequest = (productionId, adId) => ({
  type: actionTypes.REMOVE_AD_FROM_PRODUCTION_REQUEST,
  productionId,
  adId
});

export const removeAdFromProductionSuccess = (production) => ({
  type: actionTypes.REMOVE_AD_FROM_PRODUCTION_SUCCESS,
  production
});

export const removeAdFromProductionFailure = (error) => ({
  type: actionTypes.REMOVE_AD_FROM_PRODUCTION_FAILURE,
  error
});

export const showHideProductionDayQrCodeWindow = (show, production, productionDay, action) => ({
  type: actionTypes.SHOW_HIDE_PRODUCTION_DAY_QR_CODE_WINDOW,
  show,
  production,
  productionDay,
  action
});


export const getActionTokenQrCodeRequest = (productionDayId, action) => ({
  type: actionTypes.GET_ACTION_TOKEN_QR_CODE_REQUEST,
  productionDayId,
  action
});

export const getActionTokenQrCodeSuccess = (qrCode, action) => ({
  type: actionTypes.GET_ACTION_TOKEN_QR_CODE_SUCCESS,
  qrCode,
  action
});

export const getActionTokenQrCodeFailure = (error) => ({
  type: actionTypes.GET_ACTION_TOKEN_QR_CODE_FAILURE,
  error
});

export const setHealthSurveySettingsRequest = (productionId, settings) => ({
  type: actionTypes.SET_HEALTH_SURVEY_SETTINGS_REQUEST,
  productionId,
  settings
});

export const setHealthSurveySettingsSuccess = (production) => ({
  type: actionTypes.SET_HEALTH_SURVEY_SETTINGS_SUCCESS,
  production
});

export const setHealthSurveySettingsFailure = (error) => ({
  type: actionTypes.SET_HEALTH_SURVEY_SETTINGS_FAILURE,
  error
});

export const showHideCreateProductionDocumentWindow = show => ({
  type: actionTypes.SHOW_HIDE_CREATE_PRODUCTION_DOCUMENT_WINDOW,
  show
})

export const showHideUpdateProductionDocumentWindow = (show, productionDocument) => ({
  type: actionTypes.SHOW_HIDE_UPDATE_PRODUCTION_DOCUMENT_WINDOW,
  show,
  productionDocument
});

export const createProductionDocumentRequest = data => ({
  type: actionTypes.CREATE_PRODUCTION_DOCUMENT_REQUEST,
  data
});

export const createProductionDocumentSuccess = document => ({
  type: actionTypes.CREATE_PRODUCTION_DOCUMENT_SUCCESS,
  document
});

export const createProductionDocumentFailure = error => ({
  type: actionTypes.CREATE_PRODUCTION_DOCUMENT_FAILURE,
  error
});

export const updateProductionDocumentRequest = data => ({
  type: actionTypes.UPDATE_PRODUCTION_DOCUMENT_REQUEST,
  data
});

export const updateProductionDocumentSuccess = document => ({
  type: actionTypes.UPDATE_PRODUCTION_DOCUMENT_SUCCESS,
  document
});

export const updateProductionDocumentFailure = error => ({
  type: actionTypes.UPDATE_PRODUCTION_DOCUMENT_FAILURE,
  error
});

export const deleteProductionDocumentRequest = (id, productionId) => ({
  type: actionTypes.DELETE_PRODUCTION_DOCUMENT_REQUEST,
  id,
  productionId
});

export const deleteProductionDocumentSuccess = document => ({
  type: actionTypes.DELETE_PRODUCTION_DOCUMENT_SUCCESS,
  document
});

export const deleteProductionDocumentFailure = error => ({
  type: actionTypes.DELETE_PRODUCTION_DOCUMENT_FAILURE,
  error
});

export const setQrCodesRequirementRequest = (productionId, required) => ({
  type: actionTypes.SET_QR_CODES_REQUIREMENT_REQUEST,
  productionId,
  required
});

export const setQrCodesRequirementSuccess = production => ({
  type: actionTypes.SET_QR_CODES_REQUIREMENT_SUCCESS,
  production
});

export const setQrCodesRequirementFailure = error => ({
  type: actionTypes.SET_QR_CODES_REQUIREMENT_FAILURE,
  error
});

export const downloadDocumentsRequest = (productionId, productionName, date) => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_REQUEST,
  productionId,
  productionName,
  date
});

export const downloadDocumentsSuccess = () => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
});

export const downloadDocumentsFailure = (error) => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_FAILURE,
  error
});

export const downloadDocumentsReportRequest = (productionId, productionName, date) => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_REPORT_REQUEST,
  productionId,
  productionName,
  date
});

export const downloadDocumentsReportSuccess = () => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_REPORT_SUCCESS,
});

export const downloadDocumentsReportFailure = (error) => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_REPORT_FAILURE,
  error
});




// transpotation group actions
export const showHideTarnspotationGroups = (transportGroupId = 0) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_CREATE_UPDATE_MODAL_SHOW_HIDE,
  transportGroupId

})

export const setCreateTarnspotationGroups = (name, productionId, locationId) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_CREATEING,
  ...{ name, productionId, locationId }
})

export const setCreateTarnspotationGroupsSuccess = (productionData) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_CREATEING_SUCCESS,
  ...{ productionData }

})

export const setCreateTarnspotationGroupsFailure = (error) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_CREATEING_FAILTURE,
  error

})

export const setUpdateTranspotationGroups = (transportGroupId, productionId, locationId, name) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_UPDATE_REQUEST,
  transportGroupId, productionId, locationId, name
})

export const setUpdateTranspotationGroupsSuccess = (productionData) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_UPDATE_SUCCESS,
  ...{ productionData }

})

export const setUpdateTarnspotationGroupsFailure = (error) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_UPDATE_FAILURE,
  error

})


export const setTransportationGroupShowHideWindow = (transportGroupId) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_DELETE_WINDOW,
  transportGroupId
})

export const setTranspotationGroupDeleteRequest = (productionId, transportGroupId) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_DELETE_REQUEST,
  productionId,
  transportGroupId

})
export const setTranspotationGroupDeleteSuccess = (productionData) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_DELETE_SUCCESS,
  ...{ productionData }

}
)

export const setTranspotationGroupDeleteFailure = (error) => ({
  type: actionTypes.SET_TRANSPOTATION_GROUP_DELETE_FAILURE,
  ...{ error }
})


//location

export const setShowHideLocation = () => ({
  type: actionTypes.SET_LOCATION_CREATE_MODAL_SHOW_HIDE

})



export const setCreateLocation = (name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street) => ({
  type: actionTypes.SET_LOCATION_CREATEING,
  ...{ name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street }
})

export const setCreateLocationSuccess = (newLocRecord) => ({
  type: actionTypes.SET_LOCATION_CREATEING_SUCCESS,
  ...{ newLocRecord }

})

export const setCreateLocationFailure = (error) => ({
  type: actionTypes.SET_LOCATION_CREATEING_FAILTURE,
  error

})


export const setShowHideLocationEditModal = (id) => ({
  type: actionTypes.SET_LOCATION_EDIT_MODAL_SHOW_HIDE,
  id
})

export const setEditLocationRequest = (id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street) => ({
  type: actionTypes.SET_LOCATION_EDIT_REQUEST,
  id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street
});

export const setEditLocationSuccess = (updatedLocation) => ({
  type: actionTypes.SET_LOCATION_EDIT_SUCCESS,
  updatedLocation
})

export const setEditLocationFailure = (error) => ({
  type: actionTypes.SET_LOCATION_EDIT_FAILTURE,
  error,
})

export const setDeleteLocationRequest = (id) => ({
  type: actionTypes.SET_LOCATION_DELETE_REQUEST,
  id,
})

export const setDeleteLocationSuccess = (deletedLocationId) => ({
  type: actionTypes.SET_LOCATION_DELETE_SUCCESS,
  deletedLocationId,
})

export const setDeleteLocationFailure = (error) => ({
  type: actionTypes.SET_LOCATION_DELETE_FAILTURE,
  error
})


export const loadLocationData = () => ({
  type: actionTypes.LOAD_LOCATION_DATA_REQUEST
})

export const loadLocationDataSucess = (location) => ({
  type: actionTypes.LOAD_LOCATION_DATA_SUCCESS,
  location

})

export const loadLocationDataFailure = (error) => ({
  type: actionTypes.LOAD_LOCATION_DATA_FAILURE,
  error
})

