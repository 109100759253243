import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

class ProductionDayRowActionsQrCode extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const { production, productionDay, action } = this.props;
    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              <div className="tooltip-content">
                Production day's {action} qr code
              </div>
            </Tooltip>
          }
        >
          <div className="d-inline-block">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={this.props.showHideProductionDayQrCodeWindow.bind(this, true, production, productionDay, action)}>
              {action} QR code
            </Button>
          </div>
        </OverlayTrigger>
      </div>
    )
  };
}

ProductionDayRowActionsQrCode.propTypes = {
  showHideProductionDayQrCodeWindow: PropTypes.func.isRequired,
  production: PropTypes.object.isRequired,
  productionDay: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired
};

export default ProductionDayRowActionsQrCode;
