import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import * as constants from '../../constants';
import {unitWord} from "../../../production/getters";

class WrapUpProductionUnit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWrapUpProductionUnitConfirmationDialog: false,
    };
  }

  showHideWrapUpProductionUnitConfirmationDialog = show => {
    this.setState({
      showWrapUpProductionUnitConfirmationDialog: show
    })
  };

  wrapUpProductionUnit = () => {
    this.props.wrapUpProductionUnitOnDayRequest(this.props.currentProductionDay.id, this.props.productionUnit.id);
    this.showHideWrapUpProductionUnitConfirmationDialog(false);
  }

  /**
   * All chits of production unit in given day should be managed
   * (chits' statuses must be either CHECKED_OUT, CANCELLED or NO_SHOW).
   */
  allChitsAreManaged = () =>
    this.props.chitRecordsOfProductionUnit
      .filter(chitRecord =>
        chitRecord.chit.extraStatus !== constants.EXTRA_STATUS_NO_SHOW
        && chitRecord.chit.extraStatus !== constants.EXTRA_STATUS_CHECKED_OUT
        && chitRecord.chit.extraStatus !== constants.EXTRA_STATUS_CANCELLED
        && chitRecord.chit.extraStatus !== constants.EXTRA_STATUS_RELEASED
      ).length === 0


  render() {
    if (this.props.closedProductionUnitOnDay) {
      // Production unit is already wrapped up or locked? Do not show close button
      return null;
    }

    const allChitsAreManaged = this.allChitsAreManaged();

    return (
      <div>
        {!allChitsAreManaged &&
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
              All chits of production unit must be managed: chits' statuses must be either CHECKED_OUT, CANCELLED, NO_SHOW or RELEASED.
            </Tooltip>
          }
        >
          <div className="d-inline-block">
            <Button
              variant="outline-danger"
              size="sm"
              disabled
              // Enable tooltip when button is disabled
              style={{pointerEvents: 'none'}}>
              Close Unit
            </Button>
          </div>
        </OverlayTrigger>
        }
        {allChitsAreManaged &&
        <Button variant="outline-danger" size="sm"
                onClick={this.showHideWrapUpProductionUnitConfirmationDialog.bind(this, true)}>
          Close Unit
        </Button>
        }
        <ConfirmationDialog
          show={this.state.showWrapUpProductionUnitConfirmationDialog}
          title="Close production unit"
          onCancel={this.showHideWrapUpProductionUnitConfirmationDialog.bind(this, false)}
          message={
            <div>
              <h5>
                Closing {unitWord(this.props.productionUnit.name)}<strong>{this.props.productionUnit.name}</strong>
              </h5>
              <Alert variant="warning">
                Before proceeding, please make sure that all chits have been finalised and are correct in terms of rates, fees and times.
              </Alert>
            </div>
          }
          onConfirm={this.wrapUpProductionUnit.bind(this)}
          confirmLabel="Close Unit"
          cancelLabel="Cancel"
        />
      </div>
    )
  }
}

WrapUpProductionUnit.propTypes = {
  currentProductionDay: PropTypes.object.isRequired,
  productionUnit: PropTypes.object.isRequired,
  closedProductionUnitOnDay: PropTypes.object,
  wrapUpProductionUnitOnDayRequest: PropTypes.func.isRequired,
  chitRecordsOfProductionUnit: PropTypes.array.isRequired,
};

export default WrapUpProductionUnit;

