import * as React from 'react';
import PropTypes from 'prop-types';
import {dateToUveFormat} from '../../../lib/dateTimeUtils';

class EmptyProductionDayRow extends React.Component {


  render = () => {
    return (
      <React.Fragment>
        <tr style={{width: '20%'}} className={`production-days-table-row ${this.props.highlighted ? 'highlighted-row' : ''}`} ref={this.props.refProp}>
          <td style={{width: '20%', height: '100px'}} className="production-days-table-date">
              <div>
                {dateToUveFormat(this.props.date)}
              </div>
          </td>
          <td style={{width: '10%'}}>
            <div>
              No chits
            </div>
          </td>
          <td style={{width: '40%'}}>
          </td>
          <td style={{width: '20%'}}>
          </td>
          <td style={{width: '10%'}}>
          </td>
        </tr>
      </React.Fragment>
    );
  };
}

EmptyProductionDayRow.propTypes = {
  date: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  refProp: PropTypes.object
};

export default EmptyProductionDayRow;
