import React from 'react';

const ChitsHeaderCampaigns = (props) => {
    return (
        <React.Fragment>
            <td className="cd-header chit-column-type">Type</td>
            <td className="cd-header chit-column-response">Response</td>
            <td className="cd-header chit-column-date-sent">Date Sent</td>
            <td className="cd-header chit-column-time-sent">Time Sent</td>
            <td className="cd-header chit-column-response-date">Response Date</td>
            <td className="cd-header chit-column-response-time">Response Time</td>
        </React.Fragment>
    );
};

ChitsHeaderCampaigns.propTypes = {};

export default ChitsHeaderCampaigns;
