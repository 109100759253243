import store from '../../store';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {signInSuccess} from '../../signin/actions';
import {signOutSuccess} from '../../app/actions';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch(signInSuccess(user))
  } else {
    store.dispatch(signOutSuccess())
  }
});

export { auth, firebaseConfig };