import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';
import { DebounceInput } from 'react-debounce-input';
import './Filter.css';
import * as constants from '../../constants';
import { momentToDateTime, parseFromDateTime } from '../../../lib/dateTimeUtils';
import * as productionConstants from '../../../production/constants';
import { propTypes } from 'react-bootstrap/esm/Image';
import { allCallDetailsDeliveryStatuses } from '../../getters';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keywordsFilter: '',
            keywordsTypingFinished: true,
        };
    }

    getExtraRolesSelectOptions = (productionDayExtraRoles) => {
        return productionDayExtraRoles.map((extraRole) => ({
            value: extraRole.id,
            label: extraRole.name,
        }));
    };

    getExtraStatusesOptions = () => {
        return this.props.allExtraStatuses.map((status) => ({
            value: status,
            label: status,
        }));
    };

    getProductionUnitOptions = (productionUnits) => {
        return Object.values(productionUnits).map((unit) => ({
            value: unit.id,
            label: unit.name,
        }));
    };

    getActivityTypesOptions = () => {
        return [constants.ACTIVITY_TYPE_FILMING, constants.ACTIVITY_TYPE_OTHER].map(
            (activityType) => ({
                value: activityType,
                label: activityType.toUpperCase(),
            }),
        );
    };

    getProductionDocumentStatusesOptions = () => [
        {
            value: true,
            label: 'Completed',
        },
        {
            value: false,
            label: 'Not Completed',
        },
    ];

    getHealthSurveyStatusesOptions = () => [
        {
            value: productionConstants.HEALTH_SURVEY_STATUS_NOT_COMPLETED,
            label: 'Not Completed',
        },
        {
            value: productionConstants.HEALTH_SURVEY_STATUS_FAILED,
            label: 'Failed',
        },
        {
            value: productionConstants.HEALTH_SURVEY_STATUS_PASSED,
            label: 'Passed',
        },
    ];

    getTravelValueOptions = () => [
        {
            label: 'Public',
            value: constants.TRAVE_TYPE_PUBLIC,
        },
        {
            label: 'Drive',
            value: constants.TRAVE_TYPE_DRIVE,
        },
        {
            label: 'Lift',
            value: constants.TRAVE_TYPE_LIFT,
        },
    ];

    getCallDetailsStatus = () =>
        allCallDetailsDeliveryStatuses.map((status) => ({ label: status, value: status }));

    getTranspotationGroupOptons = () =>
        Object.keys(this.props.transportGroups).map((groupId) => ({
            value: groupId,
            label: this.props.transportGroups[groupId].name,
        }));
    handleKeyWordsChange = (event) => {
        this.props.setChitsFilterValue('keywords', event.target.value);
    };

    handleMultiSelectFilterChange = (key, options) => {
        const values = options ? options.map((option) => option.value) : [];
        this.props.setChitsFilterValue(key, values);
    };

    handleSelectFilterChange = (key, option) => {
        const value = option ? option.value : null;
        this.props.setChitsFilterValue(key, value);
    };

    /**
     * Set call time date part to production date and leave time part with truncated seconds.
     */
    setCallTimeFilterDatePartToProductionDate = (callTime, productionDate) => {
        const callTimeWithProductionDatePart = parseFromDateTime(
            productionDate + 'T' + callTime.format('HH:mm') + ':00',
        );
        return momentToDateTime(callTimeWithProductionDatePart);
    };

    handleCallTimeFromChange = (value) => {
        const dateTimeOrNull = value
            ? this.setCallTimeFilterDatePartToProductionDate(value, this.props.productionDate)
            : value;
        this.props.setChitsFilterValue('callTimeFrom', dateTimeOrNull);
    };

    handleCallTimeToChange = (value) => {
        const dateTimeOrNull = value
            ? this.setCallTimeFilterDatePartToProductionDate(value, this.props.productionDate)
            : value;
        this.props.setChitsFilterValue('callTimeTo', dateTimeOrNull);
    };

    handleTravelValueChange = (currentFilterTravelValues) => {
        this.props.setChitsFilterValue(
            'travelValues',
            (currentFilterTravelValues &&
                currentFilterTravelValues.map((option) => option.value)) ||
                [],
        );
    };

    handleTranspotataionGroupChange = (currentFilterTranspotationValue) => {
        this.props.setChitsFilterValue(
            'transportationGroups',
            (currentFilterTranspotationValue &&
                currentFilterTranspotationValue.map((option) => option.value)) ||
                [],
        );
    };

    handleCallDetailsStatusChange = (currentFilterForCallDetails) => {
        this.props.setChitsFilterValue('callDetails', currentFilterForCallDetails?.value ?? '');
    };

    render = () => {
        return (
            <form>
                <div className="row">
                    <div className="col-md-4">
                        {/*<label>Search query</label>*/}
                        <DebounceInput
                            name="keywords"
                            placeholder="First Name, Last Name, Extra Role Name, Extra ID or their combination..."
                            className="form-control"
                            debounceTimeout={300}
                            onChange={this.handleKeyWordsChange}
                        />
                    </div>
                    <div className="col-md-4">
                        {/*<label>Extra Roles</label>*/}
                        <Select
                            options={this.getExtraRolesSelectOptions(
                                this.props.productionDayExtraRoles,
                            )}
                            placeholder="Extra Roles..."
                            className="basic-multi-select"
                            isMulti
                            onChange={this.handleMultiSelectFilterChange.bind(this, 'extraRoleIds')}
                        />
                    </div>
                    <div className="col-md-2">
                        {/*<label>Extra Statuses</label>*/}
                        <Select
                            options={this.getExtraStatusesOptions()}
                            placeholder="Extra Statuses..."
                            className="basic-multi-select"
                            isMulti
                            onChange={this.handleMultiSelectFilterChange.bind(
                                this,
                                'extraStatuses',
                            )}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            options={this.getCallDetailsStatus()}
                            placeholder="CallDetails Status"
                            className="basic-multi-select"
                            onChange={this.handleCallDetailsStatusChange.bind(this)}
                            isClearable
                        />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '15px' }}>
                    <div className="col-md-2">
                        {/*<label>Call Time</label>*/}
                        <TimePicker
                            placeholder="Call Time From..."
                            showSecond={false}
                            allowEmpty={true}
                            className="call-time-filter"
                            onChange={this.handleCallTimeFromChange}
                        />
                    </div>
                    <div className="col-md-2">
                        {/*<label>Call Time</label>*/}
                        <TimePicker
                            placeholder="Call Time To..."
                            showSecond={false}
                            allowEmpty={true}
                            className="call-time-filter"
                            onChange={this.handleCallTimeToChange}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            options={this.getProductionUnitOptions(this.props.productionUnits)}
                            placeholder="Production Units..."
                            className="basic-multi-select"
                            isMulti
                            onChange={this.handleMultiSelectFilterChange.bind(
                                this,
                                'productionUnitIds',
                            )}
                        />
                    </div>
                    <div className="col-md-2">
                        {/*<label>Day Types</label>*/}
                        <Select
                            options={this.getActivityTypesOptions()}
                            placeholder="Day Types..."
                            className="basic-multi-select"
                            isMulti
                            onChange={this.handleMultiSelectFilterChange.bind(
                                this,
                                'activityTypes',
                            )}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            options={this.getTravelValueOptions()}
                            placeholder="TravelValue..."
                            className="basic-multi-select"
                            onChange={this.handleTravelValueChange.bind(this)}
                            isMulti
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            options={this.getTranspotationGroupOptons()}
                            placeholder="TransportGroups..."
                            className="basic-multi-select"
                            onChange={this.handleTranspotataionGroupChange.bind(this)}
                            isMulti
                        />
                    </div>
                </div>
                {(this.props.production.healthSurveySettings.type !==
                    productionConstants.HEALTH_SURVEY_TYPE_NO_SURVEY ||
                    this.props.productionDayRequiresDocuments) && (
                    <div className="row" style={{ marginTop: '15px' }}>
                        {this.props.production.healthSurveySettings.type !==
                            productionConstants.HEALTH_SURVEY_TYPE_NO_SURVEY && (
                            <div className="col-md-2">
                                <Select
                                    options={this.getHealthSurveyStatusesOptions()}
                                    placeholder="Health Survey Status"
                                    className="basic-multi-select"
                                    onChange={this.handleMultiSelectFilterChange.bind(
                                        this,
                                        'healthSurveyStatuses',
                                    )}
                                    isMulti
                                    isClearable
                                />
                            </div>
                        )}
                        {this.props.productionDayRequiresDocuments && (
                            <div className="col-md-2">
                                <Select
                                    options={this.getProductionDocumentStatusesOptions()}
                                    placeholder="Documents Status"
                                    className="basic-multi-select"
                                    onChange={this.handleSelectFilterChange.bind(
                                        this,
                                        'productionDocumentsCompleted',
                                    )}
                                    isClearable
                                    isDisabled={!this.props.extrasDocumentsLoaded}
                                />
                            </div>
                        )}
                    </div>
                )}
            </form>
        );
    };
}

Filter.propTypes = {
    productionUnits: PropTypes.array.isRequired,
    setChitsFilterValue: PropTypes.func.isRequired,
    productionDayExtraRoles: PropTypes.array.isRequired,
    productionDate: PropTypes.string.isRequired,
    production: PropTypes.object.isRequired,
    productionDayRequiresDocuments: PropTypes.bool.isRequired,
    extrasDocumentsLoaded: PropTypes.bool.isRequired,
    allExtraStatuses: PropTypes.array.isRequired,
    transportGroups: PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
};

export default Filter;
