import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { prepLocation } from '../utils';

const API_KEY = process.env.GOOGLE_JAVA_SCRIPT_API_KEY;
const containerStyle = {
    width: '100%',
    height: '400px',
};

const center = {
    lat: -3.745,
    lng: -38.523,
};

export default function GoogleMaps(props) {
    const { location, zoomLevel } = props;
    const latLng = prepLocation(location);

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={location ? latLng : center}
            zoom={zoomLevel}>
            {latLng ? <Marker position={latLng} /> : null}
            {/* Child components, such as markers, info windows, etc. */}
            <></>
        </GoogleMap>
    );
}
