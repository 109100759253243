import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, FloatingLabel, Form, H5 } from 'react-bootstrap';
import PropTypes from 'prop-types';

const NoteInputModal = (props) => {
  const { closeModal, show, notes, setProductionDayNotes, id: productionDayId } = props;
  const [value, setValue] = React.useState(notes);

  React.useEffect(()=> {
    setValue(notes);
  },[notes])

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    setProductionDayNotes(productionDayId, value);
    closeModal();
  });

  return (
    <Modal show={show} onHide={closeModal} size="sm">
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton={closeModal}>
          <Modal.Title>
            <h5>Internal Notes</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={6}
              col={6}
              value={value}
              onChange={onChange}
              placeholder="Enter notes, max 100 characters"
              maxLength={100}
              name="notes"
              // isInvalid={value.notes && value.notes.length == 0}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default w-25" onClick={closeModal}>
            Cancel
          </Button>

          <Button variant="success w-75" type={'submit'}>
            Set Notes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NoteInputModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  notes: PropTypes.string.isRequired,
  setProductionDayNotes: PropTypes.func.isRequired,
};

export default NoteInputModal;
