import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import CentredLayout from '../../app/components/CentredLayout';
import {Button} from 'react-bootstrap';
import firebase from "firebase/compat/app";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './MfaEnrollment.css'

class MfaEnrollment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      recaptchaVerified: false,
      verifyingRecaptcha: false,
      phoneNumber: null,
    }

    this.recaptchaRef = React.createRef();
  }

  handleSubmit = () => {
    this.setState({
      verifyingRecaptcha: true
    });
    this.props.firebaseUser.multiFactor.getSession().then(multiFactorSession => {
      const phoneNumber = this.state.phoneNumber.startsWith('+') ? this.state.phoneNumber : `+${this.state.phoneNumber}`;
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession
      };
      this.props.sendMfaVerificationCodeRequest(phoneInfoOptions, window.recaptchaVerifier)
    })
  }

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({
      phoneNumber: phoneNumber
    })
  }

  componentDidMount() {
    const that = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptchaRef.current, {
      'size': 'invisible',
      'callback': function (response) {
        that.setState({
          recaptchaVerified: true,
          verifyingRecaptcha: false
        })
      },
      'expired-callback': function () {
        console.log('EXPIRED');
      }
    });
  }

  render() {
    const {sendingMfaVerificationCode, error, errorCode, signOutRequest, mfaEnrollmentRequired} = this.props;

    const sendButtonDisabled = sendingMfaVerificationCode || !this.state.phoneNumber || this.state.verifyingRecaptcha;

    return (
      <CentredLayout title="Set Up MFA">
        <div className="phone-input">
        <PhoneInput
          international
          placeholder="Enter phone number"
          value={this.state.phoneNumber}
          onChange={this.handlePhoneNumberChange.bind(this)}/>
        </div>

        {error &&
        <div className="alert alert-danger" style={{marginTop: '10px'}} role="alert">
          {error}
        </div>
        }

        {errorCode !== 'auth/requires-recent-login' &&
        <div style={{marginTop: '15px'}}>
          <div style={{float: 'left'}}>
            <Button variant="primary" disabled={sendButtonDisabled} onClick={this.handleSubmit.bind(this)}>
              {(sendingMfaVerificationCode || this.state.verifyingRecaptcha) &&
              <span>Sending Code...</span>
              }
              {!(sendingMfaVerificationCode || this.state.verifyingRecaptcha) &&
              <span>Send Code</span>
              }
            </Button>
          </div>
          <div style={{float: 'right'}}>
            <Button variant="secondary" onClick={() => this.props.signOutRequest()}>Sign Out</Button>
          </div>
          <div style={{clear: 'both'}}/>
        </div>
        }
        {errorCode === 'auth/requires-recent-login' &&
          <Button variant="primary" onClick={signOutRequest}>Sign In</Button>
        }
        {!mfaEnrollmentRequired &&
        <div style={{marginTop: '10px'}}>
          <a onClick={() => this.props.skipMfaEnrollment()}>Skip</a>
        </div>
        }
        <div ref={this.recaptchaRef}></div>
        </CentredLayout>
    );
  }
}

MfaEnrollment.propTypes = {
  firebaseUser: PropTypes.object.isRequired,
  sendMfaVerificationCodeRequest: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorCode: PropTypes.string,
  sendingMfaVerificationCode: PropTypes.bool.isRequired,
  mfaEnrollmentRequired: PropTypes.bool.isRequired,
  skipMfaEnrollment: PropTypes.func.isRequired
}

export default MfaEnrollment;