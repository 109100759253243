import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import '../ProductionDay.css';
import {EXTRA_STATUS_CHECKED_IN, EXTRA_STATUS_CHECKED_OUT} from '../../constants';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

class ChitRecordUnit extends React.Component {

  constructor(props) {
    super(props);
  }

  handleProductionUnitChange = productionUnit => {
    this.props.assignExtraToProductionUnitRequest(this.props.record.chit.id, productionUnit.value);
  };

  render = () => {
    const {record} = this.props;

    const selectOptions = this.props.productionUnits.map((unit) => ({
      value: unit.id,
      label: unit.name
    }));

    // Changing production units for chits with statuses CHECKED_IN and CHECKED_OUT is not allowed.
    const canChangeProductionUnit =
      record.chit.extraStatus !== EXTRA_STATUS_CHECKED_IN
      && record.chit.extraStatus !== EXTRA_STATUS_CHECKED_OUT;

    const currentValue = selectOptions.find(o => (o.value === record.chit.extraWorkDetails.productionUnitId));
    return (
      <div>
        {canChangeProductionUnit &&
        <Select options={selectOptions}
                value={currentValue}
                classNamePrefix="chit-record-select"
                onChange={this.handleProductionUnitChange}
        />
        }
        {!canChangeProductionUnit &&
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
              Changing production units for chits with statuses CHECKED_IN and CHECKED_OUT is not allowed
            </Tooltip>
          }
        >
        <span>
          {currentValue.label}
        </span>
        </OverlayTrigger>
        }
      </div>
    )
  }
}

ChitRecordUnit.propTypes = {
  record: PropTypes.object.isRequired,
  productionUnits: PropTypes.array.isRequired,
  assignExtraToProductionUnitRequest: PropTypes.func.isRequired
};

export default ChitRecordUnit;

