import {axiosDefault} from '../lib/network';

export const getInitialData = () => {
  return axiosDefault.get(`/v2/initial-data`).then(function (response) {
    return response.data;
  });
};

export const signOut = () => {
  return axiosDefault.post(`/v2/auth/signout`).then(function (response) {
    return response.data;
  });
};

