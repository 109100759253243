import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from 'react-google-places-autocomplete';

const AutoCompleteAddress = (props) => {
    const {
        fieldInfo,
        values,
        setValues,
        name,
        setFieldValue,
        convertGoogleAddressToUvLocationObject,
    } = props;

    const address = values[name];
    useEffect(() => {
        const func = async () => {
            const geocodeObj =
                address && address.value && (await geocodeByPlaceId(address.value.place_id));
            if (geocodeObj) {
                setValues({
                    ...values,
                    ...convertGoogleAddressToUvLocationObject(
                        geocodeObj[0].address_components,
                        {
                            lat: geocodeObj[0].geometry.location.lat(),
                            lng: geocodeObj[0].geometry.location.lng(),
                        },
                        address.label,
                    ),
                });
            }
        };
        func();
    }, [address]);

    return (
            <GooglePlacesAutocomplete
                // apiKey={API_KEY}
                onLoadFailed={(error) => console.error('Could not inject Google script', error)}
                selectProps={{
                    isClearable: true,
                    ...fieldInfo,

                    value: address,

                    onChange: (val) => {
                        setFieldValue(name, val);
                    },
                }}
            />
    );
};

AutoCompleteAddress.propTypes = {
    fieldInfo: PropTypes.object.isRequired,
    name: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    convertGoogleAddressToUvLocationObject: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default AutoCompleteAddress;
