import {
  CANCELLATION_FEE_TYPE_FULL,
  CANCELLATION_FEE_TYPE_PARTIAL,
  CANCELLATION_FEE_TYPE_ZERO
} from '../production-day/constants';
import * as constants from "../production-day/constants";

export const getCancellationFeeTypeLabel = feeType => {
  const map = {
    [CANCELLATION_FEE_TYPE_FULL]: 'Full Cancellation Fee',
    [CANCELLATION_FEE_TYPE_PARTIAL]: 'Partial Cancellation Fee',
    [CANCELLATION_FEE_TYPE_ZERO]: 'Zero Cancellation Fee',
  }

  return map[feeType];
}

const extraStatusToCssClassParts = {
  [constants.EXTRA_STATUS_NO_SHOW]: 'badge-outline-danger',
  [constants.EXTRA_STATUS_BOOKED]: 'badge-outline-secondary',
  [constants.EXTRA_STATUS_CHECKED_IN]: 'badge-outline-success',
  [constants.EXTRA_STATUS_CHECKED_OUT]: 'badge badge-outline-dark',
  [constants.EXTRA_STATUS_CANCELLED]: 'badge-outline-danger',
};

export const getExtraStatusCssClassPart = status => extraStatusToCssClassParts[status];



