import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  setPasswordAndVerifyEmailRequest,
  verifyPasswordResetCodeRequest
} from '../actions';
import OnboardingSetPasswordAndVerifyEmail from './OnboardingSetPasswordAndVerifyEmail';
import OnboardingSetPasswordAndVerifyEmailSuccess from './OnboardingSetPasswordAndVerifyEmailSuccess';
import store from '../../store';
import {getQueryParam} from '../../lib/urlUtils';
import {
  STEP_SET_PASSWORD_AND_VERIFY_EMAIL,
  STEP_SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS,
  STEP_VERIFY_PASSWORD_RESET_CODE
} from '../constants';
import OnboardingVerifyPasswordResetCode from './OnboardingVerifyPasswordResetCode';

class OnboardingContainer extends React.Component {

  constructor(props, context) {
    super(props, context);

    const oobCode = getQueryParam(window.location.search, 'oobCode');

    store.dispatch(verifyPasswordResetCodeRequest(oobCode));
  }

  render() {
    const {currentStep} = this.props;

    return (
      <React.Fragment>
      {currentStep === STEP_VERIFY_PASSWORD_RESET_CODE &&
      <OnboardingVerifyPasswordResetCode {...this.props}  />
      }
      {currentStep === STEP_SET_PASSWORD_AND_VERIFY_EMAIL &&
      <OnboardingSetPasswordAndVerifyEmail {...this.props} />
      }
      {currentStep === STEP_SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS &&
      <OnboardingSetPasswordAndVerifyEmailSuccess {...this.props} />
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const onboardingState = state.onboarding;
  return {
    currentStep: onboardingState.currentStep,
    verifyingPasswordRestCode: onboardingState.verifyingPasswordRestCode,
    passwordResetLinkSent: onboardingState.passwordResetLinkSent,
    settingPasswordAndVerifyingEmail: onboardingState.settingPasswordAndVerifyingEmail,
    error: onboardingState.error,
    oobCode: onboardingState.oobCode,
    user: onboardingState.user
  };
};

const actionCreators = {
  verifyPasswordResetCodeRequest,
  setPasswordAndVerifyEmailRequest
};

export default withRouter(connect(mapStateToProps, actionCreators)(OnboardingContainer));

