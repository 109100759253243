import {call, put, takeEvery} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as api from './api';
import { auth } from '../lib/auth'

export function* verifyPasswordResetCode(action) {
  try {
    const {oobCode} = action;
    console.log(oobCode);
    yield call([auth, auth.verifyPasswordResetCode], oobCode)
    yield put(actions.verifyPasswordResetCodeSuccess());
  } catch (error) {
    console.log(error);
    const message = error.message ? error.message : 'Unknown error';
    yield put(actions.verifyPasswordResetCodeFailure(message));
  }
}

export function* setPasswordAndVerifyEmail(action) {
  try {
    const {oobCode, password} = action;
    yield call([auth, auth.confirmPasswordReset], oobCode, password)
    const user = yield call(api.verifyEmail, oobCode)
    yield put(actions.setPasswordAndVerifyEmailSuccess(user));
  } catch (error) {
    console.log(error);
    const message = error.message ? error.message : 'Unknown error';
    yield put(actions.setPasswordAndVerifyEmailFailure(message));
  }
}

export default function* sagas() {
  yield takeEvery(actionTypes.VERIFY_PASSWORD_RESET_CODE_REQUEST, verifyPasswordResetCode);
  yield takeEvery(actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_REQUEST, setPasswordAndVerifyEmail);
}