import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../../constants';
import ProductionExternalSurveyDocumentUpload from './ProductionExternalSurveyDocumentUpload';
import './Survey.css';

class ProductionExternalSurvey extends React.Component {
  constructor(props) {
    super(props);
  }

  setProductionExternalSurveyType = (type, productionExternalSurvey) => {
    let data = {};
    if (type === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL) {
      data = {url: ''};
    } else if (type === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT) {
      data = {url: ''};
    }
    const updatedProductionExternalSurvey = {
      ...productionExternalSurvey,
      type,
      data
    }

    this.props.onProductionExternalSurveyChange(updatedProductionExternalSurvey);
  }

  setProductionExternalSurveyExternalUrl = (event, productionExternalSurvey) => {
    const url = event.target.value;

    const updatedProductionExternalSurvey = {
      ...productionExternalSurvey,
        data: {
          ...productionExternalSurvey.data,
          url
        }
    };

    this.props.onProductionExternalSurveyChange(updatedProductionExternalSurvey);
  }

  setProductionExternalSurveyUploadedDocument = (productionExternalSurveyUploadedDocument, productionExternalSurvey) => {
    const updatedProductionExternalSurvey = {
      ...productionExternalSurvey,
      data: {
        ...productionExternalSurvey.data,
        url: productionExternalSurveyUploadedDocument.url
      }
    };

    this.props.onProductionExternalSurveyChange(updatedProductionExternalSurvey);
  }

  render = () => {
    const productionExternalSurvey = this.props.productionExternalSurvey || {
      type: constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL,
      data: {
        url: ''
      }
    };

    const productionExternalSurveyType = productionExternalSurvey.type;
    return (
      <div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="productionExternalSurveyType"
                 id="productionExternalSurveyTypeExternalUrl"
                 value={constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL}
                 checked={productionExternalSurveyType === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL}
                 onChange={this.setProductionExternalSurveyType.bind(this, constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL, productionExternalSurvey)}
          />
          <label className="form-check-label" htmlFor="productionExternalSurveyTypeExternalUrl">External Url</label>
          {productionExternalSurveyType === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL &&
          <div className="form-group">
            <input type="text" className="form-control" placeholder="External url"
                   onChange={e => this.setProductionExternalSurveyExternalUrl(e, productionExternalSurvey)}
                   value={productionExternalSurvey.data.url}
            />
          </div>
          }
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="productionExternalSurveyType"
                 id="productionExternalSurveyTypeUploadedDocument"
                 value={constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT}
                 checked={productionExternalSurveyType === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT}
                 onChange={this.setProductionExternalSurveyType.bind(this, constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT, productionExternalSurvey)}
          />
          <label className="form-check-label" htmlFor="productionExternalSurveyTypeUploadedDocument">Upload
            Document</label>
          {productionExternalSurveyType === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT &&
          <div className="production-external-survey-upload-document-container">
            <ProductionExternalSurveyDocumentUpload
              {...this.props}
              url={productionExternalSurvey.data.url}
              onChange={data => this.setProductionExternalSurveyUploadedDocument(data, productionExternalSurvey)}
            />
          </div>
          }
        </div>
      </div>
    )
  }
}

ProductionExternalSurvey.propTypes = {
  productionExternalSurvey: PropTypes.object,
  productionId: PropTypes.number.isRequired,
  onProductionExternalSurveyChange: PropTypes.func
};

export default ProductionExternalSurvey;
