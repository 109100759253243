import React from 'react';
import PropTypes from 'prop-types';
import PaySchemeItem from './PaySchemeItem';

class PaySchemeDetails extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const { payScheme } = this.props;
    const items = payScheme.items.map((item, index) => <PaySchemeItem key={index} item={item} />)
    return (
      <div>
        <div className="card p-3 mb-2">
          <h5 className="m-0">{payScheme.title}</h5>
          <span className="m-0">{payScheme.description}</span>
        </div>
        {items}
      </div>

    )
  }
}

PaySchemeDetails.propTypes = {
  payScheme: PropTypes.object.isRequired,
};

export default PaySchemeDetails;
