import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from './api';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { arrayToMap } from '../lib/arrayUtils';
import * as notification from '../lib/notification';
import * as constants from './constants';
/**
 * Convert list of chit records to map.
 */
const convertChitRecordsToMap = (chitRecords) =>
    arrayToMap(chitRecords, (record) => record.chit.id);

/**
 * Convert lists in data to maps.
 */
const convertLoadProductionDayAggregatedDataToMaps = (data) => {
    const productionDays = arrayToMap(data.productionDays, 'id');
    const productionUnits = arrayToMap(data.productionUnits, 'id');
    const chitRecords = convertChitRecordsToMap(data.chitRecords);

    // Leave other data as is, replacing arrays with maps
    return {
        ...data,
        productionDays,
        productionUnits,
        chitRecords,
    };
};

const convertBulkActionResultsToMap = (bulkActionResults) =>
    arrayToMap(bulkActionResults, 'chitId');

const convertExtrasDocumentsToMapAndAddGeneralCompletionStatus = (extrasDocuments) => {
    const withAdditionalInfo = extrasDocuments.map((documents) => {
        const allCompleted = documents.documents.filter((doc) => !doc.completed).length === 0;
        return {
            ...documents,
            allCompleted,
        };
    });

    return arrayToMap(withAdditionalInfo, (extraDocument) => extraDocument.extraId);
};

const convertBulkActionNotesMakeChitObject = (responseArray, notes) => {
    return responseArray.reduce((acc, next) => {
        if (next.result == 'success') {
            acc[next.chitId] = { ...next, generalNotes: notes };
        } else {
            // failure happend dont update the previous notes
            acc[next.chitId] = next;
        }

        return acc;
    }, {});
};

export function* loadChitRecords(action) {
    try {
        const { productionId, date } = action;
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.loadChitRecordsSuccess(convertedChitRecords));
    } catch (error) {
        yield put(actions.loadChitRecordsFailure(error));
    }
}

export function* loadProductionDayDayAggregatedData(action) {
    try {
        const { productionId, date } = action;
        const data = yield call(api.getProductionDayAggregateData, productionId, date);
        const convertedData = convertLoadProductionDayAggregatedDataToMaps(data);
        yield put(actions.loadProductionDayDataSuccess(convertedData));
    } catch (error) {
        yield put(actions.loadProductionDayDataFailure(error));
    }
}

export function* wrapUpProductionUnitOnDay(action) {
    try {
        const { productionDayId, productionUnitId } = action;
        const data = yield call(api.wrapUpProductionUnitOnDay, productionDayId, productionUnitId);
        yield put(actions.wrapUpProductionUnitOnDaySuccess(data));
        notification.success('Production unit has been successfully closed');
    } catch (error) {
        yield put(actions.wrapUpProductionUnitOnDayFailure(error));
    }
}

export function* generateProductionUnitCurrentReport(action) {
    try {
        const { productionDayId, productionUnitId } = action;
        const data = yield call(
            api.generateProductionUnitCurrentReport,
            productionDayId,
            productionUnitId,
        );
        yield put(actions.generateProductionUnitCurrentReportSuccess(data));
        notification.success('Report has been successfully generated');
    } catch (error) {
        yield put(actions.generateProductionUnitCurrentReportFailure(error));
    }
}

export function* generateCurrentReport(action) {
    try {
        const { productionId, date } = action;
        const data = yield call(api.generateCurrentReport, productionId, date);
        yield put(actions.generateCurrentReportRequestSuccess(data));
        notification.success('Report has been successfully generated');
    } catch (error) {
        yield put(actions.generateCurrentReportRequestFailure(error));
    }
}

export function* setChitCallTime(action) {
    try {
        const { chitId, callTime } = action;
        const updatedChit = yield call(api.setChitCallTime, chitId, callTime);
        const updatedChitRecord = yield call(api.getChitRecord, chitId);
        notification.success('Call time has been successfully set');
        yield put(actions.setChitCallTimeSuccess(updatedChitRecord));
    } catch (error) {
        yield put(actions.setChitCallTimeFailure(error));
    }
}

export function* setChitsCallTime(action) {
    const { chitIds, callTime, productionId, date } = action;
    try {
        const result = yield call(api.setChitsCallTime, chitIds, callTime);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.setChitsCallTimeSuccess(convertedResults, convertedChitRecords));
        notification.success('Call time for chits has been set');
    } catch (error) {
        yield put(actions.setChitsCallTimeFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* assignExtraToProductionUnit(action) {
    try {
        const { chitId, productionUnitId } = action;
        const updatedChit = yield call(api.assignExtraToProductionUnit, chitId, productionUnitId);
        const updatedChitRecord = yield call(api.getChitRecord, chitId);
        notification.success('Extra has been successfully assigned to production unit');
        yield put(actions.assignExtraToProductionUnitSuccess(updatedChitRecord));
    } catch (error) {
        yield put(actions.assignExtraToProductionUnitFailure(error));
    }
}

export function* assignExtrasToProductionUnit(action) {
    const { chitIds, productionUnitId, productionId, date } = action;
    try {
        const result = yield call(api.assignExtrasToProductionUnit, chitIds, productionUnitId);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(
            actions.assignExtrasToProductionUnitSuccess(convertedResults, convertedChitRecords),
        );
        notification.success('Extras has been assigned to production unit');
    } catch (error) {
        yield put(actions.assignExtrasToProductionUnitFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* addSupplementaryFeeToChits(action) {
    const { chitIds, paySchemeId, feeName, payRate, productionId, date } = action;
    try {
        const result = yield call(
            api.addSupplementaryFeeToChits,
            chitIds,
            paySchemeId,
            feeName,
            payRate,
        );
        // Just change error message if chit's pay scheme does not match unit's one
        const resultsWithAdjustedMessage = result.results.map((actionResult) => {
            if (
                actionResult.result === 'failure' &&
                actionResult.message.includes('Pay scheme of the chit') &&
                actionResult.message.includes(
                    'is not the same as the pay scheme the supplementary fee being added is part of',
                )
            ) {
                actionResult.message =
                    'Whoops - it looks like the pay scheme for this chit is not the same as the current pay scheme for that unit. Please edit chit individually.';
            }
            return actionResult;
        });
        const convertedResults = convertBulkActionResultsToMap(resultsWithAdjustedMessage);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(
            actions.addSupplementaryFeeToChitsSuccess(convertedResults, convertedChitRecords),
        );
        notification.success('Supplementary fee has been added to chits');
    } catch (error) {
        yield put(actions.addSupplementaryFeeToChitsFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* changeDailyRateOfChits(action) {
    const { chitIds, paySchemeId, rateName, productionId, date } = action;
    try {
        const result = yield call(api.changeDailyRateOfChits, chitIds, paySchemeId, rateName);
        // Just change error message if chit's pay scheme does not match unit's one
        const resultsWithAdjustedMessage = result.results.map((actionResult) => {
            if (
                actionResult.result === 'failure' &&
                actionResult.message.includes('Pay scheme of the chit') &&
                actionResult.message.includes(
                    'is not the same as the pay scheme the daily rate being set is part of',
                )
            ) {
                actionResult.message =
                    'Whoops - it looks like the pay scheme for this chit is not the same as the current pay scheme for that unit. Please edit chit individually.';
            }
            return actionResult;
        });

        const convertedResults = convertBulkActionResultsToMap(resultsWithAdjustedMessage);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        notification.success('Chits primary rate has been changed');
        yield put(actions.changeDailyRateOfChitsSuccess(convertedResults, convertedChitRecords));
    } catch (error) {
        yield put(actions.changeDailyRateOfChitsFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* setExtrasBookingDateDetails(action) {
    const { chitIds, bookingDateDetails, productionId, date } = action;
    try {
        const result = yield call(api.setExtrasBookingDateDetails, chitIds, bookingDateDetails);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(
            actions.setExtrasBookingDateDetailsSuccess(convertedResults, convertedChitRecords),
        );
        // Switch tab to booking date details in chits view page if on another tab.
        yield put(actions.setChitsActiveTab(constants.CHITS_TAB_BOOKING_DATE_DETAILS));
        notification.success('Call details has been set');
        // if (!sendSMS) {
        //notification.success('Booking date details has been set');
        // }

        // if (sendSMS && template) {
        //     const { results } = yield call(api.sendingSMS, { chitIds, template });
        //     yield put(actions.sendingSMSSuccess(arrayToMap(results,'chitId')))
        //     //refershing chit records...
        //     yield put(actions.loadChitRecordsRequest(productionId, date));
        //     notification.success('Booking date details and SMS has been sent');
        // }
    } catch (error) {
        yield put(actions.setExtrasBookingDateDetailsFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* cancelChits(action) {
    const { chitIds, cancellationFeeType, productionId, date } = action;
    try {
        const result = yield call(api.cancelChits, chitIds, cancellationFeeType);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.cancelChitsSuccess(convertedResults, convertedChitRecords));
        notification.success('Booking date details for has been set');
    } catch (error) {
        yield put(actions.cancelChitsFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* setExtrasStatuses(action) {
    const { chitIds, extraStatus, details, productionId, date } = action;
    try {
        const result = yield call(api.setExtrasStatus, chitIds, extraStatus, details);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.setExtrasStatusesSuccess(convertedResults, convertedChitRecords));
        notification.success('Extras statuses have been change');
    } catch (error) {
        yield put(actions.setExtrasStatusesFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* removeSupplementaryFees(action) {
    const { chitIds, productionId, date } = action;
    try {
        const result = yield call(api.removeSupplementaryFeesChits, chitIds);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.removeSupplementaryFeesSuccess(convertedResults, convertedChitRecords));
        notification.success('Supplementary fees have been set');
    } catch (error) {
        yield put(actions.removeSupplementaryFeesFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* sendBreakdownEmails(action) {
    const { chitIds, productionId, date } = action;
    try {
        const result = yield call(api.sendBreakdownEmails, chitIds);
        const convertedResults = convertBulkActionResultsToMap(result.results);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.sendBreakdownEmailsSuccess(convertedResults, convertedChitRecords));
        notification.success('Breakdown emails has been sent');
    } catch (error) {
        yield put(actions.sendBreakdownEmailsFailure(error));
        // Update chits records anyway
        yield put(actions.loadChitRecordsRequest(productionId, date));
    }
}

export function* resetHealthSurveyResult(action) {
    try {
        const { chitId } = action;
        const updatedChit = yield call(api.resetHealthSurveyResult, chitId);
        const updatedChitRecord = yield call(api.getChitRecord, chitId);
        notification.success(`Extra's health result has been reset successfully`);
        yield put(actions.resetHealthSurveyResultSuccess(updatedChitRecord));
    } catch (error) {
        yield put(actions.resetHealthSurveyResultFailure(error));
    }
}

export function* getHealthSurveyResultDetails(action) {
    try {
        const { chitId } = action;
        const healthSurveyResultDetails = yield call(api.getHealthSurveyResultDetails, chitId);
        yield put(actions.getHealthSurveyResultDetailsSuccess(healthSurveyResultDetails));
    } catch (error) {
        yield put(actions.getHealthSurveyResultDetailsFailure(error));
    }
}

export function* getExtrasDocuments(action) {
    try {
        const { productionId, date } = action;
        const extrasDocuments = yield call(api.getExtrasDocuments, productionId, date);
        const convertedExtrasDocuments =
            convertExtrasDocumentsToMapAndAddGeneralCompletionStatus(extrasDocuments);
        yield put(actions.getExtrasDocumentsSuccess(convertedExtrasDocuments));
    } catch (error) {
        yield put(actions.getExtrasDocumentsFailure(error));
    }
}

export function* setProductionDayRelease(action) {
    try {
        const { productionDayId } = action;
        const { data: currentData } = yield call(api.setProductionDayRelease, productionDayId);
        notification.success(`ProductionDay has been Released to Client`);
        yield put(actions.setProductionDayReleaseSuccess(currentData));
    } catch (error) {
        console.error(error);
        yield put(actions.setProductionDayReleaseFailure(error));
    }
}

export function* setProductionNotes(action) {
    try {
        const { productionId, notes } = action;
        const extrasProductionNotes = yield call(api.setProductionNotes, productionId, notes);
        notification.success(`Production notes has been saved successfully`);
        yield put(actions.setProductionNotesSuccess(extrasProductionNotes));
    } catch (error) {
        yield put(actions.setProductionNotesFailure(error));
    }
}

export function* setProductionDayNotes(action) {
    try {
        const { productionDayId, notes } = action;
        const { id: savedProductionDayId, notes: savedNotes } = yield call(
            api.setProductionDayNotes,
            productionDayId,
            notes,
        );
        notification.success(`Production Day notes has been saved successfully`);
        yield put(actions.setProductionDayNotesSuccess(savedProductionDayId, savedNotes));
    } catch (error) {
        console.error(error);
        notification.error(`Something went wrong while saving productionday notes `);
        yield put(actions.setProductionDayNotesFailure(error));
    }
}

export function* setIndividualChitNotes(action) {
    try {
        const { chitId, generalNotes } = action;
        const { id: savedChitId, generalNotes: savedGeneralnotes } = yield call(
            api.setIndividualChitNotes,
            chitId,
            generalNotes,
        );
        notification.success(`Notes has been saved successfully`);
        yield put(actions.setChitIndividualNotesRequestSuccess(savedChitId, savedGeneralnotes));
    } catch (error) {
        console.log(error);
        yield put(actions.setChitIndividualNotesRequestFailure(error));
    }
}

export function* setBulkChitNotesRequest(action) {
    try {
        const { chitIds, generalNotes } = action;
        const { results } = yield call(api.setBulkChitNotesRequest, chitIds, generalNotes);
        yield put(
            actions.setBulkChitNotesRequestSuccess(
                convertBulkActionNotesMakeChitObject(results, generalNotes),
            ),
        );
        notification.success(`Notes  has been saved successfully`);
    } catch (error) {
        console.error(error);
        yield put(actions.setBulkChitNotesRequestFailure(error));
    }
}

export function* setChitLevelTravelNotesRequest(action) {
    try {
        const { chitId, data } = action;
        const result = yield call(api.setChitLevelTravelNotes, chitId, data);
        yield put(actions.setChitLevelTravelNotesSuccess(result));
        notification.success(`Travel Notes  has been saved successfully`);
    } catch (error) {
        console.log(error);
        yield put(actions.setChitLevelTravelNotesFailure(error));
    }
}

export function* setBulkChitTravelNotes(action) {
    try {
        const { chitIds, travelInfo } = action;
        const { results } = yield call(api.setChitLevelTravelBulkNotes, chitIds, travelInfo);
        yield put(actions.setBulkChitTravelNotesSuccess(results, travelInfo));
    } catch (error) {
        console.log(error);
        yield put(actions.setBulkChitTravelNotesFailure(error));
    }
}

export function* setProductionDayDataExportRequest(action) {
    try {
        const {
            payload: { label, ...rest },
        } = action;
        const data = yield call(api.setProductionDayDataExportRequest, rest);
        notification.success(`Booking forms report for ${label}  successfully dispatched`);
    } catch (error) {
        console.error(error);
    }
}

export function* sendingSMS(action) {
    try {
        const { productionId, date, ...smsData } = action.payload;
        const { results } = yield call(api.sendingSMS, smsData);
        const chitRecords = yield call(api.getChitRecords, productionId, date);
        const convertedChitRecords = convertChitRecordsToMap(chitRecords);
        yield put(actions.sendingSMSSuccess(arrayToMap(results, 'chitId'),convertedChitRecords))
        yield put(actions.setChitsActiveTab(constants.CHITS_TAB_CALL_DETAILS));

    } catch (error) {
        yield put(actions.sendingSMSFailure(error));
        console.log(error);
    }
}

export function* setCallDetailsResponse(action) {
    try {
        const { chitId, result } = action;
        const response = yield call(api.setCallDetailsResponse, chitId, result);
        yield put(actions.setChitCallDetailsResponseSuccess(response));
    } catch (error) {
        yield put(actions.setChitCallDetailsResponseFailure(error));
        console.error(error);
    }
}

export default function* sagas() {
    yield takeEvery(actionTypes.SEND_SMS, sendingSMS);
    yield takeEvery(actionTypes.LOAD_CHIT_RECORDS_REQUEST, loadChitRecords);
    yield takeEvery(
        actionTypes.LOAD_PRODUCTION_DAY_AGGREGATED_DATA_REQUEST,
        loadProductionDayDayAggregatedData,
    );
    yield takeEvery(actionTypes.WRAP_UP_PRODUCTION_UNIT_ON_DAY_REQUEST, wrapUpProductionUnitOnDay);
    yield takeEvery(
        actionTypes.GENERATE_PRODUCTION_UNT_CURRENT_REPORT_REQUEST,
        generateProductionUnitCurrentReport,
    );
    yield takeEvery(
        actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_REQUEST,
        setProductionDayDataExportRequest,
    );
    yield takeEvery(actionTypes.GENERATE_CURRENT_REPORT_REQUEST, generateCurrentReport);
    yield takeEvery(actionTypes.SET_CHIT_CALL_TIME_REQUEST, setChitCallTime);
    yield takeEvery(actionTypes.SET_CHITS_CALL_TIME_REQUEST, setChitsCallTime);
    yield takeEvery(
        actionTypes.ASSIGN_EXTRA_TO_PRODUCTION_UNIT_REQUEST,
        assignExtraToProductionUnit,
    );
    yield takeEvery(
        actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_REQUEST,
        assignExtrasToProductionUnit,
    );
    yield takeEvery(actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_REQUEST, addSupplementaryFeeToChits);
    yield takeEvery(actionTypes.CHANGE_DAILY_RATE_OF_CHITS_REQUEST, changeDailyRateOfChits);
    yield takeEvery(
        actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_REQUEST,
        setExtrasBookingDateDetails,
    );
    yield takeEvery(actionTypes.CANCEL_CHITS_REQUEST, cancelChits);
    yield takeEvery(actionTypes.SET_EXTRAS_STATUSES_REQUEST, setExtrasStatuses);
    yield takeEvery(actionTypes.REMOVE_SUPPLEMENTARY_FEES_REQUEST, removeSupplementaryFees);
    yield takeEvery(actionTypes.SEND_BREAKDOWN_EMAILS_REQUEST, sendBreakdownEmails);
    yield takeEvery(actionTypes.RESET_HEALTH_SURVEY_RESULT_REQUEST, resetHealthSurveyResult);
    yield takeEvery(
        actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_REQUEST,
        getHealthSurveyResultDetails,
    );
    yield takeEvery(actionTypes.GET_EXTRAS_DOCUMENTS_REQUEST, getExtrasDocuments);
    yield takeEvery(actionTypes.SET_PRODUCTION_NOTES, setProductionNotes);
    yield takeEvery(actionTypes.SET_PRODUCTION_DAY_NOTES, setProductionDayNotes);
    yield takeEvery(actionTypes.SET_INDIVIDUAL_CHIT_NOTES, setIndividualChitNotes);
    yield takeEvery(actionTypes.SET_BULK_CHIT_NOTES, setBulkChitNotesRequest);
    yield takeEvery(
        actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_REQUEST,
        setChitLevelTravelNotesRequest,
    );
    yield takeEvery(actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_REQUEST, setBulkChitTravelNotes);
    yield takeEvery(actionTypes.SET_PRODUCTION_DAY_RELEASE_REQUEST, setProductionDayRelease);
    yield takeEvery(actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_REQUEST, setCallDetailsResponse);
}
