import React from 'react';
import PropTypes from 'prop-types';
import {getMfaStatusLabel} from '../getters';
import {
  MFA_STATUS_ENABLED,
  MFA_STATUS_NOT_ENABLED, MFA_STATUS_UNKNOWN,
} from '../constants';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

class UserRecordMfaInfo extends React.Component {
  getStatusLabelClass = (status) => {
    switch (status) {
      case MFA_STATUS_NOT_ENABLED:
        return 'text-secondary';
      case MFA_STATUS_ENABLED:
        return 'text-success';
      case MFA_STATUS_UNKNOWN:
        return 'text-danger';
      default:
        return 'text-primary';
    }
  };


  render() {
    const {user} = this.props;
    const {mfaInfo} = user;
    const statusLabelClass = this.getStatusLabelClass(mfaInfo.status);

    const enrolledFactors = mfaInfo.enrolledFactors.map((factor, index) => {
      return (
        <div key={index} style={{marginTop: '10px'}}>
          <div><span className="text-sm-left text-secondary" style={{fontSize: "12px"}}>Phone Number: {factor.phoneNumber}</span></div>
          <div><span className="text-sm-left text-secondary" style={{fontSize: "12px"}}>Enrollment Time: {factor.enrollmentTime}</span></div>
          <div><span className="text-sm-left text-secondary" style={{fontSize: "12px"}}>Factor Id: {factor.factorId}</span></div>
        </div>
      )
    })

    return (
      <React.Fragment>
        {user.mfaRequired &&
          <span>Required</span>
        }
        {!user.mfaRequired &&
        <span>Not Required</span>
        }
        <div>
          <span className={statusLabelClass}>{getMfaStatusLabel(mfaInfo.status)}</span>
        </div>
      </React.Fragment>
    )
  }
}

UserRecordMfaInfo.propTypes = {
  user: PropTypes.object.isRequired
,
};

export default UserRecordMfaInfo;