import PropTypes from 'prop-types';
import React from 'react';
import { dateTimeToUveTime, dateToUveWithoutWeekFormat } from '../../../lib/dateTimeUtils';
import { SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS } from '../../constants';
import CallDetailsResponseBtnCol from './CallDetailsResponseBtn';

class ChitRecordCallDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    getCallDetailDisplayLabel = (callDetailsType) => {
        if (!callDetailsType) return null;
        if (callDetailsType === SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS) return 'Call Details';

        return callDetailsType;
    };

    render = () => {
        const {
            record: {
                chit: { callDetails },
            },
        } = this.props;

        if (callDetails) {
            const callDetailsTypeLabel = this.getCallDetailDisplayLabel(callDetails.callDetailsType);
            const sentAtDate = dateToUveWithoutWeekFormat(callDetails.sentAt);
            const sentAtTime = dateTimeToUveTime(callDetails.sentAt);

            let respondAtDate = '';
            let respondAtTime = '';
            const respondAt = callDetails.response?.respondedAt;
            if (respondAt) {
                respondAtDate = dateToUveWithoutWeekFormat(respondAt);
                respondAtTime = dateTimeToUveTime(respondAt);
            }

            return (
                <React.Fragment>
                    <td className="cd-record chit-column-type">
                        <span>{callDetailsTypeLabel}</span>
                    </td>
                    <CallDetailsResponseBtnCol {...this.props} />
                    <td className="cd-record chit-column-date-sent">
                        <span>{sentAtDate}</span>
                    </td>
                    <td className="cd-record chit-column-time-sent">
                        <span>{sentAtTime}</span>
                    </td>
                    <td className="cd-record chit-column-response-date">
                        <span>{respondAtDate}</span>
                    </td>
                    <td className="cd-record chit-column-response-time">
                        <span>{respondAtTime}</span>
                    </td>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <td className="cd-record chit-column-type"></td>
                    <td className="cd-record chit-column-response"></td>
                    <td className="cd-record chit-column-date-sent"></td>
                    <td className="cd-record chit-column-time-sent"></td>
                    <td className="cd-record chit-column-response-date"></td>
                    <td className="cd-record chit-column-response-time"></td>
                </React.Fragment>
            );
        }
    };
}

ChitRecordCallDetails.propTypes = {
    record: PropTypes.object.isRequired,
    showHideCallDetailsWindow: PropTypes.func.isRequired,
};

export default ChitRecordCallDetails;
