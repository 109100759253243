import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, ModalFooter, Container, Row, Col } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
    dateTimeToHoursAndMinutes,
    momentToDateTime,
    parseFromDateTime,
} from '../../../lib/dateTimeUtils';
import TimePicker from 'rc-time-picker';
import { TRAVE_TYPE_DRIVE, TRAVE_TYPE_LIFT, TRAVE_TYPE_PUBLIC } from '../../constants';
import Loader from 'react-loader-spinner';
// import * as getters from '../../../getters';
import Select from 'react-select';
import * as Yup from 'yup';

// import Error from '../../../../common/global-error/Error';

const validationSchema = Yup.object().shape({
    type: Yup.object()
        .shape({
            label: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
        })
        .required('Required'),
});
const TextArea = ({ field, form, ...props }) => {
    return <textarea {...field} {...props} />;
};

const TravelNotes = (props) => {
    const travelValueOptions = [
        { value: TRAVE_TYPE_PUBLIC, label: 'Public' },
        { value: TRAVE_TYPE_DRIVE, label: 'Drive' },
        { value: TRAVE_TYPE_LIFT, label: 'Lift' },
    ];
    const {
        record = { chit: {} },
        showHideChitLevelTravelNotes,
        setChitLevelTravelNotesRequest,
        productionDayShowHideTravelNotesChitId,
        productionDayTravelNotesError,
        productionDayTravelNotesNewtworkCallRunning,
        transportGroups = {},
        bulkShow = false,
        isBulk = false,
        onSubmitBulk = () => {},
        selectedItems = [],
        bulkRunning = false,
        InfoComponent,
    } = props;
    const { travelInfo } = record.chit;

    const getInitialState = () => {
        const initialState = {
            type: { label: '', value: '' },
            transportGroupId: { label: '', value: '' },
            pickUpTime: parseFromDateTime(`${props.productionDate}T07:00:00`),
            notes: '',
        };

        if (!isBulk) {
            if (travelInfo) {
                const { type, data = { notes: '', transportGroupId: '', pickUpTime: '' } } =
                    travelInfo;
                const { notes = '', transportGroupId = '', pickUpTime = '' } = data;
                initialState.type =
                    (type && travelValueOptions.find((obj) => obj.value == type)) ||
                    initialState.type;
                if (transportGroupId) {
                    const { id = '', name = '' } = transportGroups[transportGroupId] || {
                        id: '',
                        name: '',
                    };
                    initialState.transportGroupId = { label: name, value: id };
                }
                if (pickUpTime) {
                    initialState.pickUpTime = parseFromDateTime(`${pickUpTime}T07:00:00`);
                }
                if (notes) {
                    initialState.notes = notes;
                }
            }
        }

        return initialState;
    };

    const checkLoaderStatus = () => {
        if (isBulk && bulkRunning) {
            return true;
        }
        if (!isBulk && productionDayTravelNotesNewtworkCallRunning) {
            return true;
        }
        return false;
    };

    const getLabelValueForTransportGroups = () => {
        return Object.keys(transportGroups).map((transportGroupId) => ({
            value: transportGroupId,
            label: transportGroups[transportGroupId].name,
        }));
    };

    //state

    const onPickupTimeChange = (values, setField) => (value) => {
        if (values.type.value === TRAVE_TYPE_DRIVE || values.type.value === TRAVE_TYPE_LIFT) {
            return;
        }
        setField('pickUpTime', value);
    };

    const onTranspotationGroupChange = (values, setField) => (obj) => {
        if (values.type.value === TRAVE_TYPE_DRIVE || values.type.value === TRAVE_TYPE_LIFT) {
            return;
        }
        setField('transportGroupId', obj);
        // setState((prev) => ({ ...prev, transportGroupId: obj }));
    };

    const disableFieldBasedonTravelType = (values) => {
        return values.type.value !== TRAVE_TYPE_PUBLIC
    };
    const onTransPortTypeChange = (values, setValues) => (obj) => {
        if (obj.value == TRAVE_TYPE_DRIVE || obj.value == TRAVE_TYPE_LIFT) {
            setValues({
                ...values,
                transportGroupId: { label: '', value: '' },
                pickUpTime: parseFromDateTime(`${props.productionDate}T07:00:00`),
                type: obj,
            });
            return;
        }
        setValues({ ...values, type: obj });
    };

    const toggleModal = () => {
        if (!isBulk) {
            showHideChitLevelTravelNotes(record.chit.id);
            return;
        }

        props.toggleModal();
    };

    const getTravelButtonName = () => {
        if (!isBulk) {
            if (travelInfo) {
                if (travelInfo.type == TRAVE_TYPE_PUBLIC) {
                    if (!(travelInfo.data.transportGroupId && travelInfo.data.pickUpTime)) {
                        return <span className="text-uppercase">{travelInfo.type}</span>;
                    }
                    return (
                        <span>{`${dateTimeToHoursAndMinutes(travelInfo.data.pickUpTime)}  ${
                            (transportGroups[travelInfo.data.transportGroupId] &&
                                transportGroups[travelInfo.data.transportGroupId].name) ||
                            'Transport data missing...'
                        }`}</span>
                    );
                }

                return <span className="text-uppercase">{travelInfo.type}</span>;
            }

            return 'Travel';
        } else {
            return `Set Travel..`;
        }
    };

    const handleSubmit = (data) => {
        const { type, transportGroupId, pickUpTime, notes } = data;
        const isPublic = type.value === TRAVE_TYPE_PUBLIC;
        const preparedData = {
            type: type.value,
            data: {
                transportGroupId: transportGroupId.value.length > 0 ? transportGroupId.value : null,
                pickUpTime: pickUpTime ? momentToDateTime(pickUpTime) : null,
                notes,
            },
        };
        if (!isBulk) {
            setChitLevelTravelNotesRequest(record.chit.id, preparedData);
        } else {
            onSubmitBulk(preparedData);
        }
    };

    const showModal = () => {
        if (!isBulk) {
            return productionDayShowHideTravelNotesChitId &&
                record.chit.id &&
                productionDayShowHideTravelNotesChitId == record.chit.id
                ? true
                : false;
        }
        if (isBulk) {
            return bulkShow;
        }

        return false;
    };

    // const getValidationFor = (fieldName) => {
    //     return {
    //         type: state.type.value,
    //         pickUpTime: state.type.value == TRAVE_TYPE_PUBLIC ? true && state.pickUpTime : true,
    //         transportGroupId: true,
    //     }[fieldName];
    // };

    // const checkAllValidations = () => {
    //     return (
    //         getValidationFor('type') &&
    //         getValidationFor('pickUpTime') &&
    //         getValidationFor('transportGroupId')
    //     );
    // };

    return (
        <React.Fragment>
            <div>
                {/* <div className="travel-note-info form-control input-group input-group-sm mb-3"  onClick={toggleModal}>{getTravelButtonName}</div> */}
                {!isBulk && (
                    <Button
                        type="text"
                        onClick={toggleModal}
                        variant="outline-secondary w-100"
                        size="sm">
                        {getTravelButtonName()}
                    </Button>
                )}
                <Modal show={showModal()} onHide={toggleModal} size="sm">
                    <Formik
                        initialValues={getInitialState()}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={handleSubmit}
                        render={({
                            errors,
                            status,
                            touched,
                            isSubmitting,
                            values,
                            setValues,
                            setFieldValue,
                        }) => {
                            const hasType = !!values.type.value;
                            const isPublic = values.type.value === TRAVE_TYPE_PUBLIC;
                            return (
                                <Form>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Travel</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container className="m-0 p-0">
                                            <Row className="m-0">
                                                <Col md={10}>
                                                    <div className="form-group">
                                                        <label htmlFor="production-document-travel-value">
                                                            Travel Value
                                                        </label>
                                                        <Select
                                                            name={'type'}
                                                            options={travelValueOptions}
                                                            placeholder="Travel Value"
                                                            onChange={onTransPortTypeChange(
                                                                values,
                                                                setValues,
                                                            )}
                                                            isDisabled={checkLoaderStatus()}
                                                            value={values['type']}
                                                        />
                                                        {/* <ErrorMessage
                                                        name={'type'}
                                                        component="div"
                                                        className={`field-error`}
                                                    /> */}
                                                    </div>
                                                </Col>
                                                <Col md={12} className="m-0 p-0">
                                                    <hr
                                                        className="dropdown-divider"
                                                        role="separator"
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <div className="form-group">
                                                        <label htmlFor="production-document-travel-group">
                                                            Transport Group
                                                        </label>
                                                        {/* <Field 
                          component={NewSelect} 
                          className="form-control"
                          name="transportGroupId" 
                          id="production-document-travel-group"
                          
                          /> */}
                                                        <Select
                                                            name="transportGroupId"
                                                            options={getLabelValueForTransportGroups()}
                                                            placeholder="Travel Group"
                                                            onChange={onTranspotationGroupChange(
                                                                values,
                                                                setFieldValue,
                                                            )}
                                                            isDisabled={
                                                                disableFieldBasedonTravelType(
                                                                    values,
                                                                ) || checkLoaderStatus()
                                                            }
                                                            value={values.transportGroupId}
                                                        />
                                                        <ErrorMessage
                                                            name={'transportGroupId'}
                                                            component="div"
                                                            className={`field-error`}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="form-group">
                                                        <label htmlFor="production-chit-travel-timePicker">
                                                            Pickup Time
                                                        </label>
                                                        <TimePicker
                                                            name="pickUpTime"
                                                            value={values.pickUpTime}
                                                            className="tn-dp"
                                                            placeholder="--:--"
                                                            showSecond={false}
                                                            disabled={
                                                                disableFieldBasedonTravelType(
                                                                    values,
                                                                ) || checkLoaderStatus()
                                                            }
                                                            onChange={onPickupTimeChange(
                                                                values,
                                                                setFieldValue,
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            name={'pickUpTime'}
                                                            component="div"
                                                            className={`field-error`}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="form-group">
                                                        <label htmlFor="production-chit-travel-notes">
                                                            Travel Notes
                                                        </label>
                                                        <Field
                                                            component={TextArea}
                                                            name="notes"
                                                            className="form-control"
                                                            id="production-chit-travel-notes"
                                                        />
                                                        <ErrorMessage
                                                            name={'notes'}
                                                            component="div"
                                                            className={`field-error`}
                                                        />
                                                    </div>
                                                </Col>
                                                {/* <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="production-chit-travel-assign-to-production">
                              Assign to the following production days
                          </label>
                          <Field
                            type="checkbox"
                            name="assign"
                            className="form-control"
                            id="production-chit-travel-assign-to-production"
                          />
                        </div>
                      </Col> */}
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <ModalFooter>
                                        {checkLoaderStatus() && (
                                            <Loader
                                                type="ThreeDots"
                                                color="#2BAD60"
                                                height={50}
                                                width={50}
                                            />
                                        )}
                                        <Button variant="secondary" onClick={toggleModal}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            id={'modal' + record.chit.id}
                                            title={'click to save'}
                                            disabled={checkLoaderStatus() || !hasType}>
                                            Update Travel
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            );
                        }}
                    />
                </Modal>

                {/* <div class="input-group input-group-sm">
          <input type="text" class="form-control" aria-label="Small" value={getTravelButtonName} aria-describedby="inputGroup-sizing-sm" />
        </div> */}
            </div>
        </React.Fragment>
    );
};
TravelNotes.propTypes = {
    record: PropTypes.object.isRequired,
    transportGroups: PropTypes.object.isRequired,
    productionDayShowHideTravelNotesChitId: PropTypes.number.isRequired,
    productionDayTravelNotesNewtworkCallRunning: PropTypes.bool.isRequired,
    productionDayTravelNotesError: PropTypes.object.isRequired,
    setChitLevelTravelNotesRequest: PropTypes.func.isRequired,
    showHideChitLevelTravelNotes: PropTypes.func.isRequired,
};

export default TravelNotes;
