import React from 'react';
import * as Yup from 'yup';
import { NEW_LOCATION_VALUE } from './transpotationConstants';

const fields = [
    {
        label: 'Transport Group Name',
        name: 'name',
        type: 'text',
        placeholder: 'Transport Group Name',
        id: 'trasnpotation-group-name',
    },
    {
        LabelComponent: () => <div>Transport Location</div>,
        name: 'locationId',
        label: 'Transport Location',
        type: 'uv-location-select',
        onChangeKey: 'onLocationChange',
        isExternalComponet: true,
        placeholder: 'Select Location...',
        clearValueText: true,
        required: true,
        isSearchable: true,
        id: 'trasnpotation-location-selector',
        valueHandelor: (options, value) => options.find((it) => it.value == value),
        ErrorComponet: (error) => error && <div className="field-error">required</div>,
        parseDataOptions: (array) => array,
        styles: {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    // backgroundColor: data.value == NEW_LOCATION_VALUE ? 'blue' : isFocused ? '#999999' : null,
                    ...(data.value == NEW_LOCATION_VALUE
                        ? { color: 'blue', cursor: 'pointer' }
                        : {}),
                    // color: data.value == NEW_LOCATION_VALUE ? 'white' : '#333333',
                };
            },
        },
    },
];

export const generateTranspotationForm = (props) => {
    return {
        formInsideModal: true,
        title: 'Transport Groups',
        showSubmitErrors: true,
        submitBtnName: props.productionTranspotationGroupUpdateOrCreateActiveId ? 'Update Group': 'Create Group',
        showLoader: true,
        fields,
        validationSchema: Yup.object().shape({
            name: Yup.string().min(2, 'Too Short!').required('Required'),
            locationId: Yup.string()
                .test((value) => value !== NEW_LOCATION_VALUE && !props.showLocationCreateUpdateWindow )
                .required('Required'),
        }),

        otherErrorHadlelor: (stateValue, fieldName) => {
            return { locationId: (value) => (value && value.length ? false : true) }[fieldName](
                stateValue,
            );
        },
    };
};
