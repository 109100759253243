import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class AdRowActions extends React.Component {

  constructor(props) {
    super(props);
    this.state  = {
      showRemoveFromProductionConfirmationDialog: false,
      showUnassignFromAllProductionUnitsConfirmationDialog: false
    };
  }

  showHideRemoveFromProductionConfirmationDialog = show => {
    this.setState({
      showRemoveFromProductionConfirmationDialog: show
    })
  };

  showHideUnassignFromAllProductionUnitsConfirmationDialog = show => {
    this.setState({
      showUnassignFromAllProductionUnitsConfirmationDialog: show
    })
  };

  removeAdFromProduction = () => {
    this.props.removeAdFromProductionRequest(this.props.production.id, this.props.ad.id);
    this.showHideRemoveFromProductionConfirmationDialog(false);
  }

  unassignAdFromAllProductionUnits = () => {
    this.props.unassignSecondaryAdFromAllProductionUnitsRequest(this.props.production.id, this.props.ad.id);
    this.showHideUnassignFromAllProductionUnitsConfirmationDialog(false);
  }

  isAdPrimary = () => this.props.ad && this.props.ad.id === this.props.production.primaryAdId

  render = () => {
    if (this.isAdPrimary()) {
      return (
        <div>
          Primary AD cannot be removed
        </div>
      )
    }
    return (
      <div>
        <Button variant="outline-danger" size="xs" onClick={this.showHideRemoveFromProductionConfirmationDialog.bind(this, true)}>
          Remove from production
        </Button>
        <Button variant="outline-secondary" size="xs" onClick={this.showHideUnassignFromAllProductionUnitsConfirmationDialog.bind(this, true)} style={{marginLeft: '10px'}}>
          Unassign from all units
        </Button>

        <ConfirmationDialog
          show={this.state.showRemoveFromProductionConfirmationDialog}
          title="Remove AD from Production"
          onCancel={this.showHideRemoveFromProductionConfirmationDialog.bind(this, false)}
          message={
            <span>
              Are you sure you want to remove AD <strong>{this.props.ad.firstName} {this.props.ad.lastName}</strong> from production <strong>{this.props.production.name}</strong>?
            </span>
          }
          onConfirm={this.removeAdFromProduction.bind(this)}
        />

        <ConfirmationDialog
          show={this.state.showUnassignFromAllProductionUnitsConfirmationDialog}
          title="Unassign AD from all Production Units"
          onCancel={this.showHideUnassignFromAllProductionUnitsConfirmationDialog.bind(this, false)}
          message={
            <span>
              Are you sure you want to unassign AD <strong>{this.props.ad.firstName} {this.props.ad.lastName}</strong> from
              all production units?
            </span>
          }
          onConfirm={this.unassignAdFromAllProductionUnits.bind(this)}
        />
      </div>
    )
  }
}

AdRowActions.propTypes = {
  production: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  removeAdFromProductionRequest: PropTypes.func.isRequired,
  unassignSecondaryAdFromAllProductionUnitsRequest: PropTypes.func.isRequired
};

export default AdRowActions;
