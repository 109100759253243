import React from 'react';
import ChitRecordHealthSurveyActionsReset from './ChitRecordHealthSurveyActionsReset';
import ChitRecordHealthSurveyActionsView from './ChitRecordHealthSurveyActionsView';


class ChitRecordHealthSurveyActions extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div style={{display: 'flex'}}>
        <ChitRecordHealthSurveyActionsView {...this.props} healthSurveyResultDetails={this.props.selectedChitHealthSurveyResultDetails} />
        <div style={{marginLeft: '10px'}}>
          <ChitRecordHealthSurveyActionsReset {...this.props } />
        </div>
      </div>
    )
  }
}

ChitRecordHealthSurveyActions.propTypes = {};

export default ChitRecordHealthSurveyActions;

