import React from 'react';
import PropTypes from 'prop-types';
import UsersTableHeader from './UsersTableHeader';
import UserRecord from "./UserRecord";
import EditUser from "./EditUser";

class UsersTable extends React.Component {


  render = () => {
    const users = this.props.users.map(user => {
      return <UserRecord
        key={user.id}
        user={user}
        {...this.props}
      />
    });

    return (
      <div className="card table-responsive user-records">
        <table className="table card-table table-with-clickable-row table-striped">
          <thead className="thead-light">
            <UsersTableHeader/>
          </thead>
          <tbody>
            {users}
          </tbody>
        </table>
        <EditUser {...this.props} />
      </div>
    )
  }
}


UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UsersTable;

