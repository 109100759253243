import {axiosDefault} from '../lib/network';

export const getProductionRecords = () => {
  return axiosDefault.get(`/v2/productions/aggregated`).then(function (response) {
    return response.data;
  });
};

export const importC5Productions = () => {
  return axiosDefault.post(`/v2/c5/import-productions`).then(function (response) {
    return response.data;
  });
};