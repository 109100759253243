import React from 'react';

const PayRateAttributeValue = ({label, subLabel, value}) => {
  return (
    <tr>
      <td>
        {label}<br />
        <small>
          {subLabel}
        </small>
      </td>
      <td className="text-right">
        <input type="text" disabled value={value} style={{textAlign: 'right', border: '1px'}} />
      </td>
    </tr>
  )
}

export default PayRateAttributeValue;