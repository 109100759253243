export const LOAD_CHIT_DATA_REQUEST = 'LOAD_CHIT_DATA_REQUEST';
export const LOAD_CHIT_DATA_SUCCESS = 'LOAD_CHIT_DATA_SUCCESS';
export const LOAD_CHIT_DATA_FAILURE = 'LOAD_CHIT_DATA_FAILURE';

export const SHOW_HIDE_SET_EXTRA_STATUS_WINDOW = 'SHOW_HIDE_SET_EXTRA_STATUS_WINDOW';
export const SHOW_HIDE_SET_CHECK_OUT_TIME_WINDOW = 'SHOW_HIDE_SET_CHECK_OUT_TIME_WINDOW';

export const SET_EXTRA_STATUS_REQUEST = 'SET_EXTRA_STATUS_REQUEST';
export const SET_EXTRA_STATUS_SUCCESS = 'SET_EXTRA_STATUS_SUCCESS';
export const SET_EXTRA_STATUS_FAILURE = 'SET_EXTRA_STATUS_FAILURE';

export const SET_CHECK_OUT_TIME_REQUEST = 'SET_CHECK_OUT_TIME_REQUEST';
export const SET_CHECK_OUT_TIME_SUCCESS = 'SET_CHECK_OUT_TIME_SUCCESS';
export const SET_CHECK_OUT_TIME_FAILURE = 'SET_CHECK_OUT_TIME_FAILURE';

export const CHANGE_DAILY_RATE_REQUEST = 'CHANGE_DAILY_RATE_REQUEST';
export const CHANGE_DAILY_RATE_SUCCESS = 'CHANGE_DAILY_RATE_SUCCESS';
export const CHANGE_DAILY_RATE_FAILURE = 'CHANGE_DAILY_RATE_FAILURE';

export const ADD_SUPPLEMENTARY_FEE_REQUEST = 'ADD_SUPPLEMENTARY_FEE_REQUEST';
export const ADD_SUPPLEMENTARY_FEE_SUCCESS = 'ADD_SUPPLEMENTARY_FEE_SUCCESS';
export const ADD_SUPPLEMENTARY_FEE_FAILURE = 'ADD_SUPPLEMENTARY_FEE_FAILURE';

export const REMOVE_SUPPLEMENTARY_FEE_REQUEST = 'REMOVE_SUPPLEMENTARY_FEE_REQUEST';
export const REMOVE_SUPPLEMENTARY_FEE_SUCCESS = 'REMOVE_SUPPLEMENTARY_FEE_SUCCESS';
export const REMOVE_SUPPLEMENTARY_FEE_FAILURE = 'REMOVE_SUPPLEMENTARY_FEE_FAILURE';

export const SEND_CHECK_IN_EMAIL_REQUEST = 'SEND_CHECK_IN_EMAIL_REQUEST';
export const SEND_CHECK_IN_EMAIL_SUCCESS = 'SEND_CHECK_IN_EMAIL_SUCCESS';
export const SEND_CHECK_IN_EMAIL_FAILURE = 'SEND_CHECK_IN_EMAIL_FAILURE';

export const SEND_CHECK_OUT_EMAIL_REQUEST = 'SEND_CHECK_OUT_EMAIL_REQUEST';
export const SEND_CHECK_OUT_EMAIL_SUCCESS = 'SEND_CHECK_OUT_EMAIL_SUCCESS';
export const SEND_CHECK_OUT_EMAIL_FAILURE = 'SEND_CHECK_OUT_EMAIL_FAILURE';

export const DELETE_CHIT_REQUEST = 'DELETE_CHIT_REQUEST';
export const DELETE_CHIT_SUCCESS = 'DELETE_CHIT_SUCCESS';
export const DELETE_CHIT_FAILURE = 'DELETE_CHIT_FAILURE';

