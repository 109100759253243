import React from 'react';
import PropTypes from 'prop-types';

const ProductionTranspotationGroupHeader = (props) => {
  return <tr>
      <th>Group Name</th>
      <th></th>
  </tr>;
};

ProductionTranspotationGroupHeader.propTypes = {
  
};

export default ProductionTranspotationGroupHeader;
