import * as actionTypes from './actionTypes';

export const signInRequest = (data) => ({
  type: actionTypes.SIGN_IN_REQUEST,
  data
});

export const signInSuccess = (user) => ({
  type: actionTypes.SIGN_IN_SUCCESS,
  user
});

export const signInFailure = (error) => ({
  type: actionTypes.SIGN_IN_FAILURE,
  error
});

export const setStep = (step, data) => ({
  type: actionTypes.SET_STEP,
  step,
  data
});

export const sendMfaVerificationCodeRequest = (phoneInfoOptions, recaptchaVerifier) => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST,
  phoneInfoOptions,
  recaptchaVerifier
});

export const sendMfaVerificationCodeSuccess = (verificationId) => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS,
  verificationId
});

export const sendMfaVerificationCodeFailure = (error, errorCode) => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_FAILURE,
  error,
  errorCode
});

export const confirmVerificationCodeRequest = (mfaResolver, verificationId, code) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST,
  mfaResolver,
  verificationId,
  code
});

export const confirmVerificationCodeSuccess = () => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS,
});

export const confirmVerificationCodeFailure = (error, errorCode) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_FAILURE,
  error,
  errorCode
});