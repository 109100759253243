import logo from '../../assets/images/logo-black.svg';
import React from 'react';
import PropTypes from 'prop-types';
import './CentredLayout.css';

const CentredLayout = (props) => {
  const {title, children} = props;
  return (
    <div className="main-container">
      <div className="logo">
        <img className="mb-4" src={logo} alt=""/>
      </div>
      {title &&
      <div>
        <h5>{title}</h5>
      </div>
      }
      <div style={{marginTop: '30px'}}>
        {children}
      </div>
    </div>
  )
}


CentredLayout.propTypes = {
  title: PropTypes.string
}

export default CentredLayout;