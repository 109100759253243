import React from 'react';
import PropTypes from 'prop-types';

const ChitsTotal = (props) => {
  return (
    <div className="container-p-x py-2">
      <div className="text-muted small">Chits Total</div>
      <strong className="text-default text-big">{props.chitsTotal}</strong>
    </div>
  )
};

ChitsTotal.propTypes = {
  chitsTotal: PropTypes.number.isRequired,
};

export default ChitsTotal;

