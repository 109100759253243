import React from 'react';
import PropTypes from 'prop-types';
import {dateTimeToUveFormat} from '../../lib/dateTimeUtils';
import {EXTRA_STATUS_CHECKED_IN, EXTRA_STATUS_CHECKED_OUT} from '../../production-day/constants';
import SetExtraStatus from './SetExtraStatus';
import SetCheckOutTime from './SetCheckOutTime';
import {getExtraStatusCssClass, getExtraStatusCssClassPart} from "../getters";

class ChitSection3LeftSection1 extends React.Component {


  render() {
    const { chit, extra } = this.props.chitData;

    let callTimeLabel = ' - - : - -';
    if (chit.extraStatus === EXTRA_STATUS_CHECKED_IN || chit.extraStatus === EXTRA_STATUS_CHECKED_OUT) {
      callTimeLabel = dateTimeToUveFormat(chit.callTime);
    }

    let checkOutTimeLabel = ' - - : - -';
    if (chit.extraStatus === EXTRA_STATUS_CHECKED_OUT) {
      checkOutTimeLabel = dateTimeToUveFormat(chit.checkOutTime);
    }

    const extraStatusCssClassPart = getExtraStatusCssClassPart(chit.extraStatus);

    return (
      <div className="row">
        <div className="col">
          <div className="text-muted small">Chit Id / Extra Id</div>
          <strong className="text-default text-big">{chit.id} / {extra.id}</strong>
        </div>

        <div className="col">
          <div className="text-muted small">Status</div>
          <span className={`badge ${extraStatusCssClassPart} w-40 __web-inspector-hide-shortcut__`}>
            {chit.extraStatus}
          </span>
          <button className="btn btn-danger btn-xs" data-toggle="modal"
                  data-target="#change-extra-status-modal" style={{marginLeft: '10px'}} onClick={() => this.props.showHideSetExtraStatusWindow(true)}>Change
          </button>
        </div>

        <div className="col">
          <div className="text-muted small">Call Time</div>
          <strong className="text-default text-uppercase">
            {callTimeLabel}
          </strong>
        </div>

        <div className="col">
          <div className="text-muted small">Check-Out</div>
          <strong className="text-default text-uppercase">
            {checkOutTimeLabel}
          </strong>
          {chit.extraStatus === EXTRA_STATUS_CHECKED_OUT &&
            <button className="btn btn-danger btn-xs" data-toggle="modal"
                    style={{marginLeft: '10px'}}
                    onClick={() => this.props.showHideSetCheckOutTimeWindow(true)}>
              Change
            </button>
          }
        </div>

        {this.props.showSetExtraStatusWindow &&
          <SetExtraStatus {...this.props} />
        }
        {this.props.showSetCheckOutTimeWindow &&
          <SetCheckOutTime {...this.props} />
        }
      </div>
    )
  }
}

ChitSection3LeftSection1.propTypes = {
  chitData: PropTypes.object.isRequired,
  showSetExtraStatusWindow: PropTypes.bool.isRequired,
  showHideSetExtraStatusWindow: PropTypes.func.isRequired,
  showSetCheckOutTimeWindow: PropTypes.bool.isRequired,
  showHideSetCheckOutTimeWindow: PropTypes.func.isRequired
}

export default ChitSection3LeftSection1;