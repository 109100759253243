import React from 'react';

class ProductionsTableHeader extends React.Component {
  render = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Company</th>
        <th>Contact</th>
        <th>Mobile</th>
        <th>Email</th>
        <th>Dates</th>
        <th>&nbsp;</th>
      </tr>
    )
  }
}

export default ProductionsTableHeader;

