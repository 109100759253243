export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';

export const SHOW_HIDE_CREATE_USER_WINDOW = 'SHOW_HIDE_CREATE_USER_WINDOW';
export const SHOW_HIDE_EDIT_USER_WINDOW = 'SHOW_HIDE_EDIT_USER_WINDOW';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';

export const UN_ENROLL_USER_FROM_MFA_REQUEST = 'UN_ENROLL_USER_FROM_MFA_REQUEST';
export const UN_ENROLL_USER_FROM_MFA_SUCCESS = 'UN_ENROLL_USER_FROM_MFA_SUCCESS';
export const UN_ENROLL_USER_FROM_MFA_FAILURE = 'UN_ENROLL_USER_FROM_MFA_FAILURE';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE';