import React from 'react';
import PropTypes from 'prop-types';
import TravelNotes from '../TravelNotes';
import { Alert } from 'react-bootstrap';

const SetBulkTravelNotes = (props) => {
  const {
    idsOfSelectedChits,
    bulkActionsShowSetTravelWindow,
    showHideBulkActionsSetTravelNotes,
    setBulkChitTravelNotes,
    filteredChitRecordsByChitId,
    bulkActionsRunning,
  } = props;

  const toggleModal = () => {
    showHideBulkActionsSetTravelNotes(!bulkActionsShowSetTravelWindow);
  };
  const selectedChits = Object.keys(idsOfSelectedChits).filter((key) => key != 0 && idsOfSelectedChits[key]);
  const onSubmitBulk = (travelInfo) => {
    setBulkChitTravelNotes(selectedChits, travelInfo);
  };

  const checkAtleastOneChitHasTravelNotes = () => {
    return selectedChits.some((chitId) => (filteredChitRecordsByChitId[chitId].chit.travelInfo ? true : false));
  };

  const AlertComponet = checkAtleastOneChitHasTravelNotes() && (
    <React.Fragment>
      <Alert variant="warning">
        {`Please note that some of selected chits are having travel notes`}, those travel notes will be updated current notes
        <p />
      </Alert>
    </React.Fragment>
  );

  return (
    <TravelNotes
      {...props}
      InfoComponent={AlertComponet}
      isBulk={true}
      bulkShow={bulkActionsShowSetTravelWindow}
      toggleModal={toggleModal}
      selectedItems={selectedChits}
      onSubmitBulk={onSubmitBulk}
      bulkRunning={bulkActionsRunning}
    />
  );
};

SetBulkTravelNotes.propTypes = {
  idsOfSelectedChits: PropTypes.object.isRequired,
  bulkActionsShowSetTravelWindow: PropTypes.bool.isRequired,
  showHideBulkActionsSetTravelNotes: PropTypes.func.isRequired,
  setBulkChitTravelNotes: PropTypes.func.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  bulkActionsRunning:PropTypes.bool.isRequired,
};

export default SetBulkTravelNotes;
