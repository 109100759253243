import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import * as getters from '../../../getters';
import Select from 'react-select';
import ProductionDocumentUpload from './ProductionDocumentUpload';
import Error from '../../../../common/global-error/Error';

const updateDocumentSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
});

class UpdateDocument extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type: props.document.type,
      requiredAction: props.document.requiredAction,
      actionFrequency: props.document.actionFrequency,
      url: props.document.url
    }
  }

  getDocumentTypeOptions = () => Object.entries(getters.productionDocumentTypes).map(([value, label]) => ({
    value,
    label
  }));

  handleOnDocumentTypeSelectChange = option => {
    this.setState(prevState => ({
      type: option.value
    }));
  };

  handleOnRequiredActionSelectChange = option => {
    this.setState(prevState => ({
      requiredAction: option.value
    }));
  };

  getRequiredActionsOptions = () => Object.entries(getters.productionDocumentRequiredActions).map(([value, label]) => ({
    value,
    label
  }));

  getActionFrequencyOptions = () => Object.entries(getters.productionDocumentActionFrequencies).map(([value, label]) => ({
    value,
    label
  }));

  handleOnActionFrequencySelectChange = option => {
    this.setState(prevState => ({
      actionFrequency: option.value
    }));
  };

  getFieldError = field => {
    if (this.state.dataSent && !this.state[field]) {
      return 'Required';
    }
  }

  updateProductionDocument = (data) => {
    this.setState({
      dataSent: true
    });

    let isError = this.getFieldError('type') || this.getFieldError('requiredAction') || this.getFieldError('actionFrequency') || this.getFieldError('url')

    if (isError) {
      return false;
    }

    const req = {
      id: this.props.document.id,
      productionId: this.props.production.id,
      ...data,
      type: this.state.type,
      requiredAction: this.state.requiredAction,
      actionFrequency: this.state.actionFrequency,
      url: this.state.url,
    }
    this.props.updateProductionDocumentRequest(req)
  };

  setProductionDocumentUrl = (url) => {
    this.setState({
      url: url
    })
  };

  handleModalClose = () => {
    this.props.showHideUpdateProductionDocumentWindow(false);
  }

  render() {
    const documentTypeOptions = this.getDocumentTypeOptions();

    const currentDocumentTypeOption = this.state.type
      ? documentTypeOptions.find(option => option.value === this.state.type)
      : null;

    const requiredActionOptions = this.getRequiredActionsOptions();

    const currentRequiredActionOption = this.state.requiredAction
      ? requiredActionOptions.find(option => option.value === this.state.requiredAction)
      : null;

    const actionFrequencyOptions = this.getActionFrequencyOptions();

    const currentActionFrequencyOption = this.state.actionFrequency
      ? actionFrequencyOptions.find(option => option.value === this.state.actionFrequency)
      : null;

    return (
      <div>
        <Modal show={this.props.productionDocumentShowUpdateWindow}
               onHide={this.handleModalClose.bind(this)}
               size="lg">
          <Formik
            initialValues={{
              title: this.props.document.title
            }}
            validationSchema={updateDocumentSchema}
            onSubmit={this.updateProductionDocument.bind(this)}
            render={({errors, status, touched, isSubmitting}) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Update Production Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="production-document-title">Document title:</label>
                    <Field type="text" name="title" className="form-control" id="production-document-title"/>
                    <ErrorMessage name="title" component="div" className="field-error"/>
                  </div>
                  <div className="form-group">
                    <div>Document Type:</div>
                    <Select
                      options={documentTypeOptions}
                      placeholder=""
                      value={currentDocumentTypeOption}
                      onChange={this.handleOnDocumentTypeSelectChange.bind(this)}
                    />
                    {this.getFieldError('type') &&
                    <div className="field-error">{this.getFieldError('type')}</div>
                    }
                  </div>
                  <div className="form-group">
                    <div>Required Action:</div>
                    <Select
                      options={requiredActionOptions}
                      placeholder=""
                      value={currentRequiredActionOption}
                      onChange={this.handleOnRequiredActionSelectChange.bind(this)}
                    />
                    {this.getFieldError('requiredAction') &&
                    <div className="field-error">{this.getFieldError('requiredAction')}</div>
                    }
                  </div>
                  <div className="form-group">
                    <div>Action Frequency:</div>
                    <Select
                      options={actionFrequencyOptions}
                      placeholder=""
                      value={currentActionFrequencyOption}
                      onChange={this.handleOnActionFrequencySelectChange.bind(this)}
                    />
                    {this.getFieldError('actionFrequency') &&
                    <div className="field-error">{this.getFieldError('actionFrequency')}</div>
                    }
                  </div>
                  <div className="form-group">
                    <ProductionDocumentUpload productionId={this.props.production.id} onChange={data => this.setProductionDocumentUrl(data.url)} />
                    {this.state.urlError &&
                    <div className="field-error">{this.state.urlError}</div>
                    }
                  </div>
                  {this.getFieldError('url') &&
                  <div className="field-error">{this.getFieldError('url')}</div>
                  }
                  {this.props.productionDocumentUpdateError &&
                  <Error error={this.props.productionDocumentUpdateError}/>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={this.props.productionDocumentUpdating} variant="secondary"
                          onClick={this.handleModalClose.bind(this)}>
                    Cancel
                  </Button>
                  <Button disabled={this.props.productionDocumentUpdating} type="submit" variant="primary">
                    Update Production Document
                  </Button>

                </Modal.Footer>
              </Form>
            )}/>
        </Modal>
      </div>
    );
  }
}

UpdateDocument.propTypes = {
  production: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  showHideUpdateProductionDocumentWindow: PropTypes.func.isRequired,
  productionDocumentShowUpdateWindow: PropTypes.bool.isRequired,
  productionDocumentUpdating: PropTypes.bool.isRequired,
  updateProductionDocumentRequest: PropTypes.func.isRequired,
  productionDocumentUpdateError: PropTypes.object
}

export default UpdateDocument;