import * as actionTypes from './actionTypes';

const initialState = {
  isLoaded: false,
  isLoading: true,
  chitData: null,
  productionData: null,
  chitsOfExtraInProduction: [],
  setExtraStatusRunning: false,
  showSetExtraStatusWindow: false,
  setCheckOutTimeRunning: false,
  showSetCheckOutTimeWindow: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CHIT_DATA_REQUEST:
      return {
        ...state,
        chitData: null,
        productionData: null,
        chitsOfExtraInProduction: [],
        isLoaded: false,
        isLoading: true
      }
    case actionTypes.LOAD_CHIT_DATA_SUCCESS:
      return {
        ...state,
        chitData: action.chitData,
        productionData: action.productionData,
        chitsOfExtraInProduction: action.chitsOfExtraInProduction,
        isLoaded: true,
        isLoading: false
      }
    case actionTypes.LOAD_CHIT_DATA_FAILURE:
      return {
        ...state,
        isLoaded: true,
        isLoading: false
      }
    case actionTypes.SHOW_HIDE_SET_EXTRA_STATUS_WINDOW:
      return {
        ...state,
        showSetExtraStatusWindow: action.show
      }
    case actionTypes.SHOW_HIDE_SET_CHECK_OUT_TIME_WINDOW:
      return {
        ...state,
        showSetCheckOutTimeWindow: action.show
      }
    case actionTypes.SET_EXTRA_STATUS_REQUEST:
      return {
        ...state,
        setExtraStatusRunning: true
      }
    case actionTypes.SET_EXTRA_STATUS_SUCCESS:
      return {
        ...state,
        setExtraStatusRunning: false,
        chitData: action.chitData,
        showSetExtraStatusWindow: false
      }
    case actionTypes.SET_EXTRA_STATUS_FAILURE:
      return {
        ...state,
        setExtraStatusRunning: false
      }
    case actionTypes.SET_CHECK_OUT_TIME_REQUEST:
      return {
        ...state,
        setCheckOutTimeRunning: true,
      }
    case actionTypes.SET_CHECK_OUT_TIME_SUCCESS:
      return {
        ...state,
        setCheckOutTimeRunning: false,
        chitData: action.chitData,
        showSetCheckOutTimeWindow: false
      }
    case actionTypes.SET_CHECK_OUT_TIME_FAILURE:
      return {
        ...state,
        setCheckOutTimeRunning: false
      }
    case actionTypes.CHANGE_DAILY_RATE_SUCCESS:
      return {
        ...state,
        chitData: action.chitData,
      }
    case actionTypes.ADD_SUPPLEMENTARY_FEE_SUCCESS:
      return {
        ...state,
        chitData: action.chitData,
      }
    case actionTypes.REMOVE_SUPPLEMENTARY_FEE_SUCCESS:
      return {
        ...state,
        chitData: action.chitData,
      }
    default:
      return state;
  }
}

export default reducer;