import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import * as constants from '../../production-day/constants';
import {EXTRA_STATUS_CANCELLED} from '../../production-day/constants';
import {getCancellationFeeTypeLabel} from '../getters';
import {convertSecondsTo24HrHHMM} from '../../lib/dateTimeUtils';
import PayRateAttributeValue from './PayRateAttributeValue';

class ChitSection3LeftSection2PayRate extends React.Component {

  getPayRateOptions = (rateCardItems) => {
    return rateCardItems
      .filter(item => item.rateType === constants.RATE_TYPE_DAILY_RATE)
      .map(item => ({
        value: item.title,
        label: `${item.title} - ${item.payRate}`
      }));
  };

  handlePayRateChange = option => {
    this.props.changeDailyRateRequest({
      chitId: this.props.chitData.chit.id,
      paySchemeId: this.props.chitData.payScheme.id,
      rateName: option.value
    })
  }

  render() {
    const {chit, payScheme} = this.props.chitData;
    return (
      <React.Fragment>
        {chit.extraStatus === EXTRA_STATUS_CANCELLED && chit.cancellationDetails &&
        <React.Fragment>
          <tr  className="bg-lighter">
            <td className="w-50">
              <strong>{getCancellationFeeTypeLabel(chit.cancellationDetails.type)}</strong> for {chit.dailyRateName}
            </td>
            <td className="text-right">
              <input type="text" disabled value={`£${chit.cancellationDetails.amount.toFixed(2)}`}
                     style={{textAlign: 'right', border: '1px'}}/>
            </td>
          </tr>
        </React.Fragment>
        }
        {chit.extraStatus !== EXTRA_STATUS_CANCELLED &&
        <React.Fragment>
          <tr className="bg-lighter">
            <td><b>Pay Rate</b></td>
            <td className="text-right">
              {payScheme &&
              <div className="text-left" style={{fontSize: '14px', fontWeight: 'normal'}}>
                <Select
                  options={this.getPayRateOptions(payScheme.items)}
                  placeholder="Amend Pay Rate..."
                  className="basic-multi-select"
                  onChange={this.handlePayRateChange.bind(this)}
                />
              </div>
              }
            </td>
          </tr>
          <tr>
            <td className="w-50">
              {chit.dailyRateName &&
              <span>{chit.dailyRateName}</span>
              }
              {!chit.dailyRateName &&
              <span>No Rate Selected</span>
              }
            </td>
            <td className="text-right">
              <input type="text" disabled value={`£${chit.dailyRate.toFixed(2)}`} style={{textAlign: 'right', border: '1px'}}/>
            </td>
          </tr>
        </React.Fragment>
        }
        {chit.holidayEntitlement > 0 &&
        <PayRateAttributeValue label='Pay Rate Holiday Pay' subLabel=''
                               value={`£${chit.holidayEntitlement.toFixed(2)}`}/>
        }
        {chit.overtimeDurationMins > 0 &&
        <PayRateAttributeValue
          label='Overtime'
          subLabel={`${convertSecondsTo24HrHHMM(chit.overtimeDurationMins * 60)} @ ${chit.overtimeRate}`}
          value={`£${chit.overtimeTotal.toFixed(2)}`}
        />
        }
        {chit.overtimeHolidayPay && chit.overtimeHolidayPay.total > 0 &&
        <PayRateAttributeValue
          label='Overtime Holiday Pay'
          subLabel={`${convertSecondsTo24HrHHMM(chit.overtimeDurationMins * 60)} @ ${chit.overtimeHolidayPay.rate}`}
          value={`£${chit.overtimeHolidayPay.total.toFixed(2)}`}
        />
        }
        {chit.secondaryOvertime && chit.secondaryOvertime.total > 0 &&
        <PayRateAttributeValue
          label='Overtime @ Secondary Rate'
          subLabel={`${convertSecondsTo24HrHHMM(chit.secondaryOvertime.durationMins * 60)} @ ${chit.secondaryOvertime.rate} / ${chit.secondaryOvertime.chunkSize} mins`}
          value={`£${chit.secondaryOvertime.total.toFixed(2)}`}
        />
        }
        {chit.secondaryOvertimeHolidayPay && chit.secondaryOvertime && chit.secondaryOvertimeHolidayPay.total > 0 &&
        <PayRateAttributeValue
          label='Secondary Overtime Holiday Pay'
          subLabel={`${convertSecondsTo24HrHHMM(chit.secondaryOvertime.durationMins * 60)} @ ${chit.secondaryOvertimeHolidayPay.rate} / ${chit.secondaryOvertime.chunkSize} mins`}
          value={`£${chit.secondaryOvertimeHolidayPay.total.toFixed(2)}`}
        />
        }
        {chit.earlyCallDurationInMins > 0 &&
        <PayRateAttributeValue
          label='Early Call'
          subLabel={`${convertSecondsTo24HrHHMM(chit.earlyCallDurationInMins * 60)} @ ${chit.earlyCallRate}`}
          value={`£${chit.earlyCallTotal.toFixed(2)}`}
        />
        }
        {chit.earlyCallHolidayPay && chit.earlyCallHolidayPay.total > 0 &&
        <PayRateAttributeValue
          label='Early Call Holiday Pay'
          subLabel={`${convertSecondsTo24HrHHMM(chit.earlyCallDurationInMins * 60)} @ ${chit.earlyCallHolidayPay.rate}`}
          value={`£${chit.earlyCallHolidayPay.total.toFixed(2)}`}
        />
        }
      </React.Fragment>
    )
  }

}

ChitSection3LeftSection2PayRate.propTypes = {
  chitData: PropTypes.object.isRequired
  ,
  changeDailyRateRequest: PropTypes.func.isRequired
}

export default ChitSection3LeftSection2PayRate;