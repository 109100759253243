import React from 'react';
import PropTypes from 'prop-types';
import AdRowProductionUnits from './AdRowProductionUnits';
import AdRowActions from './AdRowActions';

class AdRow extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <React.Fragment>
        <tr className={`ad-row ${this.props.highlighted ? 'highlighted-row' : ''}`} ref={this.props.refProp}>
          <td>{this.props.ad.firstName}</td>
          <td>{this.props.ad.lastName}</td>
          <td>{this.props.ad.username}</td>
          <td>{this.props.ad.email}</td>
          <td><AdRowProductionUnits {...this.props} /></td>
          <td><AdRowActions {...this.props}/></td>
        </tr>
      </React.Fragment>
    )
  }
}

AdRow.propTypes = {
  ad: PropTypes.object.isRequired,
  refProp: PropTypes.object,
  highlighted: PropTypes.bool
};

export default AdRow;
