import React from 'react'
import FormWithModal from './FormWithModal';




function DynamicForm(props) {
  const {formInsideModal} = props;
  return <React.Fragment>
     { formInsideModal &&  <FormWithModal {...props}/> || <Form/>}
  </React.Fragment>
}


export default DynamicForm
