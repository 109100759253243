import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from "react-bootstrap";
import {dateToUveFormat} from "../../../lib/dateTimeUtils";

class ProductionDayQrCode extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const production = this.props.productionDayProductionQrCodeBeingShown;
    const productionDay = this.props.productionDayQrCodeBeingShown;
    const qrCode = this.props.currentProductionDayQrCode;
    const action = this.props.currentProductionDayQrCodeAction;

    if (!production || !productionDay) {
      return null;
    }

    return (
        <Modal show={true} onHide={this.props.showHideProductionDayQrCodeWindow.bind(this, false)} size="md" className="production-day-qr-code-modal">
          <Modal.Header closeButton>
            <Modal.Title>{action} QR code for
              Production {production.name} on {dateToUveFormat(productionDay.date)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {qrCode &&
            <div dangerouslySetInnerHTML={{__html:  atob(qrCode.qrCode)}} />
            }
          </Modal.Body>
          <Modal.Footer>
            {qrCode &&
            <div dangerouslySetInnerHTML={{__html:  `<a class='btn btn-secondary' style='text-decoration: none !important;'  href='data:image/svg+xml;base64,${(qrCode.qrCode)}' download='${production.name}-${productionDay.date}-${action}-qr.svg'>Download</a>`}} />
            }
            <Button variant="primary" onClick={this.props.showHideProductionDayQrCodeWindow.bind(this, false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    )
  };
}

ProductionDayQrCode.propTypes = {
  showHideProductionDayQrCodeWindow: PropTypes.func.isRequired,
  productionDayProductionQrCodeBeingShown: PropTypes.object,
  productionDayQrCodeBeingShown: PropTypes.object,
  currentProductionDayQrCode: PropTypes.object,
  currentProductionDayQrCodeAction: PropTypes.string
};

export default ProductionDayQrCode;
