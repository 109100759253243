import * as actionTypes from './actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: true,
  // Production records
  productionRecords: {},
  productionsImportResults: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type)  {
    case actionTypes.LOAD_PRODUCTION_RECORDS_SUCCESS:
      return {
        ...state,
        productionRecords: action.productionRecords,
        isLoading: false,
        isLoaded: true
      };
    case actionTypes.IMPORT_C5_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        productionsImportResults: action.importResult.productions
      };

    default:
      return state;
  }
};

export default reducer;