import React from 'react';
import { generateTranspotationForm } from './TranspotationFormConfig';
import DynamicForm from '../../../common/DynamicForms/DynamicForm';
import usePrevious from '../../../lib/hooks/UsePrevious';
import PropTypes from 'prop-types';
import { NEW_LOCATION_VALUE, LOCATIONID } from './transpotationConstants';

const ProductionAddorUpdateTranspotationGroup = (props) => {
  const {
    location,
    productionTranspotationGroupShowUpdateOrCreateWindow: show,
    productionTranspotationGroupUpdateOrCreateing: showLoader,
    showHideTarnspotationGroups: onHide,
    setShowHideLocation,
    showLocationCreateUpdateWindow,
    productionTranspotationGroupUpdateOrCreateActiveId,
    setCreateTarnspotationGroups,
    setUpdateTranspotationGroups,
    transportGroups,
  } = props;
  const productionId = props.match.params.id;
  const prevLocation = usePrevious(location);
  
  const getInitialState = () => {
    if (productionTranspotationGroupUpdateOrCreateActiveId) {
    var transpotationRecord = {
      transportGroupId: '',
      name: '',
      locationId: '',
      productionId: '',
    };

    const currentRecord = transportGroups.find((group) => group.id == productionTranspotationGroupUpdateOrCreateActiveId);
    if (currentRecord) {
      transpotationRecord.transportGroupId = currentRecord.id;
      transpotationRecord.name = currentRecord.name;
      transpotationRecord.locationId = currentRecord.locationId;
    }
    const initialState = {
      locationId: transpotationRecord.locationId,
      transportGroupId: transpotationRecord.transportGroupId,
      name: transpotationRecord.name,
      productionId: transpotationRecord.productionId || productionId,
    };
   return initialState
    }
    return {
      locationId : '',
      transportGroupId:'',
      name:"",
      productionId: productionId,


    }
  };
  // React.useEffect(()=>{
  //   setState(getInitialState())
  // },[productionTranspotationGroupUpdateOrCreateActiveId])

  const optionsRaw = [
    {
      value: NEW_LOCATION_VALUE,
      label: 'New Location',
    },
    ...Object.keys(location).map((key) => ({ value: key, label: location[key].name })),
  ];



  const onSubmit = (data) => {
    // data[LOCATIONID] = state[LOCATIONID];
    if (!productionTranspotationGroupUpdateOrCreateActiveId) {
      const { name, locationId, productionId } = data;
      setCreateTarnspotationGroups(name, productionId, locationId);
    } else {
      const { name, locationId, productionId, transportGroupId } = data;
      setUpdateTranspotationGroups(transportGroupId, productionId, locationId, name);
    }
  };
  return (
    <React.Fragment>
      <DynamicForm
        {...props}
        {...generateTranspotationForm(props)}
        isDisabled={showLoader}
        onCancel={onHide}
        onHide={onHide}
        show={show}
        showLoader={showLoader}
        showError={false}
        onSubmit={onSubmit}
        initialState={getInitialState()}
        optionsRaw={optionsRaw}
      />
      {/* {show && showLocationCreateUpdateWindow && <LocationPicker {...props} />} */}
    </React.Fragment>
  );
};

ProductionAddorUpdateTranspotationGroup.propTypes = {
  productionTranspotationGroupShowUpdateOrCreateWindow: PropTypes.bool.isRequired,
  productionTranspotationGroupUpdateOrCreateing: PropTypes.bool.isRequired,
  productionTranspotationGroupUpdateOrCreateActiveId: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  transportGroups: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  showHideTarnspotationGroups: PropTypes.func.isRequired,
  setCreateTarnspotationGroups: PropTypes.func.isRequired,
  setShowHideLocation: PropTypes.func.isRequired,
  showLocationCreateUpdateWindow: PropTypes.bool.isRequired,
};

export default ProductionAddorUpdateTranspotationGroup;
