import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import { countryWithCountyCode } from '../../../../production/components/location/countries';
import LocationSelector from '../../../../production/components/location/LocationSelector';
import {
  LOCATIONID, NEW_LOCATION_VALUE
} from '../../../../production/components/transportation-groups/transpotationConstants';

const SetExtrasBookingDateDetails = (props) => {
  const { location, setShowHideLocation, showLocationCreateUpdateWindow } = props;

  // const [state, setState] = React.useState({
  //   [LOCATIONID]: '',
  // });

  const [sendMessageOpen, setSendMessageOpen] = useState(false)
  const [modalProps, setModalProps] = useState({})

  const getCurrentLocationData = (data) => {
    return location[data[LOCATIONID]];
  };

  // const onLocationChange = (obj) => {
  //   // console.log("Am Calling....");
  //   if (obj.value == NEW_LOCATION_VALUE) {
  //     setState((prev) => ({ ...prev, [LOCATIONID]: '' }));
  //     setShowHideLocation();
  //     return;
  //   }
  //   setState((prev) => ({ ...prev, [LOCATIONID]: obj.value }));
  // };

  // const getFieldError = (field) => {
  //   if (!state[LOCATIONID]) {
  //     return 'Required';
  //   }
  // };

  const setBookingDateDetails = (data) => {
    const currentLocation = getCurrentLocationData(data);
    const bookingDateDetails = {
      address: {
        city: currentLocation.city || '',
        address:currentLocation.fullAddress,
        geoLocation: currentLocation?.coordinates || {},
      },
      entrance: currentLocation.entrance,
      contactName: data.contactName,
      contactNumber: data.contactNumber,
      costume: data.costume,
      travelArrangements: data.travelArrangements,
      otherInfo: data.otherInfo,
    };

    const chitIds = Object.keys(props.idsOfSelectedChitsCrossedByFiltered);

    props.setExtrasBookingDateDetailsRequest(chitIds, bookingDateDetails, props.productionId, props.productionDate);
   
  };

  // have to make options come from props
  const modalBackdrop = props.bulkActionsRunning ? 'static' : true;
  const schema = Yup.object().shape({
    city: Yup.string(),
    address: Yup.string(),
    [LOCATIONID]: Yup.string()
      .test((value) => value !== NEW_LOCATION_VALUE && !props.showLocationCreateUpdateWindow)
      .required('Required'),
    contactName: Yup.string().required('Required'),
    contactNumber: Yup.string().required('Required'),
    costume: Yup.string().required('Required'),
  });

  let initialValues = {
    locationId: '',
    city: '',
    address: '',
    geoLocation: '',
    entrance: '',
    contactName: '',
    contactNumber: '',
    costume: '',
    travelArrangements: '',
    otherInfo: '',
  };


  const getLocationStyles = () => ({
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ...(data.value == NEW_LOCATION_VALUE ? { color: 'blue', cursor: 'pointer' } : {}),
      };
    },
  });

  return (
    <React.Fragment>
      <Modal
        show={!props.callDetailsMessageModal}
        onHide={props.showHideBulkActionsSetBookingDateDetailsWindow.bind(this, false)}
        keyboard={!props.bulkActionsRunning}
        backdrop={modalBackdrop}
        size="lg"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={setBookingDateDetails.bind(this)}
          render={(rest) => (
            <Form>
              <Modal.Header closeButton={!props.bulkActionsRunning}>
                <Modal.Title>Set Call Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor={LOCATIONID}>Location:</label>
                  <LocationSelector {...rest} {...props} fieldInfo={{ "name": LOCATIONID, styles: getLocationStyles() }} />
                  <ErrorMessage name={LOCATIONID} component="div" />
                </div>

                <div className="form-group">
                  <label htmlFor="contactName">Contact Name:</label>
                  <Field type="text" name="contactName" className="form-control" id="contactName" />
                  <ErrorMessage name="contactName" component="div" />
                </div>

                <div className="form-group">
                  <label htmlFor="contactNumber">Contact Number:</label>
                  <Field type="text" name="contactNumber" className="form-control" id="contactNumber" />
                  <ErrorMessage name="contactNumber" component="div" />
                </div>

                <div className="form-group">
                  <label htmlFor="costume">Costume</label>
                  <Field type="text" name="costume" className="form-control" id="costume" />
                  <ErrorMessage name="costume" component="div" />
                </div>

                <div className="form-group">
                  <label htmlFor="otherInfo">Other Info:</label>
                  <Field type="textarea" name="otherInfo" className="form-control" id="otherInfo" />
                  <ErrorMessage name="otherInfo" component="div" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                {props.bulkActionsRunning && <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />}
                <Button
                  variant="default"
                  onClick={props.showHideBulkActionsSetBookingDateDetailsWindow.bind(this, false)}
                  disabled={props.bulkActionsRunning}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={setBookingDateDetails} disabled={props.bulkActionsRunning} type="submit">
                  Set
                </Button>
              </Modal.Footer>
            </Form>
          )}
        ></Formik>
      </Modal>
    </React.Fragment>
  );
};

SetExtrasBookingDateDetails.propTypes = {
  showHideBulkActionsSetBookingDateDetailsWindow: PropTypes.func.isRequired,
  setExtrasBookingDateDetailsRequest: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  initialBookingDateDetails: PropTypes.object,
  location: PropTypes.object.isRequired,
  setShowHideLocation: PropTypes.func.isRequired,
  showLocationCreateUpdateWindow: PropTypes.bool.isRequired,
};

export default SetExtrasBookingDateDetails;
