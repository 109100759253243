import React from 'react';
import PropTypes from 'prop-types';
import ProductionDayRow from './ProductionDayRow';
import ProductionDaysTableHeader from './ProductionDaysTableHeader';
import DefaultPayScheme from './DefaultPayScheme';
import './ProductionDays.css';
import SyncActions from './SyncActions';
import EmptyProductionDayRow from "./EmptyProductionDayRow";
import ProductionDayQrCode from "./ProductionDayQrCode";

class ProductionDays extends React.Component {

  constructor(props) {
    super(props);
    this.refsByDay = {};
  }

  scrollToDay = () => {
    this.refsByDay = this.props.productionDays.reduce((refs, day) => {
      refs[day.date] = React.createRef();
      return refs;
    }, {});

    if (this.props.highlightedProductionDayDate) {
      const ref = this.refsByDay[this.props.highlightedProductionDayDate];
      if (ref) {
        window.scrollTo(0, ref.current.offsetTop)
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // There might be case when we need to scroll to production day, but production days
    // are not yet loaded when component mounted, so we scroll to production unit only
    // when we receive them.
    if (prevProps.productionDays.length === 0 && this.props.productionDays.length > 1) {
      this.scrollToDay();
    }
  }

  render = () => {
    const production = this.props.production;
    const dayRows = this.props.productionDays.map(day => {
      const chitsTotalsByUnits = this.props.chitsTotalsByUnitsInDate[day.date];
      if (!chitsTotalsByUnits) {
        return (
          <EmptyProductionDayRow
            key={day.date}
            date={day.date}
            refProp={this.refsByDay[day.date]}
            {...this.props}
          />
        )
      }
      return (
        <ProductionDayRow
          key={day.date}
          highlighted={day.date === this.props.highlightedProductionDayDate}
          refProp={this.refsByDay[day.date]}
          production={production}
          day={day}
          chitsTotalsByUnits={chitsTotalsByUnits}
          {...this.props}
        />
      )
    });

    return (
      <div>
        <div className="row">
          <div className="col-md-5">
            <DefaultPayScheme {...this.props}
                              productionId={this.props.production.id}
                              paySchemes={this.props.paySchemes}
                              defaultPaySchemeId={this.props.production.defaultPaySchemeId}/>
          </div>
          <div className="col-md-7 text-right">
            <SyncActions
              productionId={this.props.production.id}
              {...this.props}
            />
          </div>
        </div>
        {this.props.productionDays.length > 0 &&
        <table className="table card-table table-striped production-days-table">
          <thead>
          <ProductionDaysTableHeader/>
          </thead>
          <tbody>
          {dayRows}
          </tbody>
        </table>
        }
        {!this.props.productionDays.length &&
        <div className="no-entries">
          No productions days
        </div>
        }
        {this.props &&
        <ProductionDayQrCode {...this.props} />
        }
      </div>
    );
  };
}

ProductionDays.propTypes = {
  paySchemes: PropTypes.array.isRequired,
  production: PropTypes.object.isRequired,
  productionDays: PropTypes.array.isRequired,
  chitsTotalsByUnitsInDate: PropTypes.object.isRequired,
  highlightedProductionDayDate: PropTypes.string,
  productionDayShowQrCodeWindow: PropTypes.bool.isRequired
};

export default ProductionDays;