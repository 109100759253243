import React from 'react';
import {connect} from 'react-redux';
import OtherSettings from './OtherSettings';
import {getters as appGetters} from '../../../app';
import {assignAdminRequest, setQrCodesRequirementRequest} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    admins: appGetters.getAdmins(state),
    production: productionState.production,
    users: state.app.users
  };
};

const actionCreators = {
  assignAdminRequest,
  setQrCodesRequirementRequest
};

export default connect(mapStateToProps, actionCreators)(OtherSettings);


