import React from 'react';
import PropTypes from 'prop-types';
import * as productionGetters from '../../../production/getters';
import * as productionConstants from '../../../production/constants';
import {dateTimeToUveFormat} from '../../../lib/dateTimeUtils';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {downloadProductionDocument} from "../../api";
import {EXTRA_STATUS_CANCELLED} from "../../constants";

class ChitRecordDocuments extends React.Component {

  constructor(props) {
    super(props);
  }


  render = () => {
    const {documents} = this.props;

    const documentsRendered = documents.map(document => {
      let btnClass = null;
      if (document.requiredAction !== productionConstants.PRODUCTION_DOCUMENT_REQUIRED_ACTION_NO_ACTION && document.completed) {
        btnClass = 'btn-success';
      } else if (document.requiredAction !== productionConstants.PRODUCTION_DOCUMENT_REQUIRED_ACTION_NO_ACTION && !document.completed) {
        btnClass = 'btn-danger'
      } else {
        btnClass = 'btn-secondary'
      }

      return (
        <div key={document.originalDocumentId} className="chit-document">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="document-info-tooltip">
                <div className="tooltip-content">
                  {this.props.record.chit.extraStatus === EXTRA_STATUS_CANCELLED &&
                  <div className="row">
                    <div className="col-md-5"><strong>Extra is Cancelled</strong></div>
                  </div>
                  }
                  <div className="row">
                    <div className="col-md-5">Document Type:</div>
                    <div className="col document-value">
                      {productionGetters.getProductionDocumentTypeLabel(document.type)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">Required Action:</div>
                    <div className="col document-value">
                      {productionGetters.getProductionRequiredActionLabel(document.requiredAction)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">Action Frequency:</div>
                    <div className="col document-value">
                      {productionGetters.getProductionActionFrequencyLabel(document.actionFrequency)}
                    </div>
                  </div>
                </div>
              </Tooltip>
            }
          >
            <a onClick={() => downloadProductionDocument(document.url, document.title)} className={`btn btn-sm ${btnClass}`} style={{pointerEvents: 'auto'}}>{document.title}</a>
          </OverlayTrigger>
        </div>
      )});

    const style = this.props.record.chit.extraStatus === EXTRA_STATUS_CANCELLED ? {backgroundColor: '#fff3cd'} : {};

    return (
      <React.Fragment>
        <td className="chit-column-documents" style={style}>
          <div className="chit-documents">
            {documentsRendered}
          </div>
        </td>
      </React.Fragment>
    )
  }
}

ChitRecordDocuments.propTypes = {
  record: PropTypes.object.isRequired,
  production: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired
};

export default ChitRecordDocuments;

