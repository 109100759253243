import React from 'react';

const Header = (props) => {
  return (
    <nav className="layout-footer footer bg-footer-theme">
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          © <span className="footer-text font-weight-bolder">Uni-versal Extras Ltd</span>
        </div>
        <div>
        </div>
      </div>
    </nav>
  );
};

export default Header;