import store from '../../store';
import * as actions from '../actions';
import React from 'react';
import Chit from './Chit';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as productionDayGetters from '../../production-day/getters';
import {
  addSupplementaryFeeRequest,
  changeDailyRateRequest, deleteChitRequest,
  removeSupplementaryFeeRequest,
  sendCheckInEmailRequest,
  sendCheckOutEmailRequest,
  setCheckOutTimeRequest,
  setExtraStatusRequest,
  showHideSetCheckOutTimeWindow,
  showHideSetExtraStatusWindow
} from '../actions';

class ChitContainer extends React.Component {

  componentDidMount = () => {
    const chitId = this.props.match.params.id;
    store.dispatch(actions.loadChitDataRequest(chitId));
  };

  render = () => {
    const {isLoaded} = this.props;
    if (!isLoaded) return null;
    return <Chit {...this.props} />
  };
}

const mapStateToProps = state => {
  const chitState = state.chit;

  let chitCancellationFees = null;

  if (chitState && chitState.chitData && chitState.chitData.chit.dailyRateName) {
    const payScheme = chitState.chitData.payScheme;
    if (payScheme) {
      const dailyRate = payScheme.items.find(item => item.title === chitState.chitData.chit.dailyRateName);
      if (dailyRate) {
        chitCancellationFees = dailyRate.cancellationFees;
      }
    }
  }

  return {
    isLoaded: chitState.isLoaded,
    isLoading: chitState.isLoading,
    chitData: chitState.chitData,
    productionData: chitState.productionData,
    chitsOfExtraInProduction: chitState.chitsOfExtraInProduction,
    allExtraStatuses: productionDayGetters.allExtraStatuses,
    setExtraStatusRunning: chitState.setExtraStatusRunning,
    showSetExtraStatusWindow: chitState.showSetExtraStatusWindow,
    showSetCheckOutTimeWindow: chitState.showSetCheckOutTimeWindow,
    setCheckOutTimeRunning: chitState.setCheckOutTimeRunning,
    chitCancellationFees
  };
};

const actionCreators = {
  showHideSetExtraStatusWindow,
  showHideSetCheckOutTimeWindow,
  setExtraStatusRequest,
  setCheckOutTimeRequest,
  changeDailyRateRequest,
  addSupplementaryFeeRequest,
  removeSupplementaryFeeRequest,
  sendCheckInEmailRequest,
  sendCheckOutEmailRequest,
  deleteChitRequest
};

export default withRouter(connect(mapStateToProps, actionCreators)(ChitContainer));

