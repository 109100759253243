import * as actionTypes from './actionTypes';

export const loadProductionRecordsRequest = () => ({
  type: actionTypes.LOAD_PRODUCTION_RECORDS_REQUEST
});

export const loadProductionRecordsSuccess = (productionRecords) => ({
  type: actionTypes.LOAD_PRODUCTION_RECORDS_SUCCESS,
  productionRecords
});

export const loadProductionRecordsFailure = (error) => ({
  type: actionTypes.LOAD_PRODUCTION_RECORDS_FAILURE,
  error
});

export const importC5ProductionsRequest = () => ({
  type: actionTypes.IMPORT_C5_PRODUCTIONS_REQUEST
});

export const importC5ProductionsSuccess = (importResult) => ({
  type: actionTypes.IMPORT_C5_PRODUCTIONS_SUCCESS,
  importResult
});

export const importC5ProductionsFailure = (error) => ({
  type: actionTypes.IMPORT_C5_PRODUCTIONS_FAILURE,
  error
});

