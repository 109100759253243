export const LOAD_CHIT_RECORDS_REQUEST = 'LOAD_CHIT_RECORDS_REQUEST';
export const LOAD_CHIT_RECORDS_SUCCESS = 'LOAD_CHIT_RECORDS_SUCCESS';
export const LOAD_CHIT_RECORDS_FAILURE = 'LOAD_CHIT_RECORDS_FAILURE';

export const LOAD_PRODUCTION_DAY_AGGREGATED_DATA_REQUEST = 'LOAD_PRODUCTION_DAY_AGGREGATED_DATA_REQUEST';
export const LOAD_PRODUCTION_DAY_AGGREGATED_DATA_SUCCESS = 'LOAD_PRODUCTION_DAY_AGGREGATED_DATA_SUCCESS';
export const LOAD_PRODUCTION_DAY_AGGREGATED_DATA_FAILURE = 'LOAD_PRODUCTION_DAY_AGGREGATED_DATA_FAILURE';

export const WRAP_UP_PRODUCTION_UNIT_ON_DAY_REQUEST = 'WRAP_UP_PRODUCTION_UNIT_ON_DAY_REQUEST';
export const WRAP_UP_PRODUCTION_UNIT_ON_DAY_SUCCESS = 'WRAP_UP_PRODUCTION_UNIT_ON_DAY_SUCCESS';
export const WRAP_UP_PRODUCTION_UNIT_ON_DAY_FAILURE = 'WRAP_UP_PRODUCTION_UNIT_ON_DAY_FAILURE';

export const GENERATE_PRODUCTION_UNT_CURRENT_REPORT_REQUEST = 'GENERATE_PRODUCTION_UNT_CURRENT_REPORT_REQUEST';
export const GENERATE_PRODUCTION_UNT_CURRENT_REPORT_SUCCESS = 'GENERATE_PRODUCTION_UNT_CURRENT_REPORT_SUCCESS';
export const GENERATE_PRODUCTION_UNT_CURRENT_REPORT_FAILURE = 'GENERATE_PRODUCTION_UNT_CURRENT_REPORT_FAILURE';

export const GENERATE_CURRENT_REPORT_REQUEST = 'GENERATE_CURRENT_REPORT_REQUEST';
export const GENERATE_CURRENT_REPORT_SUCCESS = 'GENERATE_CURRENT_REPORT_SUCCESS';
export const GENERATE_CURRENT_REPORT_FAILURE = 'GENERATE_CURRENT_REPORT_FAILURE';

export const SET_CHIT_CALL_TIME_REQUEST = 'SET_CHIT_CALL_TIME_REQUEST';
export const SET_CHIT_CALL_TIME_SUCCESS = 'SET_CHIT_CALL_TIME_SUCCESS';
export const SET_CHIT_CALL_TIME_FAILURE = 'SET_CHIT_CALL_TIME_FAILURE';

export const SET_CHITS_CALL_TIME_REQUEST = 'SET_CHITS_CALL_TIME_REQUEST';
export const SET_CHITS_CALL_TIME_SUCCESS = 'SET_CHITS_CALL_TIME_SUCCESS';
export const SET_CHITS_CALL_TIME_FAILURE = 'SET_CHITS_CALL_TIME_FAILURE';

export const SET_CHITS_FILTER_VALUE = 'SET_CHITS_FILTER_VALUE';

export const ASSIGN_EXTRA_TO_PRODUCTION_UNIT_REQUEST = 'ASSIGN_EXTRA_TO_PRODUCTION_UNIT_REQUEST';
export const ASSIGN_EXTRA_TO_PRODUCTION_UNIT_SUCCESS = 'ASSIGN_EXTRA_TO_PRODUCTION_UNIT_SUCCESS';
export const ASSIGN_EXTRA_TO_PRODUCTION_UNIT_FAILURE = 'ASSIGN_EXTRA_TO_PRODUCTION_UNIT_FAILURE';

export const ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_REQUEST = 'ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_REQUEST';
export const ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_SUCCESS = 'ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_SUCCESS';
export const ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_FAILURE = 'ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_FAILURE';

export const ADD_SUPPLEMENTARY_FEE_TO_CHITS_REQUEST = 'ADD_SUPPLEMENTARY_FEE_TO_CHITS_REQUEST';
export const ADD_SUPPLEMENTARY_FEE_TO_CHITS_SUCCESS = 'ADD_SUPPLEMENTARY_FEE_TO_CHITS_SUCCESS';
export const ADD_SUPPLEMENTARY_FEE_TO_CHITS_FAILURE = 'ADD_SUPPLEMENTARY_FEE_TO_CHITS_FAILURE';

export const CHANGE_DAILY_RATE_OF_CHITS_REQUEST = 'CHANGE_DAILY_RATE_OF_CHITS_REQUEST';
export const CHANGE_DAILY_RATE_OF_CHITS_SUCCESS = 'CHANGE_DAILY_RATE_OF_CHITS_SUCCESS';
export const CHANGE_DAILY_RATE_OF_CHITS_FAILURE = 'CHANGE_DAILY_RATE_OF_CHITS_FAILURE';

export const SET_EXTRAS_BOOKING_DATE_DETAILS_REQUEST = 'SET_EXTRAS_BOOKING_DATE_DETAILS_REQUEST';
export const SET_EXTRAS_BOOKING_DATE_DETAILS_SUCCESS = 'SET_EXTRAS_BOOKING_DATE_DETAILS_SUCCESS';
export const SET_EXTRAS_BOOKING_DATE_DETAILS_FAILURE = 'SET_EXTRAS_BOOKING_DATE_DETAILS_FAILURE';

export const CANCEL_CHITS_REQUEST = 'CANCEL_CHITS_REQUEST';
export const CANCEL_CHITS_SUCCESS = 'CANCEL_CHITS_SUCCESS';
export const CANCEL_CHITS_FAILURE = 'CANCEL_CHITS_FAILURE';

export const SET_EXTRAS_STATUSES_REQUEST = 'SET_EXTRAS_STATUSES_REQUEST';
export const SET_EXTRAS_STATUSES_SUCCESS = 'SET_EXTRAS_STATUSES_SUCCESS';
export const SET_EXTRAS_STATUSES_FAILURE = 'SET_EXTRAS_STATUSES_FAILURE';

export const REMOVE_SUPPLEMENTARY_FEES_REQUEST = 'REMOVE_SUPPLEMENTARY_FEES_REQUEST';
export const REMOVE_SUPPLEMENTARY_FEES_SUCCESS = 'REMOVE_SUPPLEMENTARY_FEES_SUCCESS';
export const REMOVE_SUPPLEMENTARY_FEES_FAILURE = 'REMOVE_SUPPLEMENTARY_FEES_FAILURE';

export const SEND_BREAKDOWN_EMAILS_REQUEST = 'SEND_BREAKDOWN_EMAILS_REQUEST';
export const SEND_BREAKDOWN_EMAILS_SUCCESS = 'SEND_BREAKDOWN_EMAILS_SUCCESS';
export const SEND_BREAKDOWN_EMAILS_FAILURE = 'SEND_BREAKDOWN_EMAILS_FAILURE';

export const SET_CHITS_SELECTION = 'SET_CHITS_SELECTION';
export const DESELECT_ALL_CHITS = 'DESELECT_ALL_CHITS';

export const SHOW_HIDE_BULK_ACTIONS_SET_CALL_TIME_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_SET_CALL_TIME_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_ADD_SUPPLEMENTARY_FEE_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_ADD_SUPPLEMENTARY_FEE_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_CHANGE_DAILY_RATE_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_CHANGE_DAILY_RATE_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_SET_BOOKING_DATE_DETAILS_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_SET_BOOKING_DATE_DETAILS_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_CANCEL_CHITS_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_CANCEL_CHITS_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_SET_EXTRAS_STATUSES_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_SET_EXTRAS_STATUSES_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_REMOVE_SUPPLEMENTARY_FEES_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_REMOVE_SUPPLEMENTARY_FEES_WINDOW';
export const SHOW_HIDE_BULK_ACTIONS_SEND_BREAKDOWN_EMAILS_WINDOW = 'SHOW_HIDE_BULK_ACTIONS_SEND_BREAKDOWN_EMAILS_WINDOW';
export const SHOW_CHITS_BY_BULK_ACTION_RESULT = 'SHOW_CHITS_BY_BULK_ACTION_RESULT';
export const REMOVE_BULK_ACTIONS_FEEDBACK = 'REMOVE_BULK_ACTIONS_FEEDBACK';
export const SHOW_HIDE_BULK_ACTIONS_SET_NOTES = 'SHOW_HIDE_BULK_ACTIONS_SET_NOTES'

export const SET_CHITS_ACTIVE_TAB = 'SET_CHITS_ACTIVE_TAB';

export const RESET_HEALTH_SURVEY_RESULT_REQUEST = 'RESET_HEALTH_SURVEY_RESULT_REQUEST';
export const RESET_HEALTH_SURVEY_RESULT_SUCCESS = 'RESET_HEALTH_SURVEY_RESULT_SUCCESS';
export const RESET_HEALTH_SURVEY_RESULT_FAILURE = 'RESET_HEALTH_SURVEY_RESULT_FAILURE';

export const GET_HEALTH_SURVEY_RESULT_DETAILS_REQUEST = 'GET_HEALTH_SURVEY_RESULT_DETAILS_REQUEST';
export const GET_HEALTH_SURVEY_RESULT_DETAILS_SUCCESS = 'GET_HEALTH_SURVEY_RESULT_DETAILS_SUCCESS';
export const GET_HEALTH_SURVEY_RESULT_DETAILS_FAILURE = 'GET_HEALTH_SURVEY_RESULT_DETAILS_FAILURE';

export const GET_EXTRAS_DOCUMENTS_REQUEST = 'GET_EXTRAS_DOCUMENTS_REQUEST';
export const GET_EXTRAS_DOCUMENTS_SUCCESS = 'GET_EXTRAS_DOCUMENTS_SUCCESS';
export const GET_EXTRAS_DOCUMENTS_FAILURE = 'GET_EXTRAS_DOCUMENTS_FAILURE';


export const LOAD_PRODUCTION_DOCUMENT_REQUEST = 'LOAD_PRODUCTION_DOCUMENT_REQUEST';

export const DOWNLOAD_PRODUCTION_DOCUMENT_REQUEST = 'DOWNLOAD_PRODUCTION_DOCUMENT_REQUEST';
export const DOWNLOAD_PRODUCTION_DOCUMENT_SUCCESS = 'DOWNLOAD_PRODUCTION_DOCUMENT_SUCCESS';
export const DOWNLOAD_PRODUCTION_DOCUMENT_FAILURE = 'DOWNLOAD_PRODUCTION_DOCUMENT_FAILURE';

export const SET_PRODUCTION_NOTES = 'SET_PRODUCTION_NOTES';
export const SET_PRODUCTION_NOTES_SUCCESS = 'SET_PRODUCTION_NOTES_SUCCESS';
export const SET_PRODUCTION_NOTES_FAILURE = 'SET_PRODUCTION_NOTES_FAILURE';

export const SET_PRODUCTION_DAY_NOTES = 'SET_PRODUCTION_DAY_NOTES';
export const SET_PRODUCTION_DAY_NOTES_SUCCESS = 'SET_PRODUCTION_DAY_NOTES_SUCCESS';
export const SET_PRODUCTION_DAY_NOTES_FAILURE = 'SET_PRODUCTION_DAY_NOTES_FAILURE';


export const SET_INDIVIDUAL_CHIT_NOTES = 'SET_INDIVIDUAL_CHIT_NOTES';
export const SET_INDIVIDUAL_CHIT_NOTES_SUCCESS = 'SET_INDIVIDUAL_CHIT_NOTES_SUCCESS';
export const SET_INDIVIDUAL_CHIT_NOTES_FAILURE = 'SET_INDIVIDUAL_CHIT_NOTES_FAILURE';


export const SET_BULK_CHIT_NOTES = 'SET_BULK_CHIT_NOTES';
export const SET_BULK_CHITS_NOTES_SUCCESS = 'SET_BULK_CHITS_NOTES_SUCCESS';
export const SET_BULK_CHITS_NOTES_FAILURE = 'SET_BULK_CHITS_NOTES_FAILURE';


export const SHOW_HIDE_CHIT_LEVEL_TRAVEL_NOTES = 'SHOW_HIDE_CHIT_LEVEL_TRAVEL_NOTES';
export const SET_CHIT_LEVEL_TRAVEL_NOTES_REQUEST = 'SET_CHIT_LEVEL_TRAVEL_NOTES_REQUEST';
export const SET_CHIT_LEVEL_TRAVEL_NOTES_SUCCESS = 'SET_CHIT_LEVEL_TRAVEL_NOTES_SUCCESS';
export const SET_CHIT_LEVEL_TRAVEL_NOTES_FAILURE = 'SET_CHIT_LEVEL_TRAVEL_NOTES_FAILURE';

export const SHOW_HIDE_BULK_ACTIONS_SET_TRAVEL_NOTES = 'SHOW_HIDE_BULK_ACTIONS_SET_TRAVEL_NOTES';
export const SET_BULK_ACTIONS_SET_TRAVEL_NOTES_REQUEST = 'SET_BULK_ACTIONS_SET_TRAVEL_NOTES_REQUEST';
export const SET_BULK_ACTIONS_SET_TRAVEL_NOTES_SUCCESS = 'SET_BULK_ACTIONS_SET_TRAVEL_NOTES_SUCCESS';
export const SET_BULK_ACTIONS_SET_TRAVEL_NOTES_FAILURE = 'SET_BULK_ACTIONS_SET_TRAVEL_NOTES_FAILURE';


export const SET_PRODUCTION_DAY_DATA_EXPORT_REQUEST = 'SET_PRODUCTION_DAY_DATA_EXPORT_REQUEST';
export const SET_PRODUCTION_DAY_DATA_EXPORT_SUCCESS = 'SET_PRODUCTION_DAY_DATA_EXPORT_SUCCESS';
export const SET_PRODUCTION_DAY_DATA_EXPORT_FAILURE = 'SET_PRODUCTION_DAY_DATA_EXPORT_FAILURE';

export const SET_SHOW_HIDE_SMS_WINDOW = 'SET_SHOW_HIDE_SMS_WINDOW' 
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';

export const SET_CALL_DETAILS_MESSAGE_MODAL = 'SET_CALL_DETAILS_MESSAGE_MODAL'
export const SET_HIDE_SHOW_PRODUCTION_DAY_LOCATION_WINDOW = 'SET_HIDE_SHOW_PRODUCTION_DAY_LOCATION_WINDOW';
export const SET_PRODUCTION_DAY_LOCACTION_REQUEST = 'SET_PRODUCTION_DAY_LOCATION_REQUEST';
export const SET_PRODCUTION_DAY_LOCATION_SUCCESS = 'SET_PRODCUTION_DAY_LOCATION_SUCCESS';
export const SET_PRODCUTION_DAY_LOCATION_FAILURE = 'SET_PRODCUTION_DAY_LOCATION_FAILURE';


export const SET_PRODUCTION_DAY_RELEASE_REQUEST = 'SET_PRODUCTION_DAY_RELEASE_REQUEST';
export const SET_PRODUCTION_DAY_RELEASE_SUCCESS = 'SET_PRODUCTION_DAY_RELEASE_SUCCESS';
export const SET_PRODUCTION_DAY_RELEASE_FAILURE = 'SET_PRODUCTION_DAY_RELEASE_FAILURE';
export const SET_SHOW_HIDE_PRODUCTION_DAY_RELEASE_WINDOW = 'SET_SHOW_HIDE_PRODUCTION_DAY_RELEASE_WINDOW';

export const SET_CHIT_CALL_DETAILS_RESPONSE_WINDOW = 'SET_CHIT_CALLDETAILS_RESPONSE_WINDOW';
export const SET_CHIT_CALL_DETAILS_RESPONSE_REQUEST='SET_CHIT_CALL_DETAILS_RESPONSE_REQUEST';
export const SET_CHIT_CALL_DETAILS_RESPONSE_SUCCESS='SET_CHIT_CALL_DETAILS_RESPONSE_SUCCESS';
export const SET_CHIT_CALL_DETAILS_RESPONSE_FAILURE='SET_CHIT_CALL_DETAILS_RESPONSE_FAILURE';











