import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {
  getExtraHealthSurveyStatusCssClassName,
  getExtraHealthSurveyStatusLabel,
  getExtraHealthSurveyTypeLabel
} from '../../../production/getters';
import {dateTimeToUveFormat} from '../../../lib/dateTimeUtils';
import * as constants from '../../../production/constants';

const ProductionExternalSurveyExternalUrlResult = props => {
  const {url, viewed} = props
  return (
    <div>
      <div className="row">
        <div className="col-md-2">
          External Url
        </div>
        <div className="col-md-10">
          <a href={url} target="_blank"><strong>{url}</strong></a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2"/>
        <div className="col-md-10">
          {viewed &&
          <span className="text-success">Viewed</span>
          }
          {!viewed &&
          <span className="text-danger">Not Viewed</span>
          }
        </div>
      </div>
    </div>
  )
};

ProductionExternalSurveyExternalUrlResult.propTypes = {
  url: PropTypes.string.isRequired,
  viewed: PropTypes.bool.isRequired
}

const ProductionExternalSurveyDocumentResult = props => {
  const {url, signed} = props
  return (
    <div>
      <div className="row">
        <div className="col-md-2">
          Document
        </div>
        <div className="col-md-10">
          <a href={url} className="btn btn-primary btn-sm" target="_blank"><i className="fa fa-file"/> View document</a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2"/>
        <div className="col-md-10">
          {signed &&
          <span className="text-success">Accepted</span>
          }
          {!signed &&
          <span className="text-danger">Not Accepted</span>
          }
        </div>
      </div>
    </div>
  )
};

ProductionExternalSurveyExternalUrlResult.propTypes = {
  url: PropTypes.string.isRequired,
  signed: PropTypes.bool.isRequired
}

const UveHealthSurveyResultQuestion = props => {
  const {question, answersSet} = props;

  const answer = answersSet.answers.find(answer => answer.questionId === question.id)
  let answerDetails = null;
  if (answer) {
    const choice = question.choices.find(choice => answer.content.content.choiceId === choice.id)
    answerDetails = {
      isCorrect: answer.isCorrect,
      choice
    };
  }

  return (
    <li>
      {question.title}
      <div>
        {!answerDetails &&
        <span className="text-danger">Not Answered</span>
        }
        {answerDetails &&
        <React.Fragment>
          {answerDetails.isCorrect &&
          <span className="text-success">{answerDetails.choice.title}</span>
          }
          {!answerDetails.isCorrect &&
          <span className="text-danger">{answerDetails.choice.title}</span>
          }
        </React.Fragment>
        }
      </div>
    </li>
  )
};

UveHealthSurveyResultQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answersSet: PropTypes.object.isRequired
};

const UveHealthSurveyResult = props => {
  const {questionnaire, extraQuestionnaireAnswersSet} = props;
  return (
    <ul className="uve-questionnaire-details">
      {questionnaire.questions.map(question => <UveHealthSurveyResultQuestion key={question.id} question={question}
                                                                              answersSet={extraQuestionnaireAnswersSet}/>)}
    </ul>
  );
};

UveHealthSurveyResult.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  extraQuestionnaireAnswersSet: PropTypes.object.isRequired
};

class ChitRecordHealthSurveyActionsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHealthSurveyResultWindow: false
    };
  }


  showHideHealthSurveyResultWindow = show => {
    this.setState({
      showHealthSurveyResultWindow: show
    })
  }

  doShowHideHealthSurveyResultWindow = () => {
    this.showHideHealthSurveyResultWindow(true);
    this.props.getHealthSurveyResultDetailsRequest(this.props.record.chit.id);
  }

  renderHealthSurveyInfo = surveyInfo => {
    if (surveyInfo.type === constants.HEALTH_SURVEY_TYPE_UVE_SURVEY) {
      return <UveHealthSurveyResult questionnaire={this.props.healthSurveyResultDetails.questionnaire}
                                    extraQuestionnaireAnswersSet={this.props.healthSurveyResultDetails.extraQuestionnaireAnswersSet}/>
    } else if (surveyInfo.type === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY && surveyInfo.data.type === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL) {
      return <ProductionExternalSurveyExternalUrlResult url={surveyInfo.data.data.url} viewed={surveyInfo.data.data.viewed} />
    } else if (surveyInfo.type === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY && surveyInfo.data.type === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT) {
      return <ProductionExternalSurveyDocumentResult url={surveyInfo.data.data.url} signed={surveyInfo.data.data.signed} />
    }

    return null;
  }

  render = () => {
    const {record, healthSurveyResultDetails} = this.props;
    const {extra} = record;
    const extraStatusCssClass = healthSurveyResultDetails ? getExtraHealthSurveyStatusCssClassName(healthSurveyResultDetails.healthSurveyResult.status) : '';
    const healthSurveyResult = healthSurveyResultDetails ? healthSurveyResultDetails.healthSurveyResult : null;

    const healthSurveyType = healthSurveyResult && healthSurveyResult.surveyInfo ? healthSurveyResult.surveyInfo.type : null;
    const healthSurveySubType = healthSurveyResult && healthSurveyResult.surveyInfo ? healthSurveyResult.surveyInfo.data.type : null;

    return (
      <div>
        <button className="btn btn-xs btn-outline-secondary"
                onClick={this.doShowHideHealthSurveyResultWindow.bind(this)}>View result
        </button>
        <Modal show={this.state.showHealthSurveyResultWindow}
               onHide={this.showHideHealthSurveyResultWindow.bind(this, false)}
               keyboard={true} size="lg">
          <Modal.Header closeButton={true}>
            <Modal.Title>Health Survey Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="health-survey-result-details">
              <div className="row">
                <div className="col-md-2">Extra</div>
                <div className="col-md-9"><strong>{extra.id}, {extra.firstName} {extra.lastName}</strong></div>
              </div>
              {healthSurveyResultDetails &&
              <React.Fragment>
                <div className="row">
                  <div className="col-md-2">Survey Type</div>
                  <div className="col-md-9">
                    <strong>{getExtraHealthSurveyTypeLabel(healthSurveyType, healthSurveySubType)}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">Survey Status</div>
                  <div className="col-md-9">
                <span className={`badge badge ${extraStatusCssClass} w-50`}>
                  {getExtraHealthSurveyStatusLabel(healthSurveyResultDetails.healthSurveyResult.status)}
                </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">Recorded At</div>
                  <div className="col-md-9">
                    <strong>{dateTimeToUveFormat(healthSurveyResultDetails.healthSurveyResult.recordedAt)}</strong>
                  </div>
                </div>
                {this.renderHealthSurveyInfo(healthSurveyResultDetails.healthSurveyResult.surveyInfo)}
              </React.Fragment>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.showHideHealthSurveyResultWindow.bind(this, false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

ChitRecordHealthSurveyActionsView.propTypes = {
  record: PropTypes.object.isRequired,
  getHealthSurveyResultDetailsRequest: PropTypes.func.isRequired,
  healthSurveyResultDetails: PropTypes.object
};

export default ChitRecordHealthSurveyActionsView;

