export const  LOCATION_NAME = 'name';
export const  COUNTRY_CODE = 'countryCode';
export const COUNTY = 'county';
export const  CITY='city';
export const NUMBEROF_BUILDING ='numberOfBuilding';
export const POST_CODE = 'postCode';
export const COORDINATES = 'coordinates';
export const LAT = 'lat';
export const LNG='lng';
export const ENTRANCE = 'entrance';
export const ADDITIONALINFO = 'additionalInfo';
export const STREET = 'street'
export const SEARCH_ADDRESS = 'SEARCH_ADDRESS'