import {axiosDefault} from '../lib/network';

export const getChitData = chitId => {
  return axiosDefault.get(`/v2/chits/${chitId}/aggregated`).then(function (response) {
    return response.data;
  });
};

export const getChitsOfExtraInProduction = (extraId, productionId) => {
  return axiosDefault.get(`/v2/chits/chits-of-extra-in-production?extraId=${extraId}&productionId=${productionId}`).then(function (response) {
    return response.data;
  });
};

export const setExtraStatus = data => {
  return axiosDefault.post(`/v2/chits/set-extra-status`, data).then(function (response) {
    return response.data;
  });
};

export const changeDailyRate = data => {
  return axiosDefault.post(`/v2/chits/change-daily-rate`, data).then(function (response) {
    return response.data;
  });
};

export const addSupplementaryFee = data => {
  return axiosDefault.post(`/v2/chits/add-supplementary-fee`, data).then(function (response) {
    return response.data;
  });
};

export const removeSupplementaryFee = data => {
  return axiosDefault.post(`/v2/chits/remove-supplementary-fee`, data).then(function (response) {
    return response.data;
  });
};

export const sendCheckInEmail = chitId => {
  return axiosDefault.post(`/v2/chits/${chitId}/send-check-in-email`).then(function (response) {
    return response.data;
  });
};

export const sendCheckOutEmail = chitId => {
  return axiosDefault.post(`/v2/chits/${chitId}/send-check-out-email`).then(function (response) {
    return response.data;
  });
};

export const setCheckOutTime = data => {
  return axiosDefault.post(`/v2/chits/set-check-out-time`, data).then(function (response) {
    return response.data;
  });
};

export const deleteChit = chitId => {
  return axiosDefault.post(`/v2/chits/delete`, {chitId}).then(function (response) {
    return response.data;
  });
};


