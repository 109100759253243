import * as actionTypes from './actionTypes';
import * as appActionTypes from '../app/actionTypes';
import {STEP_SIGN_IN} from './constants';

const initialState = {
  signingIn: false,
  error: null,
  step: STEP_SIGN_IN,
  mfaResolver: null,
  sendingMfaVerificationCode: false,
  mfaVerificationCodeSent: false,
  confirmingVerificationCode: false,
  errorCode: null,
  verificationId: null
}

const reducer = (state = initialState, action) => {
  switch (action.type)  {
    case actionTypes.SET_STEP:
      return {
        ...state,
        step: action.step,
        mfaResolver: action.data ? action.data.mfaResolver : null,
        signingIn: false
      }
    case actionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        signingIn: true,
        error: null
      }
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        signingIn: false,
        error: null
      }
    case actionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        signingIn: false,
        error: action.error
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        sendingMfaVerificationCode: true,
        mfaVerificationCodeSent: false,
        error: null,
        errorCode: null
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS:
      return  {
        ...state,
        sendingMfaVerificationCode: false,
        mfaVerificationCodeSent: true,
        verificationId: action.verificationId
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        error: action.error,
        errorCode: action.errorCode,
        sendingMfaVerificationCode: false,
        mfaVerificationCodeSent: false
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        confirmingVerificationCode: true,
        error: null,
        errorCode: null
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS:
      return  {
        ...state,
        confirmingVerificationCode: false
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        error: action.error,
        errorCode: action.errorCode,
        confirmingVerificationCode: false
      }
    case appActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        step: STEP_SIGN_IN
      }
    default:
      return state;
  }
};

export default reducer;