import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import {
    dateTimeToUveFormat,
    momentToUveDateFormat,
    parseFromDateTime,
} from '../../../../lib/dateTimeUtils';
import { PRODUCTION_DAY_STATUS_INACTIVE } from '../../../constants';
import ConfirmationDialog from '../../../../common/confimration-dialog/ConfirmationDialog';

const ProductionDayRelease = (props) => {
    const {
        currentProductionDay,
        currentProductionDayId,
        setProductionDayRelease,
        productionDayChangeStatusChanging,
    } = props;
    const onRelase = () => setProductionDayRelease(currentProductionDayId);
    const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);

    const showModal = (flag) => () => {
        setConfirmationDialog(flag);
    };

    const handleConfirm = () => {
       
        onRelase();
        setConfirmationDialog(false);
    };
    return (
        <React.Fragment>
            <ConfirmationDialog
                show={showConfirmationDialog}
                onCancel={showModal(false)}
                title={'Are you sure  you want to active this date?'}
                message={'This action cannot be undone.'}
                onConfirm={handleConfirm}
                cancelLabel="Cancel"
                confirmLabel="Send Day's Data"
                size="xs"
            />

            <div className="d-flex align-items-center p-4">
                {(currentProductionDay.status == PRODUCTION_DAY_STATUS_INACTIVE &&
                    !currentProductionDay.sent && (
                        <div className="h-50">
                            <button
                                className="btn btn-xs btn-success"
                                onClick={showModal(true)}
                                title="click to release for AD">
                                {productionDayChangeStatusChanging
                                    ? 'Updating...'
                                    : 'Release to Client'}
                            </button>
                        </div>
                    )) ||
                    (currentProductionDay.sentAt && (
                        <h5>Sent on : {dateTimeToUveFormat(currentProductionDay.sentAt)}</h5>
                    ))}
            </div>
        </React.Fragment>
    );
};

ProductionDayRelease.propTypes = {
    currentProductionDayId: PropTypes.string.isRequired,
    currentProductionDay: PropTypes.objectOf(
        PropTypes.shape({
            sent: PropTypes.bool.isRequired,
            sentAt: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        }),
    ).isRequired,
    productionDayChangeStatusChanging: PropTypes.bool.isRequired,
    productionDayChangeStatusError: PropTypes.bool.isRequired,
    setProductionDayRelease: PropTypes.func.isRequired,
};

export default ProductionDayRelease;
