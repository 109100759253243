import {Link} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {dateToUveFormat} from '../../lib/dateTimeUtils';

class ChitBreadcrumbs extends React.Component {

  render() {
    const { chit, extra } = this.props.chitData;
    const { production } = this.props.productionData;
    return (
      <h4 className="py-2 mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Productions</Link></li>
          <li className="breadcrumb-item"><Link to={`/productions/${production.id}`}>{production.name}</Link></li>
          <li className="breadcrumb-item"><Link to={`/productions/${production.id}/date/${chit.date}`}>{dateToUveFormat(chit.date)}</Link></li>
          <li className="breadcrumb-item active"><a>{extra.firstName} {extra.lastName}</a></li>
        </ol>
      </h4>
    )
  }
}

ChitBreadcrumbs.propTypes = {
  chitData: PropTypes.object.isRequired,
  productionData: PropTypes.object.isRequired
};

export default ChitBreadcrumbs;