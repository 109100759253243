import {call, put, takeEvery} from 'redux-saga/effects'
import * as api from './api';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import {arrayToMap} from '../lib/arrayUtils';
import * as notification from '../lib/notification';

/**
 * Convert list of production records to map.
 * @param productionRecords
 */
const convertProductionRecordsToMap = (productionRecords) => (
  arrayToMap(productionRecords, (record) => (record.production.id))
);

export function* loadProductionRecords(action) {
  try {
    const productionRecords = yield call(api.getProductionRecords);
    const convertedRecords = convertProductionRecordsToMap(productionRecords);
    yield put(actions.loadProductionRecordsSuccess(convertedRecords));
  } catch (error) {
    yield put(actions.loadProductionRecordsFailure(error));
  }
}


const convertImportC5ProductionsResultToMap = (importResult) => ({
    productions: arrayToMap(importResult.productions, 'productionId')
  }
);

export function* importC5Productions(action) {
  try {
    const importResult = yield call(api.importC5Productions);
    const numberOfNewProductions = importResult.productions.filter(production => production.isNew === true).length;
    // Reload productions
    yield put(actions.loadProductionRecordsRequest());

    const convertedResult = convertImportC5ProductionsResultToMap(importResult);
    yield put(actions.importC5ProductionsSuccess(convertedResult));

    const productionsWord = numberOfNewProductions === 1
      ? 'production'
      : 'productions';
    const message = numberOfNewProductions === 0
      ? 'No new productions'
      : `Successfully imported ${numberOfNewProductions} ${productionsWord}. See highlighted rows.`;
    notification.success(message);
  } catch (error) {
    yield put(actions.importC5ProductionsFailure(error));
  }
}


export default function* sagas() {
  yield takeEvery(actionTypes.LOAD_PRODUCTION_RECORDS_REQUEST, loadProductionRecords);
  yield takeEvery(actionTypes.IMPORT_C5_PRODUCTIONS_REQUEST, importC5Productions);
}