import {call, put, takeEvery} from 'redux-saga/effects';
import * as auth from '../lib/auth';
import * as actions from './actions';
import * as actionTypes from './actionTypes';

export function* sendMfaVerificationCode(action) {
  try {
    const {phoneInfoOptions, recaptchaVerifier} = action;
    console.log(phoneInfoOptions);
    const verificationId = yield call([auth, auth.verifyPhoneNumber], phoneInfoOptions, recaptchaVerifier)
    yield put(actions.sendMfaVerificationCodeSuccess(verificationId));
  } catch (error) {
    const message = error.message ? error.message : 'Unknown error';
    const errorCode = error.code;
    yield put(actions.sendMfaVerificationCodeFailure(message, errorCode));
  }
}

export function* confirmVerificationCode(action) {
  try {
    const {firebaseUser, verificationId, code} = action;
    yield call([auth, auth.enrollMfa], firebaseUser, verificationId, code)
    yield put(actions.confirmVerificationCodeSuccess());
  } catch (error) {
    console.log('ERRRRRR');
    console.log(error);
    let message = error.message ? error.message : 'Unknown error';
    const errorCode = error.code;
    if (errorCode === 'auth/invalid-verification-code') {
      message = 'Invalid verification code';
    }
    yield put(actions.confirmVerificationCodeFailure(message, errorCode));
  }
}

export default function* sagas() {
  yield takeEvery(actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST, sendMfaVerificationCode);
  yield takeEvery(actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST, confirmVerificationCode);
}