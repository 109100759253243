import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SignIn from './SignIn';
import {setStep, signInRequest} from '../actions';
import {STEP_MFA, STEP_SIGN_IN} from '../constants';
import Mfa from './Mfa';
import {confirmVerificationCodeRequest, sendMfaVerificationCodeRequest} from '../actions';
import {getQueryParam} from '../../lib/urlUtils';
import config from '../../config/config.default'

class SignInContainer extends React.Component {

  render() {
    const { step } = this.props;
    switch (step) {
      case STEP_SIGN_IN:
        const signOutReason = getQueryParam(window.location.search, 'signOutReason');
        return <SignIn {...this.props} signOutReason={signOutReason} adAppUrl={config.adAppUrl} />
      case STEP_MFA:
        return <Mfa {...this.props} />
    }
  }
}

const mapStateToProps = state => {
  const appState = state.app;
  const signInState = state.signIn;
  return {
    signingIn: signInState.signingIn,
    firebaseUser: appState.firebaseUser,
    step: signInState.step,
    mfaResolver: signInState.mfaResolver,
    confirmingVerificationCode: signInState.confirmingVerificationCode,
    sendingMfaVerificationCode: signInState.sendingMfaVerificationCode,
    error: signInState.error,
    errorCode: signInState.errorCode,
    verificationId: signInState.verificationId,
    mfaVerificationCodeSent: signInState.mfaVerificationCodeSent
  }
};

const actionCreators = {
  signInRequest,
  sendMfaVerificationCodeRequest,
  confirmVerificationCodeRequest,
  setStep
};

export default withRouter(connect(mapStateToProps, actionCreators)(SignInContainer));

