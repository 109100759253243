import React from 'react';
import Header from './Header';
import ProductionsTable from './ProductionsTable';
import './Productions.css';

class Productions extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        <Header {...this.props} />
        <ProductionsTable {...this.props} />
      </div>
    )
  }
}

Productions.propTypes = {
};


export default Productions;