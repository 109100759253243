import * as actionTypes from './actionTypes';

export const verifyPasswordResetCodeRequest = (oobCode) => ({
  type: actionTypes.VERIFY_PASSWORD_RESET_CODE_REQUEST,
  oobCode
});

export const verifyPasswordResetCodeSuccess = () => ({
  type: actionTypes.VERIFY_PASSWORD_RESET_CODE_SUCCESS
});

export const verifyPasswordResetCodeFailure = (error) => ({
  type: actionTypes.VERIFY_PASSWORD_RESET_CODE_FAILURE,
  error
});

export const setPasswordAndVerifyEmailRequest = (oobCode, password) => ({
  type: actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_REQUEST,
  oobCode,
  password
});

export const setPasswordAndVerifyEmailSuccess = (user) => ({
  type: actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS,
  user
});

export const setPasswordAndVerifyEmailFailure = (error) => ({
  type: actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_FAILURE,
  error
});

export const setStep = (step, data) => ({
  type: actionTypes.SET_STEP,
  step,
  data
});
