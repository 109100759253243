import React from 'react';

const ChitsHeaderCallDetails = props => {
  return (
    <React.Fragment>
      <td className="chit-column-address">Address</td>
      <td className="chit-column-contact-name">Contact Name</td>
      <td className="chit-column-contact-number">Contact Number</td>
      <td className="chit-column-costume">Costume</td>
      <td className="chit-column-other-info">Other Info</td>
    </React.Fragment>
  )
};

ChitsHeaderCallDetails.propTypes = {};

export default ChitsHeaderCallDetails;
