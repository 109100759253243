import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { TranspotationGroupDelete } from './TranspotationGroupDelete';

const ProductionTranspotationGroupRow = (props) => {
  const { transportGroup, setTransportationGroupShowHideWindow, showHideTarnspotationGroups } = props;
  const { id, name, locationId } = transportGroup;

  const onToggleDelete = (e) => {
    setTransportationGroupShowHideWindow(transportGroup.id);
  };

  const onToggleUpdate = (e) => {
    showHideTarnspotationGroups(transportGroup.id);
  };

  return (
    <React.Fragment>
      <TranspotationGroupDelete {...props} />
      <tr>
        <td className="text-capitalize" style={{ width: '95%' }}>
          {name}
        </td>
        <td colSpan={4}>
          <div className="d-flex justify-content-between">
            <div className="mx-2">
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={onToggleUpdate}>
                Edit
              </button>
            </div>

            <div>
              <button type="button" className="btn btn-outline-danger btn-sm" onClick={onToggleDelete}>
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

ProductionTranspotationGroupRow.propTypes = {
  transportGroup: PropTypes.object.isRequired,
  setTransportationGroupShowHideWindow: PropTypes.func.isRequired,
  showHideTarnspotationGroups: PropTypes.func.isRequired,
};

export default ProductionTranspotationGroupRow;
