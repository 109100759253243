import React from 'react';
import ChitSection3LeftSection2PayRate from './ChitSection3LeftSection2PayRate';
import ChitSection3LeftSection2SupplementaryFees from './ChitSection3LeftSection2SupplementaryFees';
import ChitSection3LeftSection2ChitTotal from './ChitSection3LeftSection2ChitTotal';

class ChitSection3LeftSection2 extends React.Component {
  render() {
    return (
      <div className="row" style={{marginLeft: '-2.2rem', marginRight: '-2.3rem', marginTop: '3rem'}}>
        <div className="col">
          <table className="table card-table chit-table mb-0">
            <tbody>
              <ChitSection3LeftSection2PayRate {...this.props} />
              <ChitSection3LeftSection2SupplementaryFees {...this.props} />
              <ChitSection3LeftSection2ChitTotal {...this.props} />
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ChitSection3LeftSection2;