import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import {
    CALL_DETAILS_RESPONSE_ACTUAL_STATUS_POSITIVE,
    CALL_DETAILS_STATUS_YES,
} from '../../constants';

const CallDetailsResponseWindow = (props) => {
    const {
        setChitCallDetailsResponseRequest,
        showHideCallDetailsWindow,
        productionDayshowCallDetailsResponseWindow: show,
        productionDayCallDetailsResponseNetworkCallRunning,
        record,
        responseStatus,
        closeModal
    } = props;

    const chitResponseMessage = record.chit.callDetails?.response?.message || '';

    const handleResponseStaus = React.useCallback(() => {
        setChitCallDetailsResponseRequest(
            record.chit.id,
            CALL_DETAILS_RESPONSE_ACTUAL_STATUS_POSITIVE,
        );
    }, []);
    return (
        <Modal show={show} onHide={closeModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>Call Details Response</h5>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label for="production-chit-call-details-response">Status</label>
                            <input
                                name="response"
                                className="form-control"
                                disabled
                                id="production-chit-call-details-response"
                                value={responseStatus}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        {responseStatus!== CALL_DETAILS_STATUS_YES && (
                            <div className="form-group" style={{ paddingTop: '2.2em' }}>
                                <Button
                                    onClick={handleResponseStaus}
                                    disabled={productionDayCallDetailsResponseNetworkCallRunning}>
                                    Change to YES
                                </Button>
                            </div>
                        )}
                    </Col>
                    <Col md={12}>
                        <div className="form-group">
                            <label for="call-detils-response">SMS Response</label>
                            <Form.Control
                                as="textarea"
                                disabled
                                rows={6}
                                col={6}
                                value={chitResponseMessage}
                                name="response"
                                id="call-details-response"
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {productionDayCallDetailsResponseNetworkCallRunning && (
                <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />
            )}

            <Modal.Footer>
                <Button variant="default w-25" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CallDetailsResponseWindow.propTypes = {
    setChitCallDetailsResponseRequest: PropTypes.func.isRequired,
    showHideCallDetailsWindow: PropTypes.func.isRequired,
    productionDayshowCallDetailsResponseWindow: PropTypes.bool.isRequired,
    productionDayCallDetailsResponseNetworkCallRunning: PropTypes.bool.isRequired,
    responseStatus: PropTypes.func.isRequired,
};

export default CallDetailsResponseWindow;
