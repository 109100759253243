import React from 'react';
import PropTypes from 'prop-types';
import ProductionDayStatus from './ProductionDayStatus';
import ChitsTotal from './TotalChits';
import Options from './Options';
import Notes from './Notes';

const ProductionDayDetails = (props) => {
  React.useEffect(()=> {
    props.deselectAllChits()
  },[])
  return (
    <div className="border-right-0 border-left-0 ui-bordered container-m--x mb-4">
      <div className="row no-gutters row-bordered row-border-light" style={{overflow: 'visible'}}>
        <div className="col-md-3">
          <ProductionDayStatus {...props} />
        </div>
        <div className="col-md-2">
          <ChitsTotal {...props} />
        </div>
        <div className="col-md-2">
          <Options {...props} /> 
        </div>
        <div className="col-md-4">
        <Notes {...props}/>
        </div>
      </div>

    </div>
  )
};

ProductionDayDetails.propTypes = {
  adAssignedToCurrentProductionDay: PropTypes.object.isRequired
};

export default ProductionDayDetails;

