import {call, put, takeEvery} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as api from './api';
import {constructResponseError} from '../lib/network';
import { auth } from '../lib/auth'

export function* sendPasswordResetLink(action) {
  try {
    const {email, sourceApp} = action;
    yield call(api.sendPasswordResetLink, email, sourceApp);
    yield put(actions.sendPasswordResetLinkSuccess());
  } catch (error) {
    const errorData = constructResponseError(error);
    yield put(actions.sendPasswordResetLinkFailure(errorData.message));
  }
}

export function* setPassword(action) {
  try {
    const {oobCode, password} = action;
    console.log(oobCode);
    console.log(password);
    // auth.confirmPasswordReset(oobCode, password).then(d => console.log(d)).catch(e => console.log(e))
    yield call([auth, auth.confirmPasswordReset], oobCode, password);
    yield put(actions.setPasswordSuccess());
  } catch (error) {
    console.log(error);
    const message = error.message ? error.message : 'Unknown error';
    yield put(actions.setPasswordFailure(message));
  }
}


export default function* sagas() {
  yield takeEvery(actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST, sendPasswordResetLink);
  yield takeEvery(actionTypes.SET_PASSWORD_REQUEST, setPassword);
}