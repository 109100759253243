import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal} from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import * as constants from '../../../constants';
import * as getters from '../../../getters';

class AddSupplementaryFee extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      feeName: null,
      payRate: null
    };
  };

  multipleProductionUnitsAreSelected = () => {
    const productionUnits = {};
    for (const chitId in this.props.idsOfSelectedChitsCrossedByFiltered) {
      productionUnits[this.props.filteredChitRecordsByChitId[chitId].productionUnit.id] = true;
    }

    return Object.keys(productionUnits).length > 1;
  };

  getSupplementaryFeeOptions = rateCardItems => {
    return rateCardItems
      .filter(item => item.rateType === constants.RATE_TYPE_SUPPLEMENTARY_FEE)
      .map(item => {
        // For custom supplementary fee do not set pay rate in label since pay rate is custom
        const label = item.title === constants.CUSTOM_SUPPLEMENTARY_FEE_NAME ? item.title : `${item.title} - ${item.payRate}`;
        return {
        value: item.title,
        label,
        payRate: parseFloat(item.payRate.replace('£', ''))
      }});
  };

  handleSupplementaryFeeChange = option => {
    this.setState({
      feeName: option.value,
      payRate: option.payRate
    })
  };

  handlePayRateChange = event => {
    this.setState({
      payRate: event.target.value
    });
  };

  handleAddSupplementaryFee = (paySchemeId) => {
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.addSupplementaryFeeToChitsRequest(chitIds, paySchemeId, this.state.feeName, this.state.payRate, this.props.productionId, this.props.productionDate);
  };

  getSelectedProductionUnitsPayScheme = () => {
    const firstChitId = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered)[0];
    if (!firstChitId) {
      return null;
    }

    const firstChitRecord = this.props.filteredChitRecordsByChitId[firstChitId];
    const productionUnit = firstChitRecord.productionUnit;
    return getters.getProductionUnitsPayScheme(productionUnit, this.props.production, this.props.rateCards);
  }

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    const addSupplementaryFeeButtonLabel = this.props.bulkActionsRunning ? 'Adding Supplementary Fee...' : 'Add Supplementary Fee';
    const isMultipleProductionUnitsAreSelected  = this.multipleProductionUnitsAreSelected();
    const selectedProductionUnitsPayScheme = this.getSelectedProductionUnitsPayScheme();
    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsAddSupplementaryFeeWindow.bind(this, false)} keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Add supplementary fee to selected chits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isMultipleProductionUnitsAreSelected &&
          <Alert variant='warning'>
            Chits of multiple production units are selected, please select chits of single production unit.
          </Alert>
          }
          {!isMultipleProductionUnitsAreSelected &&
            <div>
              <div className="form-group">
              <Select
                options={this.getSupplementaryFeeOptions(selectedProductionUnitsPayScheme.items)}
                placeholder="Supplementary Fee..."
                className="basic-multi-select"
                onChange={this.handleSupplementaryFeeChange.bind(this)}
              />
              </div>
              {this.state.feeName === constants.CUSTOM_SUPPLEMENTARY_FEE_NAME &&
                <div className="form-group">
                  <input type="number" className="form-control" placeholder="Fee, £"
                         onChange={this.handlePayRateChange}
                         autoFocus
                  />
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {isMultipleProductionUnitsAreSelected &&
          <Button variant="default" onClick={this.props.showHideBulkActionsAddSupplementaryFeeWindow.bind(this, false)}>
            Ok
          </Button>
          }

          {!isMultipleProductionUnitsAreSelected && this.props.bulkActionsRunning &&
            <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          {!isMultipleProductionUnitsAreSelected &&
          <Button variant="default" onClick={this.props.showHideBulkActionsAddSupplementaryFeeWindow.bind(this, false)} disabled={this.props.bulkActionsRunning}>
            Cancel
          </Button>
          }
          {!isMultipleProductionUnitsAreSelected &&
          <Button variant="primary" onClick={this.handleAddSupplementaryFee.bind(this, selectedProductionUnitsPayScheme.id)} disabled={this.props.bulkActionsRunning || this.state.feeName == null || this.state.feeName === constants.CUSTOM_SUPPLEMENTARY_FEE_NAME && !this.state.payRate}>
            {addSupplementaryFeeButtonLabel}
          </Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

AddSupplementaryFee.propTypes = {
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  production: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsAddSupplementaryFeeWindow: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired,
  addSupplementaryFeeToChitsRequest: PropTypes.func.isRequired,
  rateCards: PropTypes.object.isRequired
};

export default AddSupplementaryFee;