import React from 'react';
import {Alert} from 'react-bootstrap';
import {
  CANCELLATION_FEE_TYPE_FULL,
  CANCELLATION_FEE_TYPE_PARTIAL,
  CANCELLATION_FEE_TYPE_ZERO
} from '../../production-day/constants';
import PropTypes from 'prop-types';
import {getCancellationFeeTypeLabel} from '../getters';

class SetExtrasStatusToCancelled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancellationFeeType: null
    }
  };

  handleCancellationFeeTypeChange = cancellationFeeType => {
    this.setState({cancellationFeeType}, () => this.props.onChange(
      {cancellationFeeType: this.state.cancellationFeeType},
      true
    ))
  }

  render = () => {
    const { chit } = this.props.chitData;
    const { chitCancellationFees } = this.props;
    if (!chit.dailyRateName) {
      return (
        <Alert variant='warning'>
          Cancellation Fees can only be applied if a Daily Rate for that chit has been
          selected.
        </Alert>
      )
    }

    return (
      <div>
        {chit.cancellationDetails &&
        <div className="card">
          <div className="card-body">
            Current Cancellation Fee:
            <strong>
              &nbsp; {getCancellationFeeTypeLabel(chit.cancellationDetails.type)}
              {chit.cancellationDetails.amount !== null &&
              <span>&nbsp;(£{chit.cancellationDetails.amount})</span>
              }
            </strong> <br />
          </div>
        </div>
        }
        <strong>
          Please select cancellation fee:
        </strong>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="cancellationFeeOption" id="fullCancellationFee"
                 value={CANCELLATION_FEE_TYPE_FULL}
                 checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_FULL}
                 onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_FULL)}
          />
          <label className="form-check-label" htmlFor="fullCancellationFee">
            {getCancellationFeeTypeLabel(CANCELLATION_FEE_TYPE_FULL)}
            {chitCancellationFees &&
            <span> (£{chitCancellationFees.full})</span>
            }
            {!chitCancellationFees &&
              <span> (Not set in daily rate, using £0)</span>
            }
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="cancellationFeeOption" id="partialCancellationFee"
                 value={CANCELLATION_FEE_TYPE_PARTIAL}
                 checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_PARTIAL}
                 onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_PARTIAL)}
          />
          <label className="form-check-label" htmlFor="partialCancellationFee">
            {getCancellationFeeTypeLabel(CANCELLATION_FEE_TYPE_PARTIAL)}
            {chitCancellationFees &&
            <span> (£{chitCancellationFees.partial})</span>
            }
            {!chitCancellationFees &&
            <span> (Not set in daily rate, using £0)</span>
            }
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="cancellationFeeOption" id="zeroCancellationFee"
                 value={CANCELLATION_FEE_TYPE_ZERO}
                 checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_ZERO}
                 onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_ZERO)}
          />
          <label className="form-check-label" htmlFor="zeroCancellationFee">{getCancellationFeeTypeLabel(CANCELLATION_FEE_TYPE_ZERO)}</label>
        </div>
      </div>
    )
  }
}

SetExtrasStatusToCancelled.propTypes = {
  onChange: PropTypes.func.isRequired,
  chitData: PropTypes.object.isRequired,
  chitCancellationFees: PropTypes.object
}

export default SetExtrasStatusToCancelled;