import React from 'react';
import PropTypes from 'prop-types';
import ProductionTransportation from './ProductionTranspotation';
import { connect } from 'react-redux';
import {
  showHideTarnspotationGroups,
  setCreateTarnspotationGroups,
  setShowHideLocation,
  setCreateLocation,
  setUpdateTranspotationGroups,
  setTranspotationGroupDeleteRequest,
  setTransportationGroupShowHideWindow,
  setShowHideLocationEditModal,
  setEditLocationRequest,
  setDeleteLocationRequest,
} from '../../actions';
import { arrayToMap } from '../../../lib/arrayUtils';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  const productionState = state.production;

  return {
    ...productionState,
    transportGroups: productionState.production.transportGroups,
    productionTranspotationGroupShowUpdateOrCreateWindow:
    productionState.productionTranspotationGroupShowUpdateOrCreateWindow,
    productionTranspotationGroupUpdateOrCreateing: productionState.productionTranspotationGroupUpdateOrCreateing,
    productionTranspotationGroupUpdateOrCreateError: productionState.productionTranspotationGroupUpdateOrCreateError,
    productionTranspotationGroupUpdateOrCreateActiveId: productionState.productionTranspotationGroupUpdateOrCreateActiveId,
    location: productionState.location,
  };
};

const mapDispatchToProps = {
  showHideTarnspotationGroups,
  setCreateTarnspotationGroups,
  setUpdateTranspotationGroups,
  setTranspotationGroupDeleteRequest,
  setTransportationGroupShowHideWindow,
  setCreateLocation,
  setShowHideLocation,
  setShowHideLocationEditModal,
  setEditLocationRequest,
  setDeleteLocationRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductionTransportation));
