import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Users from "./Users";
import store from "../../store";
import * as actions from "../actions";
import * as getters from "../getters";
import {
  activateUserRequest,
  createUserRequest, deactivateUserRequest, sendVerificationEmailRequest,
  showHideCreateUserWindow,
  showHideEditUserWindow, unEnrollUserFromMfaRequest,
  updateUserRequest
} from '../actions';

class UsersContainer extends React.Component {

  componentDidMount() {
    store.dispatch(actions.loadUsersRequest());
  }

  render = () => {
    const {isLoaded} = this.props;
    if (!isLoaded) return null;
    return <Users {...this.props} />

  }
}

const mapStateToProps = state => {
  const usersState = state.users;
  return {
    isLoading: usersState.isLoading,
    isLoaded: usersState.isLoaded,
    users: getters.getUsers(state),
    userCreationError: usersState.userCreationError,
    userCreating: usersState.userCreating,
    userShowCreationWindow: usersState.userShowCreationWindow,
    userUpdateError: usersState.userUpdateError,
    userShowHideEditWindow: usersState.userShowHideEditWindow,
    userUpdating: usersState.userUpdating,
    userShowEditWindow: usersState.userShowEditWindow,
    userBeingUpdated: usersState.userBeingUpdated,
    unEnrollingUserFromMfa: usersState.unEnrollingUserFromMfa,
    sendingVerificationEmail: usersState.sendingVerificationEmail,
  }
};

const actionCreators = {
  showHideCreateUserWindow,
  showHideEditUserWindow,
  createUserRequest,
  updateUserRequest,
  activateUserRequest,
  deactivateUserRequest,
  unEnrollUserFromMfaRequest,
  sendVerificationEmailRequest,
};

export default withRouter(connect(mapStateToProps, actionCreators)(UsersContainer));
