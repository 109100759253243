import React from 'react';

class SetExtraStatusToBooked extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="alert alert-warning" role="alert" >
            This will re-set check-in time and payments.
          </div>
        </div>
      </div>
    )
  }
}

export default SetExtraStatusToBooked;