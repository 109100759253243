import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {dateToUveFormat} from '../../lib/dateTimeUtils';
import store from "../../store";
import * as actions from "../actions";

class ChitSection1 extends React.Component {

  getProductionDaysOptions = chits => {
    return chits.map(chit => ({
      value: chit.id,
      label: dateToUveFormat(chit.date)
    }));
  };

  handleProductionDayChange = option => {
    const chitId = option.value;
    store.dispatch(actions.loadChitDataRequest(chitId));
    this.props.history.push(`/chits/${chitId}`);
  }

  render() {
    const { chit, extra, productionUnit, payScheme } = this.props.chitData;
    const { production } = this.props.productionData;
    const { chitsOfExtraInProduction } = this.props;

    const currentDayOption = {
      value: chit.date,
      label: dateToUveFormat(chit.date)
    };

    return (
      <div className="border-right-0 border-left-0 ui-bordered container-m--x mb-0 chit-view-info">
        <div className="row no-gutters row-bordered row-border-light" style={{overflow: 'inherit'}}>
          <div className="col-md-3">
            <div className="container-p-x py-2">
              <div className="text-muted small">Email</div>
              <strong className="text-default text-big">{extra.email}</strong>
            </div>
          </div>
          <div className="col-md-2">
            <div className="container-p-x py-2">
              <div className="text-muted small">Mobile</div>
              <strong className="text-default text-big">{extra.mobileNumber}</strong>
            </div>
          </div>
          <div className="col-md-3">
            <div className="container-p-x py-2">
              <div className="text-muted small">Pay Scheme</div>
              <strong className="text-default text-big">{payScheme ? payScheme.title : ''}</strong>
            </div>
          </div>
          <div className="col-md-2">
            <div className="container-p-x py-2">
              <div className="text-muted small">Production Unit</div>
              <strong className="text-default text-big"><Link to={`/productions/${production.id}/units/${productionUnit.id}`}>{productionUnit.name}</Link></strong>
            </div>
          </div>
          <div className="col-md-2">
            <div className="container-p-x py-2">
              <div className="text-muted small">Date</div>
              <Select
                options={this.getProductionDaysOptions(chitsOfExtraInProduction)}
                placeholder="Day..."
                onChange={this.handleProductionDayChange.bind(this)}
                value={currentDayOption}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ChitSection1.propTypes = {
  chitData: PropTypes.object.isRequired,
  productionData: PropTypes.object.isRequired,
  chitsOfExtraInProduction: PropTypes.array.isRequired,
  payScheme: PropTypes.object
};

export default ChitSection1;