import * as actionTypes from './actionTypes';
import {
  STEP_SET_PASSWORD_AND_VERIFY_EMAIL,
  STEP_SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS,
  STEP_VERIFY_PASSWORD_RESET_CODE,
} from './constants';

const initialState = {
  currentStep: STEP_SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS,
  verifyingPasswordRestCode: null,
  settingPasswordAndVerifyingEmail: false,
  error: null,
  oobCode: null,
  user: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP:
      return {
        ...state,
        currentStep: action.step,
        oobCode: action.data.oobCode
      }
    case actionTypes.VERIFY_PASSWORD_RESET_CODE_REQUEST:
      return {
        ...state,
        verifyingPasswordRestCode: true,
        error: null,
        currentStep: STEP_VERIFY_PASSWORD_RESET_CODE,
        oobCode: action.oobCode
      };
    case actionTypes.VERIFY_PASSWORD_RESET_CODE_SUCCESS:
      return {
        ...state,
        verifyingPasswordRestCode: false,
        currentStep: STEP_SET_PASSWORD_AND_VERIFY_EMAIL,
        error: null
      };
    case actionTypes.VERIFY_PASSWORD_RESET_CODE_FAILURE:
      return {
        ...state,
        verifyingPasswordRestCode: false,
        error: action.error
      };
    case actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        settingPasswordAndVerifyingEmail: true,
        error: null
      }
    case actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        settingPasswordAndVerifyingEmail: false,
        error: null,
        currentStep: STEP_SET_PASSWORD_AND_VERIFY_EMAIL_SUCCESS,
        user: action.user
      }
    case actionTypes.SET_PASSWORD_AND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        settingPasswordAndVerifyingEmail: false,
        error: action.error,
      }
    default:
      return state;
  }
};

export default reducer;