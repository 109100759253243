import React from 'react';
import CentredLayout from '../../app/components/CentredLayout';

class OnboardingLayout extends React.Component {

  render() {
    return (
      <CentredLayout title="Onboarding">{this.props.children}</CentredLayout>
    )
  }
}

export default OnboardingLayout;