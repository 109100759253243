import React from 'react';
import PropTypes from 'prop-types';
import PasswordResetLayout from './PasswordResetLayout';
import PasswordSetter from '../../common/password-setter/PasswordSetter';

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordStrength: 0
    }
  }

  handlePasswordStrengthChange = strength => {
    this.setState({
      passwordStrength: strength
    })
  }

  render() {
    return (
      <PasswordResetLayout>
        <PasswordSetter {...this.props} />
      </PasswordResetLayout>
    )
  }
}

ResetPassword.propTypes = {
  setPasswordRequest: PropTypes.func.isRequired,
  settingPassword: PropTypes.bool.isRequired,
  setPasswordError: PropTypes.string,
  passwordResetCode: PropTypes.string.isRequired
}

export default ResetPassword;