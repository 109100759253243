import * as actionTypes from './actionTypes';
import PropTypes from 'prop-types';

const initialState = {
  isLoading: true,
  isLoaded: false,
  production: {
    transportGroups: []
  },
  productionDays: {},
  productionUnits: {},
  productionDocuments: [],
  data: {},
  activeTab: '',
  importingProductionDays: false,
  specificDayBeingImported: null,
  importType: null,
  productionUnitCreationError: null,
  productionUnitCreating: false,
  productionUnitShowCreationWindow: false,
  productionUnitBasicInfoUpdating: false,
  productionUnitBasicInfoBeingUpdated: null,
  productionUnitUpdateBasicInfoError: null,
  productionUnitShowUpdateBasicInfoWindow: false,
  productionDayShowQrCodeWindow: false,
  productionDayProductionQrCodeBeingShown: null,
  productionDayQrCodeBeingShown: null,
  currentProductionDayQrCode: null,
  currentProductionDayQrCodeAction: null,
  productionDocumentShowCreationWindow: false,
  productionDocumentCreating: false,
  productionDocumentCreateError: null,
  productionDocumentShowUpdateWindow: false,
  productionDocumentUpdating: false,
  productionDocumentUpdateError: null,
  // location newly added 
  location: {},
  showLocationCreateUpdateWindow: false,
  isLocationCreatingOrUpdating: false,
  locationEditId: "",






  // transpotation group
  productionTranspotationGroupShowUpdateOrCreateWindow: false,
  productionTranspotationGroupUpdateOrCreateing: false,
  productionTranspotationGroupUpdateOrCreateError: null,
  productionTranspotationGroupUpdateOrCreateActiveId: 0,
  productionTranspotationGroupShowDeleteWindow: false,
  productionTranspotationGroupDeleting: false,
  productionTranspotationGroupDeleteId: 0,









};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PRODUCTION_SUCCESS:
      return {
        ...state,
        ...action.productionData,
        isLoading: false,
        isLoaded: true
      };
    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
    case actionTypes.SET_DEFAULT_PAY_SCHEME_SUCCESS:
      return {
        ...state,
        production: action.production
      };
    case actionTypes.IMPORT_PRODUCTION_DAYS_REQUEST:
      return {
        ...state,
        importingProductionDays: true,
        specificDayBeingImported: action.startDate,
        importType: action.importType
      };
    case actionTypes.IMPORT_PRODUCTION_DAYS_FAILURE:
    case actionTypes.IMPORT_PRODUCTION_DAYS_SUCCESS:
      return {
        ...state,
        importingProductionDays: false,
        specificDayBeingImported: null,
        importType: null
      };
    case actionTypes.SHOW_HIDE_CREATE_PRODUCTION_UNIT_WINDOW:
      return {
        ...state,
        productionUnitShowCreationWindow: action.show,
        productionUnitCreationError: null
      }
    case actionTypes.CREATE_PRODUCTION_UNIT_REQUEST:
      return {
        ...state,
        productionUnitCreating: true,
        productionUnitCreationError: null
      }
    case actionTypes.CREATE_PRODUCTION_UNIT_SUCCESS:
      return {
        ...state,
        productionUnits: {
          ...state.productionUnits,
          [action.productionUnit.id]: action.productionUnit
        },
        productionUnitCreating: false,
        productionUnitShowCreationWindow: false
      }
    case actionTypes.CREATE_PRODUCTION_UNIT_FAILURE:
      return {
        ...state,
        productionUnitCreating: false,
        productionUnitCreationError: action.error
      }
    case actionTypes.SHOW_HIDE_UPDATE_PRODUCTION_UNIT_BASIC_INFO_WINDOW:
      return {
        ...state,
        productionUnitShowUpdateBasicInfoWindow: action.show,
        productionUnitUpdateBasicInfoError: null,
        productionUnitBasicInfoBeingUpdated: action.productionUnit
      }
    case actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_REQUEST:
      return {
        ...state,
        productionUnitBasicInfoUpdating: true,
        productionUnitUpdateBasicInfoError: null
      }
    case actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_SUCCESS:
      return {
        ...state,
        productionUnits: {
          ...state.productionUnits,
          [action.productionUnit.id]: action.productionUnit
        },
        productionUnitBasicInfoUpdating: false,
        productionUnitShowUpdateBasicInfoWindow: false,
        productionUnitBasicInfoBeingUpdated: null
      }
    case actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_FAILURE:
      return {
        ...state,
        productionUnitBasicInfoUpdating: false,
        productionUnitUpdateBasicInfoError: action.error
      }
    case actionTypes.SET_PRODUCTION_UNIT_PAY_SCHEME_SUCCESS:
    case actionTypes.ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_SUCCESS:
    case actionTypes.UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_SUCCESS:
      return {
        ...state,
        productionUnits: {
          ...state.productionUnits,
          [action.productionUnit.id]: action.productionUnit
        }
      }
    case actionTypes.ADD_ADS_TO_PRODUCTION_SUCCESS:
    case actionTypes.REMOVE_AD_FROM_PRODUCTION_SUCCESS:
      return {
        ...state,
        production: action.production
      }
    case actionTypes.DELETE_PRODUCTION_UNIT_SUCCESS: {
      return {
        ...state,
        productionUnits: Object.fromEntries(Object.entries(state.productionUnits).filter(([id, unit]) => id !== action.productionUnit.id))
      }
    }
    case actionTypes.SHOW_HIDE_PRODUCTION_DAY_QR_CODE_WINDOW:
      if (action.show) {
        return {
          ...state,
          productionDayShowQrCodeWindow: action.show,
          productionDayProductionQrCodeBeingShown: action.production,
          productionDayQrCodeBeingShown: action.productionDay,
          currentProductionDayQrCodeAction: action.action
        }
      } else {
        return {
          ...state,
          productionDayShowQrCodeWindow: action.show,
          productionDayProductionQrCodeBeingShown: null,
          productionDayQrCodeBeingShown: null,
          currentProductionDayQrCode: null,
          currentProductionDayQrCodeAction: null
        }
      }
    case actionTypes.GET_ACTION_TOKEN_QR_CODE_SUCCESS:
      return {
        ...state,
        currentProductionDayQrCode: action.qrCode,
        currentProductionDayQrCodeAction: action.action
      }
    case actionTypes.SHOW_HIDE_CREATE_PRODUCTION_DOCUMENT_WINDOW:
      return {
        ...state,
        productionDocumentShowCreationWindow: action.show,
        productionDocumentCreateError: null
      }
    case actionTypes.CREATE_PRODUCTION_DOCUMENT_REQUEST:
      return {
        ...state,
        productionDocumentCreating: true,
        productionDocumentCreateError: null
      }
    case actionTypes.CREATE_PRODUCTION_DOCUMENT_SUCCESS:
      return {
        ...state,
        productionDocumentCreating: false,
        productionDocumentShowCreationWindow: false
      }
    case actionTypes.CREATE_PRODUCTION_DOCUMENT_FAILURE:
      return {
        ...state,
        productionDocumentCreating: false,
        productionDocumentCreateError: action.error
      }
    case actionTypes.SHOW_HIDE_UPDATE_PRODUCTION_DOCUMENT_WINDOW:
      return {
        ...state,
        productionDocumentShowUpdateWindow: action.show,
        productionDocumentUpdateError: null,
        productionDocumentBeingUpdated: action.productionDocument
      }

    case actionTypes.UPDATE_PRODUCTION_DOCUMENT_REQUEST:
      return {
        ...state,
        productionDocumentUpdating: true,
        productionDocumentUpdateError: null
      }
    case actionTypes.UPDATE_PRODUCTION_DOCUMENT_SUCCESS:
      return {
        ...state,
        productionDocumentUpdating: false,
        productionDocumentShowUpdateWindow: false
      }
    case actionTypes.UPDATE_PRODUCTION_DOCUMENT_FAILURE:
      return {
        ...state,
        productionDocumentUpdating: false,
        productionDocumentUpdateError: action.error
      }
    case actionTypes.SET_TRANSPOTATION_GROUP_CREATE_UPDATE_MODAL_SHOW_HIDE: {
      if (state.productionTranspotationGroupShowUpdateOrCreateWindow) {
        return {
          ...state,
          productionTranspotationGroupShowUpdateOrCreateWindow: false,
          productionTranspotationGroupUpdateOrCreateActiveId: 0
        }
      }
      return {
        ...state,
        productionTranspotationGroupShowUpdateOrCreateWindow: true,
        productionTranspotationGroupUpdateOrCreateActiveId: action.transportGroupId

      }

    }

    case actionTypes.SET_TRANSPOTATION_GROUP_CREATEING:
      return {
        ...state,
        productionTranspotationGroupUpdateOrCreateing: true

      }
    case actionTypes.SET_TRANSPOTATION_GROUP_CREATEING_SUCCESS: return JSON.parse(JSON.stringify({
      ...state,
      production: {
        ...action.productionData
      },
      productionTranspotationGroupUpdateOrCreateing: false,
      productionTranspotationGroupUpdateOrCreateActiveId: 0,
      productionTranspotationGroupShowUpdateOrCreateWindow: false
    }))
    case actionTypes.SET_TRANSPOTATION_GROUP_CREATEING_FAILTURE: return {
      ...state,
      productionTranspotationGroupUpdateOrCreateing: false,
      productionTranspotationGroupUpdateOrCreateError: action.error,
    }
    case actionTypes.LOAD_LOCATION_DATA_SUCCESS: return {
      ...state,
      location: action.location

    }
    case actionTypes.SET_LOCATION_CREATE_MODAL_SHOW_HIDE: return {
      ...state,
      showLocationCreateUpdateWindow: !state.showLocationCreateUpdateWindow,
      locationEditId: ''
    }
    case actionTypes.SET_LOCATION_CREATEING: return {
      ...state,
      isLocationCreatingOrUpdating: true,

    }
    case actionTypes.SET_LOCATION_CREATEING_SUCCESS: return {
      ...state,
      showLocationCreateUpdateWindow: false,
      isLocationCreatingOrUpdating: false,
      location: {
        ...state.location,
        [action.newLocRecord.id]: action.newLocRecord,
      }
    }
    case actionTypes.SET_LOCATION_CREATEING_FAILTURE: return {
      ...state,
      isLocationCreatingOrUpdating: false,
    }

    case actionTypes.SET_LOCATION_EDIT_MODAL_SHOW_HIDE: return {
      ...state,
      locationEditId: action.id,
      showLocationCreateUpdateWindow: true,
    }

    case actionTypes.SET_LOCATION_EDIT_REQUEST: return {
      ...state,
      isLocationCreatingOrUpdating: true,

    }

    case actionTypes.SET_LOCATION_EDIT_SUCCESS: return {
      ...state,
      showLocationCreateUpdateWindow: false,
      isLocationCreatingOrUpdating: false,
      locationEditId: '',
      location: {
        ...state.location,
        [action.updatedLocation.id]: action.updatedLocation

      }

    }

    case actionTypes.SET_LOCATION_EDIT_FAILTURE: return {
      ...state,
      isLocationCreatingOrUpdating: false,
    }

    case actionTypes.SET_LOCATION_DELETE_REQUEST: {

      return {
        ...state,

        isLocationCreatingOrUpdating: true,
      }

    }
    case actionTypes.SET_LOCATION_DELETE_SUCCESS: {
      const currentLocations = { ...state.location };
      delete currentLocations[action.deletedLocationId];
      return {
        ...state,
        isLocationCreatingOrUpdating: false,
        location: {
          ...currentLocations,
        },
        locationEditId: "",
        showLocationCreateUpdateWindow : false

      }
    }
    case actionTypes.SET_LOCATION_DELETE_FAILTURE: return {
      ...state,
      isLocationCreatingOrUpdating: false,
    }

    case actionTypes.SET_TRANSPOTATION_GROUP_DELETE_WINDOW: {
      if (state.productionTranspotationGroupShowDeleteWindow) {
        return {
          ...state,
          productionTranspotationGroupDeleteId: 0,
          productionTranspotationGroupShowDeleteWindow: false
        }
      } else {
        return {
          ...state,
          productionTranspotationGroupShowDeleteWindow: true,
          productionTranspotationGroupDeleteId: action.transportGroupId

        }
      }

    }
    case actionTypes.SET_TRANSPOTATION_GROUP_DELETE_REQUEST: return {
      ...state,
      productionTranspotationGroupDeleting: true,
      productionTranspotationGroupDeleteId: action.transportGroupId

    }
    case actionTypes.SET_TRANSPOTATION_GROUP_DELETE_SUCCESS: return {
      ...state,
      production: {
        ...action.productionData
      },
      productionTranspotationGroupDeleteId: 0,
      productionTranspotationGroupShowDeleteWindow: false
    }
    case actionTypes.SET_TRANSPOTATION_GROUP_UPDATE_SUCCESS: {
      return {
        ...state,
        production: {
          ...action.productionData
        },
        productionTranspotationGroupShowUpdateOrCreateWindow: false,
        productionTranspotationGroupUpdateOrCreateing: false,
        showLocationCreateUpdateWindow: false,
        isLocationCreatingOrUpdating: false


      }
    }
    default:
      return state;
  }
};

export default reducer;