import React from 'react';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
const deletModalData = {
  title: 'Delete Transpotation Group',
  message: (recordName) => `Are you sure you want to delete  Transpotation Group ${recordName} ?`,
};
export const TranspotationGroupDelete = (props) => {
  const {
    transportGroup,
    setTranspotationGroupDeleteRequest,
    productionTranspotationGroupShowDeleteWindow,
    setTransportationGroupShowHideWindow,
    productionTranspotationGroupDeleteId,
  } = props;
  const { id, name, locationId } = transportGroup;
  const [show, setShow] = [
    productionTranspotationGroupShowDeleteWindow && transportGroup.id == productionTranspotationGroupDeleteId,
    setTransportationGroupShowHideWindow,
  ];
  const onToggle = (e) => {
    setTransportationGroupShowHideWindow(transportGroup.id);
  };

  const productionId = props.match.params.id;
  const onConfirm = () => {
    setTranspotationGroupDeleteRequest(productionId, transportGroup.id);
  };

  return (
    <ConfirmationDialog
      show={show}
      title={deletModalData.title}
      message={deletModalData.message(name)}
      onConfirm={onConfirm}
      onCancel={onToggle}
    />
  );
};
