import React from 'react';

const ChitsHeaderBaseInfo = (props) => {
  return (
    <React.Fragment>
      <td className="chit-column-role">Role</td>
      <td className="chit-column-costume">Costume #</td>
      <td className="text-center chit-column-status">Status</td>
      <td className="text-left chit-column-call-time">Call Time</td>
      <td className="text-left chit-column-check-in-time">
        <div className="text-muted small">Logged</div>
        Check-In Time
      </td>
      <td className="text-left chit-column-check-out-time">
        <div className="text-muted small">Logged</div>
        Check-Out Time
      </td>
      <td className="text-left chit-column-unit">Unit</td>
      <td className="text-left chit-column-tarvel-notes">Travel</td>
      
      <td className="text-left chit-colum-notes">Other</td>
      <td className="text-left chit-column-day-type">Day Type</td>
    </React.Fragment>
  );
};

ChitsHeaderBaseInfo.propTypes = {};

export default ChitsHeaderBaseInfo;
