import {call, put, takeEvery} from 'redux-saga/effects';
import * as api from './api';
import * as productionApi from '../production/api';
import * as actions from './actions'
import * as actionTypes from './actionTypes'
import * as notification from '../lib/notification';
import config from '../config/config.default';

export function* getChitData(action) {
  try {
    const {chitId} = action;
    const chitData = yield call(api.getChitData, chitId);
    const productionData = yield call(productionApi.getProductionAggregatedData, chitData.chit.productionId);
    const chitsOfExtraInProduction = yield call(api.getChitsOfExtraInProduction, chitData.chit.extraId, chitData.chit.productionId);
    yield put(actions.loadChitDataSuccess(chitData, productionData, chitsOfExtraInProduction));
  } catch (error) {
    yield put(actions.loadChitDataFailure(error));
  }
}

export function* setExtraStatus(action) {
  try {
    const {data} = action;
    yield call(api.setExtraStatus, data);
    const chitData = yield call(api.getChitData, data.chitId);
    yield put(actions.setExtraStatusSuccess(chitData));
    notification.success('Extra status has been successfully set');
  } catch (error) {
    yield put(actions.setExtraStatusFailure(error));
  }
}

export function* changeDailyRate(action) {
  try {
    const {data} = action;
    yield call(api.changeDailyRate, data);
    const chitData = yield call(api.getChitData, data.chitId);
    yield put(actions.changeDailyRateSuccess(chitData));
    notification.success('Daily rate has been successfully set');
  } catch (error) {
    yield put(actions.changeDailyRateFailure(error));
  }
}

export function* addSupplementaryFee(action) {
  try {
    const {data} = action;
    yield call(api.addSupplementaryFee, data);
    const chitData = yield call(api.getChitData, data.chitId);
    yield put(actions.addSupplementaryFeeSuccess(chitData));
    notification.success('Supplementary fee has been successfully added');
  } catch (error) {
    yield put(actions.addSupplementaryFeeFailure(error));
  }
}

export function* removeSupplementaryFee(action) {
  try {
    const {data} = action;
    yield call(api.removeSupplementaryFee, data);
    const chitData = yield call(api.getChitData, data.chitId);
    yield put(actions.removeSupplementaryFeeSuccess(chitData));
    notification.success('Supplementary fee has been successfully removed');
  } catch (error) {
    yield put(actions.removeSupplementaryFeeFailure(error));
  }
}

export function* sendCheckInEmail(action) {
  try {
    const {chitId} = action;
    yield call(api.sendCheckInEmail, chitId);
    yield put(actions.sendCheckInEmailSuccess());
    notification.success('Check-In email has been successfully sent');
  } catch (error) {
    yield put(actions.sendCheckOutEmailFailure(error));
  }
}

export function* sendCheckOutEmail(action) {
  try {
    const {chitId} = action;
    yield call(api.sendCheckOutEmail, chitId);
    yield put(actions.sendCheckOutEmailSuccess());
    notification.success('Chit Update email has been successfully sent');
  } catch (error) {
    yield put(actions.sendCheckOutEmailFailure(error));
  }
}

export function* setCheckOutTime(action) {
  try {
    const {data} = action;
    yield call(api.setCheckOutTime, data);
    const chitData = yield call(api.getChitData, data.chitId);
    yield put(actions.setCheckOutTimeSuccess(chitData));
    notification.success('Check-Out time has been successfully set');
  } catch (error) {
    yield put(actions.setCheckOutTimeFailure(error));
  }
}

export function* deleteChit(action) {
  try {
    const {data, history} = action;
    yield call(api.deleteChit, data.chitId);
    yield put(actions.deleteChitSuccess());
    yield call(history.push, `/productions/${data.productionId}/date/${data.date}`)
    notification.success('Chit has been successfully deleted');
    // location.href = `${config.baseUrl}/productions/${data.productionId}/date/${data.date}`;
  } catch (error) {
    yield put(actions.deleteChitFailure(error));
  }
}

function forwardTo(location) {
  history.push(location);
}


export default function* sagas() {
  yield takeEvery(actionTypes.LOAD_CHIT_DATA_REQUEST, getChitData);
  yield takeEvery(actionTypes.SET_EXTRA_STATUS_REQUEST, setExtraStatus);
  yield takeEvery(actionTypes.CHANGE_DAILY_RATE_REQUEST, changeDailyRate);
  yield takeEvery(actionTypes.ADD_SUPPLEMENTARY_FEE_REQUEST, addSupplementaryFee);
  yield takeEvery(actionTypes.REMOVE_SUPPLEMENTARY_FEE_REQUEST, removeSupplementaryFee);
  yield takeEvery(actionTypes.SEND_CHECK_IN_EMAIL_REQUEST, sendCheckInEmail);
  yield takeEvery(actionTypes.SEND_CHECK_OUT_EMAIL_REQUEST, sendCheckOutEmail);
  yield takeEvery(actionTypes.SET_CHECK_OUT_TIME_REQUEST, setCheckOutTime);
  yield takeEvery(actionTypes.DELETE_CHIT_REQUEST, deleteChit);
}