import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getMfaStatusLabel, getUserRoleLabel, getUserRoles, getUserStatusCssClass, getUserStatusLabel } from '../getters';
import Error from '../../common/global-error/Error';
import { MFA_STATUS_ENABLED, MFA_STATUS_NOT_ENABLED, MFA_STATUS_UNKNOWN, USER_STATUS_DEACTIVATED } from '../constants';
import EditUserSendEmailVerification from './EditUserSendEmailVerification';
import { USER_ROLE_AD } from '../../app/constants';
import { arraysEqual } from '../../lib/arrayUtils';

class EditUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      roles: []
    }
  }

  getMfaStatusLabelClass = (status) => {
    switch (status) {
      case MFA_STATUS_NOT_ENABLED:
        return 'text-secondary';
      case MFA_STATUS_ENABLED:
        return 'text-success';
      case MFA_STATUS_UNKNOWN:
        return 'text-danger';
      default:
        return 'text-primary';
    }
  }


  updateUser = (data) => {
    const isAd = data.roles.includes(USER_ROLE_AD);
    this.props.updateUserRequest({
      id: this.props.userBeingUpdated.id,
      ...data,
      autoLogout: isAd ? data.autoLogout : false,
      autoLogoutDurationSeconds: isAd ? data.autoLogoutDurationSeconds : 3600,
    })
  }

  getUserRoleOptions = () => {
    return getUserRoles().map(role => ({
      value: role,
      label: getUserRoleLabel(role)
    }))
  };

  unEnrollUserFromMfa = () => {
    if (confirm('Are you sure you want to un-enroll user from MFA?')) {
      this.props.unEnrollUserFromMfaRequest({ userId: this.props.userBeingUpdated.id })
    }
  }

  userDataChanged = (changed) => {
    const original = this.props.userBeingUpdated;
    return original.firstName !== changed.firstName
      || original.lastName !== changed.lastName
      || original.email !== changed.email
      || original.phoneNumber !== changed.phoneNumber
      || !arraysEqual(original.roles, changed.roles)
      || original.mfaRequired !== changed.mfaRequired
      || original.autoLogout !== changed.autoLogout
      || original.autoLogoutDurationSeconds !== changed.autoLogoutDurationSeconds
  }

  render = () => {
    const user = this.props.userBeingUpdated;
    const userRoleOptions = this.getUserRoleOptions();

    if (!this.props.userShowEditWindow || !user) {
      return null;
    }

    const { mfaInfo } = user;
    const mfaStatusLabelClass = this.getMfaStatusLabelClass(mfaInfo.status);

    const enrolledFactors = mfaInfo.enrolledFactors.map((factor, index) => {
      return (
        <div key={index} style={{ marginTop: '10px' }}>
          <div><span className="text-sm-left" style={{ fontSize: '12px' }}>Phone Number: {factor.phoneNumber}</span></div>
          <div><span className="text-sm-left" style={{ fontSize: '12px' }}>Enrollment Time: {factor.enrollmentTime}</span>
          </div>
          <div><span className="text-sm-left" style={{ fontSize: '12px' }}>Factor Id: {factor.factorId}</span></div>
        </div>
      )
    })

    const updateUserSchema = Yup.object().shape({
      firstName: Yup.string()
        .required('Required'),
      lastName: Yup.string()
        .required('Required'),
      email: Yup.string().email()
        .required('Required'),
      phoneNumber: Yup.string()
        .required('Required'),
      roles: Yup.array()
        .required('Required'),
      mfaRequired: Yup.boolean(),
      autoLogout: Yup.boolean,
      autoLogoutDurationSeconds: Yup.number().required('Required').min(60)
    });

    const userStatusLabelClass = getUserStatusCssClass(user.status);
    return (
      <div>
        <Modal show={this.props.userShowEditWindow}
          onHide={this.props.showHideEditUserWindow.bind(this, false)}
          size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-5">
                ID: <strong>{user.id}</strong>
              </div>
              <div className="col-md-7">
                <div className="float-left">
                  <span className={userStatusLabelClass}>
                    {getUserStatusLabel(user.status)}
                  </span>
                </div>
                <div className="float-left" style={{ marginLeft: '10px', marginTop: '-5px' }}>
                  <EditUserSendEmailVerification {...this.props} user={this.props.userBeingUpdated} />
                </div>
                <div className="float-none" />
              </div>
            </div>

            <hr />

            <Formik
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                roles: user.roles,
                mfaRequired: user.mfaRequired,
                autoLogout: user.autoLogout,
                autoLogoutDurationSeconds: user.autoLogoutDurationSeconds
              }}
              validationSchema={updateUserSchema}
              onSubmit={this.updateUser.bind(this)}
              render={({ values, setFieldValue }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="first-name">First Name:</label>
                    <Field type="text" name="firstName" className="form-control" id="first-name" />
                    <ErrorMessage name="firstName" component="div" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="last-name">Last Name:</label>
                    <Field type="text" name="lastName" className="form-control" id="last-name" />
                    <ErrorMessage name="lastName" component="div" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <Field type="email" name="email" className="form-control" id="email" disabled={user.status === USER_STATUS_DEACTIVATED} />
                    {user.status === USER_STATUS_DEACTIVATED &&
                      <small id="emailHelpBlock" className="form-text text-muted">
                        Email of deactivated user cannot be updated, please activate the user first to change it.
                      </small>
                    }
                    {user.status !== USER_STATUS_DEACTIVATED &&
                      <small id="emailHelpBlock" className="form-text text-muted">
                        Changing the email will set the user status to pending email verification,
                        email verification link will be sent. The user won't be able to sign in until email is verified.
                      </small>
                    }
                    <ErrorMessage name="email" component="div" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="last-name">Phone Number:</label>
                    <Field type="text" name="phoneNumber" className="form-control" id="phone-number" />
                    <ErrorMessage name="phoneNumber" component="div" />
                  </div>

                  <div className="form-group">
                    <div>Roles:</div>
                    <Select
                      name="roles"
                      options={userRoleOptions}
                      placeholder="Roles..."
                      onChange={values => setFieldValue('roles', values ? values.map(v => v.value) : [])}
                      value={this.getUserRoleOptions().filter(opt => values.roles.includes(opt.value))}
                      isMulti
                    />
                    <ErrorMessage name="roles" component="div" />
                  </div>

                  {values.roles.includes(USER_ROLE_AD) &&
                    <div className="form-group">
                      <div style={{ float: 'left', marginTop: '5px' }}>
                        <Field type="checkbox" name="autoLogout" id="autoLogout"
                          onChange={event => setFieldValue('autoLogout', event.target.checked)}
                          checked={values.autoLogout} />
                        <label style={{ marginLeft: '10px' }} htmlFor="autoLogout">Automatically Logout from AD App after
                          Inactivity of </label>
                        <ErrorMessage name="autoLogout" component="div" />
                      </div>
                      <div style={{ float: 'left', marginLeft: '10px' }}>
                        <div style={{ float: 'left' }} className="auto-logout-seconds">
                          <Field size="sz" type="number" name="autoLogoutDurationSeconds"
                            className="form-control form-control-sm" id="autoLogoutDurationSeconds"
                            disabled={!values.autoLogout} />
                        </div>
                        <div style={{ float: 'left', marginLeft: '10px', marginTop: '5px' }}>
                          Seconds
                        </div>
                        <div style={{ clear: 'both' }} />
                        <ErrorMessage name="autoLogoutDurationSeconds" component="div" />
                      </div>
                      <div style={{ clear: 'both' }} />
                    </div>
                  }

                  <div className="form-group">
                    <Field type="checkbox" name="mfaRequired" id="mfaRequired"
                      onChange={event => setFieldValue('mfaRequired', event.target.checked)}
                      checked={values.mfaRequired} />
                    <label style={{ marginLeft: '10px' }} htmlFor="mfaRequired">MFA Required</label>
                    <ErrorMessage name="mfaRequired" component="div" />
                  </div>

                  {this.props.userUpdateError &&
                    <div className="form-group">
                      <Error error={this.props.userUpdateError} />
                    </div>
                  }

                  <div className="form-group">
                    <Button disabled={this.props.userUpdating || !this.userDataChanged(values)} type="submit" variant="primary" size="sm">
                      Update User
                    </Button>
                  </div>

                  <hr />

                  <div style={{ marginTop: '10px' }}>
                    MFA Status: <span className={mfaStatusLabelClass}>{getMfaStatusLabel(mfaInfo.status)}</span>
                    {mfaInfo.infoUpdatedAt &&
                      <div>
                        <span className="text-sm-left text-secondary"
                          style={{ fontSize: '12px' }}>Info updated at: {mfaInfo.infoUpdatedAt}</span>
                      </div>
                    }
                  </div>
                  {mfaInfo.additionalMessage &&
                    <div style={{ marginTop: '10px' }}>
                      <span>{mfaInfo.additionalMessage}</span>
                    </div>
                  }
                  {mfaInfo.enrolledFactors.length > 0 &&
                    <div style={{ marginTop: '10px' }}>
                      {enrolledFactors}
                      <div style={{ marginTop: '5px' }}>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={this.unEnrollUserFromMfa.bind(this, user.id)}
                          disabled={this.props.unEnrollingUserFromMfa}
                        >
                          {this.props.unEnrollingUserFromMfa ? 'Un-enrolling from MFA...' : 'Un-enroll from MFA'}
                        </Button>
                      </div>
                    </div>
                  }
                </Form>
              )
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.props.userUpdating} variant="secondary"
              onClick={this.props.showHideEditUserWindow.bind(this, false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

EditUser.propTypes = {
  userBeingUpdated: PropTypes.object,
  updateUserRequest: PropTypes.func.isRequired,
  showHideEditUserWindow: PropTypes.func.isRequired,
  userUpdateError: PropTypes.object,
  userUpdating: PropTypes.bool.isRequired,
  userShowEditWindow: PropTypes.bool.isRequired,
  unEnrollUserFromMfaRequest: PropTypes.func.isRequired,
  unEnrollingUserFromMfa: PropTypes.bool.isRequired
};

export default EditUser;
