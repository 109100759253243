import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

// import {useSelector} from "react-redux"

const ChitNotes = (props) => {
    const { setChitIndividualNotesRequest, record } = props;

    const [state, setState] = React.useState({ showModal: false, notes: '' });
    
    const controlModal = (e) => {
        setState((state) => ({ ...state, showModal: !state.showModal }));
    };

    React.useEffect(() => {
        setState({ showModal: false, notes: record.chit.generalNotes });
    }, [record]);

    const onSubmit = (e) => {
        e.preventDefault();
        setChitIndividualNotesRequest(record.chit.id, state.notes || '');
        controlModal();
    };

    const onChange = (e) => {
        const newValue = e.target.value;
        setState((state) => ({ ...state, notes: newValue }));
    };

    const { showModal, notes: value } = state;

    return (
        <React.Fragment>
            <Modal show={state.showModal} onHide={controlModal} size="sm">
                <Form onSubmit={onSubmit}>
                    <Modal.Header>
                        <h5>Set Notes</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="indivudal.ControlTextarea1">
                            <Form.Control
                                as="textarea"
                                rows={6}
                                col={4}
                                value={value}
                                onChange={onChange}
                                placeholder="Enter notes, max 30 characters"
                                maxLength={30}
                                name="notes"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default w-25" onClick={controlModal}>
                            Cancel
                        </Button>

                        <Button
                            variant="success w-75"
                            type={'submit'}
                            // disabled={this.props.bulkActionsRunning}
                        >
                            Set Notes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Button
                variant={`${!record.chit.generalNotes ? `outline-light` : `light`}`}
                onClick={controlModal}
                size="sm">
                Notes
            </Button>
        </React.Fragment>
    );
};

ChitNotes.propTypes = {
    record: PropTypes.object.isRequired,
    setChitIndividualNotesRequest: PropTypes.func.isRequired,
    setBulkChitNotesRequest: PropTypes.func.isRequired,
    idsOfSelectedChits: PropTypes.object.isRequired,
};

export default ChitNotes;
