import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Config from '../../../../config/config.default';
import {getAccessToken} from '../../../../lib/auth';

class ProductionExternalSurveyDocumentUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uploadError: null,
      uploadSuccess: null,
      url: this.props.url
    };
  }

  // specify upload params and url for your files
  getUploadParams = ({meta}) => {
    const apiUrl = Config.apiUrl;
    const productionId = this.props.productionId;

    return getAccessToken().then(token => {
      return {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        url: `${apiUrl}/v2/files/upload?relativeDirectory=production-external-surveys&fileName=production-external-survey-${productionId}.pdf`
      }
    });
  }

  // called every time a file's `status` changes
  handleChangeStatus = ({remove, xhr}, status) => {
    if (status === 'done') {
      const responseJson = JSON.parse(xhr.response);
      this.setState({
        uploadSuccess: true,
        uploadError: false,
        url: responseJson.url
      })
      remove()
      this.props.onChange({url: responseJson.url});
    } else if (status === 'aborted') {
      this.setState({
        uploadSuccess: false,
        uploadError: 'Upload failed',
        url: null
      })
    } else if (status === 'rejected_file_type') {
      this.setState({
        uploadSuccess: false,
        uploadError: 'Only pdf supported',
        url: null
      })
    }
  }

  render = () => {
    const uploadText = this.state.fileUrl ? 'Upload another PDF document' : 'Upload PDF document';
    return (
      <div>
        {this.state.url &&
        <a href={this.state.url} className="btn btn-primary btn-sm" target="_blank"><i className="fa fa-file"/> View document</a>
        }
        <div style={{marginTop: '10px'}}>
          <Dropzone
            getUploadParams={this.getUploadParams}
            onChangeStatus={this.handleChangeStatus}
            multiple={false}
            maxFiles={1}
            accept="application/pdf"
            inputContent={uploadText}
          />
        </div>
        {this.state.uploadError &&
        <div className="alert alert-danger" role="alert">
          {this.state.uploadError}
        </div>
        }
      </div>
    )
  }
}

ProductionExternalSurveyDocumentUpload.propTypes = {
  productionId: PropTypes.number.isRequired,
  url: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default ProductionExternalSurveyDocumentUpload;
