import React from 'react';
import PropTypes from 'prop-types';
import ProductionTranspotationGroupHeader from './ProductionTranspotationGroupHeader';
import ProductionTranspotationGroupRow from './ProductionTranspotationGroupRow';
import ProductionAddorUpdateTranspotationGroup from './ProductionAddorUpdateTranspotationGroup';
const ProductionTransportation = (props) => {
  const { transportGroups, showHideTarnspotationGroups } = props;

  const containsTransportGroups = transportGroups.length;
  const handleCreateModal = ()=> showHideTarnspotationGroups(0);
  return (
    <React.Fragment>
      <ProductionAddorUpdateTranspotationGroup {...props} />

      <div className="text-right">
        <button type="button" className="btn btn-primary btn-sm" onClick={handleCreateModal}>
          Add Group
        </button>
      </div>
      {(containsTransportGroups && (
        <table className="table card-table table-striped production-units-table">
          <thead>
            <ProductionTranspotationGroupHeader {...props} />
          </thead>
          <tbody>
            {transportGroups.map((transportGroup) => (
              <React.Fragment key={transportGroup.id}>
                <ProductionTranspotationGroupRow transportGroup={transportGroup} {...props} />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )) || <div className="no-entries">No Transportation Groups</div>}
    </React.Fragment>
  );
};

ProductionTransportation.propTypes = {
  transportGroups: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  showHideTarnspotationGroups: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  productionTranspotationGroupUpdateOrCreateActiveId: PropTypes.number.isRequired,
};

export default ProductionTransportation;
