import React from 'react';
import {
  dateTimeToDate,
  dateTimeToHoursAndMinutes,
  dateToUveWithoutWeekFormat,
  uveDateWithoutWeekAndTimeToDateTime
} from '../../lib/dateTimeUtils';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import Loader from 'react-loader-spinner';

class SetCheckOutTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkOutDateStr: dateTimeToDate(this.props.chitData.chit.checkOutTime),
      checkOutTimeStr: dateTimeToHoursAndMinutes(this.props.chitData.chit.checkOutTime)
    }
  }

  getCheckOutTime = ()  => {
    let checkOutTime = null;
    try {
      checkOutTime = uveDateWithoutWeekAndTimeToDateTime(this.state.checkOutDateStr, this.state.checkOutTimeStr)
    } catch (e) {

    }

    return checkOutTime
  }

  isDataValid = () => uveDateWithoutWeekAndTimeToDateTime(this.state.checkOutDateStr, this.state.checkOutTimeStr)
    && this.state.checkOutDateStr && this.state.checkOutTimeStr;

  handleCheckOutDateChange = (event) => {
    this.setState({
      checkOutDateStr: event.target.value.toString(),
    });
  };

  handleCheckOutTimeChange = (event) => {
    this.setState({
      checkOutTimeStr: event.target.value.toString()
    });
  };

  handleSetCheckOutTime = () => {
    const checkOutTime = this.getCheckOutTime();
    this.props.setCheckOutTimeRequest({chitId: this.props.chitData.chit.id, checkOutTime})
  }

  render() {
    const modalBackdrop = this.props.setCheckOutTimeRunning ? 'static' : true;
    const setCheckOutTimeButtonLabel = this.props.setCheckOutTimeRunning
      ? 'Save Changes...'
      : 'Save Changes';
    return (
      <Modal show={true} onHide={this.props.showHideSetCheckOutTimeWindow.bind(this, false)} keyboard={!this.props.setCheckOutTimeRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.setCheckOutTimeRunning}>
          <Modal.Title>Set Check-Out Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="checkOutDate">Check-out time</label>
                  <input className="form-control" id="checkOutDate"
                         value={this.state.checkOutDateStr}
                         placeholder="dd-mm-yyyy" onChange={this.handleCheckOutDateChange.bind(this)}/>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="checkOutTime">&nbsp;</label>
                  <input className="form-control" id="checkOutTime"
                         value={this.state.checkOutTimeStr}
                         placeholder="hh:mm" onChange={this.handleCheckOutTimeChange.bind(this)}/>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {this.props.setCheckOutTimeRunning &&
          <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          <Button variant="default" onClick={this.props.showHideSetCheckOutTimeWindow.bind(this, false)} disabled={this.props.setCheckOutTimeRunning}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSetCheckOutTime.bind(this)} disabled={!this.isDataValid() || this.props.setCheckOutTimeRunning}>
            {setCheckOutTimeButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

SetCheckOutTime.propTypes = {
  chitData: PropTypes.object.isRequired,
  showHideSetCheckOutTimeWindow: PropTypes.func.isRequired,
  setCheckOutTimeRunning: PropTypes.bool.isRequired,
  setCheckOutTimeRequest: PropTypes.func.isRequired
}

export default SetCheckOutTime;