import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal} from 'react-bootstrap';
import {EXTRA_STATUS_CHECKED_OUT} from '../../../constants';
import TimePicker from 'rc-time-picker';
import { momentToDateTime, parseFromDateTime } from '../../../../lib/dateTimeUtils';
import Loader from 'react-loader-spinner';

class SetCallTime extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      callTime: parseFromDateTime(`${this.props.productionDate}T07:00:00`)
    }
  };

  atLeastOneCheckedOutChitSelected = () => {
    for (const chitId in this.props.idsOfSelectedChitsCrossedByFiltered) {
      if (this.props.filteredChitRecordsByChitId[chitId].chit.extraStatus === EXTRA_STATUS_CHECKED_OUT)  {
        return true;
      }
    }

    return false;
  };

  handleCallTimeChange = (callTime) => {
    this.setState({
      callTime: callTime,
    })
  };

  handleSetCallTime = () => {
    const callTime = momentToDateTime(this.state.callTime);
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.setChitsCallTimeRequest(chitIds, callTime, this.props.productionId, this.props.productionDate);
  };

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    const setCallTimeButtonLabel = this.props.bulkActionsRunning ? 'Setting Call Time...' : 'Set Call Time';
    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsSetCallTimeWindow.bind(this, false)} keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Set call time for selected chits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.atLeastOneCheckedOutChitSelected() &&
          <Alert variant='warning'>
            {`Please note that some of selected chits are ${EXTRA_STATUS_CHECKED_OUT}`}, those chits
            will be recalculated.
            <p/>
            Chit breakdown(s) will not be re-emailed automatically to extras - please do this manually if required.
          </Alert>
          }
          <TimePicker
            placeholder="Call Time"
            showSecond={false}
            allowEmpty={false}
            value={this.state.callTime}
            onChange={this.handleCallTimeChange}
            className="call-time-input"
          />

        </Modal.Body>
        <Modal.Footer>
          {this.props.bulkActionsRunning &&
            <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          <Button variant="default" onClick={this.props.showHideBulkActionsSetCallTimeWindow.bind(this, false)} disabled={this.props.bulkActionsRunning}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSetCallTime} disabled={this.props.bulkActionsRunning}>
            {setCallTimeButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

SetCallTime.propTypes = {
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsSetCallTimeWindow: PropTypes.func.isRequired,
  setChitsCallTimeRequest: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired
};

export default SetCallTime;