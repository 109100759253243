import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ReactSelect from 'react-select';
import {
    SMS_TEMPLATE_TYPE_PICKUP_CALL_DETAILS,
    SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS,
} from '../../../constants';
import '../Chits.css';

const SMS_TEMPLATE_MSG_BODY = {
    [SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS]: `PLEASE REPLY 'YES' TO CONFIRM RECEIPT. Hi [NAME], Call Details for [PROD], [UNIT] [TYPE] on [DATE]. Call Time: [CT]. Location: [ULOC]. Costume: [COSTUME]. Contact: [CONTACT]. [INFO] Any issues, please call [ADMIN_PHONE] ASAP. UVE Team (Warning: anyone caught posting production photos or confidential info online will have action taken against them)`,
    [SMS_TEMPLATE_TYPE_PICKUP_CALL_DETAILS]: `PLEASE REPLY 'YES' TO CONFIRM RECEIPT. Hi [NAME], Call Details for [PROD], [UNIT] [TYPE] on [DATE]. Pickup Time: [PT]. Pickup Location: [PLOC]. Call Time: [CT]. Location: [ULOC]. Costume: [COSTUME]. Contact: [CONTACT]. [INFO] Any issues, please call [ADMIN_PHONE] ASAP. UVE Team (Warning: anyone caught posting production photos or confidential info online will have action taken against them)`,
};

const TEMPLATE_OPTIONS = [
    {
        label: 'Standard Call details',
        value: SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS,
    },
    {
        label: 'Pickup Call details',
        value: SMS_TEMPLATE_TYPE_PICKUP_CALL_DETAILS,
    },
];

const DEFAULT_TEMPLATE_OPTION_INDEX = 0;

const SendMessageModal = (props) => {
    const [input, setInput] = useState('');
    const [smsTemplateType, setsmsTemplateType] = useState(
        TEMPLATE_OPTIONS[DEFAULT_TEMPLATE_OPTION_INDEX],
    );

    React.useEffect(() => {
        setInput(SMS_TEMPLATE_MSG_BODY[smsTemplateType.value]);
    }, [smsTemplateType]);
    const handleClose = () => {
        props.setShowHideBulkSmsModal(false);
    };

    const handleSave = () => {
        const chitIds = Object.keys(props.idsOfSelectedChits).filter(
            (chitId) => props.idsOfSelectedChits[chitId] === true,
        );
        // props.sendingSMS()
        props.sendingSMS({
            chitIds,
            type: smsTemplateType.value,
            template: input,
            productionId: props.productionId,
            date: props.productionDate,
        });
    };

    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handlesmsTemplateType = (option) => {
        setsmsTemplateType(option);
    };

    return (
        <Modal show={true} size="sm" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Call Details</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="bulk.smsmoadal">
                    <ReactSelect
                        className="form-control mb-3"
                        value={smsTemplateType}
                        options={TEMPLATE_OPTIONS}
                        placeholder="Select SMS Template"
                        onChange={handlesmsTemplateType}
                    />

                    <Form.Control
                        as="textarea"
                        rows={12}
                        col={6}
                        value={input}
                        onChange={handleChange}
                        placeholder="Enter SMS to Send"
                        maxLength={500}
                        disabled={props.bulkActionsRunning}
                        name="bulk.smsmoadal"
                    />
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                {props.bulkActionsRunning && (
                    <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />
                )}

                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={props.bulkActionsRunning}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

SendMessageModal.propTypes = {
    bulkActionsRunning: PropTypes.bool.isRequired,
    idsOfSelectedChits: PropTypes.object.isRequired,
    setShowHideBulkSmsModal: PropTypes.func.isRequired,
    bulkSmsWindow: PropTypes.bool.isRequired,
    sendingSMS: PropTypes.bool.isRequired,
};

export default SendMessageModal;
