import React from 'react';
import CreateDocument from './CreateDocument';
import DocumentRecord from './DcoumentRecord';
import PropTypes from 'prop-types';
import './Documents.css';
import UpdateDocument from './UpdateDocument';
import DownloadDocuments from "./DownloadDocuments";
import DownloadDocumentsReport from "./DownloadDocumentsReport";

class Documents extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const {productionDocuments} = this.props;

    const productionDocumentRecords = productionDocuments.map(document => <DocumentRecord key={document.id} document={document} {...this.props} />);

    return (
      <div className="card" style={{marginTop: '20px'}}>
        <div className="card-header">
          Documents
        </div>
        <div className="card-body">
          <div style={{marginBottom: '10px'}}>
            <CreateDocument {...this.props} />
          </div>
          {this.props.productionDocumentShowUpdateWindow &&
          <UpdateDocument document={this.props.productionDocumentBeingUpdated} {...this.props}/>
          }
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Required Action</th>
                <th>Action Frequency</th>
                <th>Created At</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {productionDocumentRecords}
            </tbody>
          </table>
          <DownloadDocuments {...this.props}/>
          <div style={{marginTop: '15px'}}/>
          <DownloadDocumentsReport {...this.props} />
        </div>
      </div>
    )
  }
}

Documents.propTypes = {
  productionDocuments: PropTypes.array.isRequired,
  productionDocumentShowUpdateWindow: PropTypes.bool.isRequired
}

export default Documents