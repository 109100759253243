import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Button, Modal} from 'react-bootstrap';
import PaySchemeDetailsModal from '../../../common/pay-scheme-details/PaySchemeDetailsModal';

class ProductionUnitRowPayScheme extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      paySchemeId: this.props.productionUnit.paySchemeId,
      showOriginalPaySchemeDetails: false,
      showCurrentPaySchemeDetails: false
    };
  }

  /**
   * If production unit is default or it doesn't have pay scheme,
   * then production's pay scheme is used (if production has pay scheme set).
   */
  isProductionPayScheme = () =>
    (this.props.productionUnit.isDefault || !this.props.productionUnit.paySchemeId) && this.props.production.defaultPaySchemeId;

  getProductionPayScheme = () =>
    this.props.paySchemesById[this.props.production.defaultPaySchemeId]

  getPaySchemeOptions = () => (
    this.props.paySchemes.map(payScheme => ({
      value: payScheme.id,
      label: payScheme.title
    }))
  );

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };


  setPayScheme = () => {
    this.showHideConfirmationDialog(false);
    this.props.setProductionUnitPaySchemeRequest(this.props.productionUnit.id, this.state.paySchemeId);
  };


  cancelPaySchemeChange = () => {
    this.showHideConfirmationDialog(false);
    this.setState({
      paySchemeId: this.props.productionUnit.paySchemeId
    });
  };

  handleOnSelectChange = option => {
    this.setState({
      paySchemeId: option.value
    });
    this.showHideConfirmationDialog(true);
  };

  showOriginalPaySchemeDetails = () => {
    this.setState({
      showOriginalPaySchemeDetails: true
    })
  };

  showCurrentPaySchemeDetails = () => {
    this.setState({
      showCurrentPaySchemeDetails: true
    })
  };

  hidePaySchemeDetails = () => {
    this.setState({
      showOriginalPaySchemeDetails: false,
      showCurrentPaySchemeDetails: false
    })
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.productionUnit.paySchemeId !== this.props.productionUnit.paySchemeId) {
      this.setState({
        paySchemeId: this.props.productionUnit.paySchemeId
      });
    }
  }

  render = () => {
    const paySchemeOptions = this.getPaySchemeOptions();

    const originalPayScheme = this.props.productionUnit.paySchemeId
      ? this.props.paySchemesById[this.props.productionUnit.paySchemeId]
      : null;

    const currentPayScheme = this.state.paySchemeId
      ? this.props.paySchemesById[this.state.paySchemeId]
      : null;

    const currentPaySchemeOption = this.state.paySchemeId
      ? paySchemeOptions.find(option => option.value === this.state.paySchemeId)
      : null;


    let paySchemeDetailsToShow = null;
    if (this.state.showOriginalPaySchemeDetails && originalPayScheme) {
      paySchemeDetailsToShow = originalPayScheme;
    } else if (this.state.showCurrentPaySchemeDetails && currentPayScheme) {
      paySchemeDetailsToShow = currentPayScheme;
    } else if (this.state.showOriginalPaySchemeDetails && this.isProductionPayScheme()) {
      paySchemeDetailsToShow = this.getProductionPayScheme();
    }

    return (
      <div>
        {this.props.productionUnit.isDefault &&
        <div>
          Changing Pay Scheme of Main Unit is not allowed. <br/>
          {this.isProductionPayScheme() &&
          <span>
            Using production's pay scheme <strong><a
            onClick={this.showOriginalPaySchemeDetails.bind(this)}>{this.getProductionPayScheme().title}</a></strong>.
          </span>
          }
        </div>
        }
        {!this.props.productionUnit.isDefault &&
        <div>
          <Select
            options={paySchemeOptions}
            placeholder="Pay Scheme..."
            value={currentPaySchemeOption}
            onChange={this.handleOnSelectChange.bind(this)}
          />
          <div style={{textAlign: 'right', marginTop: '10px'}}>
          {originalPayScheme &&
            <Button size="sm" variant="outline-secondary" onClick={this.showOriginalPaySchemeDetails.bind(this)}>View</Button>
          }
          </div>
          {this.isProductionPayScheme() &&
          <div>
            <small>Using production's pay scheme <strong><a
              onClick={this.showOriginalPaySchemeDetails.bind(this)}>{this.getProductionPayScheme().title}</a></strong>,
              since no pay scheme set for this unit.</small>
          </div>
          }
          <Modal show={this.state.showConfirmationDialog} onHide={this.cancelPaySchemeChange.bind(this)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Change Pay Scheme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>Are you sure you want to change Pay Scheme </span>
              {originalPayScheme &&
              <span>from <strong><a onClick={this.showOriginalPaySchemeDetails.bind(this)}>{originalPayScheme.title}</a></strong> </span>
              }
              {currentPaySchemeOption &&
              <span>
              to <strong><a
                onClick={this.showCurrentPaySchemeDetails.bind(this)}>{currentPaySchemeOption.label}</a></strong>?
              </span>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelPaySchemeChange.bind(this)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.setPayScheme.bind(this)}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        }
        {paySchemeDetailsToShow &&
        <PaySchemeDetailsModal payScheme={paySchemeDetailsToShow}
                               show={this.state.showOriginalPaySchemeDetails || this.state.showCurrentPaySchemeDetails}
                               onClose={this.hidePaySchemeDetails.bind(this)}/>
        }
      </div>
    )
  }
}

ProductionUnitRowPayScheme.propTypes = {
  production: PropTypes.object.isRequired,
  productionUnit: PropTypes.object.isRequired,
  paySchemes: PropTypes.array.isRequired,
  paySchemesById: PropTypes.object.isRequired,
  setProductionUnitPaySchemeRequest: PropTypes.func.isRequired
};

export default ProductionUnitRowPayScheme;
