import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class PrimaryAd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      primaryAdId: this.props.primaryAdId
    };
  }

  getAdOptions = () => (
    Object.values(this.props.ads).map(ad => ({
      value: ad.id,
      label: `${ad.firstName} ${ad.lastName} (${ad.username}, ${ad.email})`
    }))
  );

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };

  assignPrimaryAd = () => {
    this.showHideConfirmationDialog(false);
    this.props.assignPrimaryAdRequest(this.props.productionId, this.state.primaryAdId);
  };

  cancelPrimaryAdChange = () => {
    this.showHideConfirmationDialog(false);
    this.setState({
      primaryAdId: this.props.primaryAdId
    });
  };

  handleOnSelectChange = option => {
    this.setState({
      primaryAdId: option.value
    });
    this.showHideConfirmationDialog(true);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.primaryAdId !== this.props.primaryAdId) {
      this.setState({
        primaryAdId: this.props.primaryAdId
      });
    }
  }

  render = () => {
    const adOptions = this.getAdOptions();
    const originalAd = this.props.primaryAdId
      ? this.props.ads.find(ad => ad.id === this.props.primaryAdId)
      : null;
    const currentAdOption = this.state.primaryAdId
      ? adOptions.find(option => option.value === this.state.primaryAdId)
      : null;

    return (
      <div>
        <div>
          <div style={{float: 'left', marginTop: '10px'}}>
            Primary AD:
          </div>
          <div style={{float: 'left', marginLeft: '10px', width: '500px'}}>
            <Select
              options={adOptions}
              placeholder="Primary AD..."
              value={currentAdOption}
              onChange={this.handleOnSelectChange.bind(this)}
            />
          </div>
          <div style={{clear: 'both'}}/>
        </div>
        <ConfirmationDialog
          show={this.state.showConfirmationDialog}
          title="Assign Primary AD to Production"
          message={
            <div>
              <span>Are you sure you want to change Primary AD </span>
              {originalAd &&
              <span>from <strong>{originalAd.firstName} {originalAd.lastName}</strong> </span>
              }
              {currentAdOption &&
              <span>
            to <strong>{currentAdOption.label}</strong>?
            </span>
              }
            </div>
          }
          onCancel={this.cancelPrimaryAdChange.bind(this)}
          onConfirm={this.assignPrimaryAd.bind(this)}
        />
      </div>
    );
  };
}

PrimaryAd.propTypes = {
  ads: PropTypes.array.isRequired,
  primaryAdId: PropTypes.string,
  productionId: PropTypes.number.isRequired,
  assignPrimaryAdRequest: PropTypes.func.isRequired
};

export default PrimaryAd;