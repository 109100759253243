import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import Loader from "react-loader-spinner";
import {
  CANCELLATION_FEE_TYPE_FULL,
  CANCELLATION_FEE_TYPE_PARTIAL,
  CANCELLATION_FEE_TYPE_ZERO
} from "../../../constants";

class CancelChits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancellationFeeType: CANCELLATION_FEE_TYPE_FULL
    }
  };


  handleChitsCancellation = () => {
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.cancelChitsRequest(chitIds, this.state.cancellationFeeType, this.props.productionId, this.props.productionDate);
  };

  handleCancellationFeeTypeChange = (cancellationFeeType) => {
    this.setState({cancellationFeeType})
  }

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    const cancelChitsButtonLabel = this.props.bulkActionsRunning ? 'Cancelling Chits...' : 'Cancel Chits';

    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsCancelChitsWindow.bind(this, false)}
             keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Cancel chits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='warning'>
            Please note that Cancellation Fees can only be applied if a Daily Rate for that chit has been
            selected.
          </Alert>
          <strong>
            Please select cancellation fee:
          </strong>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="cancellationFeeOption" id="fullCancellationFee"
                   value={CANCELLATION_FEE_TYPE_FULL}
                   checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_FULL}
                   onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_FULL)}
            />
            <label className="form-check-label" htmlFor="fullCancellationFee">Full Cancellation Fee</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="cancellationFeeOption" id="partialCancellationFee"
                   value={CANCELLATION_FEE_TYPE_PARTIAL}
                   checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_PARTIAL}
                   onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_PARTIAL)}
            />
            <label className="form-check-label" htmlFor="partialCancellationFee">Partial Cancellation Fee</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="cancellationFeeOption" id="zeroCancellationFee"
                   value={CANCELLATION_FEE_TYPE_ZERO}
                   checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_ZERO}
                   onChange={this.handleCancellationFeeTypeChange.bind(this, CANCELLATION_FEE_TYPE_ZERO)}
            />
            <label className="form-check-label" htmlFor="zeroCancellationFee">Zero Cancellation Fee</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.props.bulkActionsRunning &&
          <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          <Button variant="default"
                  onClick={this.props.showHideBulkActionsCancelChitsWindow.bind(this, false)}
                  disabled={this.props.bulkActionsRunning}>
            Close
          </Button>
          <Button variant="primary"
                  onClick={this.handleChitsCancellation.bind(this)}
                  disabled={this.props.bulkActionsRunning}>
            {cancelChitsButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

CancelChits.propTypes = {
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  production: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsCancelChitsWindow: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired,
  cancelChitsRequest: PropTypes.func.isRequired,
};

export default CancelChits;