import React from 'react';
import SendPasswordResetLink from './SendPasswordResetLink';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {sendPasswordResetLinkRequest, setPasswordRequest, setStep} from '../actions';
import {
  STEP_RESET_PASSWORD,
  STEP_RESET_PASSWORD_SUCCESS,
  STEP_SEND_PASSWORD_RESET_LINK,
  STEP_SEND_PASSWORD_RESET_LINK_SUCCESS
} from '../constants';
import SendPasswordResetLinkSuccess from './SendPasswordResetLinkSuccess';
import ResetPassword from './ResetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import store from '../../store';
import {getQueryParam} from '../../lib/urlUtils';

class PasswordResetContainer extends React.Component {

  constructor(props, context) {
    super(props, context);

    const sourceApp = getQueryParam(window.location.search, 'sourceApp');

    const passwordResetCode = getQueryParam(window.location.search, 'oobCode');

    if (passwordResetCode) {
      store.dispatch(setStep(STEP_RESET_PASSWORD, {sourceApp, passwordResetCode}))
    } else {
      store.dispatch(setStep(STEP_SEND_PASSWORD_RESET_LINK, {sourceApp}))
    }
  }

  render() {
    const {currentStep} = this.props;

    return (
      <React.Fragment>
      {currentStep === STEP_SEND_PASSWORD_RESET_LINK &&
      <SendPasswordResetLink {...this.props} />
      }
      {currentStep === STEP_SEND_PASSWORD_RESET_LINK_SUCCESS &&
      <SendPasswordResetLinkSuccess {...this.props} />
      }
      {currentStep === STEP_RESET_PASSWORD &&
      <ResetPassword {...this.props} />
      }
      {currentStep === STEP_RESET_PASSWORD_SUCCESS &&
       <ResetPasswordSuccess {...this.props} />
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const passwordResetState = state.passwordReset;
  return {
    sendingPasswordResetLink: passwordResetState.sendingPasswordResetLink,
    passwordResetLinkSent: passwordResetState.passwordResetLinkSent,
    sendPasswordResetLinkError: passwordResetState.sendPasswordResetLinkError,
    currentStep: passwordResetState.currentStep,
    settingPassword: passwordResetState.settingPassword,
    setPasswordError: passwordResetState.setPasswordError,
    passwordResetCode: passwordResetState.passwordResetCode,
    sourceApp: passwordResetState.sourceApp
  };
};

const actionCreators = {
  sendPasswordResetLinkRequest,
  setPasswordRequest
};

export default withRouter(connect(mapStateToProps, actionCreators)(PasswordResetContainer));

