import React from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';
import LocationSelector from '../../production/components/location/LocationSelector';
import UVAddressToCoordinates from '../../production/components/location/UVGoogleMapComponents/UVAddressToCoordinates';
import AutoCompleteAddress from '../../production/components/location/UVGoogleMapComponents/AutoCompleteAddress';
function FormField(props) {
  const { fieldInfo, setFieldValue, values, ...rest } = props;

  const RenderExternatFileds = () => {
    const {
      type,
      LabelComponent,
      valueHandelor,
      initialState,
      ErrorComponet,
      parseDataOptions,
      name,
      onChangeKey,
      ...restFieldInfo
    } = fieldInfo;

    switch (type) {
      case 'react-select': {
        const value = props.initialState[name];
        var options = [];

        if (fieldInfo.options) {
          options = fieldInfo.options;
        } else {
          const { optionsRaw, otherErrorHadlelor } = rest;
          options = parseDataOptions(optionsRaw);
        }

        return (
          <React.Fragment key={name}>
            {LabelComponent()}
            <Select
              {...restFieldInfo}
              value={valueHandelor(options, values[name])}
              options={options}
              {...restFieldInfo}
              onChange={({ value }) => setFieldValue(name, value)}
              {...rest}
            />
            <ErrorMessage name={name} component="div" className={`field-error ${fieldInfo.errorVarient}`} />
          </React.Fragment>
        );
      }
      case 'uv-location-select': {
        const { name, id, type, varient = '', errorVarient = '', label, placeholder } = fieldInfo;
        return (
          <React.Fragment>
            <label htmlFor={id} className="text-capitalize">
              {label}
            </label>
            <LocationSelector {...props} />
            <ErrorMessage name={name} component="div" className={`field-error ${errorVarient}`} />
          </React.Fragment>
        );
      }
      case 'uv-address-to-coordinates': {
        const { name, id, type, varient = '', errorVarient = '', label, placeholder } = fieldInfo;
        return (
          <React.Fragment>
            <label htmlFor={id} className="text-capitalize">
              {label}
            </label>
            <UVAddressToCoordinates {...fieldInfo} {...props} />
            <ErrorMessage name={name} component="div" className={`field-error ${errorVarient}`} />
          </React.Fragment>
        );
      }
      case 'adress-auto-complete': {
        const { name, id, type, varient = '', errorVarient = '', label, placeholder, labelTitle } = fieldInfo;
        // <UVAddressToCoordinates {...fieldInfo} />
        return (
          <React.Fragment>
            <label htmlFor={id} title={labelTitle} className="text-capitalize">
              {label}
            </label>
            <AutoCompleteAddress {...fieldInfo} {...props} />

            <hr className="divider mt-10" />

            <ErrorMessage name={name} component="div" className={`field-error ${errorVarient}`} />
          </React.Fragment>
        );
      }
      case 'uv-date-time-picker': {
        return (
          <React.Fragment>
            <div> Date picker will be added soon </div>
          </React.Fragment>
        );
      }
      default:
        return <div>Loading....</div>;
    }
  };
  const RenderFields = () => {
    const { name, id, type, varient = '', errorVarient = '', label, placeholder } = fieldInfo;

    return (
      <React.Fragment key={name}>
        <label htmlFor={id} className="text-capitalize">
          {label}
        </label>
        <Field
          type={type}
          name={name}
          className={`form-control ${varient}`}
          id={id}
          {...fieldInfo}
          placeholder={placeholder}
        />
        <ErrorMessage name={name} component="div" className={`field-error ${errorVarient}`} />
      </React.Fragment>
    );
  };
  return (
    <div className="form-group">
      {!fieldInfo.isExternalComponet ? RenderFields() : RenderExternatFileds()}

      {/* Loading Fields.... */}
    </div>
  );
}

FormField.propTypes = {
  fieldInfo: PropTypes.object.isRequired,
};

export default FormField;
