import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

class AssignExtrasToProductionUnit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productionUnitId: null
    }
  };

  getProductionUnitOptions = (productionUnits) => {
    return productionUnits.map(unit => ({
      value: unit.id,
      label: unit.name
    }));
  };

  handleProductionUnitChange = (option) => {
    this.setState({
      productionUnitId: option.value,
    })
  };

  handleAssignExtrasToProductionUnit = () => {
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.assignExtrasToProductionUnitRequest(chitIds, this.state.productionUnitId, this.props.productionId, this.props.productionDate);
  };

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    const assignExtrasToProductionUnitButtonLabel = this.props.bulkActionsRunning
      ? 'Assigning Extras to Production Unit...'
      : 'Assign Extra to Production Unit';
    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsAssignExtrasToProductionUnitWindow.bind(this, false)} keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Assign extras of selected chits to production unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={this.getProductionUnitOptions(this.props.productionUnits)}
            placeholder="Production Unit..."
            className="basic-multi-select"
            onChange={this.handleProductionUnitChange.bind(this)}
          />
        </Modal.Body>
        <Modal.Footer>
          {this.props.bulkActionsRunning &&
            <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          <Button variant="default" onClick={this.props.showHideBulkActionsAssignExtrasToProductionUnitWindow.bind(this, false)} disabled={this.props.bulkActionsRunning}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleAssignExtrasToProductionUnit} disabled={this.props.bulkActionsRunning || this.state.productionUnitId == null}>
            {assignExtrasToProductionUnitButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

AssignExtrasToProductionUnit.propTypes = {
  productionUnits: PropTypes.array.isRequired,
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsAssignExtrasToProductionUnitWindow: PropTypes.func.isRequired,
  assignExtrasToProductionUnitRequest: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired
};

export default AssignExtrasToProductionUnit;