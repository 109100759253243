import * as React from 'react';
import PropTypes from 'prop-types';
import ProductionUnitsRowActionsDelete from './ProductionUnitsRowActionsDelete';
import ProductionUnitsRowActionsEdit from './ProductionUnitsRowActionsEdit';

class ProductionUnitsRowActions extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div className="production-units-row-actions">
        <div>
          <ProductionUnitsRowActionsDelete {...this.props} />
        </div>
        <div style={{marginTop: '5px'}}>
          <ProductionUnitsRowActionsEdit {...this.props} />
        </div>
      </div>
    )
  };
}

ProductionUnitsRowActions.propTypes = {};

export default ProductionUnitsRowActions;
