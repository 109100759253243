import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import {
    CANCELLATION_FEE_TYPE_FULL,
    CANCELLATION_FEE_TYPE_PARTIAL,
    CANCELLATION_FEE_TYPE_ZERO,
    EXTRA_STATUS_BOOKED,
    EXTRA_STATUS_CANCELLED,
    EXTRA_STATUS_CHECKED_IN,
    EXTRA_STATUS_CHECKED_OUT,
    EXTRA_STATUS_NO_SHOW,
    EXTRA_STATUS_RELEASED,
} from '../../../constants';
import { allExtraStatuses } from '../../../getters';
import Select from 'react-select';
import {
    dateToUveWithoutWeekFormat,
    uveDateWithoutWeekAndTimeToDateTime,
} from '../../../../lib/dateTimeUtils';

class SetExtrasStatuses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extraStatus: null,
            details: null,
            isDataValid: false,
        };
        this.extraStatusesOptions = allExtraStatuses.map((extraStatus) => ({
            value: extraStatus,
            label: extraStatus,
        }));
    }

    handleSetExtrasStatuses = () => {
        const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
        this.props.setExtrasStatusesRequest(
            chitIds,
            this.state.extraStatus,
            this.state.details,
            this.props.productionId,
            this.props.productionDate,
        );
    };

    handleOnStatusChange = (option) => {
        const isDataValid =
            option.value === EXTRA_STATUS_NO_SHOW ||
            option.value === EXTRA_STATUS_RELEASED ||
            option.value === EXTRA_STATUS_BOOKED;

        this.setState({
            extraStatus: option.value,
            isDataValid,
        });
    };

    getCurrentExtraStatusOption = () => {
        this.extraStatusesOptions.find((o) => o.value === this.state.extraStatus);
    };

    isDataValid = (state) => state.isDataValid;

    render = () => {
        const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
        const setExtrasStatusesButtonLabel = this.props.bulkActionsRunning
            ? 'Settings Statuses Chits...'
            : 'Set Statuses';

        return (
            <Modal
                show={true}
                onHide={this.props.showHideBulkActionsSetExtrasStatusesWindow.bind(this, false)}
                keyboard={!this.props.bulkActionsRunning}
                backdrop={modalBackdrop}>
                <Modal.Header closeButton={!this.props.bulkActionsRunning}>
                    <Modal.Title>Set Extras Statuses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <Select
                            options={this.extraStatusesOptions}
                            placeholder="Select Extra Status..."
                            value={this.getCurrentExtraStatusOption()}
                            multi={false}
                            onChange={this.handleOnStatusChange.bind(this)}
                        />
                    </div>
                    <div>
                        {this.state.extraStatus === EXTRA_STATUS_NO_SHOW && (
                            <SetExtrasStatusesToNoShow />
                        )}
                        {this.state.extraStatus === EXTRA_STATUS_BOOKED && (
                            <SetExtrasStatusesToBooked />
                        )}
                        {this.state.extraStatus === EXTRA_STATUS_CHECKED_IN && (
                            <SetExtrasStatusesToCheckedIn
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                                productionDate={this.props.productionDate}
                            />
                        )}
                        {this.state.extraStatus === EXTRA_STATUS_CHECKED_OUT && (
                            <SetExtrasStatusesToCheckedOut
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                                productionDate={this.props.productionDate}
                            />
                        )}
                        {this.state.extraStatus === EXTRA_STATUS_CANCELLED && (
                            <SetExtrasStatusesToCancelled
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.bulkActionsRunning && (
                        <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />
                    )}
                    <Button
                        variant="default"
                        onClick={this.props.showHideBulkActionsSetExtrasStatusesWindow.bind(
                            this,
                            false,
                        )}
                        disabled={this.props.bulkActionsRunning}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.handleSetExtrasStatuses.bind(this)}
                        disabled={this.props.bulkActionsRunning || !this.isDataValid(this.state)}>
                        {setExtrasStatusesButtonLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

SetExtrasStatuses.propTypes = {
    idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
    production: PropTypes.object.isRequired,
    productionId: PropTypes.number.isRequired,
    productionDate: PropTypes.string.isRequired,
    showHideBulkActionsSetExtrasStatusesWindow: PropTypes.func.isRequired,
    bulkActionsRunning: PropTypes.bool.isRequired,
    setExtrasStatusesRequest: PropTypes.func.isRequired,
};

export default SetExtrasStatuses;

class SetExtrasStatusesToNoShow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div />;
    }
}

class SetExtrasStatusesToBooked extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div />;
    }
}

class SetExtrasStatusesToCheckedIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkInDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
            checkInTimeStr: '',
        };
    }

    isDataValid = (state) =>
        uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr) &&
        state.checkInDateStr &&
        state.checkInTimeStr;

    handleCheckInTimeChange = (event) => {
        this.setState(
            {
                checkInTimeStr: event.target.value.toString(),
            },
            () => {
                this.props.onChange(
                    {
                        ...this.detailsFromState(this.state),
                    },
                    this.isDataValid(this.state),
                );
            },
        );
    };

    detailsFromState = (state) => {
        let checkInTime = null;
        try {
            checkInTime = uveDateWithoutWeekAndTimeToDateTime(
                state.checkInDateStr,
                state.checkInTimeStr,
            );
        } catch (e) {}

        return {
            checkInTime,
        };
    };

    render() {
        return (
            <div>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkInDate">Check-in time</label>
                            <input
                                className="form-control"
                                id="checkInDate"
                                value={this.state.checkInDateStr}
                                disabled={true}
                                placeholder="dd-mm-yyyy"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkInTime">&nbsp;</label>
                            <input
                                className="form-control"
                                id="checkInTime"
                                value={this.state.checkInTimeStr}
                                placeholder="hh:mm"
                                onChange={this.handleCheckInTimeChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SetExtrasStatusesToCheckedIn.propTypes = {
    productionDate: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

class SetExtrasStatusesToCheckedOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkInDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
            checkInTimeStr: '',
            checkOutDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
            checkOutTimeStr: '',
        };
    }

    detailsFromState = (state) => {
        let checkInTime = null;
        let checkOutTime = null;
        try {
            checkInTime = uveDateWithoutWeekAndTimeToDateTime(
                state.checkInDateStr,
                state.checkInTimeStr,
            );
            checkOutTime = uveDateWithoutWeekAndTimeToDateTime(
                state.checkOutDateStr,
                state.checkOutTimeStr,
            );
        } catch (e) {}

        return {
            checkInTime,
            checkOutTime,
        };
    };

    isDataValid = (state) =>
        uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr) &&
        uveDateWithoutWeekAndTimeToDateTime(state.checkOutDateStr, state.checkOutTimeStr) &&
        state.checkInDateStr &&
        state.checkInTimeStr &&
        state.checkOutDateStr &&
        state.checkOutTimeStr;

    handleCheckInTimeChange = (event) => {
        this.setState(
            {
                checkInTimeStr: event.target.value.toString(),
            },
            () => {
                this.props.onChange(
                    {
                        ...this.detailsFromState(this.state),
                    },
                    this.isDataValid(this.state),
                );
            },
        );
    };

    handleCheckOutDateChange = (event) => {
        this.setState(
            {
                checkOutDateStr: event.target.value.toString(),
            },
            () => {
                this.props.onChange(
                    {
                        ...this.detailsFromState(this.state),
                    },
                    this.isDataValid(this.state),
                );
            },
        );
    };

    handleCheckOutTimeChange = (event) => {
        this.setState(
            {
                checkOutTimeStr: event.target.value.toString(),
            },
            () => {
                this.props.onChange(
                    {
                        ...this.detailsFromState(this.state),
                    },
                    this.isDataValid(this.state),
                );
            },
        );
    };

    render() {
        return (
            <div>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkInDate">Check-in time</label>
                            <input
                                className="form-control"
                                id="checkInDate"
                                value={this.state.checkInDateStr}
                                disabled={true}
                                placeholder="dd-mm-yyyy"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkInTime">&nbsp;</label>
                            <input
                                className="form-control"
                                id="checkInTime"
                                value={this.state.checkInTimeStr}
                                placeholder="hh:mm"
                                onChange={this.handleCheckInTimeChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkOutDate">Check-out time</label>
                            <input
                                className="form-control"
                                id="checkOutDate"
                                value={this.state.checkOutDateStr}
                                placeholder="dd-mm-yyyy"
                                onChange={this.handleCheckOutDateChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="checkOutTime">&nbsp;</label>
                            <input
                                className="form-control"
                                id="checkOutTime"
                                value={this.state.checkOutTimeStr}
                                placeholder="hh:mm"
                                onChange={this.handleCheckOutTimeChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SetExtrasStatusesToCheckedOut.propTypes = {
    productionDate: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

class SetExtrasStatusesToCancelled extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cancellationFeeType: null,
        };
    }

    handleCancellationFeeTypeChange = (cancellationFeeType) => {
        this.setState({ cancellationFeeType }, () =>
            this.props.onChange({ cancellationFeeType: this.state.cancellationFeeType }, true),
        );
    };

    render = () => {
        return (
            <div>
                <Alert variant="warning">
                    Please note that Cancellation Fees can only be applied if a Daily Rate for that
                    chit has been selected.
                </Alert>
                <strong>Please select cancellation fee:</strong>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="cancellationFeeOption"
                        id="fullCancellationFee"
                        value={CANCELLATION_FEE_TYPE_FULL}
                        checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_FULL}
                        onChange={this.handleCancellationFeeTypeChange.bind(
                            this,
                            CANCELLATION_FEE_TYPE_FULL,
                        )}
                    />
                    <label className="form-check-label" htmlFor="fullCancellationFee">
                        Full Cancellation Fee
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="cancellationFeeOption"
                        id="partialCancellationFee"
                        value={CANCELLATION_FEE_TYPE_PARTIAL}
                        checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_PARTIAL}
                        onChange={this.handleCancellationFeeTypeChange.bind(
                            this,
                            CANCELLATION_FEE_TYPE_PARTIAL,
                        )}
                    />
                    <label className="form-check-label" htmlFor="partialCancellationFee">
                        Partial Cancellation Fee
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="cancellationFeeOption"
                        id="zeroCancellationFee"
                        value={CANCELLATION_FEE_TYPE_ZERO}
                        checked={this.state.cancellationFeeType === CANCELLATION_FEE_TYPE_ZERO}
                        onChange={this.handleCancellationFeeTypeChange.bind(
                            this,
                            CANCELLATION_FEE_TYPE_ZERO,
                        )}
                    />
                    <label className="form-check-label" htmlFor="zeroCancellationFee">
                        Zero Cancellation Fee
                    </label>
                </div>
            </div>
        );
    };
}

SetExtrasStatusesToCancelled.propTypes = {
    onChange: PropTypes.func.isRequired,
};
