import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import CentredLayout from '../../app/components/CentredLayout';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {SIGN_OUT_REASON_EMAIL_NOT_VERIFIED, SIGN_OUT_REASON_NO_BSS_RIGHTS} from '../../app/constants';

class SignIn extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSignIn = ({email, password}) => {
    this.props.signInRequest({email, password});
  }

  render() {
    const signInSchema = Yup.object().shape({
      email: Yup.string()
        .required('Required'),
      password: Yup.string()
        .required('Required')
    });

    const {signingIn, error, signOutReason, adAppUrl} = this.props;

    return (
      <CentredLayout>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={signInSchema}
          onSubmit={this.handleSignIn.bind(this)}
          render={({errors, status, touched, isSubmitting}) => (
            <Form className="sign-in-form">
              <div className="form-group">
                <Field type="email" name="email" className="form-control" placeholder="Your email" id="email"/>
                <ErrorMessage name="email" component="div"/>
              </div>

              <div className="form-group">
                <Field type="password" name="password" className="form-control" placeholder="Your password"
                       id="password"/>
                <ErrorMessage name="password" component="div"/>
              </div>

              {error &&
              <div className="alert alert-danger" role="alert">
                {error.message}
              </div>
              }

              {(signOutReason === SIGN_OUT_REASON_NO_BSS_RIGHTS && !error) &&
              <div className="alert alert-danger" role="alert">
                You don't have correct permissions. Please navigate to the <a href={adAppUrl}><strong>UVE Client App</strong></a>.
              </div>
              }
              {(signOutReason === SIGN_OUT_REASON_EMAIL_NOT_VERIFIED && !error) &&
              <div className="alert alert-danger" role="alert">
                Your email is not verified.
              </div>
              }
              <div>
                <div style={{float: 'left'}}>
                  <Button variant="primary" disabled={signingIn} type="submit">
                    {signingIn &&
                    <span>Signing In...</span>
                    }
                    {!signingIn &&
                    <span>Sign In</span>
                    }
                  </Button>
                </div>
                <div style={{float: 'right'}}>
                  <Link to="/reset-password?sourceApp=bss">Forgot password?</Link>
                </div>
                <div style={{clear: 'both'}}/>
              </div>
            </Form>
          )}
        />
      </CentredLayout>
    );
  }
}

SignIn.propTypes = {
  signInRequest: PropTypes.func.isRequired,
  error: PropTypes.object,
  signingIn: PropTypes.bool.isRequired,
  signOutReason: PropTypes.string,
  adAppUrl: PropTypes.string.isRequired
}

export default SignIn;