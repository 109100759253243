import * as LOC_CONSTANTS from './LocationConstants'
import * as Yup from 'yup';
import { countryWithCountyCode } from './countries';
import React from 'react';
import { convertGoogleAddressToUvLocationObject } from './UVGoogleMapComponents/UvAdressHelper'


const countryOptions = countryWithCountyCode.map(c => ({ label: c.name, value: c.code }));



const fields = [
    {
        label: 'Find Address',
        name: LOC_CONSTANTS.SEARCH_ADDRESS,
        labelTitle: 'Enter Your Address and verify Details',
        type: 'adress-auto-complete',
        placeholder: 'Search Address...',
        isExternalComponet: true,
        convertGoogleAddressToUvLocationObject: convertGoogleAddressToUvLocationObject,
        id: 'search-address'
    },
    {
        label: 'Location Name (Internal use)',
        name: LOC_CONSTANTS.LOCATION_NAME,
        type: 'text',
        placeholder: 'Location Name',
        id: 'location_name',

    },



    {
        label: 'Building Name or Number',
        name: LOC_CONSTANTS.NUMBEROF_BUILDING,
        type: 'text',
        placeholder: 'Building Name or Number',
        id: 'location_building_number',


    },




    {
        label: 'Street',
        name: LOC_CONSTANTS.STREET,
        type: 'text',
        placeholder: 'Street',
        id: 'location_street',

    }, {
        label: 'Town/City',

        name: LOC_CONSTANTS.CITY,
        type: 'text',
        placeholder: 'Town/City',
        id: 'location_town_city',
    },
    {
        label: 'County',
        name: LOC_CONSTANTS.COUNTY,
        type: 'text',
        placeholder: 'County',
        id: 'County',


    },

    {
        LabelComponent: () => <div>Country</div>,
        label: 'Country',
        name: LOC_CONSTANTS.COUNTRY_CODE,
        type: 'react-select',
        isExternalComponet: true,
        placeholder: 'Country',
        id: 'Country',
        valueHandelor: (options, value) => options.find((it) => it.value == value),
        options: countryOptions

    },
    {
        label: 'Postcode',
        name: LOC_CONSTANTS.POST_CODE,
        type: 'text',
        placeholder: 'Postcode',
        isExternalComponet: false,
        id: 'location_post_code'
    },


    {
        id: LOC_CONSTANTS.COORDINATES + '_id',
        label: 'Geo Location',
        name: LOC_CONSTANTS.COORDINATES,
        isExternalComponet: true,
        type: 'uv-address-to-coordinates',
        placeholder: 'Geo Location',
        zoomLevel: 10,
        height: '450px',
    },
]



export const generateLocationFormWith = (state) => {
    return {
        formInsideModal: true,
        title: state.id ? 'Edit Location' : 'New Location',
        showSubmitErrors: true,
        showLoader: true,
        submitBtnName: state.id ? 'Update Location' : 'Add Location',
        modalSize: 'lg',
        initialState: {
            [LOC_CONSTANTS.LOCATION_NAME]: state[LOC_CONSTANTS.LOCATION_NAME] || "",
            [LOC_CONSTANTS.NUMBEROF_BUILDING]: state[LOC_CONSTANTS.NUMBEROF_BUILDING] || "",
            [LOC_CONSTANTS.STREET]: state[LOC_CONSTANTS.STREET] || '',
            [LOC_CONSTANTS.COUNTY]: state[LOC_CONSTANTS.COUNTY] || '',
            [LOC_CONSTANTS.COUNTRY_CODE]: state[LOC_CONSTANTS.COUNTRY_CODE] || '',
            [LOC_CONSTANTS.CITY]: state[LOC_CONSTANTS.CITY] || '',
            [LOC_CONSTANTS.POST_CODE]: state[LOC_CONSTANTS.POST_CODE] || '',
            [LOC_CONSTANTS.COORDINATES]: state[LOC_CONSTANTS.COORDINATES] || {
                lat: "0",
                lng: "0",
            },
            [LOC_CONSTANTS.SEARCH_ADDRESS]: state[LOC_CONSTANTS.SEARCH_ADDRESS] || null,
            [LOC_CONSTANTS.ADDITIONALINFO]: state[LOC_CONSTANTS.ADDITIONALINFO] || ''
        },
        fields,
        validationSchema: Yup.object().shape({
            [LOC_CONSTANTS.LOCATION_NAME]: Yup.string().min(2, 'Too Short!').required('Required'),
            [LOC_CONSTANTS.COUNTRY_CODE]: Yup.string().min(2, 'Too Short').required('Required'),
            [LOC_CONSTANTS.STREET]: Yup.string().min(2, 'Too Short').required('Required'),
            [LOC_CONSTANTS.NUMBEROF_BUILDING] : Yup.string().required('Required'),
            [LOC_CONSTANTS.COUNTY]: Yup.string().min(2, 'Too Short').required('Required'),
            [LOC_CONSTANTS.POST_CODE]: Yup.string().min(2, 'Too Short').required('Required'),
            [LOC_CONSTANTS.COORDINATES]: Yup.object().shape({
                lat: Yup.string().required('Required'),
                lng: Yup.string().required('Required'),
            }).required('Required'),
            [LOC_CONSTANTS.CITY]: Yup.string().min(2, 'Too Short!').required('Required')

        }),



        otherErrorHadlelor: (stateValue, fieldName) => {
            return { locationId: (value) => (value && value.length ? false : true) }[fieldName](stateValue);
        },
    }
};


