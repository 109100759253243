import React from 'react';
import PropTypes from 'prop-types';

class Header extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div className="row">
        <div className="col">
          <h4 className="py-2 mb-2">
            <ol className="breadcrumb">
              <li className="active">Productions</li>
            </ol>
          </h4>
        </div>
        <div className="col">
          <p className="text-right mt-2">
            <input type="button"
                   value="Import C5 Productions"
                   className="btn btn-sm btn-primary"
                   onClick={this.props.importC5ProductionsRequest.bind(this)}
            /></p>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  importC5ProductionsRequest: PropTypes.func.isRequired
};

export default Header;