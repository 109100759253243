import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import {
    EXTRA_STATUS_BOOKED,
    EXTRA_STATUS_CANCELLED,
    EXTRA_STATUS_CHECKED_IN,
    EXTRA_STATUS_CHECKED_OUT,
    EXTRA_STATUS_NO_SHOW,
    EXTRA_STATUS_RELEASED,
} from '../../production-day/constants';
import SetExtraStatusToBooked from './SetExtraStatusToBooked';
import SetExtraStatusToNoShow from './SetExtraStatusToNoShow';
import SetExtraStatusToCheckedOut from './SetExtraStatusToCheckedOut';
import SetExtraStatusToCheckedIn from './SetExtraStatusToCheckedIn';
import { dateTimeToHoursAndMinutes } from '../../lib/dateTimeUtils';
import SetExtraStatusToCancelled from './SetExtraStatusToCancelled';

class SetExtraStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: null,
            isDataValid: false,
            details: {},
        };
    }

    getExtraStatusOptions = (originalStatus) => {
        return this.props.allExtraStatuses
            .filter((status) => status !== originalStatus || status === EXTRA_STATUS_CANCELLED)
            .map((status) => ({
                value: status,
                label: status,
            }));
    };

    handleExtraStatusOptionChange = (option) => {
        const isDataValid =
            option.value === EXTRA_STATUS_NO_SHOW ||
            option.value === EXTRA_STATUS_RELEASED ||
            option.value === EXTRA_STATUS_BOOKED ||
            (option.value === EXTRA_STATUS_CHECKED_IN && this.props.chitData.chit.callTime);
    
        let details = {};
        // Set check in time in details by default to call time if call time is already set.
        if (option.value === EXTRA_STATUS_CHECKED_IN && this.props.chitData.chit.callTime) {
            details.checkInTime = this.props.chitData.chit.callTime;
        }

        this.setState({
            selectedStatus: option.value,
            isDataValid,
            details,
        });
    };

    handleSetExtraStatus = () => {
        this.props.setExtraStatusRequest({
            chitId: this.props.chitData.chit.id,
            status: this.state.selectedStatus,
            ...this.state.details,
        });
    };

    render = () => {
        const modalBackdrop = this.props.setExtraStatusRunning ? 'static' : true;
        const setExtraStatusButtonLabel = this.props.setExtraStatusRunning
            ? 'Save Changes...'
            : 'Save Changes';

        const { chit } = this.props.chitData;

        const setExtraStatusButtonDisabled =
            this.props.setExtraStatusRunning ||
            !this.state.selectedStatus ||
            !this.state.isDataValid;

        return (
          
            <Modal
                show={true}
                onHide={this.props.showHideSetExtraStatusWindow.bind(this, false)}
                keyboard={!this.props.setExtraStatusRunning}
                backdrop={modalBackdrop}>
                <Modal.Header closeButton={!this.props.setExtraStatusRunning}>
                    <Modal.Title>Set Extra Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        options={this.getExtraStatusOptions(chit.extraStatus)}
                        placeholder="Status..."
                        className="basic-multi-select"
                        onChange={this.handleExtraStatusOptionChange.bind(this)}
                    />
                    <div style={{ marginTop: '20px' }}>
                        {this.state.selectedStatus === EXTRA_STATUS_NO_SHOW && (
                            <SetExtraStatusToNoShow />
                        )}
                        {this.state.selectedStatus === EXTRA_STATUS_BOOKED && (
                            <SetExtraStatusToBooked />
                        )}
                        {this.state.selectedStatus === EXTRA_STATUS_CHECKED_IN && (
                            <SetExtraStatusToCheckedIn
                                productionDate={chit.date}
                                checkInTime={
                                    chit.callTime ? dateTimeToHoursAndMinutes(chit.callTime) : ''
                                }
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                            />
                        )}
                        {this.state.selectedStatus === EXTRA_STATUS_CHECKED_OUT && (
                            <SetExtraStatusToCheckedOut
                                productionDate={chit.date}
                                checkInTime={
                                    chit.callTime ? dateTimeToHoursAndMinutes(chit.callTime) : ''
                                }
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                            />
                        )}
                        {this.state.selectedStatus === EXTRA_STATUS_CANCELLED && (
                            <SetExtraStatusToCancelled
                                {...this.props}
                                onChange={(details, isDataValid) =>
                                    this.setState({ details, isDataValid })
                                }
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.setExtraStatusRunning && (
                        <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />
                    )}
                    <Button
                        variant="default"
                        onClick={this.props.showHideSetExtraStatusWindow.bind(this, false)}
                        disabled={this.props.setExtraStatusRunning}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.handleSetExtraStatus.bind(this)}
                        disabled={setExtraStatusButtonDisabled}>
                        {setExtraStatusButtonLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

SetExtraStatus.propTypes = {
    chitData: PropTypes.object.isRequired,
    showHideSetExtraStatusWindow: PropTypes.func.isRequired,
    setExtraStatusRequest: PropTypes.func.isRequired,
    setExtraStatusRunning: PropTypes.bool.isRequired,
    allExtraStatuses: PropTypes.array.isRequired,
};

export default SetExtraStatus;
