import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import {Button} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import {Link} from 'react-router-dom';

class ProductionUnitRowAds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUnassignSecondaryAdConfirmationDialog: false
    };
  }

  showHideUnassignSecondaryAdConfirmationDialog = show => {
    this.setState({
      showUnassignSecondaryAdConfirmationDialog: show
    });
  }

  /**
   * Get all ADs of production except of ADs which are already assigned to given production unit.
   */
  getProductionAdsOptions = () => {
    const assignedAdIds = Object.fromEntries(this.props.productionUnit.secondaryAdIds.map(adId => [adId, true]))
    const nonAssignedAds = this.props.productionAds.filter(ad => !(ad.id in assignedAdIds || ad.id === this.props.production.primaryAdId));
    return nonAssignedAds.map(ad => ({
      value: ad.id,
      label: `${ad.firstName} ${ad.lastName} (${ad.username})`
    }));
  };

  handleOnSelectChange = option => {
    this.props.assignSecondaryAdsToProductionUnitRequest(this.props.productionUnit.id, [option.value]);
  };

  unassignSecondaryAd = adId => {
    this.showHideUnassignSecondaryAdConfirmationDialog(false);
    this.props.unassignSecondaryAdFromProductionUnitRequest(this.props.productionUnit.id, adId);
  }

  render = () => {
    const secondaryAds = this.props.productionUnit.secondaryAdIds.map(secondaryAdId => {
      const secondaryAd = this.props.usersById[secondaryAdId];
      return (
        <Row key={secondaryAdId} className="in-row-row">
          <Col md={8} className="in-row-row-col">
            <Link to={`/productions/${this.props.production.id}/ads/${secondaryAdId}`}>
              {secondaryAd.firstName} {secondaryAd.lastName} ({secondaryAd.username})
            </Link>
          </Col>
          <Col md={4} className="in-row-row-col">
            <div className="in-row-row-actions">
              <Button size="sm" onClick={this.showHideUnassignSecondaryAdConfirmationDialog.bind(this, true)}
                      variant="outline-danger">
                Unassign
              </Button>
            </div>
          </Col>
          <ConfirmationDialog
            show={this.state.showUnassignSecondaryAdConfirmationDialog}
            onCancel={this.showHideUnassignSecondaryAdConfirmationDialog.bind(this, false)}
            title="Unassign Secondary AD"
            message={
              <span>Are you sure you want to unassign secondary AD <strong>{secondaryAd.firstName} {secondaryAd.lastName}</strong> from
                production unit <strong>{this.props.productionUnit.name}</strong>?
              </span>
            }
            onConfirm={this.unassignSecondaryAd.bind(this, secondaryAdId)}
          />
        </Row>
      )
    });

    const productionAdsOptions = this.getProductionAdsOptions();
    return (
      <div>
        {this.props.productionUnit.isDefault &&
        <div>
          All ADs of production have access to this unit
        </div>
        }
        {!this.props.productionUnit.isDefault && this.props.productionUnit.secondaryAdIds.length === 0 &&
        <div>
          No Secondary ADs
        </div>
        }
        {!this.props.productionUnit.isDefault && this.props.productionUnit.secondaryAdIds.length > 0 &&
        <div>
          {secondaryAds}
        </div>
        }
        {!this.props.productionUnit.isDefault &&
        <div className="add-ads-select">
          <Select
            options={productionAdsOptions}
            placeholder="Select AD to assign..."
            value={null}
            onChange={this.handleOnSelectChange.bind(this)}
          />
        </div>
        }
      </div>
    )
  }
}

ProductionUnitRowAds.propTypes = {
  production: PropTypes.object.isRequired,
  productionUnit: PropTypes.object.isRequired,
  usersById: PropTypes.object.isRequired,
  productionAds: PropTypes.array.isRequired,
  assignSecondaryAdsToProductionUnitRequest: PropTypes.func.isRequired,
  unassignSecondaryAdFromProductionUnitRequest: PropTypes.func.isRequired
};

export default ProductionUnitRowAds;
