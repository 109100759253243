import { axiosDefault, locationAxioxDefault } from '../lib/network';
import { dateToTimestamp } from '../lib/dateTimeUtils';
import fileDownload from "js-file-download";

export const getProductionAggregatedData = (productionId) => {
  return axiosDefault.get(`/v2/productions/${productionId}/aggregated`).then(function (response) {
    return response.data;
  });
};

export const assignPrimaryAd = (productionId, adId) => {
  return axiosDefault.post('/v2/productions/assign-primary-ad', { productionId, adId }).then(function (response) {
    return response.data
  })
};

export const assignAdmin = (productionId, adminId) => {
  return axiosDefault.post('/v2/productions/assign-admin', { productionId, adminId }).then(function (response) {
    return response.data
  })
};

export const setDefaultPayScheme = (productionId, paySchemeId) => {
  return axiosDefault.post('/v2/productions/set-default-pay-scheme', {
    productionId,
    paySchemeId
  }).then(function (response) {
    return response.data
  })
};

export const importProductionDays = (productionId, startDate, endDate) => {
  let url = `/import-production-days/${productionId}`;
  if (startDate) {
    const startTimestamp = dateToTimestamp(startDate);
    url += `/startdate/${startTimestamp}`;
  }
  if (endDate) {
    const endTimestamp = dateToTimestamp(endDate);
    url += `/enddate/${endTimestamp}`;
  }
  return axiosDefault.post(url).then(function (response) {
    return response.data
  })
};

export const createProductionUnit = (productionId,
  name,
  description,
  secondaryAdIds,
  paySchemeId) => {
  return axiosDefault.post('/v2/production-units/create', {
    productionId,
    name,
    description,
    secondaryAdIds: secondaryAdIds ? secondaryAdIds : [],
    paySchemeId
  }).then(function (response) {
    return response.data
  })
};

export const updateProductionUnitBasicInfo = (productionUnitId,
  name,
  description
) => {
  return axiosDefault.post('/v2/production-units/update-basic-info', {
    productionUnitId,
    name,
    description
  }).then(function (response) {
    return response.data
  })
};


export const deleteProductionUnit = (productionUnitId) => {
  return axiosDefault.post('/v2/production-units/delete', {
    productionUnitId,
  }).then(function (response) {
    return response.data
  })
};

export const setProductionUnitPayScheme = (productionUnitId, paySchemeId) => {
  return axiosDefault.post('/v2/production-units/set-pay-scheme', {
    productionUnitId,
    paySchemeId,
  }).then(function (response) {
    return response.data
  })
};

export const assignSecondaryAdsToProductionUnit = (productionUnitId, secondaryAdIds) => {
  return axiosDefault.post('/v2/production-units/assign-secondary-ads', {
    productionUnitId,
    secondaryAdIds,
  }).then(function (response) {
    return response.data
  })
};

export const unassignSecondaryAdFromProductionUnit = (productionUnitId, secondaryAdId) => {
  return axiosDefault.post('/v2/production-units/unassign-secondary-ad', {
    productionUnitId,
    secondaryAdId,
  }).then(function (response) {
    return response.data
  })
};

export const unassignSecondaryAdFromAllProductionUnits = (productionId, secondaryAdId) => {
  return axiosDefault.post('/v2/production-units/unassign-secondary-ad-from-all-production-units', {
    productionId,
    secondaryAdId,
  }).then(function (response) {
    return response.data
  })
};

export const addAdsToProduction = (productionId, adIds) => {
  return axiosDefault.post('/v2/productions/add-ads', {
    productionId,
    adIds,
  }).then(function (response) {
    return response.data
  })
};

export const removeAdFromProduction = (productionId, adId) => {
  return axiosDefault.post('/v2/productions/remove-ad', {
    productionId,
    adId,
  }).then(function (response) {
    return response.data
  })
};

export const getActionTokenQrCode = (productionDayId, action) => {
  return axiosDefault.get(`/v2/production-days/${productionDayId}/action-token-qr-code/${action}`).then(function (response) {
    return response.data;
  });
};

export const setHealthSurveySettings = (productionId, settings) => {
  return axiosDefault.post('/v2/productions/set-health-survey-settings', {
    productionId,
    settings
  }).then(function (response) {
    return response.data
  })
};

export const createProductionDocument = (data) => {
  return axiosDefault.post('/v2/productions/create-document', data).then(function (response) {
    return response.data
  })
};

export const updateProductionDocument = (data) => {
  return axiosDefault.post('/v2/productions/update-document', data).then(function (response) {
    return response.data
  })
};

export const deleteProductionDocument = (id) => {
  return axiosDefault.post('/v2/productions/delete-document', { id }).then(function (response) {
    return response.data
  })
};

export const setQrCodesRequirement = (productionId, required) => {
  return axiosDefault.post('/v2/productions/set-qr-codes-requirement', { productionId, required }).then(function (response) {
    return response.data
  })
};

export const downloadDocuments = (productionId, productionName, date) => {
  return axiosDefault.post(`/v2/productions/download-documents`, { productionId, date }, { responseType: 'blob' }).then(function (response) {
    fileDownload(response.data, `${productionName}-documents.zip`);
    return response.data;
  })
};

export const downloadDocumentsReport = (productionId, productionName, date) => {
  return axiosDefault.post(`/v2/productions/download-documents-report`, { productionId, date }).then(function (response) {
    fileDownload(response.data, `${productionName}-documents-report.csv`);
    return response.data;
  })
};

export const setCreateTranspotationGroup = (productionId, locationId, name) => {
  return axiosDefault.post('/v2/productions/create-transport-group', { productionId: 0 + +productionId, locationId, name }).then(function (response) {
    return response.data
  })
}

export const setUpdateTransportationGroup = (transportGroupId, productionId, locationId, name) => {
  return axiosDefault.post('/v2/productions/update-transport-group', { transportGroupId, productionId, locationId, name }).then(function (response) {
    return response.data
  })
}


export const setTransportationGroupDelete = (
  productionId,
  transportGroupId) => {
  return axiosDefault.post('/v2/productions/delete-transport-group', {
    productionId,
    transportGroupId
  }).then(function (response) {
    return response.data
  })
}

export const loadLocationData = () => {
  return locationAxioxDefault.get('/locations').then(function (response) {
    return response.data;
  });
}


export const setLocationCreate = (name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street) => {
  return locationAxioxDefault.post('/locations/create', {
    name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street
  }).then(function (response) { return response.data })
}

export const setEditLocation = (id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street) => {
  return locationAxioxDefault.patch(`/locations/${id}`, { id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street }).then((res) => res.data);
}

// not in use-> changed the logic on backend
// export const setDeleteLocation = (id) => locationAxioxDefault.delete(`/locations/${id}`).then((res) => res.data);

export const setDeleteLocation = (id) => locationAxioxDefault.patch(`/locations/${id}`,{status:'INACTIVE'}).then((res) => res.data);