import './styles.css';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import LocationPicker from './LocationPicker';
import { NEW_LOCATION_VALUE } from '../transportation-groups/transpotationConstants';
import usePrevious from '../../../lib/hooks/UsePrevious';
import { Button } from 'react-bootstrap';

const LocationSelector = (props) => {
    const {
        showLocationCreateUpdateWindow,
        setShowHideLocationEditModal,
        setShowHideLocation,
        location,
        setFieldValue,
        fieldInfo,
        values,
        validateForm,
    } = props;
    const { name } = fieldInfo;
    const prevLocation = usePrevious(location);
    const onChange = ({ value }) => {
        if (value === NEW_LOCATION_VALUE) {
            setShowHideLocation();
        }
        setFieldValue(name, value);
    };

    React.useEffect(() => {
        if (prevLocation && Object.keys(prevLocation).length !== Object.keys(location).length) {
            if (
                values[name] == NEW_LOCATION_VALUE &&
                Object.keys(prevLocation).length < Object.keys(location).length
            ) {
                const value = Object.keys(location).find((key) => !prevLocation[key]);
                setFieldValue(name, value);
            } else {
                if (
                    Object.keys(prevLocation).length > Object.keys(location).length &&
                    !location[values[name]]
                ) {
                    setFieldValue(name, NEW_LOCATION_VALUE);
                }
            }
        } else {
            validateForm(values);
        }
    }, [location]);

    const optionsRaw = [
        {
            value: NEW_LOCATION_VALUE,
            label: 'New Location',
        },
        ...Object.keys(location).map((key) => ({ value: key, label: location[key].name })),
    ];

    const getLocationValue = () => {
        if (values[name] == NEW_LOCATION_VALUE) {
            return null;
        }
        return optionsRaw.find((item) => item.value == values[name]);
    };

    const currentLocationValue = getLocationValue();

    const openLocationFormInEditMode = () => setShowHideLocationEditModal(values[name]);

    const makeEditButtonDisabled = () => {
        if (!values[name]) {
            return true;
        }
        if (!currentLocationValue) {
            return true;
        }

        return false;
    };

    return (
        <React.Fragment>
            <div className="input-group">
                <div className="form-control h-75">
                    <Select
                        options={optionsRaw}
                        {...fieldInfo}
                        onChange={onChange}
                        value={currentLocationValue}
                    />
                </div>

                <div className="align-middle input-group-text p-1">
                    <Button
                        disabled={makeEditButtonDisabled()}
                        size="xs"
                        variant="outline-secondary border border-white"
                        onClick={openLocationFormInEditMode}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="36"
                            fill="currentColor"
                            className="bi bi-pencil"
                            viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                    </Button>
                </div>
            </div>
            {showLocationCreateUpdateWindow && <LocationPicker {...props} />}
        </React.Fragment>
    );
};

LocationSelector.propTypes = {
    location: PropTypes.object.isRequired,
    showLocationCreateUpdateWindow: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    setShowHideLocation: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setShowHideLocationEditModal: PropTypes.func.isRequired,
};

export default LocationSelector;
