import React from 'react';
import { connect } from 'react-redux';
import ProductionDayDetails from './ProductionDayDetails';
import {
    deselectAllChits,
    generateCurrentReportRequest,
    setProductionDayNotes,
    setBulkChitNotesRequest,
    setChitIndividualNotesRequest,
    setProductionDayRelease,
} from '../../actions';
import * as getters from '../../getters';

const mapStateToProps = (state) => {
    const productionDayState = state.productionDay;
    return {
        ...productionDayState,
        chitsTotal: getters.getProductionDayChitsTotal(state),
        currentProductionDay: getters.getCurrentProductionDay(state),
        adAssignedToCurrentProductionDay: getters.getAdAssignedToCurrentProductionDay(state),
    };
};

const actionCreators = {
    deselectAllChits,
    generateCurrentReportRequest,
    setProductionDayNotes,
    setProductionDayRelease: setProductionDayRelease,
};

export default connect(mapStateToProps, actionCreators)(ProductionDayDetails);
