import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const Options = (props) => {
  return (
    <div className="container-p-x py-2">
      <div className="text-muted small">Options</div>
      <OverlayTrigger
        key={'top'}
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-top`}>
            A current report of the whole day will be generated and sent to your email.
          </Tooltip>
        }
      >
        <span
          data-tip="A current report will be generated and sent to your email"
          data-place="bottom"
        >
          <button type="button" style={{whiteSpace: 'normal'}} className="btn btn-xs btn-success" onClick={props.generateCurrentReportRequest.bind(this, props.currentProductionDay.id)}>Email Whole Day's Current Report</button>
        </span>
      </OverlayTrigger>
    </div>
  )
};

Options.propTypes = {
  currentProductionDay: PropTypes.object.isRequired,
  generateCurrentReportRequest: PropTypes.func.isRequired
};

export default Options;

