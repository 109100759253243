import React from 'react';
import {connect} from 'react-redux';
import SurveysAndDocuments from './SurveysAndDocuments';
import {
  createProductionDocumentRequest,
  deleteProductionDocumentRequest,
  downloadDocumentsReportRequest,
  downloadDocumentsRequest,
  setHealthSurveySettingsRequest,
  showHideCreateProductionDocumentWindow,
  showHideUpdateProductionDocumentWindow,
  updateProductionDocumentRequest,
} from '../../actions';
import * as getters from "../../getters";

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    production: productionState.production,
    productionDays: getters.getProductionDays(state),
    productionDocumentShowCreationWindow: productionState.productionDocumentShowCreationWindow,
    productionDocumentCreating: productionState.productionDocumentCreating,
    productionDocumentShowUpdateWindow: productionState.productionDocumentShowUpdateWindow,
    productionDocumentUpdating: productionState.productionDocumentUpdating,
    productionDocuments: productionState.productionDocuments,
    productionDocumentBeingUpdated: productionState.productionDocumentBeingUpdated,
    productionDocumentCreateError: productionState.productionDocumentCreateError,
    productionDocumentUpdateError: productionState.productionDocumentUpdateError
  };
};

const actionCreators = {
  setHealthSurveySettingsRequest,
  showHideCreateProductionDocumentWindow,
  showHideUpdateProductionDocumentWindow,
  createProductionDocumentRequest,
  updateProductionDocumentRequest,
  deleteProductionDocumentRequest,
  downloadDocumentsRequest,
  downloadDocumentsReportRequest
};

export default connect(mapStateToProps, actionCreators)(SurveysAndDocuments);


