import React from 'react'
import PropTypes from 'prop-types';
import CentredLayout from '../../app/components/CentredLayout';

class MfaEnrollmentConfirmCodeSuccess extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {signOutRequest} = this.props;

    return (
      <CentredLayout title="Set Up MFA">
        <div className="alert alert-success" role="alert">
          MFA has been set up successfully. Please <a onClick={() => signOutRequest()}><strong>Sign In</strong></a> again and continue using the app.
        </div>
      </CentredLayout>
    );
  }
}

MfaEnrollmentConfirmCodeSuccess.propTypes = {
  signOutRequest: PropTypes.func.isRequired
}

export default MfaEnrollmentConfirmCodeSuccess;