import React from 'react';
import Measurement from './Measurement';
import ProductionDay from '../../production-day/components/ProductionDay';
import PropTypes from 'prop-types';
import {
  convertCMToInches, convertInchesToCM,
  formatBraMeasurement, formatShoeSize,
  getCollarSizeFormattedFromCM,
  getFeetInchesFromCM
} from '../../lib/measurementUtils';

class ChitSection2 extends React.Component {
  render() {
    const {extra} = this.props.chitData;
    return (
      <div className="border-top-0 border-right-0 border-left-0 ui-bordered container-m--x mb-4">
        <div className="row no-gutters row-border-light">
          <Measurement label="Height" value={getFeetInchesFromCM(extra.measurements.height)} />
          <Measurement label="Collar" value={getCollarSizeFormattedFromCM(extra.measurements.collar)} />
          <Measurement label="Dress Size" value={extra.measurements.dressSize || 'X'} />
          <Measurement label="Chest Size" value={convertCMToInches(extra.measurements.chestSize)} />
          <Measurement label="Bra Size" value={formatBraMeasurement(extra.measurements.braSize, extra.measurements.braCupSize)} />
          <Measurement label="Under Bust" value={convertCMToInches(extra.measurements.underbust)} />
          <Measurement label="Hips" value={convertCMToInches(extra.measurements.hips)} />
          <Measurement label="Natural Waist" value={convertCMToInches(extra.measurements.waist)} />
          <Measurement label="Trouser Waist" value={convertCMToInches(extra.measurements.trouserSize)} />
          <Measurement label="Inside Leg" value={convertCMToInches(extra.measurements.insideLeg)} />
          <Measurement label="Shoe Size" value={formatShoeSize(extra.measurements.shoeSize)} />
          <Measurement label="Hat Size" value={convertCMToInches(extra.measurements.hatSize)} />
          <Measurement label="Glove Size" value={convertInchesToCM(extra.measurements.gloveSize)} />
        </div>
      </div>
    )
  }
}

ChitSection2.propTypes = {
  chitData: PropTypes.object.isRequired
}

export default ChitSection2;