import React from 'react';
import { connect } from 'react-redux';
import Chits from './Chits';
import {
    setChitsFilterValue,
    setChitCallTimeRequest,
    setChitsCallTimeRequest,
    assignExtraToProductionUnitRequest,
    assignExtrasToProductionUnitRequest,
    addSupplementaryFeeToChitsRequest,
    changeDailyRateOfChitsRequest,
    setChitsSelection,
    deselectAllChits,
    showHideBulkActionsSetCallTimeWindow,
    showHideBulkActionsAssignExtrasToProductionUnitWindow,
    showHideBulkActionsAddSupplementaryFeeWindow,
    showHideBulkActionsChangeDailyRateWindow,
    showChitsByBulkActionResult,
    removeBulkActionsFeedback,
    wrapUpProductionUnitOnDayRequest,
    generateProductionUnitCurrentReportRequest,
    showHideBulkActionsSetBookingDateDetailsWindow,
    setExtrasBookingDateDetailsRequest,
    setChitsActiveTab,
    resetHealthSurveyResultRequest,
    getHealthSurveyResultDetailsRequest,
    showHideBulkActionsCancelChitsWindow,
    cancelChitsRequest,
    removeSupplementaryFeesRequest,
    showHideBulkActionsRemoveSupplementaryFeesWindow,
    showHideBulkActionsSendBreakdownEmailsWindow,
    sendBreakdownEmailsRequest,
    setExtrasStatusesRequest,
    showHideBulkActionsSetExtrasStatusesWindow,
    setChitIndividualNotesRequest,
    setBulkChitNotesRequest,
    showHideBulkActionsSetNote,
    showHideBulkActionsSetTravelNotes,
    setBulkChitTravelNotes,
    sendingSMS,
    setShowHideBulkSmsModal,
    setCallDetailsMessageModal,
    setChitCallDetailsResponseRequest,
    showHideCallDetailsWindow,
} from '../../actions';
import {
    setChitLevelTravelNotesRequest,
    showHideChitLevelTravelNotes,
} from '../../../production-day/actions';
import * as getters from '../../getters';
import { isObjectEmpty } from '../../../lib/objectUtils';
import { arrayToMap } from '../../../lib/arrayUtils';
import * as productionConstants from '../../../production/constants';
import { EXTRA_STATUS_CANCELLED, EXTRA_STATUS_RELEASED } from '../../constants';
import { getChitRecords, getDeliveryStatusOfCallDetails } from '../../getters';
import {
    setShowHideLocation,
    setCreateLocation,
    setShowHideLocationEditModal,
    setEditLocationRequest,
    setDeleteLocationRequest,
} from '../../../production/actions';

const filterChits = (chitRecords, filter, bulkActionResults, extrasDocuments) =>
    Object.values(chitRecords).filter((record) => {
        let result = true;

        if (result && filter.callDetails) {
            result = result && getDeliveryStatusOfCallDetails(record.chit) === filter.callDetails;
        }

        if (result && filter.transportationGroups.length) {
            result =
                record.chit.travelInfo &&
                filter.transportationGroups.includes(record.chit.travelInfo.data.transportGroupId);
        }

        if (result && filter.travelValues.length) {
            result =
                record.chit.travelInfo && filter.travelValues.includes(record.chit.travelInfo.type);
        }

        if (result && filter.extraRoleIds.length) {
            result = filter.extraRoleIds.includes(record.chit.extraWorkDetails.extraRoleId);
        }

        if (result && filter.productionUnitIds.length) {
            result = filter.productionUnitIds.includes(
                record.chit.extraWorkDetails.productionUnitId,
            );
        }

        if (result && filter.extraStatuses.length) {
            result = filter.extraStatuses.includes(record.chit.extraStatus);
        } else {
            result = result && record.chit.extraStatus !== EXTRA_STATUS_RELEASED;
        }

        if (result && filter.activityTypes.length) {
            result = filter.activityTypes.includes(record.chit.extraWorkDetails.activityType);
        }

        if (result && filter.callTimeFrom) {
            result = record.chit.callTime >= filter.callTimeFrom;
        }

        if (result && filter.callTimeTo) {
            result = record.chit.callTime <= filter.callTimeTo;
        }

        if (result && filter.keywords) {
            // example for keyword: "ho la"
            // logic:
            // (first name contains "ho" or last name contains "ho" or extra role name contains "ho")
            // AND
            // (first name contains "la" or last name contains "la" or extra role name contains "la")
            const keywordsArr = filter.keywords.toLowerCase().split(/\s+/);
            keywordsArr.forEach((keyword) => {
                if (result) {
                    result =
                        record.extra.firstName.toLowerCase().includes(keyword) ||
                        record.extra.lastName.toLowerCase().includes(keyword) ||
                        record.extraRole.name.toLowerCase().includes(keyword) ||
                        record.chit.extraId.toString().includes(keyword);
                }
            });
        }

        if (result && filter.healthSurveyStatuses.length) {
            const chitHealthSurveyResult = record.chit.healthSurveResult
                ? record.chit.healthSurveResult.status
                : productionConstants.HEALTH_SURVEY_STATUS_NOT_COMPLETED;
            result = filter.healthSurveyStatuses.includes(chitHealthSurveyResult);
        }

        if (result && filter.productionDocumentsCompleted !== null) {
            const extraDocuments = extrasDocuments[record.chit.extraId];
            result =
                extraDocuments &&
                extraDocuments.allCompleted === filter.productionDocumentsCompleted;
        }

        if (result && !isObjectEmpty(bulkActionResults) && filter.bulkActionResult) {
            result =
                bulkActionResults &&
                bulkActionResults[record.chit.id] &&
                bulkActionResults[record.chit.id].result === filter.bulkActionResult;
        }

        return result;
    });

const mapStateToProps = (state) => {
    const bulkActionsResults = state.productionDay.bulkActionsResults;
    const filteredChitRecords = filterChits(
        state.productionDay.chitRecords,
        state.productionDay.chitsFilter,
        bulkActionsResults,
        state.productionDay.extrasDocuments,
    );
    const filteredChitRecordsByChitId = arrayToMap(
        filteredChitRecords,
        (chitRecord) => chitRecord.chit.id,
    );
    const idsOfSelectedChits = state.productionDay.idsOfSelectedChits;
    const bulkActionsFailedChits = getters.getBulkActionsFailedChits(
        state.productionDay.bulkActionsResults,
    );
    const productionUnits = getters.getProductionUnits(state);
    const productionUnitsById = arrayToMap(productionUnits, 'id');
    const transportGroups = arrayToMap(getters.getTranspotationGroups(state), 'id');
    const chitRecordsAsArray = Object.values(state.productionDay.chitRecords);
    const chitsCountWithFailedHealthSurveyStatus = chitRecordsAsArray.filter((record) => {
        const { chit } = record;
        return (
            chit.healthSurveyResult &&
            chit.healthSurveyResult.status === productionConstants.HEALTH_SURVEY_STATUS_FAILED
        );
    }).length;

    const chitsCountWithNotCompletedHealthSurvey = chitRecordsAsArray.filter((record) => {
        // Do not include documents of cancelled extras.
        const { chit } = record;
        return !chit.healthSurveyResult && chit.extraStatus !== EXTRA_STATUS_CANCELLED;
    }).length;

    const chitsCountWithPassedHealthSurveyStatus = chitRecordsAsArray.filter((record) => {
        // Do not include documents of cancelled extras.
        const { chit } = record;
        return (
            chit.healthSurveyResult &&
            chit.healthSurveyResult.status === productionConstants.HEALTH_SURVEY_STATUS_PASSED &&
            chit.extraStatus !== EXTRA_STATUS_CANCELLED
        );
    }).length;

    const {
        productionDayShowHideTravelNotesChitId,
        productionDayTravelNotesNewtworkCallRunning,
        productionDayTravelNotesError,
    } = state.productionDay;

    return {
        location: state.production.location,
        

        showLocationCreateUpdateWindow: state.production.showLocationCreateUpdateWindow,
        locationEditId: state.production.locationEditId,
        isLocationCreatingOrUpdating: state.production.isLocationCreatingOrUpdating,
        transportGroups: transportGroups,
        production: state.productionDay.production,
        rateCards: state.app.rateCards,
        chitRecordByProductionUnit: getters.getChitRecordsGroupedByProductionUnit(state),
        productionUnits,
        productionUnitsById,
        chitsFilter: state.productionDay.chitsFilter,
        filteredChitRecords,
        filteredChitRecordsByChitId,
        productionDayExtraRoles: getters.getProductionDayExtraRoles(state),
        productionId: state.productionDay.production.id,
        productionDate: getters.getCurrentProductionDay(state).date,
        idsOfSelectedChits,
        idsOfSelectedChitsCrossedByFiltered: getters.getIdsOfSelectedChitsCrossedByFiltered(
            filteredChitRecordsByChitId,
            idsOfSelectedChits,
        ),
        bulkSmsWindow:state.productionDay.bulkSmsWindow,
        productionDayShowHideTravelNotesChitId,
        productionDayTravelNotesNewtworkCallRunning,
        productionDayTravelNotesError,
        bulkActionsRunning: state.productionDay.bulkActionsRunning,
        bulkActionsResults,
        bulkActionsFailedChits,
        bulkActionsShowSetCallTimeWindow: state.productionDay.bulkActionsShowSetCallTimeWindow,
        bulkActionsShowAssignExtrasToProductionUnitWindow:
            state.productionDay.bulkActionsShowAssignExtrasToProductionUnitWindow,
        bulkActionsShowAddSupplementaryFeeWindow:
            state.productionDay.bulkActionsShowAddSupplementaryFeeWindow,
        bulkActionsShowChangeDailyRateWindow:
            state.productionDay.bulkActionsShowChangeDailyRateWindow,
        bulkActionsShowSetBookingDateDetailsWindow:
            state.productionDay.bulkActionsShowSetBookingDateDetailsWindow,
        bulkActionsShowCancelChitsWindow: state.productionDay.bulkActionsShowCancelChitsWindow,
        bulkActionsShowSetExtrasStatusesWindow:
            state.productionDay.bulkActionsShowSetExtrasStatusesWindow,
        bulkActionsShowRemoveSupplementaryFeesWindow:
            state.productionDay.bulkActionsShowRemoveSupplementaryFeesWindow,
        bulkActionsShowSendBreakdownEmailsWindow:
            state.productionDay.bulkActionsShowSendBreakdownEmailsWindow,
        bulkActionsShowSetNotes: state.productionDay.bulkActionsShowSetNotes,
        bulkActionsShowSetTravelWindow: state.productionDay.bulkActionsShowSetTravelWindow,
        latestBulkActionType: state.productionDay.latestBulkActionType,
        latestBulkActionParams: state.productionDay.latestBulkActionParams,
        currentProductionDay: getters.getCurrentProductionDay(state),
        chitsActiveTab: state.productionDay.chitsActiveTab,
        chitsCountWithFailedHealthSurveyStatus,
        chitsCountWithNotCompletedHealthSurvey,
        chitsCountWithPassedHealthSurveyStatus,
        selectedChitHealthSurveyResultDetails:
            state.productionDay.selectedChitHealthSurveyResultDetails,
        extrasDocumentsLoaded: state.productionDay.extrasDocumentsLoaded,
        extrasDocuments: state.productionDay.extrasDocuments,
        numberOfExtrasWithNotCompletedDocuments:
            getters.getNumberOfExtrasWithNotCompletedDocuments(state),
        productionDayRequiresDocuments: getters.doesProductionDayRequireDocuments(state),
        allExtraStatuses: getters.allExtraStatuses,
        callDetailsMessageModal: state.productionDay.callDetailsMessageModal,
        productionDayshowCallDetailsResponseWindow:  state.productionDay.productionDayshowCallDetailsResponseWindow,
        productionDayCallDetailsResponseNetworkCallRunning: state.productionDay.productionDayCallDetailsResponseNetworkCallRunning,
    };
};

const actionCreators = {
    setChitsFilterValue,
    setChitCallTimeRequest,
    setChitsCallTimeRequest,
    assignExtraToProductionUnitRequest,
    assignExtrasToProductionUnitRequest,
    addSupplementaryFeeToChitsRequest,
    changeDailyRateOfChitsRequest,
    setExtrasBookingDateDetailsRequest,
    cancelChitsRequest,
    setExtrasStatusesRequest,
    removeSupplementaryFeesRequest,
    sendBreakdownEmailsRequest,
    setChitsSelection,
    deselectAllChits,
    showHideBulkActionsSetCallTimeWindow,
    showHideBulkActionsAssignExtrasToProductionUnitWindow,
    showHideBulkActionsAddSupplementaryFeeWindow,
    showHideBulkActionsChangeDailyRateWindow,
    showHideBulkActionsSetBookingDateDetailsWindow,
    showHideBulkActionsCancelChitsWindow,
    showHideBulkActionsSetExtrasStatusesWindow,
    showHideBulkActionsRemoveSupplementaryFeesWindow,
    showHideBulkActionsSendBreakdownEmailsWindow,
    showChitsByBulkActionResult,
    removeBulkActionsFeedback,
    wrapUpProductionUnitOnDayRequest,
    generateProductionUnitCurrentReportRequest,
    setChitsActiveTab,
    resetHealthSurveyResultRequest,
    getHealthSurveyResultDetailsRequest,
    setChitIndividualNotesRequest,
    setBulkChitNotesRequest,
    showHideBulkActionsSetNote,
    setChitLevelTravelNotesRequest,
    showHideChitLevelTravelNotes,
    showHideBulkActionsSetTravelNotes,
    setBulkChitTravelNotes,
    setShowHideLocation,
    setCreateLocation,
    setShowHideLocationEditModal,
    setEditLocationRequest,
    setDeleteLocationRequest,
    setShowHideBulkSmsModal,
    sendingSMS,
    setCallDetailsMessageModal,
    setChitCallDetailsResponseRequest,
    showHideCallDetailsWindow,
};

export default connect(mapStateToProps, actionCreators)(Chits);
