import React from 'react';
import PasswordResetLayout from './PasswordResetLayout';

class SendPasswordResetLinkSuccess extends React.Component {

  render() {

    return (
      <PasswordResetLayout>
        <div className="alert alert-success" role="alert">
          Your submission has been received. If we have an account matching your email address, you will receive an email with a link to reset your password.
        </div>
      </PasswordResetLayout>
    )
  }
}

SendPasswordResetLinkSuccess.propTypes = {}


export default SendPasswordResetLinkSuccess;