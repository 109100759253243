import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from './Filter';
import './Chits.css';
import ProductionUnitOnDay from './ProductionUnitOnDay';
import ChitsHeader from './ChitsHeader';
import * as constants from '../../constants';
import * as productionConstants from '../../../production/constants';

const handleDocumentsTabClick = (props) => {
  if (!props.extrasDocumentsLoaded) {
    return false;
  }
  props.setChitsActiveTab(constants.CHITS_TAB_DOCUMENTS, props.production.id, props.currentProductionDay.date);
};

const Chits = (props) => {
  const selectedChits = Object.keys(props.idsOfSelectedChitsCrossedByFiltered)
  const selectedChitsNumbers = Object.entries(props.filteredChitRecordsByChitId).filter(entry => selectedChits.includes(entry[0]))


  const chitsByProductionUnit = {};
  props.filteredChitRecords.forEach(record => {
    if (!chitsByProductionUnit[record.productionUnit.id]) {
      chitsByProductionUnit[record.productionUnit.id] = [];
    }
    chitsByProductionUnit[record.productionUnit.id].push(record);
  });

  const chitsByProductionUnitComponents = Object.entries(chitsByProductionUnit).map(([productionUnitId, chitRecords]) => {
    const productionUnit = chitRecords[0].productionUnit;
    const closedProductionUnitOnDay = props.currentProductionDay.closedProductionUnits.find(unit => unit.productionUnitId === productionUnit.id)
    return <ProductionUnitOnDay {...props} key={productionUnit.id}
      filteredChitRecords={chitRecords}
      productionUnit={productionUnit}
      closedProductionUnitOnDay={closedProductionUnitOnDay}
    />
  });

  const { production, chitsCountWithFailedHealthSurveyStatus, chitsCountWithNotCompletedHealthSurvey, chitsCountWithPassedHealthSurveyStatus, currentProductionDay } = props;

  const tabChitsBaseInfoAriaSelected = props.chitsActiveTab === constants.CHITS_TAB_BASE_INFO;
  const tabChitsCallDetailsAriaSelected = props.chitsActiveTab === constants.CHITS_TAB_CALL_DETAILS;
  const tabChitsBookingDateDetailsAriaSelected = props.chitsActiveTab === constants.CHITS_TAB_BOOKING_DATE_DETAILS;
  const tabChitsHealthSurveyAriaSelected = props.chitsActiveTab === constants.CHITS_TAB_HEALTH_SURVEY;
  const tabChitsDocumentsAriaSelected = props.chitsActiveTab === constants.CHITS_TAB_DOCUMENTS;

  const tabChitsBaseInfoTabActiveClass = tabChitsBaseInfoAriaSelected ? 'active' : '';
  const tabChitsCallDetailsTabActiveClass = tabChitsCallDetailsAriaSelected ? 'active' : ''
  const tabChitsBookingDateDetailsTabActiveClass = tabChitsBookingDateDetailsAriaSelected ? 'active' : '';
  const tabChitsHealthSurveyTabActiveClass = tabChitsHealthSurveyAriaSelected ? 'active' : '';
  const tabChitsDocumentsTabActiveClass = tabChitsDocumentsAriaSelected ? 'active' : '';
  const tabChitsDocumentsDisabledClass = !props.extrasDocumentsLoaded ? 'disabled' : '';

  const healthSurveyResultsSummaries = [];
  if (chitsCountWithPassedHealthSurveyStatus) {
    const passedSummary = <span key={1} className="text-success">{chitsCountWithPassedHealthSurveyStatus} passed</span>
    healthSurveyResultsSummaries.push(passedSummary)
  }
  if (chitsCountWithNotCompletedHealthSurvey) {
    const notCompletedSummary = <span key={2} className="text-secondary">{chitsCountWithNotCompletedHealthSurvey} not completed</span>
    healthSurveyResultsSummaries.push(notCompletedSummary)
  }
  if (chitsCountWithFailedHealthSurveyStatus) {
    const failedSummary = <span key={3} className="text-danger">{chitsCountWithFailedHealthSurveyStatus} failed</span>
    healthSurveyResultsSummaries.push(failedSummary)
  }

  return (

    <div>
      <div className="card bg-light" style={{ padding: '10px' }}>
        <Filter {...props} />
      </div>
      <div style={{ marginTop: '10px' }}>
        <ChitsHeader {...props} />
      </div>
      <nav className="nav nav-tabs chits-tabs" role="tablist" style={{ marginTop: '10px' }}>
        <a onClick={props.setChitsActiveTab.bind(this, constants.CHITS_TAB_BASE_INFO, production.id, currentProductionDay.date)} role="tab"
          aria-selected={tabChitsBaseInfoAriaSelected}
          className={`nav-item nav-link ${tabChitsBaseInfoTabActiveClass}`}>
          Base Info
        </a>
        <a onClick={props.setChitsActiveTab.bind(this, constants.CHITS_TAB_CALL_DETAILS, production.id, currentProductionDay.date)} role="tab"
          aria-selected={tabChitsCallDetailsAriaSelected}
          className={`nav-item nav-link ${tabChitsCallDetailsTabActiveClass}`}>
          Campaigns
        </a>
        <a role="tab" onClick={props.setChitsActiveTab.bind(this, constants.CHITS_TAB_BOOKING_DATE_DETAILS, production.id, currentProductionDay.date)}
          aria-selected={tabChitsBookingDateDetailsAriaSelected}
          className={`nav-item nav-link ${tabChitsBookingDateDetailsTabActiveClass}`}>
          Call Details Info
        </a>
        {production.healthSurveySettings.type !== productionConstants.HEALTH_SURVEY_TYPE_NO_SURVEY &&
          <a role="tab" onClick={props.setChitsActiveTab.bind(this, constants.CHITS_TAB_HEALTH_SURVEY, production.id, currentProductionDay.date)}
            aria-selected={tabChitsHealthSurveyAriaSelected}
            className={`nav-item nav-link ${tabChitsHealthSurveyTabActiveClass}`}>
            {healthSurveyResultsSummaries.length > 0 &&
              <span>Health Survey ({healthSurveyResultsSummaries.reduce((prev, curr) => [prev, ', ', curr])})</span>
            }
            {healthSurveyResultsSummaries.length === 0 &&
              <span>Health Survey</span>
            }
          </a>
        }
        {props.productionDayRequiresDocuments &&
          <a role="tab" onClick={handleDocumentsTabClick.bind(this, props)}
            aria-selected={tabChitsDocumentsAriaSelected}
            className={`nav-item nav-link ${tabChitsDocumentsTabActiveClass} ${tabChitsDocumentsDisabledClass}`}
          >
            Documents
            {props.numberOfExtrasWithNotCompletedDocuments > 0 &&
              <span>
                &nbsp;({props.numberOfExtrasWithNotCompletedDocuments} not completed)
              </span>
            }
          </a>
        }
      </nav>
      {chitsByProductionUnitComponents}
    </div>
  )
};

Chits.propTypes = {
  production: PropTypes.object.isRequired,
  filteredChitRecords: PropTypes.array.isRequired,
  productionUnits: PropTypes.array.isRequired,
  idsOfSelectedChits: PropTypes.object.isRequired,
  bulkActionsResults: PropTypes.object.isRequired,
  currentProductionDay: PropTypes.object.isRequired,
  chitsActiveTab: PropTypes.string.isRequired,
  setChitsActiveTab: PropTypes.func.isRequired,
  chitsCountWithFailedHealthSurveyStatus: PropTypes.number,
  chitsCountWithNotCompletedHealthSurvey: PropTypes.number,
  chitsCountWithPassedHealthSurveyStatus: PropTypes.number,
  productionDayRequiresDocuments: PropTypes.bool.isRequired,
  extrasDocumentsLoaded: PropTypes.bool.isRequired,
  extrasDocuments: PropTypes.object.isRequired,
  numberOfExtrasWithNotCompletedDocuments: PropTypes.number.isRequired
};

export default Chits;

