export const TAB_PRODUCTION_DAYS = 'PRODUCTION_DAYS';
export const TAB_UNITS = 'UNITS';
export const TRANSPORT_GROUPS = 'TRANSPORT_GROUPS';
export const TAB_ADS = 'ADS';
export const TAB_OTHER_SETTINGS = 'OTHER_SETTINGS';
export const TAB_SURVEYS_AND_DOCUMENTS = 'SURVEYS_AND_DOCUMENTS';

export const IMPORT_TYPE_SPECIFIC_DAY = 'SPECIFIC_DAY';
export const IMPORT_TYPE_CURRENT_DAY = 'CURRENT_DAY';
export const IMPORT_TYPE_4_DAYS = '4_DAY';

export const HEALTH_SURVEY_TYPE_NO_SURVEY = 'NO_SURVEY';
export const HEALTH_SURVEY_STATUS_NOT_REQUIRED = 'HEALTH_SURVEY_STATUS_NOT_REQUIRED';
export const HEALTH_SURVEY_TYPE_UVE_SURVEY = 'UVE_SURVEY';
export const HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY = 'PRODUCTION_EXTERNAL_SURVEY';

export const PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL = 'EXTERNAL_URL';
export const PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT = 'DOCUMENT';

export const DEFAULT_UVE_HEALTH_SURVEY_QUESTIONNAIRE_ID = '5f4aa2e0a8bf21f908fc57c8';

export const HEALTH_SURVEY_STATUS_NOT_COMPLETED = 'NOT_COMPLETED';
export const HEALTH_SURVEY_STATUS_PASSED = 'PASSED';
export const HEALTH_SURVEY_STATUS_FAILED = 'FAILED';
export const HEALTH_SURVEY_STATUS_NOT_COMPLETED_OR_NOT_REQUIRED = 'NOT_COMPLETED_OR_NOT_REQUIRED';

export const PRODUCTION_DOCUMENT_TYPE_TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
export const PRODUCTION_DOCUMENT_TYPE_HEALTH_AND_SAFETY_NOTICE = 'HEALTH_AND_SAFETY_NOTICE';
export const PRODUCTION_DOCUMENT_TYPE_UVE_ON_SET_GUIDELINES = 'UVE_ON_SET_GUIDELINES';
export const PRODUCTION_DOCUMENT_TYPE_OTHER = 'OTHER';

export const PRODUCTION_DOCUMENT_REQUIRED_ACTION_NO_ACTION = 'NO_ACTION';
export const PRODUCTION_DOCUMENT_REQUIRED_ACTION_ACCEPTANCE = 'ACCEPTANCE';
export const PRODUCTION_DOCUMENT_REQUIRED_ACTION_SIGNATURE = 'SIGNATURE';

export const PRODUCTION_DOCUMENT_ACTION_FREQUENCY_DAILY = 'DAILY';
export const PRODUCTION_DOCUMENT_ACTION_FREQUENCY_ONE_TIME = 'ONE_TIME';



export const PRODUCTION_EXPORT_ALL_DATA = 'allData';
export const PRODUCTION_EXPORT_ALPHABETICAL = 'alphabetical';
export const PRODUCTION_EXPORT_CALL_TIME_ORDER = 'callTime';
export const PRODUCTION_EXPORT_MEASUREMENTS = 'measurements';
export const PRODUCTION_EXPORT_OFFICE = 'office';
export const PRODUCTION_EXPORT_ROLE_ORDER = 'roles';
export const PRODUCTION_EXPORT_TRANSPORTGROUPS = 'transport';

