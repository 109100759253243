const braCupSizeMap = [
  'X',
  'AAA',
  'AA',
  'A',
  'B',
  'C',
  'D',
  'DD',
  'E',
  'F',
  'FF',
  'G',
  'GG',
  'H',
  'HH',
  'J',
  'JJ',
  'K',
  'KK',
  'L',
  'LL',
  'M',
  'MM',
  'N',
]

const collarMap = {
  0: 0,
  25: 10,
  27: 10.5,
  28: 11,
  29: 11.5,
  30: 12,
  32: 12.5,
  33: 13,
  34: 13.5,
  36: 14,
  37: 14.5,
  38: 15,
  39: 15.5,
  41: 16,
  42: 16.5,
  43: 17,
  44: 17.5,
  46: 18,
  47: 18.5,
  48: 19,
  50: 19.5,
  51: 20,
  52: 20.5,
  53: 21,
  55: 21.5,
  56: 22,
  57: 22.5,
  58: 23,
  60: 23.5,
  61: 24,
  62: 24.5,
  64: 25
};

const cmToHeightMap = {
  73: "2'5\" (73.5cm)",
  76: "2'6\" (76cm)",
  78: "2'7\" (78.5cm)",
  81: "2'8\" (81.5cm)",
  84: "2'9\" (84cm)",
  86: "2'10\" (86.5cm)",
  89: "2'11\" (89cm)",
  91: "3' (91.5cm)",
  94: "3'1\" (94cm)",
  96: "3'2\" (96.5cm)",
  99: "3'3\" (99cm)",
  101: "3'4\" (101.5cm)",
  104: "3'5\" (104cm)",
  106: "3'6\" (106.5cm)",
  109: "3'7\" (109cm)",
  112: "3'8\" (112cm)",
  114: "3'9\" (114.5cm)",
  117: "3'10\" (117cm)",
  119: "3'11\" (119.5cm)",
  122: "4' (122cm)",
  124: "4'1\" (124.5cm)",
  127: "4'2\" (127cm)",
  130: "4'3\" (130cm)",
  132: "4'4\" (132cm)",
  134: "4'5\" (134.5cm)",
  137: "4'6\" (137cm)",
  140: "4'7\" (140cm)",
  142: "4'8\" (142cm)",
  144: "4'9\" (144.5cm)",
  147: "4'10\" (147cm)",
  150: "4'11\" (150cm)",
  152: "5' (152.5cm)",
  155: "5'1\" (155cm)",
  157: "5'2\" (157.5cm)",
  160: "5'3\" (160cm)",
  162: "5'4\" (162.5cm)",
  165: "5'5\" (165cm)",
  167: "5'6\" (167.5cm)",
  170: "5'7\" (170cm)",
  173: "5'8\" (173cm)",
  175: "5'9\" (175cm)",
  177: "5'10\" (177.5cm)",
  180: "5'11\" (180cm)",
  183: "6' (183cm)",
  185: "6'1\" (185.5cm)",
  188: "6'2\" (188cm)",
  190: "6'3\" (190.5cm)",
  193: "6'4\" (193cm)",
  195: "6'5\" (195.5cm)",
  198: "6'6\" (198cm)",
  200: "6'7\" (200.5cm)",
  203: "6'8\" (203cm)",
  206: "6'9\" (206cm)",
  208: "6'10\" (208cm)",
  211: "6'11\" (211cm)",
  213: "7' (213.5cm)",
  216: "7'1\" (216cm)",
  218: "7'2\" (218.5cm)",
  221: "7'3\" (221cm)",
  223: "7'4\" (223.5cm)",
  226: "7'5\" (226cm)",
  228: "7'6\" (228.5cm)",
  231: "7'7\" (231cm)",
  233: "7'8\" (233.5cm)",
  236: "7'9\" (236cm)",
  239: "7'10\" (239cm)",
  241: "7'11\" (241.5cm)"
}

export const formatBraMeasurement = (size, cupSize) => {
  if (!size) {
    return 'X'
  } else {
    return `${size} ${braCupSizeMap[parseInt(cupSize)]}` ;
  }
}

export const convertCMToFeetInches = cm => {
  if (!cm) {
    return "X"
  } else {
    const realFeet = ((n*0.393700) / 12);
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);

    return `${feet}" ${inches}' (${Math.round(cm)} cm)`
  }
}

export const convertCMToInches = cm => {
  if (!cm) {
    return "X"
  } else {
    const inches = Math.round(cm * 0.3937);

    return `${inches}" (${cm} cm)`
  }
}

export const convertInchesToCM = inch => {
  if (!inch) {
    return "X"
  } else {
    const cm = Math.round(inch * 2.54);
    return `${inch}" (${Math.round(cm)} cm)`
  }
}

export const formatShoeSize = size => {
  if (!size) {
    return "X"
  } else {
    return `UK ${size}`
  }
}

export const getCollarSizeFormattedFromCM = sizeInCM => {
  if (sizeInCM > 0) {
    return `${collarMap[sizeInCM]}" (${sizeInCM} cm)`;
  } else {
    return "X";
  }
};


/**
 * Returns the Formatted Height String in Feets and Inches by looking up into the heightmap. This Map was copied from UVE website.
 * @param cm
 * @return
 */
export const getFeetInchesFromCM = cm => {
  if (cm === 0 || !cmToHeightMap[cm]) {
    return "--"
  } else {
    return cmToHeightMap[cm];
  }
}
