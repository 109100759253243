import React from 'react';
import PropTypes from 'prop-types';

const Measurement = props => {
  const { label, value } = props;
  if (value === 'X') {
    return null;
  }

  return (
    <div className="col border-left-0 border-right-0">
      <div className="container-p-x py-2">
        <div className="text-muted small">{label}</div>
        <strong className="text-default">{value}</strong>
      </div>
    </div>
  )
}

Measurement.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default Measurement;