import React from 'react';
import PropTypes from 'prop-types';
import ProductionRecord from './ProductionRecord';
import ProductionsTableHeader from './ProductionsTableHeader';

class ProductionsTable extends React.Component {

  render = () => {
    const productions = this.props.productionRecords.map(record => (
      <ProductionRecord
        key={record.production.id}
        record={record}
        onProductionDayClick={this.props.onProductionDayClick}
        newlyImported={record.production.id in this.props.productionsImportResults && this.props.productionsImportResults[record.production.id].isNew === true}
      />
    ));
    return (
      <div className="card table-responsive production-records">
        <table className="table card-table table-with-clickable-row table-striped">
          <thead className="thead-light">
          <ProductionsTableHeader/>
          </thead>
          <tbody>
            {productions}
          </tbody>
        </table>
      </div>
    )
  }
}


ProductionsTable.propTypes = {
  productionRecords: PropTypes.array.isRequired,
  onProductionDayClick: PropTypes.func.isRequired,
  productionsImportResults: PropTypes.object.isRequired
};

export default ProductionsTable;

