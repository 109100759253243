import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import Select from 'react-select';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {getUserRoleLabel, getUserRoles} from '../getters';
import Error from '../../common/global-error/Error';
import {USER_ROLE_AD} from '../../app/constants';

class CreateUser extends React.Component {

  constructor(props) {
    super(props);
  }

  createUser = (data) => {
    const isAd = data.roles.includes(USER_ROLE_AD);
    this.props.createUserRequest({
      ...data,
      autoLogout: isAd ? data.autoLogout : false,
      autoLogoutDurationSeconds: isAd ? data.autoLogoutDurationSeconds : 3600,
    })
  }

  getUserRoleOptions = () => {
    return getUserRoles().map(role => ({
      value: role,
      label: getUserRoleLabel(role)
    }))
  };

  render = () => {
    const userRoleOptions = this.getUserRoleOptions();

    const createUserSchema = Yup.object().shape({
      firstName: Yup.string()
        .required('Required'),
      lastName: Yup.string()
        .required('Required'),
      email: Yup.string().email()
        .required('Required'),
      phoneNumber: Yup.string()
        .required('Required'),
      roles: Yup.array()
        .required('Required'),
      mfaRequired: Yup.boolean(),
      autoLogout: Yup.boolean,
      autoLogoutDurationSeconds: Yup.number().required('Required').min(60)
    });

    return (
      <div>
        <div style={{textAlign: 'right'}}>
          <Button onClick={this.props.showHideCreateUserWindow.bind(this, true)} size="sm">Create User</Button>
        </div>

        <Modal show={this.props.userShowCreationWindow}
               onHide={this.props.showHideCreateUserWindow.bind(this, false)}
               size="lg">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              roles: [],
              mfaRequired: false,
              autoLogout: false,
              autoLogoutDurationSeconds: 3600
            }}
            validationSchema={createUserSchema}
            onSubmit={this.createUser.bind(this)}
            render={({values, setFieldValue}) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="first-name">First Name:</label>
                    <Field type="text" name="firstName" className="form-control" id="first-name"/>
                    <ErrorMessage name="firstName" component="div"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="last-name">Last Name:</label>
                    <Field type="text" name="lastName" className="form-control" id="last-name"/>
                    <ErrorMessage name="lastName" component="div"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <Field type="email" name="email" className="form-control" id="email"/>
                    <ErrorMessage name="email" component="div"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="last-name">Phone Number:</label>
                    <Field type="text" name="phoneNumber" className="form-control" id="phone-number" />
                    <ErrorMessage name="phoneNumber" component="div" />
                  </div>

                  <div className="form-group">
                    <div>Roles:</div>
                    <Select
                      name="roles"
                      options={userRoleOptions}
                      placeholder="Roles..."
                      onChange={values => setFieldValue('roles', values ? values.map(v => v.value) : [])}
                      value={this.getUserRoleOptions().filter(opt => values.roles.includes(opt.value))}
                      isMulti
                    />
                    <ErrorMessage name="roles" component="div"/>
                  </div>

                  {values.roles.includes(USER_ROLE_AD) &&
                  <div className="form-group">
                    <div style={{float: 'left', marginTop: '5px'}}>
                      <Field type="checkbox" name="autoLogout" id="autoLogout"
                             onChange={event => setFieldValue('autoLogout', event.target.checked)}
                             checked={values.autoLogout}/>
                      <label style={{marginLeft: '10px'}} htmlFor="autoLogout">Automatically Logout from AD App after
                        Inactivity of </label>
                      <ErrorMessage name="autoLogout" component="div"/>
                    </div>
                    <div style={{float: 'left', marginLeft: '10px'}}>
                      <div style={{float: 'left'}} className="auto-logout-seconds">
                        <Field size="sz" type="number" name="autoLogoutDurationSeconds"
                               className="form-control form-control-sm" id="autoLogoutDurationSeconds"
                               disabled={!values.autoLogout}/>
                      </div>
                      <div style={{float: 'left', marginLeft: '10px', marginTop: '5px'}}>
                        Seconds
                      </div>
                      <div style={{clear: 'both'}}/>
                      <ErrorMessage name="autoLogoutDurationSeconds" component="div"/>
                    </div>
                    <div style={{clear: 'both'}}/>
                  </div>
                  }

                  <div className="form-group">
                    <Field type="checkbox" name="mfaRequired" id="mfaRequired"
                           onChange={event => setFieldValue('mfaRequired', event.target.checked)}
                           checked={values.mfaRequired}/>
                    <label style={{marginLeft: '10px'}} htmlFor="mfaRequired">MFA Required</label>
                    <ErrorMessage name="mfaRequired" component="div"/>
                  </div>
                  {this.props.userCreationError &&
                  <Error error={this.props.userCreationError}/>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={this.props.userCreating} variant="secondary"
                          onClick={this.props.showHideCreateUserWindow.bind(this, false)}>
                    Cancel
                  </Button>
                  <Button disabled={this.props.userCreating} type="submit" variant="primary">
                    Create User
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
      </div>
    )
  }
}

CreateUser.propTypes = {
  createUserRequest: PropTypes.func.isRequired,
  showHideCreateUserWindow: PropTypes.func.isRequired,
  userCreationError: PropTypes.object,
  userCreating: PropTypes.bool.isRequired,
  userShowCreationWindow: PropTypes.bool.isRequired
};

export default CreateUser;

