import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CallDetailsResponseWindow from './CallDetailsResponseWindow';
import { getDeliveryStatusOfCallDetails } from '../../getters';
import {
    CALL_DETAILS_STATUS_NO,
    CALL_DETAILS_STATUS_NOT_SENT,
    CALL_DETAILS_STATUS_REVIEW,
    CALL_DETAILS_STATUS_YES,
} from '../../constants';

const CallDetailsResponseBtn = (props) => {
    const { responded, showHideCallDetailsWindow, record } = props;
    const responseStatus = getDeliveryStatusOfCallDetails(record.chit);
    const [isModalShown, setIsModalShown] = useState(false);

    const handleResponse = () => {
        if (responseStatus === CALL_DETAILS_STATUS_YES) {
            return;
        }
        setIsModalShown(true);
        showHideCallDetailsWindow(true);
    };
    const closeModal = () => {
        showHideCallDetailsWindow(false);
        setIsModalShown(false);
    };
    const showCallDetialsActions = responseStatus !== CALL_DETAILS_STATUS_NOT_SENT;
    const getresponseBtnCss = () => {
        if (responseStatus === CALL_DETAILS_STATUS_NOT_SENT) {
            return 'cd-record chit-column-response';
        }
        if (responseStatus === CALL_DETAILS_STATUS_NO) {
            return 'cd-record chit-column-response-no';
        }
        if (responseStatus === CALL_DETAILS_STATUS_YES) {
            return 'cd-record chit-column-response-yes disabled';
        }
        return 'cd-record chit-column-response-review';
    };
    return (
        <>
            {isModalShown ? (
                <CallDetailsResponseWindow
                    {...props}
                    responseStatus={responseStatus}
                    closeModal={closeModal}
                />
            ) : null}
            {showCallDetialsActions ? (
                <td className={`cd-record ${getresponseBtnCss()}`} onClick={handleResponse}>
                    <span>{responseStatus}</span>
                </td>
            ) : (
                <td className={`cd-record ${getresponseBtnCss()}`}></td>
            )}
        </>
    );
};

CallDetailsResponseBtn.propTypes = {
    record: PropTypes.object.isRequired,
    showHideCallDetailsWindow: PropTypes.object.isRequired,
};

export default CallDetailsResponseBtn;
