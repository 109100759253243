import * as actionTypes from './actionTypes';
import {
  STEP_RESET_PASSWORD_SUCCESS,
  STEP_SEND_PASSWORD_RESET_LINK_SUCCESS
} from './constants';

const initialState = {
  sourceApp: null,
  sendingPasswordResetLink: false,
  passwordResetLinkSent: false,
  sendPasswordResetLinkError: null,
  currentStep: null,
  settingPassword: false,
  setPasswordError: null,
  passwordSet: false,
  passwordResetCode: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP:
      return {
        ...state,
        currentStep: action.step,
        sourceApp: action.data.sourceApp,
        passwordResetCode: action.data.passwordResetCode
      }
    case actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST:
      return {
        ...state,
        sendingPasswordResetLink: true,
        passwordResetLinkSent: false,
        sendPasswordResetLinkError: null
      };
    case actionTypes.SEND_PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        sendingPasswordResetLink: false,
        passwordResetLinkSent: true,
        currentStep: STEP_SEND_PASSWORD_RESET_LINK_SUCCESS,
        sendPasswordResetLinkError: null
      };
    case actionTypes.SEND_PASSWORD_RESET_LINK_FAILURE:
      return {
        ...state,
        sendingPasswordResetLink: false,
        passwordResetLinkSent: false,
        sendPasswordResetLinkError: action.error
      };
    case actionTypes.SET_PASSWORD_REQUEST:
      return {
        ...state,
        settingPassword: true,
        setPasswordError: null
      }
    case actionTypes.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        settingPassword: false,
        setPasswordError: null,
        passwordSet: true,
        currentStep: STEP_RESET_PASSWORD_SUCCESS
      }
    case actionTypes.SET_PASSWORD_FAILURE:
      return {
        ...state,
        settingPassword: false,
        setPasswordError: action.error,
        passwordSet: false
      }
    default:
      return state;
  }
};

export default reducer;