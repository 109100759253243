import React from 'react';
import PropTypes from 'prop-types';
import ProductionUnitsTableHeader from './ProductionUnitsTableHeader';
import ProductionUnitRow from './ProductionUnitRow';
import './ProductionUnits.css';
import CreateProductionUnit from './CreateProductioinUnit';
import UpdateProductionUnitBaseInfo from './UpdateProductionUnitBaseInfo';

class ProductionUnits extends React.Component {

  constructor(props) {
    super(props);
    this.refsByProductionUnit = {};
  }

  scrollToProductionUnit = () => {
    this.refsByProductionUnit = this.props.productionUnits.reduce((refs, productionUnit) => {
      refs[productionUnit.id] = React.createRef();
      return refs;
    }, {});

    if (this.props.highlightedProductionUnitId) {
      const ref = this.refsByProductionUnit[this.props.highlightedProductionUnitId];
      if (ref) {
        window.scrollTo(0, ref.current.offsetTop)
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // There might be case when we need to scroll to production unit, but production units
    // are not yet loaded when component mounted, so we scroll to production unit only
    // when we receive them.
    if (prevProps.productionUnits.length === 0 && this.props.productionUnits.length > 1) {
      this.scrollToProductionUnit();
    }
  }

  render = () => {
    const productionUnitRows = this.props.productionUnits.map(productionUnit => (
      <ProductionUnitRow
        key={productionUnit.id}
        highlighted={productionUnit.id === this.props.highlightedProductionUnitId}
        refProp={this.refsByProductionUnit[productionUnit.id]}
        productionUnit={productionUnit}
        chitsTotal={this.props.chitsTotalsByUnits[productionUnit.id] || 0}
        productionDays={this.props.productionDaysByUnits[productionUnit.id] ? this.props.productionDaysByUnits[productionUnit.id] : []}
        {...this.props}
      />
    ));

    return (
      <div>
        <CreateProductionUnit {...this.props} />
        {this.props.productionUnitShowUpdateBasicInfoWindow &&
        <UpdateProductionUnitBaseInfo productionUnit={this.props.productionUnitBasicInfoBeingUpdated} {...this.props}/>
        }
        {this.props.productionUnits.length > 0 &&
        <table className="table card-table table-striped production-units-table">
          <thead>
          <ProductionUnitsTableHeader/>
          </thead>
          <tbody>
          {productionUnitRows}
          </tbody>
        </table>
        }
        {!this.props.productionUnits.length &&
        <div className="no-entries">
          No productions units
        </div>
        }
      </div>
    )
  }
}

ProductionUnits.propTypes = {
  productionUnits: PropTypes.array.isRequired,
  chitsTotalsByUnits: PropTypes.object.isRequired,
  productionDaysByUnits: PropTypes.object.isRequired,
  highlightedProductionUnitId: PropTypes.string,
  productionUnitShowUpdateBasicInfoWindow: PropTypes.bool.isRequired,
  productionUnitBasicInfoBeingUpdated: PropTypes.object
};

export default ProductionUnits;
