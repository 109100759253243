import * as React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

class ProductionUnitsRowActionsDelete extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        {this.props.productionUnit.isDefault &&
        <small>Cannot edit main unit.</small>
        }
        {!this.props.productionUnit.isDefault &&
        <Button variant="outline-primary" size="sm"
                onClick={this.props.showHideUpdateProductionUnitBasicInfoWindow.bind(this, true, this.props.productionUnit)}>Edit</Button>
        }
      </div>
    )
  };
}

ProductionUnitsRowActionsDelete.propTypes = {
  productionUnit: PropTypes.object.isRequired,
  showHideUpdateProductionUnitBasicInfoWindow: PropTypes.func.isRequired
};

export default ProductionUnitsRowActionsDelete;
