import React from 'react';
import {connect} from 'react-redux';
import ProductionDays from './ProductionUnits';
import * as getters from '../../getters';
import {getters as appGetters} from '../../../app';
import {
  assignSecondaryAdsToProductionUnitRequest, createProductionUnitRequest, deleteProductionUnitRequest,
  setProductionUnitPaySchemeRequest, showHideCreateProductionUnitWindow, showHideUpdateProductionUnitBasicInfoWindow,
  unassignSecondaryAdFromProductionUnitRequest, updateProductionUnitBasicInfoRequest
} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    production: productionState.production,
    productionUnits: getters.getProductionUnits(state),
    paySchemes: appGetters.getRateCards(state),
    paySchemesById: state.app.rateCards,
    usersById: state.app.users,
    chitsTotalsByUnits: getters.getChitsTotalsByUnits(state),
    productionDaysByUnits: getters.getProductionDaysByUnits(state),
    productionAds: getters.getProductionAds(state),
    productionUnitShowCreationWindow: productionState.productionUnitShowCreationWindow,
    productionUnitCreating: productionState.productionUnitCreating,
    productionUnitCreationError: productionState.productionUnitCreationError,
    productionUnitBasicInfoBeingUpdated: productionState.productionUnitBasicInfoBeingUpdated,
    productionUnitShowUpdateBasicInfoWindow: productionState.productionUnitShowUpdateBasicInfoWindow,
    productionUnitBasicInfoUpdating: productionState.productionUnitBasicInfoUpdating,
    productionUnitUpdateBasicInfoError: productionState.productionUnitUpdateBasicInfoError,
    highlightedProductionUnitId: ownProps.currentProductionUnitId,
  };
};

const actionCreators = {
  setProductionUnitPaySchemeRequest,
  assignSecondaryAdsToProductionUnitRequest,
  unassignSecondaryAdFromProductionUnitRequest,
  showHideCreateProductionUnitWindow,
  createProductionUnitRequest,
  showHideUpdateProductionUnitBasicInfoWindow,
  updateProductionUnitBasicInfoRequest,
  deleteProductionUnitRequest
};

export default connect(mapStateToProps, actionCreators)(ProductionDays);


