import { auth, firebaseConfig } from './firebase';
import firebase from "firebase/compat/app";

/**
 * Get access token.
 */
export const getAccessToken = () => {
  return auth.currentUser ? auth.currentUser.getIdToken() : Promise.resolve(null);
};

export const signIn = ({email, password}) => {
  return auth.signInWithEmailAndPassword(email, password)
}

export const signOut = () => {
  return auth.signOut();
}

export const verifyPhoneNumber = (phoneInfoOptions, recaptchaVerifier) => {
  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
  return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
}

export const enrollMfa = (firebaseUser, verificationId, code) => {
  const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  return firebaseUser.multiFactor.enroll(multiFactorAssertion);
}

export const resolveMfaSignIn = (mfaResolver, verificationId, code) => {
  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId, code);
  const multiFactorAssertion =
    firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  return mfaResolver.resolveSignIn(multiFactorAssertion)
}

export { auth, firebaseConfig }