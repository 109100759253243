import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import * as constants from '../../production-day/constants';
import {Button, Modal} from 'react-bootstrap';

class ChitSection3LeftSection2SupplementaryFees extends React.Component {

  CUSTOM_FEE_NAME = 'Custom Fee';

  constructor(props) {
    super(props);
    this.state = {
      isCustomFee: false,
      customFeePayRate: 0,
      currentFeeOption: null
    }
  }

  getSupplementaryFeeOptions = rateCardItems => {
    const chitSupplementaryFeeNames = this.props.chitData.chit.supplementaryFee.map(sf => sf.name)
    const supplementaryFees = rateCardItems
      .filter(item => {
        const sfAlreadyAdded = chitSupplementaryFeeNames.includes(item.title);
        const allowsMultipleSf = item.multiple;
        const isCustomSf = item.title === this.CUSTOM_FEE_NAME;
        const hideSf = sfAlreadyAdded && !(allowsMultipleSf || isCustomSf);

        return item.rateType === constants.RATE_TYPE_SUPPLEMENTARY_FEE && !hideSf;
      })
      .map(item => {
        // For custom supplementary fee do not set pay rate in label since pay rate is custom
        const label = item.title === constants.CUSTOM_SUPPLEMENTARY_FEE_NAME ? item.title : `${item.title} - ${item.payRate}`;
        return {
          value: item.title,
          label,
          payRate: parseFloat(item.payRate.replace('£', ''))
        }});

    return supplementaryFees;
  };

  handleAddSupplementaryFee = option => {
    const supplementaryFees = this.props.chitData.payScheme.items.filter(item => item.rateType === constants.RATE_TYPE_SUPPLEMENTARY_FEE);
    const fee = supplementaryFees.find(sf => sf.title === option.value);
    const payRate = parseFloat(fee.payRate.replace('£', ''));

    if (option.value === this.CUSTOM_FEE_NAME) {
      this.setState({
        isCustomFee: true
      })
    } else {
      this.addSupplementaryFee({
        chitId: this.props.chitData.chit.id,
        paySchemeId: this.props.chitData.payScheme.id,
        supplementaryFeeName: option.value,
        payRate
      });
    }
  }

  addSupplementaryFee = ({chitId, paySchemeId, supplementaryFeeName, payRate}) => {
    this.props.addSupplementaryFeeRequest({
      chitId: this.props.chitData.chit.id,
      paySchemeId: this.props.chitData.payScheme.id,
      supplementaryFeeName: supplementaryFeeName,
      payRate
    });
    this.setState({
      currentFeeOption: null
    })
  }

  handleCustomFeePayRateChange = event => {
    this.setState({
      customFeePayRate: event.target.value
    });
  }

  handleAddCustomSupplementaryFee = () => {
    this.addSupplementaryFee({
      chitId: this.props.chitData.chit.id,
      paySchemeId: this.props.chitData.payScheme.id,
      supplementaryFeeName: this.CUSTOM_FEE_NAME,
      payRate: this.state.customFeePayRate
    });
    this.setState({
      isCustomFee: false,
      currentFeeOption: null
    });
  }

  handleRemoveSupplementaryFee = (feeName, feeTotal) => {
    this.props.removeSupplementaryFeeRequest({
      chitId: this.props.chitData.chit.id,
      feeName,
      feeTotal
    })
  }

  render() {
    const { chit, payScheme } = this.props.chitData;

    const supplementaryFees = chit.supplementaryFee.filter(sf => sf.name.length > 0).map((sf, index) => {
      return (
        <tr key={`${sf.name}-${index}`}>
          <td>{sf.name}<br /><small>{sf.description}</small></td>
          <td>
            <span>
              <a className="danger-btn" title="Remove Fee" style={{color: '#ddd'}} onClick={this.handleRemoveSupplementaryFee.bind(this, sf.name, sf.total)}>
                <i className="fas fa-times-circle"/>
              </a>
            </span>
            <input disabled type="text" value={`£${sf.total.toFixed(2)}`} style={{marginLeft: '10px', textAlign: 'right', border: '1px'}} />
        </td>
      </tr>
      )
    });

    const customFeeModal =
      <Modal show={true} onHide={() => this.setState({isCustomFee: false})} backdrop={true}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Set Custom Fee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="checkOutDate">Fee, £</label>
                  <input className="form-control"
                         value={this.state.customFeePayRate}
                         placeholder="dd-mm-yyyy" onChange={this.handleCustomFeePayRateChange.bind(this)}/>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={() => this.setState({isCustomFee: false})}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {this.handleAddCustomSupplementaryFee()}} >
            Add Custom Fee
          </Button>
        </Modal.Footer>
      </Modal>

    return (
      <React.Fragment>
        {this.state.isCustomFee &&
          <React.Fragment>
          {customFeeModal}
          </React.Fragment>
        }
        <tr className="bg-lighter">
          <td><b>Supplementary Fees</b></td>
          <td className="text-right">
            {payScheme &&
            <div className="text-left" style={{fontSize: '14px', fontWeight: 'normal'}}>
              <Select
                options={this.getSupplementaryFeeOptions(payScheme.items)}
                placeholder="Add/Remove Supplementary Fees..."
                className="basic-multi-select"
                onChange={this.handleAddSupplementaryFee.bind(this)}
                value={this.state.currentFeeOption}
              />
            </div>
            }
          </td>
        </tr>
        {supplementaryFees}
      </React.Fragment>
    )
  }
}

ChitSection3LeftSection2SupplementaryFees.propTypes = {
  chitData: PropTypes.object.isRequired,
  addSupplementaryFeeRequest: PropTypes.func.isRequired,
  removeSupplementaryFeeRequest: PropTypes.func.isRequired
}

export default ChitSection3LeftSection2SupplementaryFees;