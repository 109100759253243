import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../constants';
import ChitRecordCallTime from './ChitRecordCallTime';
import { parseFromDateTime } from '../../../lib/dateTimeUtils';
import ChitRecordUnit from './ChitRecordUnit';
import { getExtraStatusCssClassPart } from '../../../chit/getters';
import ChitNotes from './ChitNotes';
import TravelNotes from './TravelNotes';
class ChitRecordBaseInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  timeBlock = (dateTime) => {
    return (
      <div className="small mt-1">
        <strong>{parseFromDateTime(dateTime).format('HH:mm')}</strong>,&nbsp;
        <span className="text-muted text-uppercase">{parseFromDateTime(dateTime).format('D MMM')}</span>
      </div>
    );
  };

  render = () => {
    const { record } = this.props;

    const extraStatusCssClassPart = getExtraStatusCssClassPart(record.chit.extraStatus);

    let checkInTimeLoggedAtText = '- - : - -';
    if (
      record.chit.extraStatus === constants.EXTRA_STATUS_CHECKED_IN ||
      record.chit.extraStatus === constants.EXTRA_STATUS_CHECKED_OUT
    ) {
      checkInTimeLoggedAtText = this.timeBlock(record.chit.checkInTimeLoggedAt);
    }

    let checkOutTimeLoggedAtText = '- - : - -';
    if (record.chit.extraStatus === constants.EXTRA_STATUS_CHECKED_OUT) {
      checkOutTimeLoggedAtText = this.timeBlock(record.chit.checkOutTimeLoggedAt);
    }

    return (
      <React.Fragment>
        <td className="chit-column-role">
          <span>{record.extraRole.name}</span>
        </td>
        <td className="chit-column-costume">
          <span>{record.chit.extraWorkDetails.costumeId}</span>
        </td>
        <td className="text-center chit-column-status">
          <span className={`badge badge ${extraStatusCssClassPart} w-100`}>{record.chit.extraStatus}</span>
        </td>
        <td className="chit-column-call-time">
          <ChitRecordCallTime {...this.props} />
        </td>
        <td className="text-left chit-column-check-in-time">{checkInTimeLoggedAtText}</td>
        <td className="text-left chit-column-check-out-time">{checkOutTimeLoggedAtText}</td>
        <td className="text-left chit-column-unit">
          <ChitRecordUnit {...this.props} />
        </td>
        <td className="text-left chit-column-tarvel-notes">
          <TravelNotes {...this.props} />
        </td>
        <td className="text-left chit-colum-notes">
          <ChitNotes {...this.props} isBulk={false} />
        </td>
        <td className="chit-column-day-type">
          <span>{record.chit.extraWorkDetails.activityType.toUpperCase()}</span>
        </td>
      </React.Fragment>
    );
  };
}

ChitRecordBaseInfo.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ChitRecordBaseInfo;
