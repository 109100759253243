import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../constants';
import {dateTimeToUveFormat} from '../../../lib/dateTimeUtils';
import * as getters from '../../getters';

const ProductionDayOnDayStatus = (props) => {

  if (!props.closedProductionUnitOnDay) {
    return null;
  }

  let productionUnitOnDayStatusLabel = getters.getProductionUnitOnDayStatusLabel(props.closedProductionUnitOnDay.status);

  if (props.closedProductionUnitOnDay.status === constants.PRODUCTION_UNIT_ON_DAY_STATUS_WRAPPED_UP) {
    productionUnitOnDayStatusLabel += ` at ${dateTimeToUveFormat(props.closedProductionUnitOnDay.wrappedUpAt)}`;
  } else if (props.closedProductionUnitOnDay.status === constants.PRODUCTION_UNIT_ON_DAY_STATUS_LOCKED) {
    productionUnitOnDayStatusLabel += ` at ${dateTimeToUveFormat(props.closedProductionUnitOnDay.lockedAt)}`;
  }

  return (
    <span className="text-danger" style={{fontWeight: 'bold'}}>
      {productionUnitOnDayStatusLabel}
    </span>
  )
};

ProductionDayOnDayStatus.propTypes = {
  closedProductionUnitOnDay: PropTypes.object,
};

export default ProductionDayOnDayStatus;



