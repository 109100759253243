import {call, put, takeEvery} from 'redux-saga/effects'
import * as api from './api';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import {actionTypes as signInActionTypes} from '../signin';
import {arrayToMap} from '../lib/arrayUtils';
import * as auth from '../lib/auth';
import config from '../config/config.default';
import {SIGN_OUT_REASON_EMAIL_NOT_VERIFIED, SIGN_OUT_REASON_NO_BSS_RIGHTS, USER_ROLE_ADMIN} from './constants';

/**
 * Convert lists in data to maps
 */
const convertLoadInitialDataToMaps = (data) => {
  const users = arrayToMap(data.users, 'id');
  const rateCards = arrayToMap(data.rateCards, 'id');

  // Leave other data as is, replacing arrays with maps
  return {
    ...data,
    users,
    rateCards,
  }
};

export function* loadInitialData(action) {
  try {
    const initialData = yield call(api.getInitialData);
    const convertedData = convertLoadInitialDataToMaps(initialData);
    yield put(actions.loadInitialDataSuccess(convertedData));
  } catch (error) {
    if (error.response && error.response.status === 403) {
      if (error.response.data.errorCode === 'EMAIL_NOT_VERIFIED') {
        yield put(actions.signOutRequest({reason: SIGN_OUT_REASON_EMAIL_NOT_VERIFIED}));
      } else {
        // Probably AD tries to log in, log him out.
        yield put(actions.signOutRequest({reason: SIGN_OUT_REASON_NO_BSS_RIGHTS}));
      }
    } else {
      yield put(actions.loadInitialDataFailure(error));
    }
  }
}

export function* signOut(action) {
  try {
    // yield call(api.signOut); - TODO: turn this back after disney fix is merged
    yield call(auth.signOut);
    let redirectUrl = config.baseUrl;
    if (action.data && action.data.reason) {
      redirectUrl += `?signOutReason=${action.data.reason}`
    }
    location.href = redirectUrl;
  } catch (error) {
    yield put(actions.signOutFailure(error));
  }
}

export function* loadInitialDataAfterSignIn(action) {
  yield put(actions.loadInitialDataRequest());
  
}

export default function* sagas() {
  yield takeEvery(actionTypes.LOAD_INITIAL_DATA_REQUEST, loadInitialData);
  yield takeEvery(signInActionTypes.SIGN_IN_SUCCESS, loadInitialDataAfterSignIn);
  yield takeEvery(actionTypes.SIGN_OUT_REQUEST, signOut);
}