import {axiosDefault} from '../lib/network';

export const getUsers = () => {
  return axiosDefault.get(`/v2/users`).then(function (response) {
    return response.data;
  });
};

export const createUser = data => {
  return axiosDefault.post(`/v2/users/create`, data).then(function (response) {
    return response.data;
  });
};

export const updateUser = data => {
  return axiosDefault.post(`/v2/users/update`, data).then(function (response) {
    return response.data;
  });
};

export const activateUser = data => {
  return axiosDefault.post(`/v2/users/activate`, data).then(function (response) {
    return response.data;
  });
};

export const deactivateUser = data => {
  return axiosDefault.post(`/v2/users/deactivate`, data).then(function (response) {
    return response.data;
  });
};

export const unEnrollUserFromMfa = data => {
  return axiosDefault.post(`/v2/users/un-enroll-mfa`, data).then(function (response) {
    return response.data;
  });
};

export const sendVerificationEmail = data => {
  return axiosDefault.post(`/v2/users/send-verification-email`, data).then(function (response) {
    return response.data;
  });
};