import {call, put, takeEvery} from 'redux-saga/effects';
import * as loadIndicatorActions from '../common/load-indicator/actions';
import * as auth from '../lib/auth';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import {STEP_MFA} from './constants';

/**
 * Sign in with firebase, then firebase's onAuthChange function will dispatch appropriate actions (SIGN_IN_SUCCESS or
 * SIGN_OUT_SUCCESS if user was logged out)
 */
export function* signIn(action) {
  try {
    yield put(loadIndicatorActions.showLoadIndicator());
    yield call(auth.signIn, action.data);
  } catch (error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      yield put(actions.setStep(STEP_MFA, {mfaResolver: error.resolver}));
    } else {
      yield put(actions.signInFailure(error));
    }
  }
}

export function* sendMfaVerificationCode(action) {
  try {
    const {phoneInfoOptions, recaptchaVerifier} = action;
    const verificationId = yield call([auth, auth.verifyPhoneNumber], phoneInfoOptions, recaptchaVerifier)
    yield put(actions.sendMfaVerificationCodeSuccess(verificationId));
  } catch (error) {
    const message = error.message ? error.message : 'Unknown error';
    const errorCode = error.code;
    yield put(actions.sendMfaVerificationCodeFailure(message, errorCode));
  }
}

export function* confirmVerificationCode(action) {
  try {
    const {mfaResolver, verificationId, code} = action;
    yield call([auth, auth.resolveMfaSignIn], mfaResolver, verificationId, code)
    yield put(actions.confirmVerificationCodeSuccess());
  } catch (error) {
    let message = error.message ? error.message : 'Unknown error';
    const errorCode = error.code;
    if (errorCode === 'auth/invalid-verification-code') {
      message = 'Invalid verification code';
    }
    yield put(actions.confirmVerificationCodeFailure(message, errorCode));
  }
}

export default function* sagas() {
  yield takeEvery(actionTypes.SIGN_IN_REQUEST, signIn);
  yield takeEvery(actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST, sendMfaVerificationCode);
  yield takeEvery(actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST, confirmVerificationCode);
}