import * as actionTypes from './actionTypes';
import {
  STEP_CONFIRM_VERIFICATION_CODE,
  STEP_SEND_VERIFICATION_CODE,
  STEP_CONFIRM_VERIFICATION_CODE_SUCCESS
} from './constants';

const initialState = {
  sendingMfaVerificationCode: false,
  verificationCodeSent: false,
  confirmingVerificationCode: false,
  error: null,
  errorCode: null,
  verificationId: null,
  step: STEP_SEND_VERIFICATION_CODE
}

const reducer = (state = initialState, action) => {
  switch (action.type)  {
    case actionTypes.SET_STEP:
      return {
        ...state,
        step: action.step
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        sendingMfaVerificationCode: true,
        error: null,
        verificationCodeSent: false
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        sendingMfaVerificationCode: false,
        error: null,
        verificationId: action.verificationId,
        verificationCodeSent: true,
        step: STEP_CONFIRM_VERIFICATION_CODE
      }
    case actionTypes.SEND_MFA_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        sendingMfaVerificationCode: false,
        error: action.error,
        errorCode: action.errorCode,
        verificationCodeSent: false
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        confirmingVerificationCode: true,
        error: null,
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        confirmingVerificationCode: false,
        step: STEP_CONFIRM_VERIFICATION_CODE_SUCCESS,
        error: null,
      }
    case actionTypes.CONFIRM_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        confirmingVerificationCode: false,
        error: action.error,
        errorCode: action.errorCode
      }
    default:
      return state;
  }
};

export default reducer;