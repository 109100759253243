import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import App from './App';
import {LoadIndicatorContainer} from '../../common/load-indicator';
import {signOutRequest} from '../actions';
import {ProductionsContainer} from '../../productions';
import {ProductionContainer} from '../../production';
import {ProductionDayContainer} from '../../production-day';
import {UsersContainer} from '../../users';
import {ChitContainer} from '../../chit';
import PasswordResetContainer from '../../password-reset/components/PasswordResetContainer';
import OnboardingContainer from '../../onboarding/components/OnboardingContainer';
import {SignInContainer} from '../../signin';
import MfaEnrollmentContainer from '../../mfa-enrollment/components/MfaEnrollmentContainer';

class AppContainer extends React.Component {

  render() {
    const {isLoaded, authenticationChecked, authenticated, mfaEnrollmentCompleted} = this.props;

    if (!authenticationChecked) {
      return <LoadIndicatorContainer/>
    }

    if (!authenticated) {
      return (
        <Switch>
          <Route exact path="/" component={SignInContainer}/>
          <Route path="/reset-password" component={PasswordResetContainer}/>
          <Route exact path="/onboarding" component={OnboardingContainer}/>
          <Route path="*">
            <Redirect to="/"/>
          </Route>

        </Switch>)
    } else {
      if (!isLoaded) return (
        <LoadIndicatorContainer/>
      );

      if (!mfaEnrollmentCompleted) {
        return <MfaEnrollmentContainer />
      }

      return (
        <App {...this.props}>
          <Switch>
            <Route exact path="/" component={ProductionsContainer}/>
            <Route exact path="/productions/:id" component={ProductionContainer}/>
            <Route exact path="/productions/:id/:tab" component={ProductionContainer}/>
            <Route exact path="/productions/:id/date/:date" component={ProductionDayContainer}/>
            <Route exact path="/productions/:id/:tab/:entityId" component={ProductionContainer}/>
            <Route path="/users" component={UsersContainer}/>
            <Route path="/chits/:id" component={ChitContainer}/>
            <Route path="*">
              <div>Page not found</div>
            </Route>
          </Switch>
        </App>
      )
    }
  }
}

const mapStateToProps = state => {
  const appState = state.app;
  return {
    isLoaded: appState.isLoaded,
    isLoading: appState.isLoading,
    user: appState.user,
    signingIn: appState.signingIn,
    authenticated: appState.authenticated,
    authenticationChecked: appState.authenticationChecked,
    mfaEnrollmentRequired: appState.mfaEnrollmentRequired,
    mfaEnrollmentCompleted: appState.mfaEnrollmentCompleted,
  }
};

const actionCreators = {
  signOutRequest,
};

export default withRouter(connect(mapStateToProps, actionCreators)(AppContainer));

