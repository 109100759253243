import React from 'react';
import PropTypes from 'prop-types';
import * as productionConstants from '../../../production/constants';
import ChitRecordHealthSurveyActions from './ChitRecordHealthSurveyActions';
import {dateTimeToUveFormat} from '../../../lib/dateTimeUtils';
import {
  getExtraHealthSurveyStatusCssClassName,
  getExtraHealthSurveyStatusLabel,
  getExtraHealthSurveyTypeLabel
} from '../../../production/getters';
import {EXTRA_STATUS_CANCELLED} from "../../constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class ChitRecordHealthSurvey extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const {record, production} = this.props;
    const healthSurveyResult = record.chit.healthSurveyResult;

    const healthSurveyStatus = healthSurveyResult ? healthSurveyResult.status : productionConstants.HEALTH_SURVEY_STATUS_NOT_COMPLETED;


    const extraStatusCssClass = getExtraHealthSurveyStatusCssClassName(healthSurveyStatus);
    const healthSurveyType = healthSurveyResult && healthSurveyResult.surveyInfo ? healthSurveyResult.surveyInfo.type : (production.healthSurveySettings ? production.healthSurveySettings.type : null);
    const healthSurveySubType = healthSurveyResult && healthSurveyResult.surveyInfo ? healthSurveyResult.surveyInfo.data.type : (production.healthSurveySettings ? production.healthSurveySettings.data.type : null);

    let surveyTypeLabel = getExtraHealthSurveyTypeLabel(healthSurveyType, healthSurveySubType);
      const style = this.props.record.chit.extraStatus === EXTRA_STATUS_CANCELLED ? {backgroundColor: '#fff3cd'} : {};

    return (
      <React.Fragment>
        <td className="chit-column-health-survey-type">
          <span>{surveyTypeLabel}</span>
        </td>
        <td className="text-center chit-column-health-survey-status" style={style}>
           <span className={`badge badge ${extraStatusCssClass} w-50`}>
               {this.props.record.chit.extraStatus === EXTRA_STATUS_CANCELLED &&
               <OverlayTrigger
                   placement="top"
                   overlay={
                     <Tooltip className="document-info-tooltip">
                         <div><strong>Extra is Cancelled</strong></div>
                     </Tooltip>
                   }
               >
               <div>
                 {getExtraHealthSurveyStatusLabel(healthSurveyStatus)}
               </div>
               </OverlayTrigger>
               }
               {this.props.record.chit.extraStatus !== EXTRA_STATUS_CANCELLED &&
                   <div>
                       {getExtraHealthSurveyStatusLabel(healthSurveyStatus)}
                   </div>
               }
            </span>
        </td>
        <td className="chit-column-health-survey-recorded-at">
          <span>{healthSurveyResult ? dateTimeToUveFormat(healthSurveyResult.recordedAt) : ''}</span>
        </td>
        <td className="chit-column-health-survey-actions">
          {healthSurveyStatus !== productionConstants.HEALTH_SURVEY_STATUS_NOT_COMPLETED &&
            <ChitRecordHealthSurveyActions {...this.props} />
          }
        </td>
      </React.Fragment>
    )
  }
}

ChitRecordHealthSurvey.propTypes = {
  record: PropTypes.object.isRequired,
  production: PropTypes.object.isRequired
};

export default ChitRecordHealthSurvey;

