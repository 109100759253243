import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo-icon.svg';

const SideNav = (props) => {
  return (
    <div id="layout-sidenav" className="layout-sidenav sidenav sidenav-vertical bg-dark"
         style={{width: '4.375rem', paddingRight: '1rem'}}>
      <div className="app-brand demo">
        <span className="app-brand-logo demo">
            <img src={logo} alt=""/>
        </span>
        <a className="app-brand-text demo sidenav-text font-weight-normal ml-2"/>
        <a className="layout-sidenav-toggle sidenav-link text-large ml-auto">
          <i className="ion ion-md-menu align-middle"/>
        </a>
      </div>
      <div className="sidenav-divider mt-0"/>

      <ul className="sidenav-inner py-1">
        <li className="sidenav-item">
          <Link to='/' className="sidenav-link">
            <i className="sidenav-icon fas fa-film"/>
          </Link>
        </li>
        <li className="sidenav-item">
          <Link to='/users' className="sidenav-link">
            <i className="sidenav-icon fas fa-user-friends"/>
          </Link>
        </li>
        <li className="sidenav-item">
          <a href='/project-x/' className="sidenav-link">
            <i className="sidenav-icon fas fa-project-diagram"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideNav;