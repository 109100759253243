import React from 'react';
import CentredLayout from '../../app/components/CentredLayout';

class PasswordResetLayout extends React.Component {

  render() {
    return (
      <CentredLayout title="Password Reset">{this.props.children}</CentredLayout>
    )
  }
}

export default PasswordResetLayout;