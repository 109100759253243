import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

class GenerateProductionUnitCurrentReport extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{textAlign: 'right'}}>
        <Button variant="outline-success" size="sm"
                onClick={this.props.generateProductionUnitCurrentReportRequest.bind(
                  this, this.props.currentProductionDay.id, this.props.productionUnit.id
                )}>
          Email current report
        </Button>
      </div>
    );
  }
}

GenerateProductionUnitCurrentReport.propTypes = {
  currentProductionDay: PropTypes.object.isRequired,
  productionUnit: PropTypes.object.isRequired,
  generateProductionUnitCurrentReportRequest: PropTypes.func.isRequired,
};

export default GenerateProductionUnitCurrentReport;

