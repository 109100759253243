import * as constants from './constants';
import {arrayToMap} from '../lib/arrayUtils';

/**
 * Get ADs sorted by first name and last name.
 */
export const getAds = state => getUsersByRole(state, constants.USER_ROLE_AD);

/**
 * Get admins sorted by first name and last name.
 */
export const getAdmins = state => getUsersByRole(state, constants.USER_ROLE_ADMIN);

/**
 * Get users by role sorted by first name and last name.
 */
export const getUsersByRole = (state, role) => Object.values(state.app.users).filter(user => user.roles.includes(role)).sort((user1, user2) => {
  let compersion = 0;
  const fullName1 = `${user1.firstName} ${user1.lastName}`;
  const fullName2 = `${user2.firstName} ${user2.lastName}`;
  if (fullName1 > fullName2) {
    compersion = 1;
  } else if (fullName1 < fullName2) {
    compersion = -1;
  }

  return compersion;
});

export const getAdsGroupedById = state => arrayToMap(getAds(state), 'id');

export const getAdminsGroupedById = state => arrayToMap(getAdmins(state), 'id');

export const getRateCards = state => Object.values(state.app.rateCards);

export const getRateCard = (state, id) => state.app.rateCards[id]

