import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../constants';
import { dateTimeToUveFormat } from '../../../lib/dateTimeUtils';
import * as getters from '../../getters';
import ProductionDayRelease from './ProductionDayRelease';

const ProductionDayStatus = (props) => {
  const productionDayStatusToCssClasses = {
    [constants.PRODUCTION_DAY_STATUS_INACTIVE]: 'w-100',
    [constants.PRODUCTION_DAY_STATUS_ACTIVE]: 'text-success w-100',
    [constants.PRODUCTION_DAY_STATUS_WRAPPED_UP]: 'text-danger w-120',
    [constants.PRODUCTION_DAY_STATUS_LOCKED]: 'text-danger w-120',
  };

  const productionDayStatusCssClass = productionDayStatusToCssClasses[props.currentProductionDay.status];

  let productionDayStatusLabel = getters.getProductionDayStatusLabel(props.currentProductionDay.status);

  if (
    props.currentProductionDay.status === constants.PRODUCTION_DAY_STATUS_LOCKED ||
    props.currentProductionDay.status === constants.PRODUCTION_DAY_STATUS_WRAPPED_UP
  ) {
    productionDayStatusLabel += ` at ${dateTimeToUveFormat(props.currentProductionDay.statusChangedAt)}`;
  }

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="container-p-x py-2 production-day-status-container">
        <div className="text-muted small" title="click to change the status" onClick={handleClick}>
          Status
        </div>
        <span className={` ${productionDayStatusCssClass}`} style={{ fontWeight: 'bold' }}>
          {productionDayStatusLabel}
        </span>
      </div>
      <ProductionDayRelease {...props}/>
    </div>
  );
};

ProductionDayStatus.propTypes = {
  currentProductionDay: PropTypes.object.isRequired,
};

export default ProductionDayStatus;
