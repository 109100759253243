import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Modal} from 'react-bootstrap';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import Error from '../../../common/global-error/Error';

class CreateProductionUnit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      secondaryAdIds: {},
      paySchemeId: null
    }
  }

  createProductionUnit = (data) => {
    this.props.createProductionUnitRequest(
      this.props.production.id, data.name,
      data.description,
      Object.keys(this.state.secondaryAdIds), this.state.paySchemeId
    );
  }

  getProductionAdsOptions = () => {
    const nonSelectedAds = this.props.productionAds
      .filter(ad => !(ad.id in this.state.secondaryAdIds || ad.id === this.props.production.primaryAdId));
    return nonSelectedAds.map(ad => ({
      value: ad.id,
      label: `${ad.firstName} ${ad.lastName}`
    }))
  };

  handleOnSecondaryAdsSelectChange = option => {
    this.setState(prevState => ({
      secondaryAdIds: {...prevState.secondaryAdIds, [option.value]: true}
    }));
  };

  removeAd = adId => {
    this.setState(prevState => {
      let secondaryAdIds = {...prevState.secondaryAdIds};
      delete secondaryAdIds[adId];
      return {
        secondaryAdIds: secondaryAdIds
      };
    })
  }

  getPaySchemeOptions = () => (
    this.props.paySchemes.map(payScheme => ({
      value: payScheme.id,
      label: payScheme.title
    }))
  );

  handleOnPaySchemeSelectChange = option => {
    const paySchemeId = option ? option.value : null;
    this.setState({
      paySchemeId: paySchemeId
    });
  };

  render = () => {
    const productionAdsOptions = this.getProductionAdsOptions();
    const addedSecondaryADs = Object.keys(this.state.secondaryAdIds).map(adId => {
      const selectedAd = this.props.productionAds.find(ad => ad.id === adId)
      return (
        <Row key={selectedAd.id} className="selected-ads-row">
          <Col md={10}>{selectedAd.firstName} {selectedAd.lastName}</Col>
          <Col md={2} onClick={this.removeAd.bind(this, selectedAd.id)} style={{textAlign: 'right'}}>
            <Button size="sm" variant="danger">Remove</Button>
          </Col>
        </Row>
      )
    });

    const paySchemeOptions = this.getPaySchemeOptions();
    const currentPaySchemeOption = this.state.paySchemeId
      ? paySchemeOptions.find(option => option.value === this.state.paySchemeId)
      : null;

    const createProductionUnitSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    });

    return (
      <div>
        <div style={{textAlign: 'right'}}>
          <Button onClick={this.props.showHideCreateProductionUnitWindow.bind(this, true)} size="sm">Create Production Unit</Button>
        </div>

        <Modal show={this.props.productionUnitShowCreationWindow}
               onHide={this.props.showHideCreateProductionUnitWindow.bind(this, false)}
               size="lg">
          <Formik
            initialValues={{
              name: '',
              description: ''
            }}
            validationSchema={createProductionUnitSchema}
            onSubmit={this.createProductionUnit.bind(this)}
            render={({errors, status, touched, isSubmitting}) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Create Production Unit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="production-unit-name">Production unit name:</label>
                    <Field type="text" name="name" className="form-control" id="production-unit-name"/>
                    <ErrorMessage name="name" component="div"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="production-unit-description">Production unit description:</label>
                    <Field name="description" className="form-control" component="textarea"/>
                    <ErrorMessage name="description" component="div"/>
                  </div>

                  <div className="form-group">
                    <div>Secondary ADs:</div>
                    {Object.keys(this.state.secondaryAdIds).length > 0 &&
                    <div className="selected-ads">
                      <div className="selected-ads-content">
                        {addedSecondaryADs}
                      </div>
                    </div>
                    }
                    <Select
                      options={productionAdsOptions}
                      placeholder="Add Secondary AD..."
                      value={null}
                      onChange={this.handleOnSecondaryAdsSelectChange.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <div>Pay Scheme:</div>
                    <Select
                      options={paySchemeOptions}
                      placeholder="Pay Scheme..."
                      value={currentPaySchemeOption}
                      onChange={this.handleOnPaySchemeSelectChange.bind(this)}
                      isClearable={true}
                    />
                  </div>
                  {this.props.productionUnitCreationError &&
                    <Error error={this.props.productionUnitCreationError} />
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={this.props.productionUnitCreating} variant="secondary"
                          onClick={this.props.showHideCreateProductionUnitWindow.bind(this, false)}>
                    Cancel
                  </Button>
                  <Button disabled={this.props.productionUnitCreating} type="submit" variant="primary">
                    Create Production Unit
                  </Button>

                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
      </div>
    )
  }
}

CreateProductionUnit.propTypes = {
  productionAds: PropTypes.array.isRequired,
  production: PropTypes.object.isRequired,
  paySchemes: PropTypes.array.isRequired,
  createProductionUnitRequest: PropTypes.func.isRequired,
  showHideCreateProductionUnitWindow: PropTypes.func.isRequired,
  productionUnitCreationError: PropTypes.object,
  productionUnitCreating: PropTypes.bool.isRequired,
  productionUnitShowCreationWindow: PropTypes.bool.isRequired
};

export default CreateProductionUnit;

