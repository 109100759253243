import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

import * as constants from '../constants';

const ProductionDayExport = (props) => {
    const { setProductionDayDataExportRequest, currentProductionDay } = props;
    const exportItems = [
        {
            label: 'All Data',
            value: constants.PRODUCTION_EXPORT_ALL_DATA,
        },
        {
            label: 'Alphabetical',
            value: constants.PRODUCTION_EXPORT_ALPHABETICAL,
        },
        {
            label: 'Call time order',
            value: constants.PRODUCTION_EXPORT_CALL_TIME_ORDER,
        },
        {
            label: 'Measurements',
            value: constants.PRODUCTION_EXPORT_MEASUREMENTS,
        },
        {
            label: 'Office',
            value: constants.PRODUCTION_EXPORT_OFFICE,
        },
        {
          label:'Transport',
          value: constants.PRODUCTION_EXPORT_TRANSPORTGROUPS
        },
        {
            label: 'Role Order',
            value: constants.PRODUCTION_EXPORT_ROLE_ORDER,
        },
    ];
    const handleExport = (type,label) => () => {
        const productionId = parseInt(props.match.params.id);
        const date = props.match.params.date;
        const sending = {
            type,
            destination:'admin',
            productionId: productionId,
            dayId: currentProductionDay.id,
            label,
           
        };
        setProductionDayDataExportRequest(sending);
    };

    return (
        <DropdownButton
            variant="outline-secondary"
            title="Export"
            size="sm"
            style={{ minWidth: '5%', height: '0%', marginTop: '5px' }}>
            {exportItems.map(({ label, value }) => (
                <Dropdown.Item key={value} onClick={handleExport(value,label)}>{label}</Dropdown.Item>
            ))}
        </DropdownButton>
    );
};

ProductionDayExport.propTypes = {
    currentProductionDay: PropTypes.object.isRequired,
    setProductionDayDataExportRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

export default ProductionDayExport;
