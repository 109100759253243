import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as constants from '../constants';
import ProductionDaysContainer from './production-days/ProductionDaysContainer';
import './Production.css';
import ProductionUnitsContainer from './production-units/ProductionUnitsContainer';
import AdsContainer from './ads/AdsContainer';
import OtherSettingsContainer from './other-settings/OtherSettingsContainer';
import SurveysAndDocumentsContainer from './surveys-and-documents/SurveysAndDocumentsContainer';
import ProductionTransportationGroupContainer from './transportation-groups/ProductionTransportationGroupContainer';
import ProductionDayExport from "./ProductionDayExport"


class Production extends React.Component {
  constructor(props) {
    super(props);
  }

  

  render = () => {
    const production = this.props.production;
    const activeTab = this.props.activeTab;

    const tabProductionDaysActiveClass = activeTab === constants.TAB_PRODUCTION_DAYS ? 'active' : '';
    const tabProductionDaysAriaSelected = activeTab === constants.TAB_PRODUCTION_DAYS ? 'true' : 'false';

    const tabUnitsActiveClass = activeTab === constants.TAB_UNITS ? 'active' : '';
    const tabUnitsAriaSelected = activeTab === constants.TAB_UNITS ? 'true' : 'false';

    const transportgroupsActiveClass = activeTab === constants.TRANSPORT_GROUPS ? 'active' : '';
    const transportgroupsAriaSelected = activeTab === constants.TRANSPORT_GROUPS ? 'true' : 'false';

    const tabAdsActiveClass = activeTab === constants.TAB_ADS ? 'active' : '';
    const tabAdsAriaSelected = activeTab === constants.TAB_ADS ? 'true' : 'false';

    const tabSurveysAndDocumentsActiveClass = activeTab === constants.TAB_SURVEYS_AND_DOCUMENTS ? 'active' : '';
    const tabSurveysAndDocumentsAriaSelected = activeTab === constants.TAB_SURVEYS_AND_DOCUMENTS ? 'true' : 'false';

    const tabOtherSettingsActiveClass = activeTab === constants.TAB_OTHER_SETTINGS ? 'active' : '';
    const tabOtherSettingsAriaSelected = activeTab === constants.TAB_OTHER_SETTINGS ? 'true' : 'false';

    const daysCount = this.props.productionDays.length;
    const daysWord = daysCount === 1 ? 'day' : 'days';

    const chitsTotal = this.props.chitsTotal;
    const chitsTotalWord = chitsTotal === 1 ? 'chit' : 'chits';

    return (
      <div>
        <div className="d-flex justify-content-between">
          <h4 className="py-2 mb-2">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Productions</Link>
              </li>
              <li className="breadcrumb-item active">
                <a>{production.name}</a>
              </li>
            </ol>
          </h4>
        </div>

        <nav className="nav nav-tabs" role="tablist">
          <Link
            to={`/productions/${production.id}`}
            role="tab"
            aria-selected={tabProductionDaysAriaSelected}
            className={`nav-item nav-link ${tabProductionDaysActiveClass}`}
          >
            Production Days ({daysCount} {daysWord}, {chitsTotal} {chitsTotalWord})
          </Link>
          <Link
            to={`/productions/${production.id}/units`}
            role="tab"
            tabIndex="-1"
            aria-selected={tabUnitsAriaSelected}
            className={`nav-item nav-link ${tabUnitsActiveClass}`}
          >
            Units ({this.props.unitsTotal})
          </Link>
          <Link
            to={`/productions/${production.id}/transport-groups`}
            role="tab"
            tabIndex="-1"
            aria-selected={transportgroupsAriaSelected}
            className={`nav-item nav-link ${transportgroupsActiveClass}`}
          >
            Transportation Groups
          </Link>
          <Link
            to={`/productions/${production.id}/ads`}
            role="tab"
            tabIndex="-1"
            aria-selected={tabAdsAriaSelected}
            className={`nav-item nav-link ${tabAdsActiveClass}`}
          >
            ADs ({this.props.productionAdsTotal})
          </Link>
          <Link
            to={`/productions/${production.id}/surveys-and-documents`}
            role="tab"
            tabIndex="-1"
            aria-selected={tabSurveysAndDocumentsAriaSelected}
            className={`nav-item nav-link ${tabSurveysAndDocumentsActiveClass}`}
          >
            Surveys and Documents
          </Link>
          <Link
            to={`/productions/${production.id}/other-settings`}
            role="tab"
            tabIndex="-1"
            aria-selected={tabOtherSettingsAriaSelected}
            className={`nav-item nav-link ${tabOtherSettingsActiveClass}`}
          >
            Other Settings
          </Link>
        </nav>
        <div className="tab-content">
          {activeTab === constants.TRANSPORT_GROUPS && <ProductionTransportationGroupContainer />}
          {activeTab === constants.TAB_PRODUCTION_DAYS && (
            <ProductionDaysContainer
              currentProductionDayDate={this.props.activeTab === constants.TAB_PRODUCTION_DAYS ? this.props.entityId : null}
            />
          )}

          {activeTab === constants.TAB_UNITS && (
            <ProductionUnitsContainer
              currentProductionUnitId={this.props.activeTab === constants.TAB_UNITS ? this.props.entityId : null}
            />
          )}
          {activeTab === constants.TAB_ADS && (
            <AdsContainer currentProductionAdId={this.props.activeTab === constants.TAB_ADS ? this.props.entityId : null} />
          )}
          {activeTab === constants.TAB_SURVEYS_AND_DOCUMENTS && <SurveysAndDocumentsContainer />}
          {activeTab === constants.TAB_OTHER_SETTINGS && <OtherSettingsContainer />}
        </div>
      </div>
    );
  };
}

Production.propTypes = {
  production: PropTypes.object.isRequired,
  productionDays: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  chitsTotal: PropTypes.number.isRequired,
  unitsTotal: PropTypes.number.isRequired,
  productionAdsTotal: PropTypes.number.isRequired,
  entityId: PropTypes.string,
};

export default Production;
