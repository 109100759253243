import axios from 'axios';
import store from '../store';
import Config from '../config/config.default';
import { actions as loadIndicatorActions } from '../common/load-indicator/index';
import { actions as globalErrorActions } from '../common/global-error/index';
import { getAccessToken } from './auth';
import * as notification from '../lib/notification';



// Default axios instance with api url and spinner
const axiosDefaultConfig = {
  baseURL: Config.apiUrl
};

// location service axiosInstance with api url and spinner;

const axiosDefaultLocationConfig = {
  baseURL: Config.locationApi
}

export const axiosDefault = axios.create(axiosDefaultConfig);

export const locationAxioxDefault = axios.create(axiosDefaultLocationConfig)

export const constructResponseError = error => {
  const errorData = {
    message: 'Sorry, something went wrong, please try again...'
  };
  if (error.response && error.response.data && error.response.data.message) {
    errorData.message = error.response.data.message;
    if (error.response.data.errorId) {
      errorData.errorId = error.response.data.errorId;
    }
  } else if (error.message) {
    // If no response, fallback to error.message
    errorData.message = error.message
  }

  return errorData;
}

locationAxioxDefault.interceptors.request.use(
  conf => {
    store.dispatch(loadIndicatorActions.showLoadIndicator());
    return getAccessToken()
      .then(token => {
        const confWithAdditionalHeaders = { ...conf };
        if (token) {
          confWithAdditionalHeaders.headers = {
            "Authorization": `Bearer ${token}`
          };
        }
        return Promise.resolve(confWithAdditionalHeaders);
      })
      .catch(error => {
        console.log("[Network] Get token error");
        console.log(error);
        notification.error(error);
      });
  },
  error => {
    console.log("[Network] Failed to start request");
    console.log(error);
    notification.error('Failed to start request');
    store.dispatch(loadIndicatorActions.hideLoadIndicator());
    store.dispatch(globalErrorActions.showGlobalError('Failed to start request'));
    return Promise.reject(error);
  }
);

axiosDefault.interceptors.request.use(
  conf => {
    store.dispatch(loadIndicatorActions.showLoadIndicator());
    return getAccessToken()
      .then(token => {
        const confWithAdditionalHeaders = { ...conf };
        if (token) {
          confWithAdditionalHeaders.headers = {
            "Authorization": `Bearer ${token}`
          };
        }
        return Promise.resolve(confWithAdditionalHeaders);
      })
      .catch(error => {
        console.log("[Network] Get token error");
        console.log(error);
        notification.error(error);
      });
  },
  error => {
    console.log("[Network] Failed to start request");
    console.log(error);
    notification.error('Failed to start request');
    store.dispatch(loadIndicatorActions.hideLoadIndicator());
    store.dispatch(globalErrorActions.showGlobalError('Failed to start request'));
    return Promise.reject(error);
  }
);

locationAxioxDefault.interceptors.response.use(
  response => {
    store.dispatch(loadIndicatorActions.hideLoadIndicator());
    return response;
  },
  error => {
    store.dispatch(loadIndicatorActions.hideLoadIndicator());
    // happening here
    
    // Prepare and show error message
    const errorData = error.response?.data?.error?.message[0] ?? constructResponseError(error).message;
    notification.error(errorData);
    store.dispatch(globalErrorActions.showGlobalError(errorData));

    return Promise.reject(error);
  }
);

axiosDefault.interceptors.response.use(
  response => {
    store.dispatch(loadIndicatorActions.hideLoadIndicator());
    return response;
  },
  error => {
    store.dispatch(loadIndicatorActions.hideLoadIndicator());

    // Prepare and show error message
    const errorData = constructResponseError(error);

    notification.error(errorData.message);

    store.dispatch(globalErrorActions.showGlobalError(errorData));

    return Promise.reject(error);
  }
);


