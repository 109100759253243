import React from 'react';
import OnboardingLayout from './OnboardingLayout';
import * as PropTypes from 'prop-types';
import config from '../../config/config.default'
import {USER_ROLE_AD, USER_ROLE_ADMIN} from '../../app/constants';

class OnboardingSetPasswordAndVerifyEmailSuccess extends React.Component {

  render() {
    const { user } = this.props;
    if (!user) {
      return  null;
    }

    const isAdminOnly = user.roles.length === 1 && user.roles.includes(USER_ROLE_ADMIN);
    const isAdOnly = user.roles.length === 1 && user.roles.includes(USER_ROLE_AD);
    const isAdminAndAd = user.roles.includes(USER_ROLE_ADMIN) && user.roles.includes(USER_ROLE_AD);

    return (
      <OnboardingLayout>
        <div className="alert alert-success" role="alert">
          Password has been set successfully.
          {isAdminOnly &&
            <span style={{marginLeft: '2px'}}>You can navigate to <a href={config.baseUrl}>Login</a> page</span>
          }
          {isAdOnly &&
          <span style={{marginLeft: '2px'}}>You can navigate to <a href={config.adAppUrl}>Login</a> page</span>
          }
          {isAdminAndAd &&
            <span style={{marginLeft: '2px'}}>You can navigate to <a href={config.baseUrl}>BSS</a> or <a href={config.adAppUrl}>AD App</a> login page</span>
          }
        </div>
      </OnboardingLayout>
    )
  }
}

OnboardingSetPasswordAndVerifyEmailSuccess.propTypes = {
  sourceApp: PropTypes.string,
  user: PropTypes.object
}


export default OnboardingSetPasswordAndVerifyEmailSuccess;