import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import DynamicForm from '../../../common/DynamicForms/DynamicForm';
import { generateLocationFormWith } from './LocationFormConfig';
import { Button } from 'react-bootstrap';
import { NEW_LOCATION_VALUE } from '../transportation-groups/transpotationConstants';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import { LoadScript } from '@react-google-maps/api';
const API_KEY = process.env.GOOGLE_JAVA_SCRIPT_API_KEY;

const LocationPicker = (props) => {
    const {
        location,
        setCreateLocation,
        setEditLocationRequest,
        setShowHideLocation: onHide,
        showLocationCreateUpdateWindow: show,
        isLocationCreatingOrUpdating: showLoader,
        locationEditId,
        setDeleteLocationRequest,
    } = props;
    const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);

    // const initialState ={ name:'', countryCode:'', city:'', numberOfBuilding:'', county:'', postCode:'', coordinates:'', entrance:'', additionalInfo:'', street:'' }
    const onSubmit = (data) => {
        const {
            name,
            countryCode,
            city,
            numberOfBuilding,
            county,
            postCode,
            coordinates,
            entrance = ' ',
            additionalInfo,
            street,
        } = data;

        if (locationEditId !== NEW_LOCATION_VALUE && locationEditId) {
            const id = locationEditId;
            setEditLocationRequest(
                id,
                name,
                countryCode,
                city,
                numberOfBuilding,
                county,
                postCode,
                coordinates,
                entrance,
                additionalInfo,
                street,
            );
        } else {
            setCreateLocation(
                name,
                countryCode,
                city,
                numberOfBuilding,
                county,
                postCode,
                coordinates,
                entrance,
                additionalInfo,
                street,
            );
        }
    };
    const showConfirmModal = (flag) => (e) => {
        setConfirmationDialog(flag);
    };
    const deleteCurrentLocation = (e) => {
        setDeleteLocationRequest(locationEditId);
    };
    const getCurrentState = () => {
        if (locationEditId == NEW_LOCATION_VALUE || !locationEditId) {
            return {};
        }
        return location[locationEditId];
    };

    const handleConfirm = (e) => {
        deleteCurrentLocation();
        setConfirmationDialog(false);
    };

    return (
        <LoadScript googleMapsApiKey={API_KEY} libraries={['places', 'visualization']}>
            <ConfirmationDialog
                show={showConfirmationDialog}
                onCancel={showConfirmModal(false)}
                title={'Are you sure  you want to delete this location?'}
                message={'This action cannot be undone.'}
                onConfirm={handleConfirm}
                cancelLabel="Cancel"
                confirmLabel="Delete"
                size="xs"
            />
            <DynamicForm
                {...generateLocationFormWith(getCurrentState())}
                // initialState={initialState}
                isDisabled={showLoader}
                onCancel={onHide}
                onHide={onHide}
                show={show}
                showLoader={showLoader}
                onSubmit={onSubmit}>
                {locationEditId && locationEditId !== NEW_LOCATION_VALUE && (
                    <Button className="d-btn" variant="danger" onClick={showConfirmModal(true)}>
                        {' '}
                        Delete
                    </Button>
                )}
            </DynamicForm>
        </LoadScript>
    );
};

LocationPicker.propTypes = {
    locationEditId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    showLocationCreateUpdateWindow: PropTypes.bool.isRequired,
    isLocationCreatingOrUpdating: PropTypes.bool.isRequired,
    setCreateLocation: PropTypes.func.isRequired,
    setShowHideLocation: PropTypes.func.isRequired,
    setEditLocationRequest: PropTypes.func.isRequired,
    setDeleteLocationRequest: PropTypes.func.isRequired,
};

export default LocationPicker;
