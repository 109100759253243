import React from 'react';

class AdsTableHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Username</th>
        <th>Email</th>
        <th>Assigned to Units</th>
        <th>Actions</th>
      </tr>
    )
  }
}

AdsTableHeader.propTypes = {
};

export default AdsTableHeader;

