import React from 'react';
import ChitBreadcrumbs from './ChitBreadcrumbs';
import ChitSection1 from './ChitSection1';
import ChitSection2 from './ChitSection2';
import ChitSection3 from './ChitSection3';
import './Chit.css';

class Chit extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <React.Fragment>
        <ChitBreadcrumbs {...this.props} />
        <ChitSection1 {...this.props} />
        <ChitSection2 {...this.props} />
        <ChitSection3 {...this.props} />
      </React.Fragment>
    )
  }
}

export default Chit;