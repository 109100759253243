import React from 'react';
import PropTypes from 'prop-types';

class ProductionUnitsTableHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Secondary ADs</th>
        <th>Chits</th>
        <th>Days in Unit</th>
        <th>Pay Scheme</th>
        <th>Actions</th>
      </tr>
    )
  }
}

ProductionUnitsTableHeader.propTypes = {
};

export default ProductionUnitsTableHeader;

