import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import {AppContainer} from './app';
import './Global.css';
import config from './config/config.default';

const Root = ({store}) => (
  <Provider store={store}>
    <Router basename={config.baseUrl}>
      <AppContainer />
    </Router>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;