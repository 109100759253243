import React from 'react';

const ChitsHeaderDocuments = props => {
  return (
    <React.Fragment>
      <td className="chit-column-documents">Documents</td>
    </React.Fragment>
  )
};

ChitsHeaderDocuments.propTypes = {};

export default ChitsHeaderDocuments;
