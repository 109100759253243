import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal} from 'react-bootstrap';
import {EXTRA_STATUS_CHECKED_OUT} from '../../../constants';
import TimePicker from 'rc-time-picker';
import { momentToDateTime, parseFromDateTime } from '../../../../lib/dateTimeUtils';
import Loader from 'react-loader-spinner';

class RemoveSupplementaryFees extends React.Component {

  constructor(props) {
    super(props);
  };

  handleRemoveSupplementaryFees = () => {
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.removeSupplementaryFeesRequest(chitIds, this.props.productionId, this.props.productionDate);
  };

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsRemoveSupplementaryFeesWindow.bind(this, false)} keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Remove supplementary fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='warning'>
            Are you sure you want to remove all supplementary fees for selected chits?
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          {this.props.bulkActionsRunning &&
            <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          <Button variant="default" onClick={this.props.showHideBulkActionsRemoveSupplementaryFeesWindow.bind(this, false)} disabled={this.props.bulkActionsRunning}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleRemoveSupplementaryFees} disabled={this.props.bulkActionsRunning}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

RemoveSupplementaryFees.propTypes = {
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsRemoveSupplementaryFeesWindow: PropTypes.func.isRequired,
  removeSupplementaryFeesRequest: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired
};

export default RemoveSupplementaryFees;