import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class ChitRecordHealthSurveyActionsReset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResetHealthSurveyResultConfirmationDialog: false
    };
  }

  resetHealthSurveyResult = () => {
    this.props.resetHealthSurveyResultRequest(this.props.record.chit.id);
    this.showHideResetHealthSurveyResultConfirmationDialog(false);
  }

  showHideResetHealthSurveyResultConfirmationDialog = show => {
    this.setState({
      showResetHealthSurveyResultConfirmationDialog: show
    })
  }

  render = () => {
    const {record} = this.props;
    const {extra} = record;
    return (
      <div>
        <button className="btn btn-xs btn-outline-danger" onClick={this.showHideResetHealthSurveyResultConfirmationDialog.bind(this, true)}>Reset survey</button>
        <ConfirmationDialog
          show={this.state.showResetHealthSurveyResultConfirmationDialog}
          title="Result Health Survey Result"
          onCancel={this.showHideResetHealthSurveyResultConfirmationDialog.bind(this, false)}
          message={
            <span>
              Are you sure you want to reset health survey result of extra <strong>{extra.id} {extra.firstName} {extra.lastName}</strong>?
            </span>
          }
          onConfirm={this.resetHealthSurveyResult.bind(this)}
        />
      </div>
    )
  }
}

ChitRecordHealthSurveyActionsReset.propTypes = {
  record: PropTypes.object.isRequired,
  resetHealthSurveyResultRequest: PropTypes.func.isRequired
};

export default ChitRecordHealthSurveyActionsReset;

