import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {dateToUveFormat} from '../../../lib/dateTimeUtils';
import {Button} from 'react-bootstrap';

class ProductionUnitRowDays extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return this.props.productionDays.map(productionDay => (
      <div key={productionDay.date} className="in-row-row">
        <Link to={`/productions/${this.props.production.id}/days/${productionDay.date}`} className="date-label">
          {dateToUveFormat(productionDay.date)}
        </Link>
        <Link to={`/productions/${this.props.production.id}/date/${productionDay.date}`} className="in-row-row-actions">
          <Button size="xs" variant="outline-primary">View chits</Button>
        </Link>
      </div>
    ))
  }
}

ProductionUnitRowDays.propTypes = {
  productionDays: PropTypes.array.isRequired,
};

export default ProductionUnitRowDays;
