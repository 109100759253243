import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from './api';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { arrayToMap } from '../lib/arrayUtils';
import * as notification from '../lib/notification';
import { setDefaultPaySchemeRequest } from './actions';
import { constructResponseError } from '../lib/network';
import * as productionApi from "./api";

/**
 * Convert lists in data to maps.
 */
const convertLoadProductionAggregatedDataToMaps = (data) => {
  const productionDays = arrayToMap(data.productionDays, 'id');
  const productionUnits = arrayToMap(data.productionUnits, 'id');

  // Leave other data as is, replacing arrays with maps
  return {
    ...data,
    productionDays,
    productionUnits,
  };
};

export function* loadProduction(action) {
  try {
    const productionData = yield call(api.getProductionAggregatedData, action.productionId);
    const convertedData = convertLoadProductionAggregatedDataToMaps(productionData);
    yield put(actions.loadProductionSuccess(convertedData));
  } catch (error) {
    yield put(actions.loadProductionFailure(error));
  }
}

export function* assignPrimaryAd(action) {
  try {
    const production = yield call(api.assignPrimaryAd, action.productionId, action.adId);
    // Re-load production's data since assigning Primary AD affects not only production itself, but potentially
    // production units (Given AD will be removed from Secondary ADs list of units if he were)
    yield put(actions.loadProductionRequest(action.productionId))
    yield put(actions.assignPrimaryAdSuccess(production));
    notification.success('Primary AD has been successfully changed.');
  } catch (error) {
    yield put(actions.assignPrimaryAdFailure(error));
  }
}

export function* assignAdmin(action) {
  try {
    const production = yield call(api.assignAdmin, action.productionId, action.adminId);
    // Re-load production's data
    yield put(actions.loadProductionRequest(action.productionId))
    yield put(actions.assignAdminSuccess(production));
    notification.success('Admin has been successfully changed.');
  } catch (error) {
    yield put(actions.assignAdminFailure(error));
  }
}

export function* setDefaultPayScheme(action) {
  try {
    const production = yield call(api.setDefaultPayScheme, action.productionId, action.paySchemeId);
    yield put(actions.setDefaultPaySchemeSuccess(production));
    notification.success('Default Pay Scheme has been successfully changed.');
  } catch (error) {
    yield put(actions.setDefaultPaySchemeFailure(error));
  }
}

export function* importProductionDays(action) {
  try {
    yield call(api.importProductionDays, action.productionId, action.startDate, action.endDate);
    yield put(actions.importProductionDaysSuccess());
    const successMessage = action.successMessage || 'Production days has been successfully synced';
    notification.success(successMessage);
  } catch (error) {
    yield put(actions.importProductionDaysFailure(error));
  }
}

export function* createProductionUnit(action) {
  try {
    const productionUnt = yield call(api.createProductionUnit, action.productionId, action.name, action.description, action.secondaryAdIds, action.paySchemeId);
    yield put(actions.createProductionUnitSuccess(productionUnt));
    const successMessage = action.successMessage || 'Production unit has been successfully created';
    notification.success(successMessage);
  } catch (error) {
    yield put(actions.createProductionUnitFailure(constructResponseError(error)));
  }
}

export function* updateProductionUnitBasicInfo(action) {
  try {
    const productionUnt = yield call(api.updateProductionUnitBasicInfo, action.productionUnitId, action.name, action.description);
    yield put(actions.updateProductionUnitBaseInfoSuccess(productionUnt));
    const successMessage = action.successMessage || 'Production unit has been successfully updated';
    notification.success(successMessage);
  } catch (error) {
    yield put(actions.updateProductionUnitBasicInfoFailure(constructResponseError(error)));
  }
}

export function* deleteProductionUnit(action) {
  try {
    const deletedProductionUnt = yield call(api.deleteProductionUnit, action.productionUnitId);
    yield put(actions.deleteProductionUnitSuccess(deletedProductionUnt));
    const successMessage = action.successMessage || `Production unit "${deletedProductionUnt.name}" has been successfully deleted`;
    notification.success(successMessage);
  } catch (error) {
    yield put(actions.deleteProductionUnitFailure(constructResponseError(error)));
  }
}


export function* setProductionUnitPayScheme(action) {
  try {
    const productionUnt = yield call(api.setProductionUnitPayScheme, action.productionUnitId, action.paySchemeId);
    const successMessage = action.successMessage || 'Pay scheme was successfully set';
    notification.success(successMessage);
    yield put(actions.setProductionUnitPaySchemeSuccess(productionUnt));
  } catch (error) {
    yield put(actions.setProductionUnitPaySchemeFailure(error));
  }
}

export function* assignSecondaryAdsToProductionUnit(action) {
  try {
    const productionUnit = yield call(api.assignSecondaryAdsToProductionUnit, action.productionUnitId, action.secondaryAdIds);
    const successMessage = action.successMessage || 'Secondary ADs have been successfully assigned';
    notification.success(successMessage);
    yield put(actions.assignSecondaryAdsToProductionUnitSuccess(productionUnit));
  } catch (error) {
    yield put(actions.assignSecondaryAdsToProductionUnitFailure(error));
  }
}

export function* unassignSecondaryAdFromProductionUnit(action) {
  try {
    const productionUnit = yield call(api.unassignSecondaryAdFromProductionUnit, action.productionUnitId, action.secondaryAdId);
    const successMessage = action.successMessage || 'Secondary AD has been successfully unassigned';
    notification.success(successMessage);
    yield put(actions.unassignSecondaryAdFromProductionUnitSuccess(productionUnit));
  } catch (error) {
    yield put(actions.unassignSecondaryAdFromProductionUnitFailure(error));
  }
}

export function* unassignSecondaryAdFromAllProductionUnits(action) {
  try {
    const productionUnitIds = yield call(api.unassignSecondaryAdFromAllProductionUnits, action.productionId, action.secondaryAdId);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.productionId));

    if (productionUnitIds.length === 0) {
      notification.info('Secondary AD has not been assigned to any production unit');
    } else {
      const unitsWord = productionUnitIds.length === 1 ? 'unit' : 'units';
      notification.success(`Secondary AD has been successfully unassigned from ${productionUnitIds.length} production ${unitsWord}`);
    }

    yield put(actions.unassignSecondaryAdFromAllProductionUnitsSuccess(productionUnitIds));
  } catch (error) {
    yield put(actions.unassignSecondaryAdFromAllProductionUnitsFailure(error));
  }
}

export function* addAdsToProduction(action) {
  try {
    const production = yield call(api.addAdsToProduction, action.productionId, action.adIds);
    const successMessage = action.successMessage || 'ADs have been successfully added';
    notification.success(successMessage);
    yield put(actions.addAdsToProductionSuccess(production));
  } catch (error) {
    yield put(actions.addAdsToProductionFailure(error));
  }
}

export function* removeAdFromProduction(action) {
  try {
    const production = yield call(api.removeAdFromProduction, action.productionId, action.adId);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.productionId));
    const successMessage = action.successMessage || 'ADs have been successfully removed from the production.';
    notification.success(successMessage);
    yield put(actions.removeAdFromProductionSuccess(production));
  } catch (error) {
    yield put(actions.removeAdFromProductionFailure(error));
  }
}

export function* getActionTokenQrCode(action) {
  try {
    const qrCode = yield call(api.getActionTokenQrCode, action.productionDayId, action.action);
    yield put(actions.getActionTokenQrCodeSuccess(qrCode, action))
  } catch (error) {
    yield put(actions.getActionTokenQrCodeFailure(error));
  }
}

export function* showProductionDayQrCodeWindow(action) {
  if (action.show) {
    yield put(actions.getActionTokenQrCodeRequest(action.productionDay.id, action.action))
  }
}

export function* setHealthSurveySettings(action) {
  try {
    const production = yield call(api.setHealthSurveySettings, action.productionId, action.settings);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.productionId));
    const successMessage = action.successMessage || 'Health survey settings have been successfully saved.';
    notification.success(successMessage);
    yield put(actions.setHealthSurveySettingsSuccess(production));
  } catch (error) {
    yield put(actions.setHealthSurveySettingsFailure(error));
  }
}

export function* createProductionDocument(action) {
  try {
    const document = yield call(api.createProductionDocument, action.data);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.data.productionId));
    const successMessage = 'Production document has been successfully created.';
    notification.success(successMessage);
    yield put(actions.createProductionDocumentSuccess(document));
  } catch (error) {
    yield put(actions.createProductionDocumentFailure(constructResponseError(error)));
  }
}

export function* updateProductionDocument(action) {
  try {
    const document = yield call(api.updateProductionDocument, action.data);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.data.productionId));
    const successMessage = 'Production document has been successfully updated.';
    notification.success(successMessage);
    yield put(actions.updateProductionDocumentSuccess(document));
  } catch (error) {
    yield put(actions.updateProductionDocumentFailure(constructResponseError(error)));
  }
}

export function* deleteProductionDocument(action) {
  try {
    const document = yield call(api.deleteProductionDocument, action.id);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.productionId));
    const successMessage = 'Production document has been successfully deleted.';
    notification.success(successMessage);
    yield put(actions.deleteProductionDocumentSuccess(document));
  } catch (error) {
    yield put(actions.deleteProductionDocumentFailure(error));
  }
}

export function* setQrCodesRequirement(action) {
  try {
    const production = yield call(api.setQrCodesRequirement, action.productionId, action.required);
    // Reload production with all aggregated data
    yield put(actions.loadProductionRequest(action.productionId));
    const successMessage = 'Qr Codes settings have been successfully saved.';
    notification.success(successMessage);
    yield put(actions.setQrCodesRequirementSuccess(production));
  } catch (error) {
    yield put(actions.setQrCodesRequirementFailure(error));
  }
}

export function* downloadDocuments(action) {
  const { productionId, productionName, date } = action;
  try {
    const result = yield call(productionApi.downloadDocuments, productionId, productionName, date);
    yield put(actions.downloadDocumentsSuccess());
    notification.success('Documents have been downloaded');
  } catch (error) {
    yield put(actions.downloadDocumentsFailure(error));
  }
}

export function* downloadDocumentsReport(action) {
  const { productionId, productionName, date } = action;
  try {
    const result = yield call(productionApi.downloadDocumentsReport, productionId, productionName, date);
    yield put(actions.downloadDocumentsSuccess());
    notification.success('Documents report have been downloaded');
  } catch (error) {
    yield put(actions.downloadDocumentsReportFailure(error));
  }
}




export function* loadLocationData(action) {
  try {
    const { result } = yield call(productionApi.loadLocationData);
    yield put(actions.loadLocationDataSucess(arrayToMap(result, 'id')))

  } catch (error) {

    yield put(actions.loadLocationDataFailure(error));

  }
}

export function* setCreateLocation(action) {
  try {
    const { name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street } = action;
    const { result } = yield call(productionApi.setLocationCreate, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street);


    yield put(actions.setCreateLocationSuccess(result))
    notification.success('Location added successfully..');

  } catch (error) {
    yield put(actions.setCreateLocationFailure(error));
  }
}

export function* setEditLocation(action) {
  try {
    const { id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street } = action;
    const { result } = yield call(productionApi.setEditLocation, id, name, countryCode, city, numberOfBuilding, county, postCode, coordinates, entrance, additionalInfo, street);
    yield put(actions.setEditLocationSuccess(result));
    notification.success('Location updated successfully..');
  } catch (error) {
      yield put(actions.setEditLocationFailure(error));
     

  }
}

export function*setDeleteLocation(action){
  try{
    const {id} = action;
    const {result} = yield call(productionApi.setDeleteLocation,id);
    yield put(actions.setDeleteLocationSuccess(result.id));
    notification.success('Location deleted successfully..');
  }catch(error){
    console.log(error);
    yield put(actions.setDeleteLocationFailure(error));
  }
}


export function* setCreateTranspotationGroup(action) {

  const { productionId, locationId, name } = action;
  try {
    const result = yield call(productionApi.setCreateTranspotationGroup, productionId, locationId, name);
    yield put(actions.setCreateTarnspotationGroupsSuccess(result));
    notification.success('Transportation Group added successfully..');

  } catch (error) {
    yield put(actions.setCreateTarnspotationGroupsFailure(error));

  }

}

export function* setTransportationGroupDelete(action) {
  try {
    const { productionId,
      transportGroupId } = action;

    const result = yield call(productionApi.setTransportationGroupDelete, parseInt(productionId), transportGroupId)
    yield put(actions.setTranspotationGroupDeleteSuccess(result))
    notification.success('Transportation Group Deleted successfully..');
  } catch (error) {
    yield put(actions.setTranspotationGroupDeleteFailure(error))
  }
}

export function* setUpdateTransportationGroup(action) {
  try {
    const { transportGroupId, productionId, locationId, name } = action;
    const result = yield call(productionApi.setUpdateTransportationGroup, transportGroupId, parseInt(productionId), locationId, name)
    yield put(actions.setUpdateTranspotationGroupsSuccess(result))
    notification.success('Transportation Group Updated successfully..');
  } catch (error) {
    yield put(actions.setUpdateTarnspotationGroupsFailure(error))
  }
}


export default function* sagas() {
  yield takeEvery(actionTypes.LOAD_PRODUCTION_REQUEST, loadProduction);
  yield takeEvery(actionTypes.ASSIGN_PRIMARY_AD_REQUEST, assignPrimaryAd);
  yield takeEvery(actionTypes.ASSIGN_ADMIN_REQUEST, assignAdmin);
  yield takeEvery(actionTypes.SET_DEFAULT_PAY_SCHEME_REQUEST, setDefaultPayScheme);
  yield takeEvery(actionTypes.CREATE_PRODUCTION_UNIT_REQUEST, createProductionUnit);
  yield takeEvery(actionTypes.UPDATE_PRODUCTION_UNIT_BASIC_INFO_REQUEST, updateProductionUnitBasicInfo);
  yield takeEvery(actionTypes.DELETE_PRODUCTION_UNIT_REQUEST, deleteProductionUnit);
  yield takeEvery(actionTypes.SET_PRODUCTION_UNIT_PAY_SCHEME_REQUEST, setProductionUnitPayScheme);
  yield takeEvery(actionTypes.ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_REQUEST, assignSecondaryAdsToProductionUnit);
  yield takeEvery(actionTypes.UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_REQUEST, unassignSecondaryAdFromProductionUnit);
  yield takeEvery(actionTypes.UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_REQUEST, unassignSecondaryAdFromAllProductionUnits);
  yield takeEvery(actionTypes.ADD_ADS_TO_PRODUCTION_REQUEST, addAdsToProduction);
  yield takeEvery(actionTypes.REMOVE_AD_FROM_PRODUCTION_REQUEST, removeAdFromProduction);
  yield takeEvery(actionTypes.IMPORT_PRODUCTION_DAYS_REQUEST, importProductionDays);
  yield takeEvery(actionTypes.GET_ACTION_TOKEN_QR_CODE_REQUEST, getActionTokenQrCode);
  yield takeEvery(actionTypes.SHOW_HIDE_PRODUCTION_DAY_QR_CODE_WINDOW, showProductionDayQrCodeWindow);
  yield takeEvery(actionTypes.SET_HEALTH_SURVEY_SETTINGS_REQUEST, setHealthSurveySettings);
  yield takeEvery(actionTypes.CREATE_PRODUCTION_DOCUMENT_REQUEST, createProductionDocument);
  yield takeEvery(actionTypes.UPDATE_PRODUCTION_DOCUMENT_REQUEST, updateProductionDocument);
  yield takeEvery(actionTypes.DELETE_PRODUCTION_DOCUMENT_REQUEST, deleteProductionDocument);
  yield takeEvery(actionTypes.SET_QR_CODES_REQUIREMENT_REQUEST, setQrCodesRequirement);
  yield takeEvery(actionTypes.DOWNLOAD_DOCUMENTS_REQUEST, downloadDocuments);
  yield takeEvery(actionTypes.DOWNLOAD_DOCUMENTS_REPORT_REQUEST, downloadDocumentsReport);
  yield takeEvery(actionTypes.LOAD_LOCATION_DATA_REQUEST, loadLocationData);
  yield takeEvery(actionTypes.SET_LOCATION_CREATEING, setCreateLocation);
  yield takeEvery(actionTypes.SET_LOCATION_EDIT_REQUEST,setEditLocation);
  yield takeEvery(actionTypes.SET_LOCATION_DELETE_REQUEST,setDeleteLocation);
  yield takeEvery(actionTypes.SET_TRANSPOTATION_GROUP_CREATEING, setCreateTranspotationGroup)
  yield takeEvery(actionTypes.SET_TRANSPOTATION_GROUP_UPDATE_REQUEST, setUpdateTransportationGroup)
  yield takeEvery(actionTypes.SET_TRANSPOTATION_GROUP_DELETE_REQUEST, setTransportationGroupDelete)

}