import React from 'react';
import PropTypes from 'prop-types';
import BulkActions from './bulk-actions/BulkActions';
import * as constants from '../../constants';
import ChitsHeaderBaseInfo from './ChitsHeaderBaseInfo';
import ChitsHeaderCampaigns from './ChitsHeaderCampaigns';
import ChitsHeaderCallDetails from './ChitsHeaderCallDetails';
import ChitsHeaderHealthSurvey from './ChitsHeaderHealthSurvey';
import ChitsHeaderDocuments from './ChitsHeaderDocuments';

class ChitsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bulkChitsSelectionChecked: false,
        };
    }

    handleBulkSelection = (event) => {
        this.setState({
            bulkChitsSelectionChecked: event.target.checked,
        });

        if (event.target.checked) {
            this.props.setChitsSelection(
                Object.keys(this.props.filteredChitRecordsByChitId),
                event.target.checked,
            );
        } else {
            this.props.deselectAllChits();
        }
    };

    render = () => {
        return (
            <div className="card">
                <div className="card-body chits-table-card chits-table-header-card">
                    <div className="table-responsive">
                        <div style={{ marginTop: '10px', marginLeft: '12px' }}>
                            <BulkActions {...this.props} />
                        </div>
                        <table
                            className="table card-table chits-table chits-table-header table-striped"
                            style={{ marginTop: '10px' }}>
                            <tbody>
                                <tr>
                                    <td className="chit-column-checkbox">
                                        <input
                                            type="checkbox"
                                            onChange={this.handleBulkSelection}
                                            checked={this.state.bulkChitsSelectionChecked}
                                        />
                                    </td>
                                    {this.props.chitsActiveTab ===
                                    constants.CHITS_TAB_CALL_DETAILS ? (
                                        <td className="chit-column-extra-mobile">Mobile</td>
                                    ) : (
                                        <td className="chit-column-extra-id">Extra Id</td>
                                    )}

                                    <td className="chit-column-first-name">First Name</td>
                                    <td className="chit-column-last-name">Last Name</td>
                                    {this.props.chitsActiveTab ===
                                        constants.CHITS_TAB_BASE_INFO && <ChitsHeaderBaseInfo />}
                                    {this.props.chitsActiveTab ===
                                        constants.CHITS_TAB_CALL_DETAILS && (
                                        <ChitsHeaderCampaigns />
                                    )}
                                    {this.props.chitsActiveTab ===
                                        constants.CHITS_TAB_BOOKING_DATE_DETAILS && (
                                        <ChitsHeaderCallDetails />
                                    )}
                                    {this.props.chitsActiveTab ===
                                        constants.CHITS_TAB_HEALTH_SURVEY && (
                                        <ChitsHeaderHealthSurvey />
                                    )}
                                    {this.props.chitsActiveTab ===
                                        constants.CHITS_TAB_DOCUMENTS && <ChitsHeaderDocuments />}
                                    <td className="text-center chit-column-chit">Chit</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };
}

ChitsHeader.propTypes = {
    setChitsSelection: PropTypes.func.isRequired,
    deselectAllChits: PropTypes.func.isRequired,
    filteredChitRecordsByChitId: PropTypes.object.isRequired,
    chitsActiveTab: PropTypes.string.isRequired,
};

export default ChitsHeader;
