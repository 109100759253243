import React from 'react';
import PropTypes from 'prop-types';
import AdsTableHeader from './AdsTableHeader';
import AdRow from './AdRow';
import AddAds from './AddAds';
import './Ads.css';
import ProductionUnitRow from '../production-units/ProductionUnitRow';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrimaryAd from './PrimaryAd';

class Ads extends React.Component {
  // TODO:: add days of ads!!!

  constructor(props) {
    super(props);
    this.refsByAd = {};
  }

  scrollToAd = () => {
    this.refsByAd = this.props.productionAds.reduce((refs, ad) => {
      refs[ad.id] = React.createRef();
      return refs;
    }, {});

    if (this.props.highlightedProductionAdId) {
      const ref = this.refsByAd[this.props.highlightedProductionAdId];
      if (ref) {
        window.scrollTo(0, ref.current.offsetTop)
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // There might be case when we need to scroll to AD, but Ads
    // are not yet loaded when component mounted, so we scroll to AD only
    // when we receive them.
    if (prevProps.productionAds.length === 0 && this.props.productionAds.length > 1) {
      this.scrollToAd();
    }
  }


  render = () => {
    const adRows = this.props.productionAds.map(ad => (
      <AdRow
        key={ad.id}
        ad={ad}
        highlighted={ad.id === this.props.highlightedProductionAdId}
        refProp={this.refsByAd[ad.id]}
        {...this.props}
      />
    ));

    return (
      <div>
        <Row>
          <Col md={7}>
            <PrimaryAd {...this.props}
                       ads={this.props.productionAds}
                       primaryAdId={this.props.production.primaryAdId}
                       productionId={this.props.production.id}/>
          </Col>
          <Col md={5}>
            <AddAds {...this.props} />
          </Col>
        </Row>
        {this.props.productionAds.length > 0 &&
        <table className="table card-table table-striped ads-table">
          <thead>
          <AdsTableHeader/>
          </thead>
          <tbody>
          {adRows}
          </tbody>
        </table>
        }
        {!this.props.productionAds.length &&
        <div className="no-entries">
          No ADs
        </div>
        }
      </div>
    )
  }
}

Ads.propTypes = {
  productionAds: PropTypes.array.isRequired,
  highlightedProductionAdId: PropTypes.string,
  production: PropTypes.object.isRequired
};

export default Ads;
