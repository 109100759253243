import * as actionTypes from "./actionTypes";

/**
 * Initial users state.
 * Please not that users are stored in app.users state.
 * (Since users are used in several places in the app, we keep users in single plays, to avoid duplication
 * of users state).
 */
const initialState = {
  isLoading: false,
  isLoaded: true,
  userCreationError: null,
  userCreating: false,
  userShowCreationWindow: false,
  userUpdateError: null,
  userUpdating: false,
  userShowEditWindow: false,
  userBeingUpdated: null,
  unEnrollingUserFromMfa: false,
  sendingVerificationEmail: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_HIDE_CREATE_USER_WINDOW:
      return {
        ...state,
        userShowCreationWindow: action.show,
        userCreationError: null
      }
    case actionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        userCreating: true,
        userCreationError: null
      }
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        userCreating: false,
        userShowCreationWindow: false
      }
    case actionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        userCreating: false,
        userCreationError: action.error
      }
    case actionTypes.SHOW_HIDE_EDIT_USER_WINDOW:
      return {
        ...state,
        userShowEditWindow: action.show,
        userUpdateError: null,
        userBeingUpdated: action.user
      }
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        userUpdating: true,
        userUpdateError: null
      }
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        userUpdating: false,
        userShowEditWindow: false
      }
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        userUpdating: false,
        userUpdateError: action.error
      }
    case actionTypes.UN_ENROLL_USER_FROM_MFA_REQUEST:
      return {
        ...state,
        unEnrollingUserFromMfa: true
      }
    case actionTypes.UN_ENROLL_USER_FROM_MFA_SUCCESS:
      return {
        ...state,
        unEnrollingUserFromMfa: false,
        userBeingUpdated: action.user
      }
    case actionTypes.UN_ENROLL_USER_FROM_MFA_FAILURE:
      return {
        ...state,
        unEnrollingUserFromMfa: false
      }
    case actionTypes.SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendingVerificationEmail: true
      }
    case actionTypes.SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendingVerificationEmail: false,
      }
    case actionTypes.SEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        sendingVerificationEmail: false
      }
    default:
      return state;
  }
}

export default reducer;