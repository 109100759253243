import React from "react";

class UsersTableHeader extends React.Component {
  render = () => {
    return (
      <tr>
        <th >First Name</th>
        <th >Last Name</th>
        <th >Email</th>
        <th >Phone</th>
        <th >Status</th>
        <th >Roles</th>
        <th >MFA Info</th>
        <th >&nbsp;</th>
      </tr>
    )
  }
}

export default UsersTableHeader;