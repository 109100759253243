export const SEND_MFA_VERIFICATION_CODE_REQUEST = 'SEND_MFA_VERIFICATION_CODE_REQUEST';
export const SEND_MFA_VERIFICATION_CODE_SUCCESS = 'SEND_MFA_VERIFICATION_CODE_SUCCESS';
export const SEND_MFA_VERIFICATION_CODE_FAILURE = 'SEND_MFA_VERIFICATION_CODE_FAILURE';

export const CONFIRM_VERIFICATION_CODE_REQUEST = 'CONFIRM_VERIFICATION_CODE_REQUEST';
export const CONFIRM_VERIFICATION_CODE_SUCCESS = 'CONFIRM_VERIFICATION_CODE_SUCCESS';
export const CONFIRM_VERIFICATION_CODE_FAILURE = 'CONFIRM_VERIFICATION_CODE_FAILURE';

export const SET_STEP = 'MFA_ENROLLMENT_SET_STEP';

export const SKIP_MFA_ENROLLMENT = 'SKIP_MFA_ENROLLMENT';