export const SIGN_IN_REQUEST  = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS  = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE  = 'SIGN_IN_FAILURE';

export const SEND_MFA_VERIFICATION_CODE_REQUEST = 'SIGN_IN_SEND_MFA_VERIFICATION_CODE_REQUEST';
export const SEND_MFA_VERIFICATION_CODE_SUCCESS = 'SIGN_IN_SEND_MFA_VERIFICATION_CODE_SUCCESS';
export const SEND_MFA_VERIFICATION_CODE_FAILURE = 'SIGN_IN_SEND_MFA_VERIFICATION_CODE_FAILURE';

export const CONFIRM_VERIFICATION_CODE_REQUEST = 'SIGN_IN_CONFIRM_VERIFICATION_CODE_REQUEST';
export const CONFIRM_VERIFICATION_CODE_SUCCESS = 'SIGN_IN_CONFIRM_VERIFICATION_CODE_SUCCESS';
export const CONFIRM_VERIFICATION_CODE_FAILURE = 'SIGN_IN_CONFIRM_VERIFICATION_CODE_FAILURE';

export const SET_STEP = 'SIGN_IN_SET_STEP';
