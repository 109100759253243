import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

class ProductionDayRowActionsSync extends React.Component {

  render = () => {
    const { syncDisabled, syncProductionDay, day } = this.props;
    if (syncDisabled) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <div className="tooltip-content">
                Production's Pay Scheme and Primary AD must be set to enable sync
              </div>
            </Tooltip>
          }
        >
          <div className="d-inline-block">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={syncProductionDay.bind(this, day.date)}
              disabled
              // Enable tooltip when button is disabled
              style={{pointerEvents: 'none'}}>
              Sync
            </Button>
          </div>
        </OverlayTrigger>
      );
    }

    return (
      <Button
        size="sm"
        variant="outline-primary"
        onClick={syncProductionDay.bind(this, day.date)}>
        Sync
      </Button>
    );
  };
}

ProductionDayRowActionsSync.propTypes = {
  syncDisabled: PropTypes.bool.isRequired,
  syncProductionDay: PropTypes.func.isRequired,
  day: PropTypes.object.isRequired
};

export default ProductionDayRowActionsSync;
