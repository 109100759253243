export const PRODUCTION_DAY_STATUS_ACTIVE = 'ACTIVE';
export const PRODUCTION_DAY_STATUS_INACTIVE = 'INACTIVE';
export const PRODUCTION_DAY_STATUS_WRAPPED_UP = 'WRAPPED_UP';
export const PRODUCTION_DAY_STATUS_LOCKED = 'LOCKED';

export const PRODUCTION_UNIT_ON_DAY_STATUS_WRAPPED_UP = 'WRAPPED_UP';
export const PRODUCTION_UNIT_ON_DAY_STATUS_LOCKED = 'LOCKED';

// TODO:: move to app or chit
export const EXTRA_STATUS_NO_SHOW = 'NO_SHOW';
export const EXTRA_STATUS_BOOKED = 'BOOKED';
export const EXTRA_STATUS_CHECKED_IN = 'CHECKED_IN';
export const EXTRA_STATUS_CHECKED_OUT = 'CHECKED_OUT';
export const EXTRA_STATUS_CANCELLED = 'CANCELLED';
export const EXTRA_STATUS_RELEASED = 'RELEASED';

// Activity type is labeled as Day type on front, but in all other places it is activity type,
// so keep it.
export const ACTIVITY_TYPE_FILMING = 'Filming';
export const ACTIVITY_TYPE_OTHER = 'Other';

export const BULK_ACTION_TYPE_CALL_TIME = 'CALL_TIME';
export const BULK_ACTION_TYPE_PRODUCTION_UNIT = 'PRODUCTION_UNIT';
export const BULK_ACTION_TYPE_SUPPLEMENTARY_FEE = 'SUPPLEMENTARY_FEE';
export const BULK_ACTION_TYPE_DAILY_RATE = 'DAILY_RATE';
export const BULK_ACTION_TYPE_BOOKING_DATE_DETAILS = 'BOOKING_DATE_DETAILS';
export const BULK_ACTION_TYPE_CHITS_CANCELLATION = 'CHITS_CANCELLATION';
export const BULK_ACTION_TYPE_SET_EXTRAS_STATUSES = 'SET_EXTRAS_STATUSES';
export const BULK_ACTION_TYPE_REMOVE_SUPPLEMENTARY_FEES = 'REMOVE_SUPPLEMENTARY_FEES';
export const BULK_ACTION_TYPE_SEND_BREAKDOWN_EMAILS = 'SEND_BREAKDOWN_EMAILS';
export const BULK_ACTION_TYPE_SEND_CHIT_NOTES = 'SEND_CHIT_NOTES';
export const BULK_ACTION_TYPE_SEND_CHIT_LEVEL_TRAVEL_NOTES = 'BULK_ACTION_TYPE_SEND_CHIT_LEVEL_TRAVEL_NOTES';
export const BULK_ACTION_TYPE_SEND_SMS = 'BOOKING_DATE_DETAILS_SMS'


export const BULK_ACTION_RESULT_SUCCESS = 'success';
export const BULK_ACTION_RESULT_FAILURE = 'failure';

export const RATE_TYPE_SUPPLEMENTARY_FEE = 'Supplementary Fee';
// Daily Rate = Primary Rate, in most of places Daily Rate name is used, so stick with it (comes form legacy)
export const RATE_TYPE_DAILY_RATE = 'Primary Fee';

export const CUSTOM_SUPPLEMENTARY_FEE_NAME = 'Custom Fee';

export const CHITS_TAB_BASE_INFO = 'BASE_INFO';
export const CHITS_TAB_CALL_DETAILS = 'CALL_DETAILS';
export const CHITS_TAB_BOOKING_DATE_DETAILS = 'BOOKING_DATE_DETAILS';
export const CHITS_TAB_HEALTH_SURVEY = 'HEALTH_SURVEY';
export const CHITS_TAB_DOCUMENTS = 'DOCUMENTS';

export const CANCELLATION_FEE_TYPE_FULL = 'FULL';
export const CANCELLATION_FEE_TYPE_PARTIAL = 'PARTIAL';
export const CANCELLATION_FEE_TYPE_ZERO = 'ZERO';


export const TRAVE_TYPE_DRIVE = 'drive';
export const TRAVE_TYPE_LIFT = 'lift';
export const TRAVE_TYPE_PUBLIC = 'public';
//used to display call details status in FE
export const CALL_DETAILS_STATUS_NO = 'NO';
export const CALL_DETAILS_STATUS_YES = 'YES';
export const CALL_DETAILS_STATUS_REVIEW = 'REVIEW';
export const CALL_DETAILS_STATUS_NOT_SENT = 'NOT SENT';



// actual status of call detials when a chit had responded response.result = 'POSITIVE'||'NEGATIVE'
export const CALL_DETAILS_RESPONSE_ACTUAL_STATUS_NEGATIVE = 'NEGATIVE';
export const CALL_DETAILS_RESPONSE_ACTUAL_STATUS_POSITIVE = 'POSITIVE';


// SMS TYPES 
export const SMS_TEMPLATE_TYPE_STANDARD_CALL_DETAILS = 'STANDARD';
export const SMS_TEMPLATE_TYPE_PICKUP_CALL_DETAILS = 'PICKUP';
