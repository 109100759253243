import {groupBy} from '../lib/arrayUtils';
import * as constants from './constants';

export const getProductionUnits = state => Object.values(state.production.productionUnits);

export const getProductionDays = state => Object.values(state.production.productionDays);

export const getPrimaryAd = state => state.app.users[state.production.production.primaryAdId];

export const getDefaultPayScheme = state => state.app.rateCards[state.production.production.defaultPaySchemeId];



/**
 * Group chits totals on dates and units by units in date.
 * Example:
 *  Input:
 *    [
 *      {date: "2020-04-05", unitId: ""5c76c96f1f0000c98c0e31a1", chitsTotal: 5},
 *      {date: "2020-04-05", unitId: ""5e90ba5fede855aaf74b465d", chitsTotal: 4},
 *      {date: "2020-04-06", unitId: ""5c76c96f1f0000c98c0e31a1", chitsTotal: 3},
 *   ]
 *
 *  Output:
 *    {
 *      "2020-04-05": {
 *        chitsTotal: 9,
 *        units: [
 *          {date: "2020-04-05", "unitId: ""5c76c96f1f0000c98c0e31a1", chitsTotal: 5, unit: {... corresponding unit object}},
 *          {date: "2020-04-05", "unitId: ""5e90ba5fede855aaf74b465d", chitsTotal: 4, unit: {... corresponding unit object}},
 *        ]
 *      },
 *      "2020-04-06": {
 *        chitsTotal: 4,
 *        units: [
 *          {date: "2020-04-06", unitId: ""5c76c96f1f0000c98c0e31a1", chitsTotal: 3, unit: {... corresponding unit object}}},
 *        ]
 *      }
 *    }
 *
 */
export const getChitsTotalsByUnitsInDate = state => {
  const chitsTotalOnDatesAndUnits = state.production.data.chitsTotalOnDatesAndUnits
    .map(item => {
      const unit = state.production.productionUnits[item.unitId];
      return {...item, unit}
    });
  const unitsGroupedByDate = groupBy(chitsTotalOnDatesAndUnits, 'date');

  const unitsGroupedByDateWithDateChitsTotal = {};
  for (const date in unitsGroupedByDate) {
    unitsGroupedByDateWithDateChitsTotal[date] = {
      chitsTotal: unitsGroupedByDate[date].map(item => item.chitsTotal).reduce((a, b) => a + b, 0),
      units: unitsGroupedByDate[date]
    };
  }

  return unitsGroupedByDateWithDateChitsTotal;
};

/**
 * Get chits totals by units
 */
export const getChitsTotalsByUnits = state => {

  const chitsDataGroupedByUnits = groupBy(state.production.data.chitsTotalOnDatesAndUnits, 'unitId')
  const chitsTotalByUnits = {};
  for (const unitId in chitsDataGroupedByUnits) {
    chitsTotalByUnits[unitId] = chitsDataGroupedByUnits[unitId].map(item => item.chitsTotal).reduce((a, b) => a + b, 0);
  }
  return chitsTotalByUnits;
}

/**
 * Get production days by units.
 */
export const getProductionDaysByUnits = state => {
  const chitsDataGroupedByUnits = groupBy(state.production.data.chitsTotalOnDatesAndUnits, 'unitId')
  const productionDaysByUnits = {};
  for (const unitId in chitsDataGroupedByUnits) {
    productionDaysByUnits[unitId] = chitsDataGroupedByUnits[unitId].map(item => {
      return {
        date: item.date
      }
    });
  }

  // Sort production days in each unit descending
  return Object.fromEntries(
    Object.entries(productionDaysByUnits).map(([unitId, productionDays]) => [unitId, productionDays.sort((day1, day2) => {
        let comparison = 0;
        if (day1.date > day2.date) {
          comparison = -1;
        } else if (day1.date < day2.date) {
          comparison = 1;
        }
        return comparison;
      })]
    )
  );
}

/**
 * Get total chits in production.
 */
export const getChitsTotal = state => {


  const chitsTotalOnDatesAndUnits = state.production.data.chitsTotalOnDatesAndUnits;
  return chitsTotalOnDatesAndUnits ? chitsTotalOnDatesAndUnits.map(item => item.chitsTotal).reduce((a, b) => a + b, 0) : 0;


};

/**
 * Get total units in production.
 */
export const getUnitsTotal = state => Object.keys(state.production.productionUnits).length;

/**
 * Get total ADs of production.
 */
export const getProductionAdsTotal = state => state.production.production.adIds ? state.production.production.adIds.length : 0;

/**
 * Get ADs of production.
 */
export const getProductionAds = state => {
  if (!state.production.production.adIds) {
    return [];
  }
  const adIds = state.production.production.adIds;
  return adIds.map(adId => state.app.users[adId])
};

/**
 * Get production units by secondary ADs.
 * @return only those units which have secondary ADs assigned.
 */
export const getProductionUnitsBySecondaryAds = state => {
  const productionUnits = Object.values(state.production.productionUnits);
  const productionUnitsBySecondaryAds = {};
  productionUnits.forEach(productionUnit => {
    productionUnit.secondaryAdIds.forEach(secondaryAdId => {
      const productionUnitsBySecondaryAd = productionUnitsBySecondaryAds[secondaryAdId] || [];
      productionUnitsBySecondaryAd.push(productionUnit);
      productionUnitsBySecondaryAds[secondaryAdId] = productionUnitsBySecondaryAd;
    });
  });

  return productionUnitsBySecondaryAds;
};

/**
 * Sync should be disabled if production's default pay scheme or primary AD are not set
 */
export const isSyncDisabled = (production) =>
  !production.defaultPaySchemeId || !production.primaryAdId;

/**
 * Returns word unit" if production unit doesn't contain the word "unit", otherwise empty string.
 * Used to make sentences more readable.
 * For example, if production unit name is "Unit A", we want to construct following sentence:
 * "Thanks for confirming that Unit A for production Batman Begins..." (e.g. without prefixing "Unit A" with word "unit")
 * But for production unit with name "London", it would make more sense to construct following sentence:
 * "Thanks for confirming that unit London for production Batman Begins..." (e.g., prefix "London" with word "unit")
 *
 */
export const unitWord = productionUnitName =>
  !productionUnitName.toLowerCase().includes("unit") ? ' unit ' : ' ';

export const getExtraHealthSurveyStatusLabel = status => {
  const mapping = {
    [constants.HEALTH_SURVEY_STATUS_NOT_COMPLETED]: 'Not completed',
    [constants.HEALTH_SURVEY_STATUS_PASSED]: 'Passed',
    [constants.HEALTH_SURVEY_STATUS_FAILED]: 'Failed',
    [constants.HEALTH_SURVEY_STATUS_NOT_REQUIRED]: 'Not required'
  }

  return mapping[status];
};

export const getExtraHealthSurveyTypeLabel = (type, subType) => {
  let label = null;
  if (type === constants.HEALTH_SURVEY_TYPE_UVE_SURVEY) {
    label = 'Default UVE Survey';
  } else if (type === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY && subType.type === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_EXTERNAL_URL) {
    label = 'Production external url'
  } else if (type === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY && subType === constants.PRODUCTION_EXTERNAL_SURVEY_TYPE_DOCUMENT) {
    label = `Production's document`;
  }

  return label;
}

const surveyStatusToCssClasses = {
  [constants.HEALTH_SURVEY_STATUS_NOT_COMPLETED]: 'badge-outline-secondary',
  [constants.HEALTH_SURVEY_STATUS_FAILED]: 'badge-outline-danger',
  [constants.HEALTH_SURVEY_STATUS_PASSED]: 'badge-outline-success'
};

export const getExtraHealthSurveyStatusCssClassName = status => surveyStatusToCssClasses[status];

export const productionDocumentTypes = {
  [constants.PRODUCTION_DOCUMENT_TYPE_TERMS_AND_CONDITIONS]: 'Terms and Conditions',
  [constants.PRODUCTION_DOCUMENT_TYPE_HEALTH_AND_SAFETY_NOTICE]: 'Health and Safety Notice',
  [constants.PRODUCTION_DOCUMENT_TYPE_UVE_ON_SET_GUIDELINES]: 'UVE On Set Guideline',
  [constants.PRODUCTION_DOCUMENT_TYPE_OTHER]: 'Other'
};

export const getProductionDocumentTypeLabel = type => productionDocumentTypes[type];

export const productionDocumentRequiredActions = {
    [constants.PRODUCTION_DOCUMENT_REQUIRED_ACTION_NO_ACTION]: 'No Action',
    [constants.PRODUCTION_DOCUMENT_REQUIRED_ACTION_ACCEPTANCE]: 'Acceptance',
    [constants.PRODUCTION_DOCUMENT_REQUIRED_ACTION_SIGNATURE]: 'Signature'
};

export const getProductionRequiredActionLabel = action => productionDocumentRequiredActions[action]

export const productionDocumentActionFrequencies = {
  [constants.PRODUCTION_DOCUMENT_ACTION_FREQUENCY_DAILY]: 'Daily',
  [constants.PRODUCTION_DOCUMENT_ACTION_FREQUENCY_ONE_TIME]: 'One Time',
};

export const getProductionActionFrequencyLabel = action => productionDocumentActionFrequencies[action]


