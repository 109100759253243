import * as actionTypes from './actionTypes';

const initialState = {
  message: 'Loading...',
  isActive: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOAD_INDICATOR:
      return {
        ...state,
        message: action.message,
        isActive: true
      };

    case actionTypes.HIDE_LOAD_INDICATOR:
      return {
        ...state,
        isActive: false
      };
    default:
      return state;
  }
};

export default reducer;
