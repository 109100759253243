import React from 'react'
import PropTypes from 'prop-types';
import CentredLayout from '../../app/components/CentredLayout';
import {Button} from 'react-bootstrap';
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {STEP_SEND_VERIFICATION_CODE} from '../constants';

class MfaEnrollmentConfirmCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  confirmVerificationCode = (data) => {
    const { code } = data;
    this.props.confirmVerificationCodeRequest(this.props.firebaseUser, this.props.verificationId, code);
  }

  render() {
    const schema = Yup.object().shape({
      code: Yup.string().required('Required')
    });

    let initialValues = {
      code: '',
    };

    const {confirmingVerificationCode, error, errorCode, signOutRequest} = this.props;

    return (
      <CentredLayout title="Set Up MFA">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={this.confirmVerificationCode.bind(this)}
          render={({errors, status, touched, isSubmitting, values, isValid, dirty}) => (
            <Form>
              <div className="form-group">
                <label htmlFor="code">Verification code:</label>
                <Field type="text" name="code" className="form-control" id="code" placeholder="Enter verification code"/>
                <ErrorMessage name="code" />
              </div>
              {error &&
              <div className="alert alert-danger" style={{marginTop: '10px'}} role="alert">
                {error}
              </div>
              }
              <div style={{marginTop: '15px'}}>
                <div style={{float: 'left'}}>
                  <Button variant="primary" disabled={confirmingVerificationCode} type="submit">
                    {confirmingVerificationCode &&
                    <span>Confirming Code...</span>
                    }
                    {!confirmingVerificationCode &&
                    <span>Confirm Code</span>
                    }
                  </Button>
                </div>
                <div style={{float: 'right'}}>
                  <Button variant="secondary" onClick={() => this.props.setStep(STEP_SEND_VERIFICATION_CODE)}>
                    Back
                  </Button>
                </div>
                <div style={{clear: 'both'}}/>
              </div>
            </Form>
            )}
        >
        </Formik>
      </CentredLayout>
    );
  }
}

MfaEnrollmentConfirmCode.propTypes = {
  firebaseUser: PropTypes.object.isRequired,
  confirmVerificationCodeRequest: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorCode: PropTypes.string,
  confirmingVerificationCode: PropTypes.bool.isRequired,
  verificationId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired
}

export default MfaEnrollmentConfirmCode;