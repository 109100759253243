import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap"




const ProductionDayLocation = (props) => {
    const {productionDayLocation} = props;
  return <React.Fragment>
      {/* <Button size={'sm'} variant='outline-secondary'>{!productionDayLocation ? 'Location : Not Set' : 'Location : ABC'}</Button> */}
  </React.Fragment>;
};

ProductionDayLocation.propTypes = {
    productionDayLocation : PropTypes.object.isRequired,
};

export default ProductionDayLocation;
