import React from 'react';
import PropTypes from 'prop-types';
import { getUserRoleLabel, getUserStatusCssClass, getUserStatusLabel } from '../getters';
import UserRecordActionsEdit from "./UserRecordActionsEdit";
import UserRecordActionsToggleEnableStatus from "./UserRecordActionsToggleEnableStatus";
import UserRecordMfaInfo from './UserRecordMfaInfo';

class UserRecord extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const { user } = this.props;


    const statusLabelClass = getUserStatusCssClass(user.status);

    return (
      <tr>
        <td>
          <a >
            {user.firstName}
          </a>
        </td>
        <td>
          <a >
            {user.lastName}
          </a>
        </td>
        <td>
          <a >
            {user.email}
          </a>
        </td>
        <td>
          <a >
            {user.phoneNumber}
          </a>
        </td>
        <td >
          <span className={statusLabelClass} style={{padding: '10px'}}>
            {getUserStatusLabel(user.status)}
          </span>
        </td>
        <td className="roles">
          <a >
            {user.roles.map(r => <div key={r}>{getUserRoleLabel(r)}</div>)}
          </a>
        </td>
        <td>
          <a >
            <UserRecordMfaInfo {...this.props} />
          </a>
        </td>
        <td>
          <div style={{ display: 'flex' }}>
            <UserRecordActionsToggleEnableStatus {...this.props} />
            <div style={{ marginLeft: '5px', marginRight: '10px' }}>
              <UserRecordActionsEdit {...this.props} />
            </div>
          </div>
        </td>
      </tr>
    );
  };
}

UserRecord.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserRecord;