import React from 'react';
import PropTypes from 'prop-types';
import OnboardingLayout from './OnboardingLayout';
import PasswordSetter from '../../common/password-setter/PasswordSetter';

class OnboardingSetPasswordAndVerifyEmail extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {settingPasswordAndVerifyingEmail, setPasswordAndVerifyEmailRequest, oobCode, error} = this.props;

    return (
      <OnboardingLayout>
        <PasswordSetter setPasswordRequest={setPasswordAndVerifyEmailRequest}
                        settingPassword={settingPasswordAndVerifyingEmail}
                        passwordResetCode={oobCode}
                        setPasswordError={error}/>
      </OnboardingLayout>
    )
  }
}

OnboardingSetPasswordAndVerifyEmail.propTypes = {
  setPasswordAndVerifyEmailRequest: PropTypes.func.isRequired,
  settingPasswordAndVerifyingEmail: PropTypes.bool.isRequired,
  error: PropTypes.string,
  oobCode: PropTypes.string.isRequired
}

export default OnboardingSetPasswordAndVerifyEmail;