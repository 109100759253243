// export const TIME_ZONE = 'Europe/London';
// export const TIME_ZONE = 'UTC';
export const TIME_ZONE = 'GB';

// TODO:: move to `users` folder
export const USER_ROLE_AD = 'AD';
export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_SU = 'SU';

export const SIGN_OUT_REASON_NO_BSS_RIGHTS = 'noBssRights';
export const SIGN_OUT_REASON_EMAIL_NOT_VERIFIED = 'emailNotVerified';
