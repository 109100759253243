import React from 'react';
import PropTypes from 'prop-types';
import Surveys from './surveys/Surveys';
import Documents from './documents/Documents';

class SurveysAndDocuments extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        <Surveys {...this.props} />
        <Documents {...this.props} />
      </div>
    )
  }
}

SurveysAndDocuments.propTypes = {}

export default SurveysAndDocuments;