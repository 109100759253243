import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Button, Modal} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import PaySchemeDetailsModal from '../../../common/pay-scheme-details/PaySchemeDetailsModal';

class DefaultPayScheme extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      defaultPaySchemeId: this.props.defaultPaySchemeId,
      showOriginalPaySchemeDetails: false,
      showCurrentPaySchemeDetails: false
    };
  }

  getPaySchemeOptions = () => (
    Object.values(this.props.paySchemes).map(payScheme => ({
      value: payScheme.id,
      label: payScheme.title
    }))
  );

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };

  setDefaultPayScheme = () => {
    this.showHideConfirmationDialog(false);
    this.props.setDefaultPaySchemeRequest(this.props.productionId, this.state.defaultPaySchemeId);
  };

  cancelDefaultPaySchemeChange = () => {
    this.showHideConfirmationDialog(false);
    this.setState({
      defaultPaySchemeId: this.props.defaultPaySchemeId
    });
  };

  handleOnSelectChange = option => {
    this.setState({
      defaultPaySchemeId: option.value
    });
    this.showHideConfirmationDialog(true);
  };

  showOriginalPaySchemeDetails = () => {
    this.setState({
      showOriginalPaySchemeDetails: true
    })
  };

  showCurrentPaySchemeDetails = () => {
    this.setState({
      showCurrentPaySchemeDetails: true
    })
  };

  hidePaySchemeDetails = () => {
    this.setState({
      showOriginalPaySchemeDetails: false,
      showCurrentPaySchemeDetails: false
    })
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.defaultPaySchemeId !== this.props.defaultPaySchemeId) {
      this.setState({
        defaultPaySchemeId: this.props.defaultPaySchemeId
      });
    }
  }

  render = () => {
    const paySchemeOptions = this.getPaySchemeOptions();
    const originalPayScheme = this.props.defaultPaySchemeId
      ? this.props.paySchemes.find(payScheme => payScheme.id === this.props.defaultPaySchemeId)
      : null;

    const currentPaySchemeOption = this.state.defaultPaySchemeId
      ? paySchemeOptions.find(option => option.value === this.state.defaultPaySchemeId)
      : null;

    const currentPayScheme = currentPaySchemeOption
      ? this.props.paySchemesById[currentPaySchemeOption.value]
      : null;

    let paySchemeDetailsToShow = null;
    if (this.state.showOriginalPaySchemeDetails && originalPayScheme) {
      paySchemeDetailsToShow = originalPayScheme;
    } else if (this.state.showCurrentPaySchemeDetails && currentPayScheme) {
      paySchemeDetailsToShow = currentPayScheme;
    }

    return (
      <div>
        Default Pay Scheme:
        <div style={{display: 'flex'}}>
          <div style={{width: '100%'}}>
            <Select
              options={paySchemeOptions}
              placeholder="Pay Scheme..."
              value={currentPaySchemeOption}
              onChange={this.handleOnSelectChange.bind(this)}
            />
          </div>
          {originalPayScheme &&
          <Button variant="outline-secondary" style={{marginLeft: '10px'}}
                  onClick={this.showOriginalPaySchemeDetails.bind(this)}>View</Button>
          }
        </div>
        <ConfirmationDialog
          show={this.state.showConfirmationDialog}
          title="Change Production's Default Pay Scheme"
          message={
            <div>
              <span>Are you sure you want to change Default Pay Scheme </span>
              {originalPayScheme &&
              <span>from <strong><a onClick={this.showOriginalPaySchemeDetails.bind(this)}>{originalPayScheme.title}</a></strong> </span>
              }
              {currentPaySchemeOption &&
              <span>
                to <strong><a
                onClick={this.showCurrentPaySchemeDetails.bind(this)}>{currentPaySchemeOption.label}</a></strong>?
              </span>
              }
            </div>
          }
          onCancel={this.cancelDefaultPaySchemeChange.bind(this)}
          onConfirm={this.setDefaultPayScheme.bind(this)}
        />
        {paySchemeDetailsToShow &&
        <PaySchemeDetailsModal payScheme={paySchemeDetailsToShow}
                               show={this.state.showOriginalPaySchemeDetails || this.state.showCurrentPaySchemeDetails}
                               onClose={this.hidePaySchemeDetails.bind(this)}/>
        }
      </div>
    );
  };
}

DefaultPayScheme.propTypes = {
  paySchemes: PropTypes.array.isRequired,
  defaultPaySchemeId: PropTypes.string,
  productionId: PropTypes.number.isRequired,
  setDefaultPaySchemeRequest: PropTypes.func.isRequired
};

export default DefaultPayScheme;