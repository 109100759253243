import React from 'react';
import PropTypes from 'prop-types';
import ProductionAdmin from './ProductionAdmin';
import QrCodeSettings from './QrCodeSettings';
import AdminPhoneNum from './AdminPhoneNum';

class OtherSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        <ProductionAdmin {...this.props} />
        <div style={{marginTop: '15px'}} />
        <AdminPhoneNum {...this.props} />
        <div style={{marginTop: '15px'}} />
        <QrCodeSettings {...this.props} />
      </div>
    )
  }
}

OtherSettings.propTypes = {
  production: PropTypes.object.isRequired
}
export default OtherSettings;