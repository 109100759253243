import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import NoteInputModal from './NotesInputModal';

const Notes = (props) => {
  const [show, setShow] = React.useState(false);
  const openModal = React.useCallback(() => {
    setShow((show) => true);
  }, []);
  const closeModal = React.useCallback(() => {
    setShow((show) => false);
  }, []);
  const { currentProductionDayId, productionDays, setProductionDayNotes } = props;
  const currentDay = productionDays[currentProductionDayId];
  const { notes } = currentDay;
  const notesMeta = notes
    ? { buttonName: 'Edit', variant: 'btn btn-xs btn-success', showNotes: true }
    : { buttonName: 'Add', variant: 'btn btn-xs btn-light', showNotes: false };

return (
  <React.Fragment>
    <div className="container-p-x py-2">
      <div className="text-muted small">Internal Day Notes</div>
      <div className="d-flex">
        {show && <NoteInputModal show={show} closeModal={closeModal} {...currentDay} setProductionDayNotes={setProductionDayNotes} />}
        {notesMeta.showNotes && <p className={`text text-small text-break overflow-hidden`}>{notes}</p>}
        <div className='p-1 ml-2'>
          <button type="button" className={`${notesMeta.variant}`} onClick={openModal}>
            {notesMeta.buttonName}
          </button>
        </div>
        {/* <Button variant={notesMeta.variant } onClick={openModal}>{notesMeta.buttonName}</Button> */}
      </div>
    </div>
  </React.Fragment>
);
};

Notes.propTypes = {
  currentProductionDayId: PropTypes.string.isRequired,
  productionDays: PropTypes.object.isRequired,
};

export default Notes;
