import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';


const SetChitNotes = (props) => {
  const {
    setBulkChitNotesRequest,
    idsOfSelectedChitsCrossedByFiltered,
    bulkActionsRunning,
    filteredChitRecordsByChitId,
    showHideBulkActionsSetNote,
  } = props;
  const productionId = props.match.params.id;
  const date = props.match.params.date;
  const [generalNotes, setGenralNotes] = React.useState('');

  const selectedChitIds = Object.keys(idsOfSelectedChitsCrossedByFiltered);
  const atLeastOneChitHasnotes = () =>
    selectedChitIds.some((chitId) => (filteredChitRecordsByChitId[chitId].chit.generalNotes ? true : false));

  const onChange = React.useCallback((e) => {
    setGenralNotes(e.target.value);
  });

  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    setBulkChitNotesRequest(productionId,date,selectedChitIds, generalNotes);
  });

  return (
    <React.Fragment>
      <Modal show={true} onHide={showHideBulkActionsSetNote} size="sm">
        <Form onSubmit={onSubmit}>
          <Modal.Header>
            <h5>Set Notes</h5>
          </Modal.Header>
          <Modal.Body>
            {atLeastOneChitHasnotes() && (
              <Alert variant="warning">
                {`Please note that some of selected chits are having notes`}, those notes will be updated current notes
                <p />
              </Alert>
            )}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                as="textarea"
                rows={6}
                col={4}
                value={generalNotes}
                onChange={onChange}
                placeholder="Enter notes, max 30 characters"
                maxLength={30}
                name="bulk chitnotes"
                disabled={bulkActionsRunning}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {bulkActionsRunning && <Loader type="ThreeDots" color="#2BAD60" height={50} width={50} />}
            <Button variant="default w-25" onClick={() => showHideBulkActionsSetNote(false)}>
              Cancel
            </Button>

            <Button variant="success w-75" type={'submit'} disabled={bulkActionsRunning}>
              Set Notes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

SetChitNotes.propTypes = {
  setBulkChitNotesRequest: PropTypes.func.isRequired,
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired,
  showHideBulkActionsSetNote: PropTypes.func.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
};

export default withRouter(SetChitNotes);
