import React from 'react';
import {dateToUveWithoutWeekFormat, uveDateWithoutWeekAndTimeToDateTime} from '../../lib/dateTimeUtils';
import PropTypes from 'prop-types';

class SetExtrasStatusToCheckedOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
      checkInTimeStr: this.props.checkInTime,
      checkOutDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
      checkOutTimeStr: '',
    }
  }

  detailsFromState = state => {
    let checkInTime = null;
    let checkOutTime = null;
    try {
      checkInTime = uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr)
      checkOutTime = uveDateWithoutWeekAndTimeToDateTime(state.checkOutDateStr, state.checkOutTimeStr)
    } catch (e) {

    }

    return {
      checkInTime,
      checkOutTime
    }
  }

  isDataValid = (state) => (
    uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr)
    && uveDateWithoutWeekAndTimeToDateTime(state.checkOutDateStr, state.checkOutTimeStr)
    && state.checkInDateStr && state.checkInTimeStr
    && state.checkOutDateStr && state.checkOutTimeStr
  )

  handleCheckInTimeChange = (event) => {
    this.setState({
      checkInTimeStr: event.target.value.toString(),
    }, () => {
      this.props.onChange({
          ...this.detailsFromState(this.state)
        },
        this.isDataValid(this.state)
      )
    });
  };

  handleCheckOutDateChange = (event) => {
    this.setState({
        checkOutDateStr: event.target.value.toString()
      },
      () => {
        this.props.onChange({
            ...this.detailsFromState(this.state)
          },
          this.isDataValid(this.state)
        )
      });
  };

  handleCheckOutTimeChange = (event) => {
    this.setState({
        checkOutTimeStr: event.target.value.toString()
      },
      () => {
        this.props.onChange({
            ...this.detailsFromState(this.state)
          },
          this.isDataValid(this.state)
        )
      })
  };

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkInDate">Check-in time</label>
              <input className="form-control" id="checkInDate"
                     value={this.state.checkInDateStr} disabled={true}
                     placeholder="dd-mm-yyyy"/>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkInTime">&nbsp;</label>
              <input className="form-control" id="checkInTime"
                     value={this.state.checkInTimeStr}
                     placeholder="hh:mm" onChange={this.handleCheckInTimeChange.bind(this)}/>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkOutDate">Check-out time</label>
              <input className="form-control" id="checkOutDate"
                     value={this.state.checkOutDateStr}
                     placeholder="dd-mm-yyyy" onChange={this.handleCheckOutDateChange.bind(this)}/>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkOutTime">&nbsp;</label>
              <input className="form-control" id="checkOutTime"
                     value={this.state.checkOutTimeStr}
                     placeholder="hh:mm" onChange={this.handleCheckOutTimeChange.bind(this)}/>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

SetExtrasStatusToCheckedOut.propTypes = {
  productionDate: PropTypes.string.isRequired,
  checkInTime: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default SetExtrasStatusToCheckedOut;