import * as React from 'react';
import {Button} from 'react-bootstrap';
import ConfirmationDialog from "../../common/confimration-dialog/ConfirmationDialog";
import PropTypes from 'prop-types';
import {USER_STATUS_ACTIVE, USER_STATUS_DEACTIVATED, USER_STATUS_PENDING_EMAIL_VERIFICATION} from '../constants';

class UserRecordActionsToggleEnableStatus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false
    }
  }

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };

  cancelToggleEnableStatus() {
    this.showHideConfirmationDialog(false);
  }

  toggleEnableStatus() {
    const { user } = this.props;
    if (user.status === USER_STATUS_ACTIVE || user.status === USER_STATUS_PENDING_EMAIL_VERIFICATION) {
      this.props.deactivateUserRequest({userId: user.id})
    } else  {
      this.props.activateUserRequest({userId: user.id})
    }
    this.showHideConfirmationDialog(false);
  }

  render = () => {
    const { user } = this.props;

    const buttonTitle = user.status === USER_STATUS_ACTIVE || user.status === USER_STATUS_PENDING_EMAIL_VERIFICATION
      ? 'Deactivate' : 'Activate'
    const title = user.status === USER_STATUS_ACTIVE || user.status === USER_STATUS_PENDING_EMAIL_VERIFICATION
      ? 'Deactivate user' : 'Activate user'
    const message = user.status === USER_STATUS_ACTIVE  || user.status === USER_STATUS_PENDING_EMAIL_VERIFICATION
      ? `Are you sure you want to deactivate ${user.firstName} ${user.lastName}?` : `Are you sure you want to activate ${user.firstName} ${user.lastName}?`
    const btnVariant = user.status === USER_STATUS_ACTIVE  || user.status === USER_STATUS_PENDING_EMAIL_VERIFICATION
      ? 'outline-secondary' : 'outline-primary';

    return (
      <div className="toggle-enable-status">
        <Button variant={btnVariant} size="sm" onClick={this.showHideConfirmationDialog.bind(this, true)}>{buttonTitle}</Button>
        <ConfirmationDialog
          show={this.state.showConfirmationDialog}
          title={title}
          message={
            <div>
              <span >{message}</span>
            </div>
          }
          onCancel={this.cancelToggleEnableStatus.bind(this)}
          onConfirm={this.toggleEnableStatus.bind(this)}
        />

      </div>
    )
  };
}

UserRecordActionsToggleEnableStatus.propTypes = {
  user: PropTypes.object.isRequired,
  activateUserRequest: PropTypes.func.isRequired,
  deactivateUserRequest: PropTypes.func.isRequired
};

export default UserRecordActionsToggleEnableStatus;
