import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';
import store from '../../store';
import ProductionDay from './ProductionDay';
import * as getters from '../getters';
import { loadProductionRequest, loadLocationData } from '../../production/actions';

class ProductionDayContainer extends React.Component {
  componentDidMount() {
    const productionId = this.props.match.params.id;
    const date = this.props.match.params.date;

    store.dispatch(loadProductionRequest(productionId));
    store.dispatch(loadLocationData());
    store.dispatch(actions.loadProductionDayDataRequest(productionId, date));
    store.dispatch(actions.getExtrasDocumentsRequest(productionId, date));
  }

  componentWillUnmount() {
    store.dispatch(actions.removeBulkActionsFeedback());
  }

  render() {
    const { isLoaded } = this.props;
    if (!isLoaded) return null;
    return <ProductionDay {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  const productionDayState = state.productionDay;
  return {
    productionDayDataExportRunning: state.productionDay.productionDayDataExportRunning,
    productionDayDataExportError: state.productionDay.productionDayDataExportError,
    productionDayChangeStatusShowHideWindow: state.productionDay.productionDayChangeStatusShowHideWindow,
    productionDayChangeStatusChanging: state.productionDay.productionDayChangeStatusChanging,
    productionDayChangeStatusError: state.productionDay.productionDayChangeStatusError,
    isLoaded: productionDayState.isLoaded,
    isLoading: productionDayState.isLoading,
    production: productionDayState.production,
    productionDate: getters.getCurrentProductionDayDate(state),
    currentProductionDay:getters.getCurrentProductionDay(state)
  };
};
const actionCreators = {
  setProductionDayDataExportRequest: actions.setProductionDayDataExportRequest,
};

export default withRouter(connect(mapStateToProps, actionCreators)(ProductionDayContainer));
