import * as React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class ProductionDayRowUnits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showViewPaySchemeWindow: false
    }
  }

  showHidePaySchemeWindow = show => {
    this.setState({
      showViewPaySchemeWindow: show
    })
  };

  render = () => {
    const production = this.props.production;
    return this.props.chitsTotalsByUnits.units.map(item => {
      const productionUnit = this.props.productionUnitsById[item.unit.id];

      const secondaryAdsNamesAndLinks = productionUnit.secondaryAdIds
        .map(adId => (
          <span key={adId}>
          <Link to={`/productions/${this.props.production.id}/ads/${adId}`}>
            {this.props.adsById[adId].firstName} {this.props.adsById[adId].lastName}
          </Link>
          </span>
        ));

      const chitsWord = item.chitsTotal === 1 ? 'chit' : 'chits';

      return (
        <div key={item.unitId} className="day-unit-info">
          <div><strong><Link
            to={`/productions/${production.id}/units/${item.unit.id}`}>{item.unit.name}</Link>, {item.chitsTotal} {chitsWord}
          </strong></div>
          {productionUnit.isDefault &&
          <div>All ADs of production have access to this unit</div>
          }
          {!productionUnit.isDefault && productionUnit.secondaryAdIds.length > 0 &&
          <div>
            Secondary ADs: <strong><span className="secondary-ads-names-and-links">{secondaryAdsNamesAndLinks}</span></strong>
          </div>
          }
          {!productionUnit.isDefault && productionUnit.secondaryAdIds.length === 0 &&
          <div>No Secondary ADs</div>
          }
        </div>
      )
    });
  };
}

ProductionDayRowUnits.propTypes = {
  production: PropTypes.object.isRequired,
  chitsTotalsByUnits: PropTypes.object.isRequired,
  productionUnitsById: PropTypes.object.isRequired,
  adsById: PropTypes.object.isRequired,
};

export default ProductionDayRowUnits;
