import * as actionTypes from './actionTypes';

export const sendMfaVerificationCodeRequest = (phoneInfoOptions, recaptchaVerifier) => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_REQUEST,
  phoneInfoOptions,
  recaptchaVerifier
});

export const sendMfaVerificationCodeSuccess = verificationId => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS,
  verificationId
});

export const sendMfaVerificationCodeFailure = (error, errorCode) => ({
  type: actionTypes.SEND_MFA_VERIFICATION_CODE_FAILURE,
  error,
  errorCode
});

export const confirmVerificationCodeRequest = (firebaseUser, verificationId, code) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_REQUEST,
  firebaseUser,
  verificationId,
  code
});

export const confirmVerificationCodeSuccess = () => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS,
});

export const confirmVerificationCodeFailure = (error, errorCode) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_FAILURE,
  error,
  errorCode
});

export const setStep = step => ({
  type: actionTypes.SET_STEP,
  step
});

export const skipMfaEnrollment = () => ({
  type: actionTypes.SKIP_MFA_ENROLLMENT
})