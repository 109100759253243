import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../common/confimration-dialog/ConfirmationDialog';
import {EXTRA_STATUS_BOOKED, EXTRA_STATUS_NO_SHOW} from '../../production-day/constants';

class ChitSection3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteChitConfirmationDialog: false
    }
  }

  cancelChitDeletion = () => {
    this.showHideDeleteChitConfirmationDialog(false);
  }

  deleteChit = () => {
    const { chit } = this.props.chitData;
    this.showHideDeleteChitConfirmationDialog(false);
    this.props.deleteChitRequest(
      {
        chitId: chit.id,
        productionId: chit.productionId,
        date: chit.date,
      },
      this.props.history
    );
  }

  showHideDeleteChitConfirmationDialog = show => {
    this.setState({
      showDeleteChitConfirmationDialog: show
    })
  }

  render() {
    const { sendCheckInEmailRequest, sendCheckOutEmailRequest, deleteChitRequest, chitData } = this.props;
    return (
      <div className="card">
        <div className="card-header font-weight-semibold">Options</div>
        <div className="card-body" id="card-body">
          <p>
            <a
              className="btn btn-outline-success w-100 action-btn"
              onClick={() => sendCheckInEmailRequest(chitData.chit.id)}>
              Resend Check-In Email
            </a>
          </p>
          <p>
            <a
              className="btn btn-outline-success w-100 action-btn"
              onClick={() => sendCheckOutEmailRequest(chitData.chit.id)}>
              Resend Chit Update Email
            </a>
          </p>

          {(chitData.chit.extraStatus === EXTRA_STATUS_BOOKED || chitData.chit.extraStatus === EXTRA_STATUS_NO_SHOW) &&
          <p style={{marginTop: '50px'}}>
            <a className="btn btn-outline-danger w-100 action-btn action-danger-btn"
               onClick={this.showHideDeleteChitConfirmationDialog.bind(this, true)}>
              Remove chit
            </a>
          </p>
          }
        </div>

        <ConfirmationDialog
          show={this.state.showDeleteChitConfirmationDialog}
          title="Remove Chit"
          message={
            <div>
              Are you sure you want to remove Chit?
            </div>
          }
          onCancel={this.cancelChitDeletion.bind(this)}
          onConfirm={this.deleteChit.bind(this)}
        />
      </div>
    )
  }
}

ChitSection3.propTypes = {
  sendCheckInEmailRequest: PropTypes.func.isRequired,
  sendCheckOutEmailRequest: PropTypes.func.isRequired,
  deleteChitRequest: PropTypes.func.isRequired
}

export default ChitSection3;