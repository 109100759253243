import React from 'react';
import PropTypes from 'prop-types';
import {secondsFromMidnightToHHmm} from '../../lib/dateTimeUtils';

class PaySchemeItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const {item} = this.props;

    const lineSeparator = (<hr className="mt-1 mb-1"/>);

    const isMultipleLabel = item.multiple === true ? 'YES' : 'NO';

    const categoryLabel = item.category || 'None';


    const dailyRateBlock = (
      <div>
        <div className="text-muted small">Daily Rate</div>
        {item.payRate}
      </div>
    );

    const overtimeRateBlock = (
      <div>
        <div className="text-muted small">Overtime Rate</div>
        {item.allowOvertime === true &&
        <span>{item.overtimeRatePerChunk} / {item.overtimeChunkSizeInMins} mins</span>
        }
        {!item.allowOvertime &&
        <span>N / A</span>
        }
      </div>
    );

    const secondaryOvertimeBlock = (
      <div>
        {item.allowSecondaryOvertime === true &&
        <div>
          <div className="text-muted small">
            Overtime
            after {secondsFromMidnightToHHmm(item.secondaryOvertime.overtimeKickoffTimeInSecsSinceMidnight)}
          </div>
          {item.secondaryOvertime.overtimeRatePerChunk} / {item.secondaryOvertime.overtimeChunkSizeInMins} mins
        </div>
        }
      </div>
    );

    const payRateHolidayPayBlock = (
      <div>
        <div className="text-muted small">Pay Rate Holiday&nbsp;Pay</div>
        {item.holidayPay}
      </div>
    );

    const overtimeHolidayPayBlock = (
      <div>
        <div className="text-muted small">Overtime Holiday Rate</div>
        {item.allowOvertime === true && item.overtimeHolidayPayRatePerChunk !== null &&
        <span>{item.overtimeHolidayPayRatePerChunk} / {item.overtimeChunkSizeInMins} mins</span>
        }
        {!(item.allowOvertime === true && item.overtimeHolidayPayRatePerChunk !== null) &&
        <span>N / A </span>
        }
      </div>
    );

    const secondaryOvertimeHolidayPayBlock = (
      <div>
        {item.allowSecondaryOvertime === true && item.secondaryOvertimeHolidayPayRatePerChunk !== null &&
        <div>
          <div className="text-muted small">
            Overtime Holiday Rate
            after {secondsFromMidnightToHHmm(item.secondaryOvertime.overtimeKickoffTimeInSecsSinceMidnight)}
          </div>
          {item.secondaryOvertimeHolidayPayRatePerChunk} / {item.secondaryOvertime.overtimeChunkSizeInMins} mins
        </div>
        }
      </div>
    );

    const earlyCallBlock = (
      <div>
        <div className="text-muted small">
          Early Call Before&nbsp;
          {item.allowEarlyCall === true &&
          <span>
            {secondsFromMidnightToHHmm(item.earlyCallCutoffInSecsSinceMidnight || 0)}
          </span>
          }
        </div>
        {item.allowEarlyCall === true &&
        <span>{item.earlyCallRatePerChunk} / {item.earlyCallChunkSizeInMins} mins</span>
        }
        {item.allowEarlyCall !== true &&
        <span>N / A</span>
        }
      </div>
    );

    const earlyCallHolidayPayBlock = (
      <div>
        <div className="text-muted small">
          Early Call Holiday Pay Before&nbsp;
          {item.allowEarlyCall === true  &&
          <span>
            {secondsFromMidnightToHHmm(item.earlyCallCutoffInSecsSinceMidnight || 0)}
          </span>
          }
        </div>
        {item.allowEarlyCall === true && item.earlyCallHolidayPayRatePerChunk !== null &&
        <span>{item.earlyCallHolidayPayRatePerChunk} / {item.earlyCallChunkSizeInMins} mins</span>
        }
        {!(item.allowEarlyCall === true && item.earlyCallHolidayPayRatePerChunk !== null) &&
        <span>N / A</span>
        }
      </div>
    );

    const durationBlock = (
      <div>
        <div className="text-muted small">Duration</div>
        {item.duration > 0 && item.durationUnit === 'minutes' &&
        <span>{Math.floor(item.duration / 60)} hours</span>
        }
        {item.duration > 0 && item.durationUnit !== 'minutes' &&
        <span>{item.duration} {item.durationUnit}</span>
        }
        {!item.duration &&
          <span>N / A</span>
        }
      </div>
    )

    const lunchBlock = (
      <div>
        <div className="text-muted small">Lunch</div>
        {item.includesLunchInMins > 0 &&
        <span>{item.includesLunchInMins} mins</span>
        }
        {!(item.includesLunchInMins > 0) &&
        <span>N / A</span>
        }
      </div>
    );

    const fullCancellationFeesBlock = (
      <div>
        <div className="text-muted small">
          Full Cancellation Fee
        </div>
        {item.cancellationFees &&
        <span>£{item.cancellationFees.full.toFixed(2)}</span>
        }
        {!item.cancellationFees &&
        <span>Not set in daily rate, using £0</span>
        }
      </div>
    )

    const partialCancellationFeesBlock = (
      <div>
        <div className="text-muted small">
          Partial Cancellation Fee
        </div>
        {item.cancellationFees &&
        <span>£{item.cancellationFees.partial.toFixed(2)}</span>
        }
        {!item.cancellationFees &&
        <span>Not set in daily rate, using £0</span>
        }
      </div>
    )


    return (
      <div className="card p-3 mb-2">
        <div className="row row-bordered">
          <div className="col-md-4">
            <strong>{item.title}</strong>
            <hr className="m-0"/>
            <hr className="m-0"/>
            <small>{item.description}</small><br/>
            <small>Rate Type: <strong>{item.rateType}</strong></small> <br/>
            <small>Multiple Amounts: <strong>{isMultipleLabel}</strong></small> <br/>
            <small>Category: <strong>{categoryLabel}</strong></small>
          </div>

          <div className="col">
            {dailyRateBlock}
            {lineSeparator}
            {overtimeRateBlock}
            {lineSeparator}
            {secondaryOvertimeBlock}
            {item.rateType === "Primary Fee" &&
              <React.Fragment>
              {lineSeparator}
              {fullCancellationFeesBlock}
              {lineSeparator}
              {partialCancellationFeesBlock}
                </React.Fragment>
              }
          </div>
          <div className="col">
            {payRateHolidayPayBlock}
            {lineSeparator}
            {overtimeHolidayPayBlock}
            {lineSeparator}
            {secondaryOvertimeHolidayPayBlock}
          </div>
          <div className="col">
            {earlyCallBlock}
            {lineSeparator}
            {earlyCallHolidayPayBlock}
            {lineSeparator}
            {durationBlock}
            {lineSeparator}
            {lunchBlock}
          </div>
        </div>
      </div>
    )
  }
}

PaySchemeItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PaySchemeItem;
