import { combineReducers } from 'redux';
import { reducer as app } from './app';
import { reducer as productions } from './productions';
import { reducer as production } from './production';
import { reducer as productionDay } from './production-day';
import { reducer as users } from './users';
import { reducer as chit } from './chit';
import { reducer as loadIndicator } from './common/load-indicator';
import { reducer as globalError } from './common/global-error';
import { reducer as signIn } from './signin';
import { reducer as passwordReset } from './password-reset';
import { reducer as onboarding } from './onboarding';
import { reducer as mfaEnrollment } from './mfa-enrollment';

const reducer = combineReducers({
  common: combineReducers({
    loadIndicator,
    globalError
  }),
  app,
  productions,
  production,
  productionDay,
  users,
  chit,
  signIn,
  passwordReset,
  onboarding,
  mfaEnrollment
});

export default reducer;