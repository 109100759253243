import { all } from 'redux-saga/effects'
import { sagas as app } from './app'
import { sagas as productions } from './productions'
import { sagas as production } from './production'
import { sagas as productionDay } from './production-day'
import { sagas as user } from './users'
import { sagas as chit } from './chit'
import { sagas as signIn } from './signin'
import { sagas as passwordReset } from './password-reset'
import { sagas as onboarding } from './onboarding'
import { sagas as mfaEnrollment } from './mfa-enrollment'

export default function* sagas() {
  yield all ([
    app(),
    productions(),
    production(),
    productionDay(),
    user(),
    chit(),
    signIn(),
    passwordReset(),
    onboarding(),
    mfaEnrollment()
  ]);
}