import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';
import store from '../../store';
import Production from './Production';
import * as constants from '../constants';
import * as getters from '../getters';

class ProductionContainer extends React.Component {
  extractActiveTabFromUrl = () => {
    const tabFromUrl = this.props.match.params.tab || '';
    switch (tabFromUrl.toUpperCase()) {
      case 'UNITS':
        return constants.TAB_UNITS;
      case 'ADS':
        return constants.TAB_ADS;
      case 'SURVEYS-AND-DOCUMENTS':
        return constants.TAB_SURVEYS_AND_DOCUMENTS;
      case 'OTHER-SETTINGS':
        return constants.TAB_OTHER_SETTINGS;
      case 'TRANSPORT-GROUPS':
        return constants.TRANSPORT_GROUPS;
      default:
        return constants.TAB_PRODUCTION_DAYS;
    }
  };

  componentDidMount = () => {
    const productionId = this.props.match.params.id;
    const tab = this.extractActiveTabFromUrl();

    store.dispatch(actions.loadProductionRequest(productionId));
    store.dispatch(actions.setActiveTab(tab));
    store.dispatch(actions.loadLocationData());
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const tab = this.extractActiveTabFromUrl();
    if (prevProps.activeTab !== tab) {
      store.dispatch(actions.setActiveTab(tab));
    }
  };

  render = () => {
    const { isLoaded } = this.props;
    if (!isLoaded) return null;
    return <Production {...this.props} />;
  };
}

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    isLoading: productionState.isLoading,
    isLoaded: productionState.isLoaded,
    production: productionState.production,
    productionDays: getters.getProductionDays(state),
    activeTab: productionState.activeTab,
    chitsTotal: getters.getChitsTotal(state),
    unitsTotal: getters.getUnitsTotal(state),
    productionAdsTotal: getters.getProductionAdsTotal(state),
    entityId: ownProps.match.params.entityId,

  };
};

const actionCreators = {};

export default withRouter(connect(mapStateToProps, actionCreators)(ProductionContainer));
