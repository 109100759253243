import React from 'react';
import PropTypes from 'prop-types';
import ProductionUnitRowAds from './ProductionUnitRowAds';
import ProductionUnitRowPayScheme from './ProductionUnitRowPayScheme';
import ProductionUnitsRowActions from './ProductionUnitsRowActions';
import ProductionUnitRowDays from './ProductionUnitRowDays';

class ProductionUnitRow extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <React.Fragment>
        <tr className={`production-unit-row ${this.props.highlighted ? 'highlighted-row' : ''}`}
            ref={this.props.refProp}>
          <td style={{width: '15%'}}>{this.props.productionUnit.name}</td>
          <td style={{width: '15%'}}>{this.props.productionUnit.description}</td>
          <td style={{width: '25%'}}><ProductionUnitRowAds {...this.props} /></td>
          <td style={{width: '5%'}}>{this.props.chitsTotal}</td>
          <td style={{width: '15%'}} className="days"><ProductionUnitRowDays {...this.props} /></td>
          <td style={{width: '15%'}}><ProductionUnitRowPayScheme {...this.props} /></td>
          <td style={{width: '10%'}}>
            <ProductionUnitsRowActions productionUnit={this.props.productionUnit} {...this.props} />
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

ProductionUnitRow.propTypes = {
  productionUnit: PropTypes.object.isRequired,
  chitsTotal: PropTypes.number.isRequired,
  productionDays: PropTypes.array.isRequired,
  refProp: PropTypes.object,
  highlighted: PropTypes.bool
};

export default ProductionUnitRow;
