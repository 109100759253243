import {call, put, takeEvery} from 'redux-saga/effects'
import * as api from './api';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import {arrayToMap} from '../lib/arrayUtils';
import * as notification from "../lib/notification";

/**
 * Convert list of users to map.
 * @param users
 */
const convertUsersToMap = (users) => (
  arrayToMap(users, (user) => (user.id))
);

export function* loadUsers() {
  try {
    const users = yield call(api.getUsers);
    const convertedUsers = convertUsersToMap(users);
    yield put(actions.loadUsersSuccess(convertedUsers));
  } catch (error) {
    yield put(actions.loadUsersFailure(error));
  }
}

export function* createUser(action) {
  try {
    const { data } = action
    const user = yield call(api.createUser, data);
    yield put(actions.createUserSuccess(user));
    notification.success('User has been successfully created');
  } catch (error) {
    yield put(actions.createUserFailure(error));
  }
}

export function* updateUser(action) {
  try {
    const { data } = action
    const user = yield call(api.updateUser, data);
    yield put(actions.updateUserSuccess(user));
    notification.success('User has been successfully updated');
  } catch (error) {
    yield put(actions.updateUserFailure(error));
  }
}

export function* activateUser(action) {
  try {
    const { data } = action
    const user = yield call(api.activateUser, data);
    yield put(actions.activateUserSuccess(user));
    notification.success('User has been successfully activated');
  } catch (error) {
    yield put(actions.activateUserFailure(error));
  }
}

export function* deactivateUser(action) {
  try {
    const { data } = action
    const user = yield call(api.deactivateUser, data);
    yield put(actions.deactivateUserSuccess(user));
    notification.success('User has been successfully deactivated');
  } catch (error) {
    yield put(actions.deactivateUserFailure(error));
  }
}

export function* unEnrollUserFromMfa(action) {
  try {
    const { data } = action
    const user = yield call(api.unEnrollUserFromMfa, data);
    yield put(actions.unEnrollUserFromMfaSuccess(user));
    notification.success('User has been successfully un-enrolled from MFA');
  } catch (error) {
    yield put(actions.unEnrollUserFromMfaFailure(error));
  }
}

export function* sendVerificationEmail(action) {
  try {
    const { data } = action
    yield call(api.sendVerificationEmail, data);
    yield put(actions.sendVerificationEmailSuccess());
    notification.success('Verification email has been successfully sent.');
  } catch (error) {
    yield put(actions.sendVerificationEmailFailure(error));
  }
}

export default function* sagas() {
  yield takeEvery(actionTypes.LOAD_USERS_REQUEST, loadUsers);
  yield takeEvery(actionTypes.CREATE_USER_REQUEST, createUser);
  yield takeEvery(actionTypes.UPDATE_USER_REQUEST, updateUser);
  yield takeEvery(actionTypes.ACTIVATE_USER_REQUEST, activateUser);
  yield takeEvery(actionTypes.DEACTIVATE_USER_REQUEST, deactivateUser);
  yield takeEvery(actionTypes.UN_ENROLL_USER_FROM_MFA_REQUEST, unEnrollUserFromMfa);
  yield takeEvery(actionTypes.SEND_VERIFICATION_EMAIL_REQUEST, sendVerificationEmail);
}