import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {dateToUveFormat} from '../../lib/dateTimeUtils';

class ProductionRecord extends React.Component {

  constructor(props) {
    super(props);
  }

  navigateToDay = (option) => {
    this.props.onProductionDayClick(this.props.record.production.id, option.value);
  };

  render = () => {
    const production = this.props.record.production;
    const selectOptions = Object.values(this.props.record.productionDays).map((day) => ({
      value: day.date,
      label: dateToUveFormat(day.date)
    }));

    const daysCount = this.props.record.productionDays.length;
    const daysWord = daysCount === 1 ? 'day' : 'days';
    const trClass = this.props.newlyImported ? 'table-success' : '';

    return (
      <tr className={trClass}>
        <td>
          <Link to={`/productions/${production.id}`}>{production.name}</Link>
        </td>
        <td>
          <Link to={`/productions/${production.id}`}>{production.company}</Link>
        </td>
        <td>
          <Link to={`/productions/${production.id}`}>{production.contact}</Link>
        </td>
        <td>
          <Link to={`/productions/${production.id}`}>{production.contactNumber}</Link>
        </td>
        <td>
          <Link to={`/productions/${production.id}`}>{production.contactEmail}</Link>
        </td>
        <td>
          <div className="production-days-select">
            <Select options={selectOptions}
                    classNamePrefix="production-days-select"
                    placeholder={`${daysCount} ${daysWord}`}
                    onChange={this.navigateToDay.bind(this)}
            />
          </div>
        </td>
      </tr>
    );
  };
}

ProductionRecord.propTypes = {
  record: PropTypes.object.isRequired,
  onProductionDayClick: PropTypes.func.isRequired,
  newlyImported: PropTypes.bool
};

export default ProductionRecord;