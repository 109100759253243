import * as React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class ProductionUnitsRowActionsDelete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmationDialog: false
    };
  }

  deleteProductionUnit = () => {
    this.props.deleteProductionUnitRequest(this.props.productionUnit.id);
    this.showHideDeleteConfirmationDialog(false);
  };

  showHideDeleteConfirmationDialog = show => {
    this.setState({
      showDeleteConfirmationDialog: show
    })
  }

  render = () => {
    const hasChits = this.props.chitsTotalsByUnits[this.props.productionUnit.id]
      ? this.props.chitsTotalsByUnits[this.props.productionUnit.id]
      : false;
    let cannotDeleteMessage = null;
    if (this.props.productionUnit.isDefault) {
      cannotDeleteMessage = 'Cannot delete main unit.';
    } else if (hasChits) {
      cannotDeleteMessage = 'Cannot delete this unit since it has chits.';
    }
    return (
      <div>
        {cannotDeleteMessage &&
        <small>{cannotDeleteMessage}</small>
        }
        {!cannotDeleteMessage &&
        <Button variant="outline-danger" size="sm"
                onClick={this.showHideDeleteConfirmationDialog.bind(this, true)}>Delete</Button>
        }
        <ConfirmationDialog
          show={this.state.showDeleteConfirmationDialog}
          title="Delete Production Unit"
          message={
            <span>
              Are you sure you want to delete production unit <strong>{this.props.productionUnit.name}</strong>?
            </span>
          }
          onConfirm={this.deleteProductionUnit.bind(this)}
          onCancel={this.showHideDeleteConfirmationDialog.bind(this, false)}
        />
      </div>
    )
  };
}

ProductionUnitsRowActionsDelete.propTypes = {
  productionUnit: PropTypes.object.isRequired,
  chitsTotalsByUnits: PropTypes.object.isRequired,
  deleteProductionUnitRequest: PropTypes.func.isRequired
};

export default ProductionUnitsRowActionsDelete;
