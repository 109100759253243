import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'react-bootstrap';
import logo from '../../assets/images/logo-icon.svg';

class Header extends React.Component {

  render = () => {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <i style={{marginLeft: '5px'}} className="fas fa-caret-down"/>
      </a>
    ));


    return (
      <nav className="header-component layout-navbar navbar navbar-expand-lg align-items-lg-center bg-white container-p-x"
           id="layout-navbar">

        <a className="navbar-brand app-brand demo d-lg-none py-0 mr-4">
                            <span className="app-brand-logo demo">
                                <img src={logo} alt=""/>
                            </span>
          <span className="app-brand-text demo font-weight-normal ml-2">Univeral Extras BSS</span>
        </a>
        <div className="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto">
          <a className="nav-item nav-link px-0 mr-lg-4">
            <i className="ion ion-md-menu text-large align-middle"/>
          </a>
        </div>

        <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#layout-navbar-collapse">
          <span className="navbar-toggler-icon"/>
        </button>

        <div className="navbar-collapse collapse" id="layout-navbar-collapse">
          <hr className="d-lg-none w-100 my-2"/>

          <div className="navbar-nav align-items-lg-center">
          </div>

          <div className="navbar-nav align-items-lg-center ml-auto">

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                {this.props.user.firstName} {this.props.user.lastName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.props.signOutRequest.bind(this, this.props.history)}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  signOutRequest: PropTypes.func.isRequired
};


export default Header;

