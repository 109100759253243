import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class ProductionAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      adminId: this.props.production.adminId
    };
  }

  getAdminOptions = () => (
    Object.values(this.props.admins).map(admin => ({
      value: admin.id,
      label: `${admin.firstName} ${admin.lastName} (${admin.username}, ${admin.email})`
    }))
  );

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };

  assignAdmin = () => {
    this.showHideConfirmationDialog(false);
    this.props.assignAdminRequest(this.props.production.id, this.state.adminId);
  };

  cancelProductionAdminChange = () => {
    this.showHideConfirmationDialog(false);
    this.setState({
      adminId: this.props.adminId
    });
  };

  handleOnSelectChange = option => {
    this.setState({
      adminId: option.value
    });
    this.showHideConfirmationDialog(true);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.adminId !== this.props.adminId) {
      this.setState({
        adminId: this.props.adminId
      });
    }
  }

  render = () => {
    const adminOptions = this.getAdminOptions();
    const originalAdmin = this.props.production.adminId
      ? this.props.admins.find(admin => admin.id === this.props.production.adminId)
      : null;
    const currentAdminOption = this.state.adminId
      ? adminOptions.find(option => option.value === this.state.adminId)
      : null;

    // console.log(adminOptions)
    return (
      <div>
        <div>
          <div style={{ float: 'left', marginTop: '10px', width: '200px' }}>
            Production Admin:
          </div>
          <div style={{ float: 'left', marginLeft: '10px', width: '500px' }}>
            <Select
              options={adminOptions}
              placeholder="Production Admin..."
              value={currentAdminOption}
              onChange={this.handleOnSelectChange.bind(this)}
            />
          </div>
          <div style={{ clear: 'both' }} />
        </div>
        <ConfirmationDialog
          show={this.state.showConfirmationDialog}
          title="Assign Admin to Production"
          message={
            <div>
              <span>Are you sure you want to change production's Admin </span>
              {originalAdmin &&
                <span>from <strong>{originalAdmin.firstName} {originalAdmin.lastName}</strong> </span>
              }
              {currentAdminOption &&
                <span>
                  to <strong>{currentAdminOption.label}</strong>?
                </span>
              }
            </div>
          }
          onCancel={this.cancelProductionAdminChange.bind(this)}
          onConfirm={this.assignAdmin.bind(this)}
        />
      </div>
    );
  };
}

ProductionAdmin.propTypes = {
  admins: PropTypes.array.isRequired,
  adminId: PropTypes.string,
  production: PropTypes.object.isRequired,
  assignAdminRequest: PropTypes.func.isRequired
}

export default ProductionAdmin;