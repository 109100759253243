import * as actionTypes from './actionTypes';
import { actionTypes as userActionTypes } from '../users';
import { actionTypes as signInActionTypes } from '../signin';
import { actionTypes as mfaEnrollmentInActionTypes } from '../mfa-enrollment';
import {MFA_STATUS_ENABLED} from '../users/constants';

const initialState = {
  isLoading: true,
  isLoaded: false,
  // TODO:: move to `users` reducer
  users: {},
  rateCards: {},
  authenticated: false,
  authenticationChecked: false,
  mfaEnrollmentRequired: false,
  // Indicates whether mfa enrollment completed: either enrolled or skipped.
  mfaEnrollmentCompleted: false,
  // Logged in user
  user: {},
  firebaseUser: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type)  {
    case actionTypes.LOAD_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ...action.initialData,
        mfaEnrollmentRequired: action.initialData.user.mfaRequired,
        mfaEnrollmentCompleted: state.firebaseUser && state.firebaseUser.multiFactor.enrolledFactors.length > 0
      };
    case userActionTypes.LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.users
      }
    case signInActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        authenticationChecked: true,
        authenticated: true,
        firebaseUser: action.user
      }
    case signInActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        authenticated: false,
        authenticationChecked: true,
      }
    case actionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        authenticationChecked: true,
        authenticated: false
      }
    case userActionTypes.CREATE_USER_SUCCESS:
    case userActionTypes.UPDATE_USER_SUCCESS:
    case userActionTypes.ACTIVATE_USER_SUCCESS:
    case userActionTypes.DEACTIVATE_USER_SUCCESS:
    case userActionTypes.UN_ENROLL_USER_FROM_MFA_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.user.id]: action.user
        }
      }
    case mfaEnrollmentInActionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        mfaEnrollmentCompleted: true
      }
    case mfaEnrollmentInActionTypes.SKIP_MFA_ENROLLMENT:
      return {
        ...state,
        mfaEnrollmentCompleted: true
      }
    default:
      return state;
  }
};

export default reducer;