import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {momentToHoursAndMinutesValues, parseFromDateTime} from '../../../lib/dateTimeUtils';
import { momentToDateTime } from '../../../lib/dateTimeUtils';
import {EXTRA_STATUS_CHECKED_IN, EXTRA_STATUS_CHECKED_OUT} from '../../constants';

class ChitRecordCallTime extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      callTimeChanged: false,
      currentCallTime: parseFromDateTime(props.record.chit.callTime),
      open: false
    };
  }

  onCallTimeChange = (newCallTime) => {
    if (newCallTime.diff(this.props.callTime, 'seconds')) {
      // Only if new call time differs from original one
      this.setState({
        callTimeChanged: true,
        currentCallTime: newCallTime,
      });
    } else {
      // If new call time is set back to original
      this.setState({
        callTimeChanged: false,
        currentCallTime: parseFromDateTime(this.props.record.chit.callTime)
      })
    }
  };

  onCallTimeChangeCancel = () => {
    this.setState({
      callTimeChanged: false,
      currentCallTime: parseFromDateTime(this.props.record.chit.callTime)
    })
  };

  onCallTimeSave = () => {
    this.props.setChitCallTimeRequest(this.props.record.chit.id, momentToDateTime(this.state.currentCallTime));
    this.setState({
      callTimeChanged: false
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // We set time picker value from the state to be able to re-set it when "Cancel" button is clicked.
    // (If we would set it directly from props, there wouldn't be any way to change the time dynamically (reset))).
    // But we need to set call time in the state in the constructor to initial value which comes from props.
    // When call time is updated outside of this component, state won't be updated since we set initial value in the
    // constructor.
    // So we should capture new props and update the state accordingly.
    if (prevProps.record.chit.callTime !== this.props.record.chit.callTime) {
      this.setState({
        currentCallTime: parseFromDateTime(this.props.record.chit.callTime)
      })
    }

    if (this.isCallTimeTbs(prevProps.record.chit.extraStatus, prevState.currentCallTime) && prevState.open) {
      this.close()
    }
  }

  /**
  * When importing chits from C5, we need set it to empty.
  * But currently, the call time is mandatory on backend, it would take not trivial development effort
  * to refactor it to make it optional and con potentially brake many things both on backend and AD App.
  * So we decided "hack" it a bit (temporary solution) - if extra is not checked in or checked out,
  * we treat call time 01:01 as empty (TBC).
  */
  isCallTimeTbs = (extraStatus, callTime) => {
    const {hours, minutes} = momentToHoursAndMinutesValues(callTime);
    return extraStatus !== EXTRA_STATUS_CHECKED_IN && extraStatus !== EXTRA_STATUS_CHECKED_OUT && hours === 1 && minutes === 1
  }

  setCallTimeInitialValue = () => {
    this.setState((prevState) => ({
      currentCallTime: prevState.currentCallTime.set({hour:0,minute:0,second:0,millisecond:0}),
      open: true,
      callTimeChanged: true
    }))
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  close = () => {
    this.setState({
      open: false
    })
  }

  render = () => {
    const callTimeTbs = this.isCallTimeTbs(this.props.record.chit.extraStatus, this.state.currentCallTime);

    return (
      <div>
        {callTimeTbs &&
          <button className="btn btn-xs" onClick={this.setCallTimeInitialValue}>Not set</button>
        }
        {!callTimeTbs &&
        <div>
          <TimePicker
            style={{width: 70}}
            showSecond={false}
            allowEmpty={false}
            value={this.state.currentCallTime}
            onChange={this.onCallTimeChange}
            open={this.state.open}
            onOpen={this.open}
            onClose={this.close}
          />
        </div>
        }
        {this.state.callTimeChanged &&
        <div style={{marginTop: '2px', fontSize: '11px'}}>
          <a
            className="text-primary"
            style={{marginLeft: '4px', cursor: 'pointer'}}
            onClick={this.onCallTimeSave}
          >
            Save
          </a>
          <a
            className={"text-secondary"}
            style={{marginLeft: '2px', cursor: 'pointer'}}
            onClick={this.onCallTimeChangeCancel}>
            Cancel
          </a>
        </div>
        }
      </div>
    )
  }
}

ChitRecordCallTime.propTypes = {
  record: PropTypes.object.isRequired,
  setChitCallTimeRequest: PropTypes.func.isRequired
  // callTimeSetInBulkAction: PropTypes.bool.isRequired
};

export default ChitRecordCallTime;

