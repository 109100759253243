import React from 'react';
import PropTypes from 'prop-types';

class ChitRecordBookingDateDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const { record } = this.props;
        const bookingDateDetails = record.chit.bookingDateDetails || {};

        const address = bookingDateDetails.address?.address ?? '';

        const geoLocation = bookingDateDetails.address?.geoLocation;
        const geoLocationLabel = geoLocation ? `${geoLocation.lat},${geoLocation.lng}` : '';

        return (
            <React.Fragment>
                <td className="chit-column-address">
                    <span>{address}</span>
                    <br />
                    <span>{geoLocationLabel}</span>
                </td>
                <td className="chit-column-contact-name">
                    <span>{bookingDateDetails.contactName}</span>
                </td>
                <td className="chit-column-contact-number">
                    <span>{bookingDateDetails.contactNumber}</span>
                </td>
                <td className="chit-column-costume">
                    <span>{bookingDateDetails.costume}</span>
                </td>

                <td className="chit-column-other-info">
                    <span>{bookingDateDetails.otherInfo}</span>
                </td>
            </React.Fragment>
        );
    };
}

ChitRecordBookingDateDetails.propTypes = {
    record: PropTypes.object.isRequired,
};

export default ChitRecordBookingDateDetails;
