import React from 'react';
import PasswordResetLayout from './PasswordResetLayout';
import * as PropTypes from 'prop-types';
import {SOURCE_APP_ADAPP, SOURCE_APP_BSS} from '../constants';
import config from '../../config/config.default';

class ResetPasswordSuccess extends React.Component {

  render() {

    return (
      <PasswordResetLayout>
        <div className="alert alert-success" role="alert">
          <string>Your password has been set successfully.</string>
          {this.props.sourceApp === SOURCE_APP_ADAPP &&
            <div>Please go back to the UVE Client app and login with your new password.</div>
          }
          {this.props.sourceApp === SOURCE_APP_BSS &&
            <div>Please go to the <a href={config.baseUrl}>Login page</a></div>
          }
        </div>
      </PasswordResetLayout>
    )
  }
}

ResetPasswordSuccess.propTypes = {
  sourceApp: PropTypes.string
}

export default ResetPasswordSuccess;