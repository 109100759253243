import React from 'react';
import PropTypes from 'prop-types';
import CreateUser from "./CreateUser";

class Header extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div className="row">
        <div className="col">
          <h4 className="py-2 mb-2">
            <ol className="breadcrumb">
              <li className="active">Users</li>
            </ol>
          </h4>
        </div>
        <div className="col">
          <div className="text-right mt-2">
            <CreateUser {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
};

export default Header;