import React from 'react';
import PropTypes from 'prop-types';
import ChitsContainer from './chits/ChitsContainer';
import ProductionDayDetailsContainer from './production-day-details/ProductionDayDetailsContainer';
import './ProductionDay.css';
import { Link } from 'react-router-dom';
import { dateToUveFormat } from '../../lib/dateTimeUtils';
import ProductionDayExport from '../../production/components/ProductionDayExport'

const ProductionDay = (props) => {
  return (
    <div>
      <div className="productionDayHeaderWrapper" style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4 className="py-2 mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Productions</Link></li>
            <li className="breadcrumb-item"><Link
              to={`/productions/${props.production.id}`}>{props.production.name}</Link></li>
            <li className="breadcrumb-item active"><a>{dateToUveFormat(props.productionDate)}</a></li>
          </ol>
        </h4>
        <ProductionDayExport {...props} />
      </div>
      <ProductionDayDetailsContainer />
      <ChitsContainer />
    </div>
  );
};

ProductionDay.propTypes = {
  // Current production
  production: PropTypes.object.isRequired,
  productionDate: PropTypes.string.isRequired
};

export default ProductionDay;

