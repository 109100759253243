import React from 'react';
import {dateToUveWithoutWeekFormat, uveDateWithoutWeekAndTimeToDateTime} from '../../lib/dateTimeUtils';
import PropTypes from 'prop-types';

class SetExtrasStatusToCheckedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInDateStr: dateToUveWithoutWeekFormat(this.props.productionDate),
      checkInTimeStr: this.props.checkInTime
    }
  }

  isDataValid = (state) => (
    uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr)
    && state.checkInDateStr && state.checkInTimeStr
  )

  handleCheckInTimeChange = (event) => {
    this.setState({
      checkInTimeStr: event.target.value.toString(),
    }, () => {
      this.props.onChange({
          ...this.detailsFromState(this.state)
        },
        this.isDataValid(this.state)
      )
    })
  };

  detailsFromState = state => {
    let checkInTime = null;
    try {
      checkInTime = uveDateWithoutWeekAndTimeToDateTime(state.checkInDateStr, state.checkInTimeStr)
    } catch (e) {
    }

    return {
      checkInTime
    }
  }

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkInDate">Check-in time</label>
              <input className="form-control" id="checkInDate" value={this.state.checkInDateStr} disabled={true}
                     placeholder="dd-mm-yyyy"/>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="checkInTime">&nbsp;</label>
              <input className="form-control" id="checkInTime"
                     value={this.state.checkInTimeStr}
                     placeholder="hh:mm" onChange={this.handleCheckInTimeChange.bind(this)}/>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

SetExtrasStatusToCheckedIn.propTypes = {
  productionDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SetExtrasStatusToCheckedIn;
