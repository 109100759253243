import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {USER_STATUS_PENDING_EMAIL_VERIFICATION} from '../constants';

class EditUserSendEmailVerification extends React.Component {

  render() {
    const { user, sendingVerificationEmail, sendVerificationEmailRequest } = this.props;
    const buttonLabel = sendingVerificationEmail ? 'Sending...' : 'Re-send verification email';

    if (user.status !== USER_STATUS_PENDING_EMAIL_VERIFICATION) {
      return null;
    }

    return (
      <div>
        <Button
          size="sm"
          variant="info"
          disabled={sendingVerificationEmail}
          onClick={() => sendVerificationEmailRequest({userId: user.id})}
        >
          {buttonLabel}
        </Button>
      </div>
    )
  }
}

EditUserSendEmailVerification.propTypes = {
  user: PropTypes.object.isRequired,
  sendingVerificationEmail: PropTypes.bool.isRequired,
  sendVerificationEmailRequest: PropTypes.func.isRequired
}

export default EditUserSendEmailVerification;