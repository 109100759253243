import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MfaEnrollment from './MfaEnrollment';
import {confirmVerificationCodeRequest, sendMfaVerificationCodeRequest, setStep, skipMfaEnrollment} from '../actions';
import {signOutRequest} from '../../app/actions';
import MfaEnrollmentConfirmCode from './MfaEnrollmentConfirmCode';
import {
  STEP_CONFIRM_VERIFICATION_CODE,
  STEP_SEND_VERIFICATION_CODE,
  STEP_CONFIRM_VERIFICATION_CODE_SUCCESS
} from '../constants';
import MfaEnrollmentConfirmCodeSuccess from './MfaEnrollmentConfirmCodeSuccess';

class MfaEnrollmentContainer extends React.Component {

  render() {
    const { step } = this.props;
    switch (step) {
      case STEP_SEND_VERIFICATION_CODE:
        return <MfaEnrollment {...this.props} />
      case STEP_CONFIRM_VERIFICATION_CODE:
        return <MfaEnrollmentConfirmCode {...this.props} />
      case STEP_CONFIRM_VERIFICATION_CODE_SUCCESS:
        return <MfaEnrollmentConfirmCodeSuccess {...this.props} />

    }
  }
}

const mapStateToProps = state => {
  const appState = state.app;
  const mfaEnrollment = state.mfaEnrollment;
  return {
    error: mfaEnrollment.error,
    errorCode: mfaEnrollment.errorCode,
    sendingMfaVerificationCode: mfaEnrollment.sendingMfaVerificationCode,
    verificationCodeSent: mfaEnrollment.verificationCodeSent,
    confirmingVerificationCode: mfaEnrollment.confirmingVerificationCode,
    verificationId: mfaEnrollment.verificationId,
    step: mfaEnrollment.step,
    firebaseUser: appState.firebaseUser,
    mfaEnrollmentRequired: appState.mfaEnrollmentRequired,
  }
};

const actionCreators = {
  sendMfaVerificationCodeRequest,
  confirmVerificationCodeRequest,
  setStep,
  signOutRequest,
  skipMfaEnrollment
};

export default withRouter(connect(mapStateToProps, actionCreators)(MfaEnrollmentContainer));

