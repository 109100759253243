import * as LOC_CONSTANTS from "../LocationConstants"

export function convertGoogleAddressToUvLocationObject(googleAddressArray, latAndLngInfo, label) {
    // console.log(JSON.stringify(googleAddressArray, undefined, 2))
    // uvdata keys =
    // console.log(googleAddressArray);
    const requiredInfoForUvLocationData = [
        'country',
        'administrative_area_level_1',
        'postal_code',
        'administrative_area_level_2', 'locality', 'postal_town'];

    const uvRequiredLocationDataKeys = {
        building_name: ['subpremise', 'premise'],
        street_number: ['street_number'],
        street: ['street_address', 'route'],
        postal_code: ['postal_code'],
        province: [
            // 'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3',
            'administrative_area_level_4',
            'administrative_area_level_5',
        ],
        city: [
            'postal_town',
            'locality',
            'sublocality',
            'sublocality_level_1',
            'sublocality_level_2',
            'sublocality_level_3',
            'sublocality_level_4',
        ],
        country: ['country'],
    }
    const uvRequiredLocationDataValues = {
        building_name: [],
        street_number: [],
        street: [],
        postal_code: [],
        province: [],
        city: [],
        country: [],
    }

    const SEPARATOR = ' ';


    function uvRequiredLocationDataStateValueParsers(uvRequiredLocationDataValues) {

        let { street_number, street, postal_code, province, country, city, building_name } = uvRequiredLocationDataValues;
        let street_number_prepared = street_number.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.long_name
            }
            return acc;
        }, []);
        let street_prepared = street.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.long_name
            }
            return acc;
        }, []);

        let postal_code_prepared = postal_code.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.long_name
            }
            return acc;

        }, [])

        let province_prepared = province.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.long_name
            }
            return acc;

        }, [])

        let country_prepared = country.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.short_name
            }
            return acc;

        }, [])

        let building_name_prepared = building_name.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.short_name
            }
            return acc;

        }, [])

        let city_prepared = city.reduce((acc, current) => {
            if (current) {
                return ((acc.length && acc + SEPARATOR) || '') + current.long_name
            }
            return acc;

        }, [])



        return {
            [LOC_CONSTANTS.NUMBEROF_BUILDING]: ((street_number_prepared + SEPARATOR)+ building_name_prepared).trim(),
            [LOC_CONSTANTS.LOCATION_NAME]: label,
            // [LOC_CONSTANTS.LOCATION_NAME]: ((street_number_prepared && street_number_prepared + SEPARATOR || '') + (street_number_prepared && street_number_prepared + SEPARATOR || '') + city_prepared).replace(/^\s+/, '').replace(/\s+$/, ''),
            [LOC_CONSTANTS.STREET]: (street_prepared),
            // [LOC_CONSTANTS.STREET]: ((street_number_prepared && street_number_prepared + SEPARATOR || '') + (street_number_prepared && street_number_prepared || '')).replace(/^\s+/, '').replace(/\s+$/, ''),
            [LOC_CONSTANTS.COUNTRY_CODE]: (country_prepared),
            [LOC_CONSTANTS.COUNTY]: (province_prepared),
            [LOC_CONSTANTS.COORDINATES]: latAndLngInfo,
            [LOC_CONSTANTS.CITY]: (city_prepared),
            [LOC_CONSTANTS.POST_CODE]: (postal_code_prepared)
        }


    }



    googleAddressArray.forEach((componet) => {

        if (componet.types) {
            for (var shouldBe in uvRequiredLocationDataKeys) {
                const currentLocationShouldbe = uvRequiredLocationDataKeys[shouldBe].forEach((currentShouldbe, currentShouldBeindex) => {
                    if (componet.types.includes(currentShouldbe)) {
                        uvRequiredLocationDataValues[shouldBe][currentShouldBeindex] = componet;

                    } else {
                        uvRequiredLocationDataValues[shouldBe][currentShouldBeindex] = uvRequiredLocationDataValues[shouldBe][currentShouldBeindex] || '';
                    }
                });
            }
        }

    })



    return uvRequiredLocationDataStateValueParsers(uvRequiredLocationDataValues)


}