import * as actionTypes from './actionTypes';

export const loadInitialDataRequest = () => ({
  type: actionTypes.LOAD_INITIAL_DATA_REQUEST
});

export const loadInitialDataSuccess = (initialData) => ({
  type: actionTypes.LOAD_INITIAL_DATA_SUCCESS,
  initialData
});

export const loadInitialDataFailure = (error) => ({
  type: actionTypes.LOAD_INITIAL_DATA_FAILURE,
  error
});

export const signOutRequest = (data) => ({
  type: actionTypes.SIGN_OUT_REQUEST,
  data
});

export const signOutSuccess = (initialData) => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
  initialData
});

export const signOutFailure = (error) => ({
  type: actionTypes.SIGN_OUT_FAILURE,
  error
});

