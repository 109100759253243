import * as actionTypes from './actionTypes';


export const setCallDetailsMessageModal = (show) => ({
  type: actionTypes.SET_CALL_DETAILS_MESSAGE_MODAL,
  show
});

export const loadChitRecordsRequest = (productionId, date) => ({
  type: actionTypes.LOAD_CHIT_RECORDS_REQUEST,
  productionId,
  date
});

export const loadChitRecordsSuccess = (chitRecords) => ({
  type: actionTypes.LOAD_CHIT_RECORDS_SUCCESS,
  chitRecords
});

export const loadChitRecordsFailure = (error) => ({
  type: actionTypes.LOAD_CHIT_RECORDS_SUCCESS,
  error
});

export const loadProductionDayDataRequest = (productionId, date) => ({
  type: actionTypes.LOAD_PRODUCTION_DAY_AGGREGATED_DATA_REQUEST,
  productionId,
  date
});

export const loadProductionDayDataSuccess = (data) => ({
  type: actionTypes.LOAD_PRODUCTION_DAY_AGGREGATED_DATA_SUCCESS,
  data
});

export const loadProductionDayDataFailure = (error) => ({
  type: actionTypes.LOAD_PRODUCTION_DAY_AGGREGATED_DATA_FAILURE,
  error
});

export const wrapUpProductionUnitOnDayRequest = (productionDayId, productionUnitId) => ({
  type: actionTypes.WRAP_UP_PRODUCTION_UNIT_ON_DAY_REQUEST,
  productionDayId,
  productionUnitId
});

export const wrapUpProductionUnitOnDaySuccess = (data) => ({
  type: actionTypes.WRAP_UP_PRODUCTION_UNIT_ON_DAY_SUCCESS,
  data
});

export const wrapUpProductionUnitOnDayFailure = (error) => ({
  type: actionTypes.WRAP_UP_PRODUCTION_UNIT_ON_DAY_FAILURE,
  error
});

export const generateProductionUnitCurrentReportRequest = (productionDayId, productionUnitId) => ({
  type: actionTypes.GENERATE_PRODUCTION_UNT_CURRENT_REPORT_REQUEST,
  productionDayId,
  productionUnitId
});

export const generateProductionUnitCurrentReportSuccess = (data) => ({
  type: actionTypes.GENERATE_PRODUCTION_UNT_CURRENT_REPORT_SUCCESS,
  data
});

export const generateProductionUnitCurrentReportFailure = (error) => ({
  type: actionTypes.GENERATE_PRODUCTION_UNT_CURRENT_REPORT_FAILURE,
  error
});

export const generateCurrentReportRequest = (productionId, date) => ({
  type: actionTypes.GENERATE_CURRENT_REPORT_REQUEST,
  productionId,
  date
});

export const generateCurrentReportRequestSuccess = (data) => ({
  type: actionTypes.GENERATE_CURRENT_REPORT_SUCCESS,
  data
});

export const generateCurrentReportRequestFailure = (error) => ({
  type: actionTypes.GENERATE_CURRENT_REPORT_FAILURE,
  error
});

export const setChitCallTimeRequest = (chitId, callTime) => ({
  type: actionTypes.SET_CHIT_CALL_TIME_REQUEST,
  chitId,
  callTime
});

export const setChitCallTimeSuccess = (chitRecord) => ({
  type: actionTypes.SET_CHIT_CALL_TIME_SUCCESS,
  chitRecord
});

export const setChitCallTimeFailure = (error) => ({
  type: actionTypes.SET_CHIT_CALL_TIME_FAILURE,
  error
});

export const setChitsCallTimeRequest = (chitIds, callTime, productionId, date) => ({
  type: actionTypes.SET_CHITS_CALL_TIME_REQUEST,
  chitIds,
  callTime,
  productionId,
  date
});

export const setChitsCallTimeSuccess = (results, chitRecords) => ({
  type: actionTypes.SET_CHITS_CALL_TIME_SUCCESS,
  results,
  chitRecords
});

export const setChitsCallTimeFailure = (error) => ({
  type: actionTypes.SET_CHITS_CALL_TIME_FAILURE,
  error
});

export const assignExtraToProductionUnitRequest = (chitId, productionUnitId) => ({
  type: actionTypes.ASSIGN_EXTRA_TO_PRODUCTION_UNIT_REQUEST,
  chitId,
  productionUnitId
});

export const assignExtraToProductionUnitSuccess = (chitRecord) => ({
  type: actionTypes.ASSIGN_EXTRA_TO_PRODUCTION_UNIT_SUCCESS,
  chitRecord
});

export const assignExtraToProductionUnitFailure = (error) => ({
  type: actionTypes.ASSIGN_EXTRA_TO_PRODUCTION_UNIT_FAILURE,
  error
});

export const assignExtrasToProductionUnitRequest = (chitIds, productionUnitId, productionId, date) => ({
  type: actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_REQUEST,
  chitIds,
  productionUnitId,
  productionId,
  date
});

export const assignExtrasToProductionUnitSuccess = (results, chitRecords) => ({
  type: actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_SUCCESS,
  results,
  chitRecords
});

export const assignExtrasToProductionUnitFailure = (error) => ({
  type: actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_FAILURE,
  error
});

export const addSupplementaryFeeToChitsRequest = (chitIds, paySchemeId, feeName, payRate, productionId, date) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_REQUEST,
  chitIds,
  paySchemeId,
  feeName,
  payRate,
  productionId,
  date
});

export const addSupplementaryFeeToChitsSuccess = (results, chitRecords) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_SUCCESS,
  results,
  chitRecords
});

export const addSupplementaryFeeToChitsFailure = (error) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_FAILURE,
  error
});

export const changeDailyRateOfChitsRequest = (chitIds, paySchemeId, rateName, productionId, date) => ({
  type: actionTypes.CHANGE_DAILY_RATE_OF_CHITS_REQUEST,
  chitIds,
  paySchemeId,
  rateName,
  productionId,
  date
});

export const changeDailyRateOfChitsSuccess = (results, chitRecords) => ({
  type: actionTypes.CHANGE_DAILY_RATE_OF_CHITS_SUCCESS,
  results,
  chitRecords
});

export const changeDailyRateOfChitsFailure = (error) => ({
  type: actionTypes.CHANGE_DAILY_RATE_OF_CHITS_FAILURE,
  error
});


export const setExtrasBookingDateDetailsRequest = (chitIds, bookingDateDetails, productionId, date, sendSMS = false, template = "") => ({
  type: actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_REQUEST,
  chitIds,
  bookingDateDetails,
  productionId,
  date,
  sendSMS,
  template
});

export const setExtrasBookingDateDetailsSuccess = (results, chitRecords) => ({
  type: actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_SUCCESS,
  results,
  chitRecords
});

export const setExtrasBookingDateDetailsFailure = (error) => ({
  type: actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_FAILURE,
  error
});

export const cancelChitsRequest = (chitIds, cancellationFeeType, productionId, date) => ({
  type: actionTypes.CANCEL_CHITS_REQUEST,
  chitIds,
  cancellationFeeType,
  productionId,
  date
});

export const cancelChitsSuccess = (results, chitRecords) => ({
  type: actionTypes.CANCEL_CHITS_SUCCESS,
  results,
  chitRecords
});

export const cancelChitsFailure = (error) => ({
  type: actionTypes.CANCEL_CHITS_FAILURE,
  error
});


export const setExtrasStatusesRequest = (chitIds, extraStatus, details, productionId, date) => ({
  type: actionTypes.SET_EXTRAS_STATUSES_REQUEST,
  chitIds,
  extraStatus,
  details,
  productionId,
  date
});

export const setExtrasStatusesSuccess = (results, chitRecords) => ({
  type: actionTypes.SET_EXTRAS_STATUSES_SUCCESS,
  results,
  chitRecords
});

export const setExtrasStatusesFailure = (error) => ({
  type: actionTypes.SET_EXTRAS_STATUSES_FAILURE,
  error
});


export const removeSupplementaryFeesRequest = (chitIds, productionId, date) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEES_REQUEST,
  chitIds,
  productionId,
  date
});

export const removeSupplementaryFeesSuccess = (results, chitRecords) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEES_SUCCESS,
  results,
  chitRecords
});

export const removeSupplementaryFeesFailure = (error) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEES_FAILURE,
  error
});

export const sendBreakdownEmailsRequest = (chitIds, productionId, date) => ({
  type: actionTypes.SEND_BREAKDOWN_EMAILS_REQUEST,
  chitIds,
  productionId,
  date
});

export const sendBreakdownEmailsSuccess = (results, chitRecords) => ({
  type: actionTypes.SEND_BREAKDOWN_EMAILS_SUCCESS,
  results,
  chitRecords
});

export const sendBreakdownEmailsFailure = (error) => ({
  type: actionTypes.SEND_BREAKDOWN_EMAILS_FAILURE,
  error
});

export const setChitsFilterValue = (key, value) => ({
  type: actionTypes.SET_CHITS_FILTER_VALUE,
  key,
  value
});

export const setChitsSelection = (chitIds, selected) => ({
  type: actionTypes.SET_CHITS_SELECTION,
  chitIds,
  selected
});

export const deselectAllChits = () => ({
  type: actionTypes.DESELECT_ALL_CHITS
});

export const showHideBulkActionsSetCallTimeWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_CALL_TIME_WINDOW,
  show
});

export const showHideBulkActionsAssignExtrasToProductionUnitWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_WINDOW,
  show
});

export const showHideBulkActionsAddSupplementaryFeeWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_ADD_SUPPLEMENTARY_FEE_WINDOW,
  show
});

export const showHideBulkActionsChangeDailyRateWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_CHANGE_DAILY_RATE_WINDOW,
  show
});

export const showHideBulkActionsSetBookingDateDetailsWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_BOOKING_DATE_DETAILS_WINDOW,
  show
});

export const showHideBulkActionsCancelChitsWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_CANCEL_CHITS_WINDOW,
  show
});

export const showHideBulkActionsSetExtrasStatusesWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_EXTRAS_STATUSES_WINDOW,
  show
});


export const showHideBulkActionsRemoveSupplementaryFeesWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_REMOVE_SUPPLEMENTARY_FEES_WINDOW,
  show
});

export const showHideBulkActionsSendBreakdownEmailsWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SEND_BREAKDOWN_EMAILS_WINDOW,
  show
});

export const showHideBulkActionsSetNote = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_NOTES,
  show
})

export const showChitsByBulkActionResult = (result) => ({
  type: actionTypes.SHOW_CHITS_BY_BULK_ACTION_RESULT,
  result
});



export const removeBulkActionsFeedback = () => ({
  type: actionTypes.REMOVE_BULK_ACTIONS_FEEDBACK
});

export const setChitsActiveTab = (tab, productionId, date) => ({
  type: actionTypes.SET_CHITS_ACTIVE_TAB,
  tab,
  productionId,
  date
});

export const resetHealthSurveyResultRequest = (chitId) => ({
  type: actionTypes.RESET_HEALTH_SURVEY_RESULT_REQUEST,
  chitId,
});

export const resetHealthSurveyResultSuccess = (chitRecord) => ({
  type: actionTypes.RESET_HEALTH_SURVEY_RESULT_SUCCESS,
  chitRecord
});

export const resetHealthSurveyResultFailure = (error) => ({
  type: actionTypes.RESET_HEALTH_SURVEY_RESULT_FAILURE,
  error
});

export const getHealthSurveyResultDetailsRequest = (chitId) => ({
  type: actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_REQUEST,
  chitId,
});

export const getHealthSurveyResultDetailsSuccess = (healthSurveyResultDetails) => ({
  type: actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_SUCCESS,
  healthSurveyResultDetails
});

export const getHealthSurveyResultDetailsFailure = (error) => ({
  type: actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_FAILURE,
  error
});

export const getExtrasDocumentsRequest = (productionId, date) => ({
  type: actionTypes.GET_EXTRAS_DOCUMENTS_REQUEST,
  productionId,
  date
});

export const getExtrasDocumentsSuccess = (extrasDocuments) => ({
  type: actionTypes.GET_EXTRAS_DOCUMENTS_SUCCESS,
  extrasDocuments
});

export const getExtrasDocumentsFailure = (error) => ({
  type: actionTypes.GET_EXTRAS_DOCUMENTS_FAILURE,
  error
});



export const setProductionNotes = (productionId, notes) => ({
  type: actionTypes.SET_PRODUCTION_NOTES,
  ...{ productionId, notes }
})

export const setProductionNotesSuccess = (productionId, notes) => ({
  type: actionTypes.SET_PRODUCTION_NOTES_SUCCESS,
  ...{ productionId, notes }
})

export const setProductionNotesFailure = (error) => ({
  type: actionTypes.SET_PRODUCTION_NOTES_FAILURE,
  error
})

export const setProductionDayNotes = (productionDayId, notes) => ({
  type: actionTypes.SET_PRODUCTION_DAY_NOTES,
  ...{ productionDayId, notes }
})
export const setProductionDayNotesSuccess = (productionDayId, notes) => ({
  type: actionTypes.SET_PRODUCTION_DAY_NOTES_SUCCESS,
  ...{ productionDayId, notes }
})

export const setProductionDayNotesFailure = (error) => ({
  type: actionTypes.SET_PRODUCTION_DAY_NOTES_FAILURE,
  error
})


export const setChitIndividualNotesRequest = (chitId, generalNotes) => ({
  type: actionTypes.SET_INDIVIDUAL_CHIT_NOTES,
  ...{ chitId, generalNotes }
})

export const setChitIndividualNotesRequestSuccess = (chitId, generalNotes) => ({
  type: actionTypes.SET_INDIVIDUAL_CHIT_NOTES_SUCCESS,
  ...{ chitId, generalNotes }
})

export const setChitIndividualNotesRequestFailure = (error) => ({
  type: actionTypes.SET_INDIVIDUAL_CHIT_NOTES_FAILURE,
  error
})


export const setBulkChitNotesRequest = (productionId, date, chitIds, generalNotes) => ({
  type: actionTypes.SET_BULK_CHIT_NOTES,
  ...{ chitIds, generalNotes, productionId, date }
})

export const setBulkChitNotesRequestSuccess = (bulkActionsResults) => ({
  type: actionTypes.SET_BULK_CHITS_NOTES_SUCCESS,
  ...{ bulkActionsResults }
})

export const setBulkChitNotesRequestFailure = (error) => ({
  type: actionTypes.SET_BULK_CHITS_NOTES_FAILURE,
  error
})


export const showHideChitLevelTravelNotes = (chitId) => {
  return {
    type: actionTypes.SHOW_HIDE_CHIT_LEVEL_TRAVEL_NOTES,
    chitId,
  }
}

export const setChitLevelTravelNotesRequest = (chitId, data) => ({
  type: actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_REQUEST,
  chitId, data

})

export const setChitLevelTravelNotesSuccess = (chitTravelInfoResponse) => ({
  type: actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_SUCCESS,
  chitTravelInfoResponse

})

export const setChitLevelTravelNotesFailure = (error) => ({
  type: actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_FAILURE,
  error
})


export const showHideBulkActionsSetTravelNotes = (show) => ({
  type: actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_TRAVEL_NOTES,
  show

})

export const setBulkChitTravelNotes = (chitIds, travelInfo) => ({
  type: actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_REQUEST,
  chitIds, travelInfo

})

export const setBulkChitTravelNotesSuccess = (results, travelInfo) => ({
  type: actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_SUCCESS,
  results, travelInfo
})

export const setBulkChitTravelNotesFailure = (error) => ({
  type: actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_FAILURE,
  error,
})

export const setProductionDayDataExportRequest = (payload) => ({
  type: actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_REQUEST,
  payload

})

export const setProductionDayDataExportSucess = () => ({
  type: actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_SUCCESS,
})

export const setProductionDayDataExportFailure = (error) => ({
  type: actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_FAILURE,
  error
})

export const setShowHideBulkSmsModal = (show) => ({
  type: actionTypes.SET_SHOW_HIDE_SMS_WINDOW,
  show,
})

export const sendingSMS = (payload) => ({
  type: actionTypes.SEND_SMS,
  payload
})

export const sendingSMSFailure = (error) => ({
  type: actionTypes.SEND_SMS_FAILURE,
  error
})

export const sendingSMSSuccess = (results, chitRecords) => ({
  type: actionTypes.SEND_SMS_SUCCESS,
  results,
  chitRecords
})


export const showHideProctionDayLocation = (status) => ({
  type: actionTypes.SET_HIDE_SHOW_PRODUCTION_DAY_LOCATION_WINDOW,
  status
})

export const setProductionDayLocationRequest = (data) => ({
  type: actionTypes.SET_PRODUCTION_DAY_LOCACTION_REQUEST,
  data
})

export const setProductionDayLocationSuccess = () => ({
  type: actionTypes.SET_PRODCUTION_DAY_LOCATION_SUCCESS
})

export const setProductionDayLocationFailure = (error) => ({
  type: actionTypes.SET_PRODCUTION_DAY_LOCATION_FAILURE,
  error
})

export const setProductionDayRelease = (productionDayId) => ({
  type: actionTypes.SET_PRODUCTION_DAY_RELEASE_REQUEST,
  productionDayId,
}
)

export const setProductionDayReleaseSuccess = (updatedCurrentProductionDayData) => ({
  type: actionTypes.SET_PRODUCTION_DAY_RELEASE_SUCCESS,
  updatedCurrentProductionDayData,

})


export const setProductionDayReleaseFailure = (error) => ({
  type: actionTypes.SET_PRODUCTION_DAY_RELEASE_FAILURE,
  error,
})

export const showHideCallDetailsWindow = (show) => ({
  type: actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_WINDOW,
  show,
})

export const setChitCallDetailsResponseRequest = (chitId, result) => ({
  type: actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_REQUEST,
  chitId, result
})

export const setChitCallDetailsResponseSuccess = (updatedChit) => ({
  type: actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_SUCCESS,
  updatedChit,
})

export const setChitCallDetailsResponseFailure = (error) => ({
  type: actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_FAILURE,
  error
})




