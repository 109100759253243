import {
  MFA_STATUS_ENABLED,
  MFA_STATUS_NOT_ENABLED, MFA_STATUS_UNKNOWN,
  USER_STATUS_ACTIVE,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_PENDING_EMAIL_VERIFICATION
} from './constants';
import {USER_ROLE_AD, USER_ROLE_ADMIN} from "../app/constants";

export const getUsers = state => Object.values(state.app.users);

export const getUserStatusLabel = status => {
  const statusToLabel = {
    [USER_STATUS_PENDING_EMAIL_VERIFICATION]: 'Pending Email Verification',
    [USER_STATUS_ACTIVE]: 'Active',
    [USER_STATUS_DEACTIVATED]: 'Deactivated',
  }

  return statusToLabel[status];
};

export const getUserRoles = () => [USER_ROLE_AD, USER_ROLE_ADMIN];

export const getUserRoleLabel = role => {
  const roleToLabel = {
    [USER_ROLE_ADMIN]: 'Admin',
    [USER_ROLE_AD]: 'Ad',
  };

  return roleToLabel[role];
}

export const getMfaStatusLabel = status => {
  const statusToLabel = {
    [MFA_STATUS_NOT_ENABLED]: 'Not Enabled',
    [MFA_STATUS_ENABLED]: 'Enabled',
    [MFA_STATUS_UNKNOWN]: 'Unknown',
  };

  return statusToLabel[status];
}

export const getUserStatusCssClass = status => {
  switch (status) {
    case USER_STATUS_PENDING_EMAIL_VERIFICATION:
      return 'text-secondary';
    case USER_STATUS_ACTIVE:
      return 'text-success';
    case USER_STATUS_DEACTIVATED:
      return 'text-danger';
    default:
      return 'text-primary';
  }
}
