import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';
import sha1 from 'js-sha1'
import {delay} from '../../lib/utils';

class PasswordSetter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordStrength: 0,
      checkingPasswordPwned: false,
      passwordPwned: false,
      checkPasswordPwnedError: null
    }
    this.axios = axios.create();
  }

  handlePasswordStrengthChange = strength => {
    this.setState({
      passwordStrength: strength
    })
  }

  isPasswordWeak = () => this.state.passwordStrength < 3;


  checkPasswordPwned = password => {

    this.setState({
      checkingPasswordPwned: true,
      checkPasswordPwnedError: null,
      passwordPwned: false
    })

    // Avoid hitting pwnedpasswors.com rate limit (1 call per 1500 milliseconds) in case when user clicks the button to fast.
    return delay(1500)
      .then(() => {
        return this.doCheckPasswordPwned(password);
      })
      .then(pwned => {
        this.setState({
          passwordPwned: pwned,
          checkingPasswordPwned: false,
          checkPasswordPwnedError: null
        });
        return pwned;
      })
      .catch(error => {
        console.log(`Cannot check if password pwned, error: ${error}`)
        this.setState({
          checkingPasswordPwned: false,
          checkPasswordPwnedError: 'Cannot check if password was exposed in data breaches.'
        })
      })
  }

  doCheckPasswordPwned = password => {
    // Algorithm is documented here https://haveibeenpwned.com/API/v3#PwnedPasswords
    const pwdSha = sha1(password);
    const shaPrefix = pwdSha.substring(0, 5);
    const shaSuffix = pwdSha.substring(5);

    return axios.get(`https://api.pwnedpasswords.com/range/${shaPrefix}`).then((response) => {
      const pwnedShaSuffixes = response.data;
      return pwnedShaSuffixes.includes(shaSuffix.toUpperCase());
    });
  }

  onFormSubmit = (data) => {
    if (this.isPasswordWeak()) {
      return false;
    }
    const { password } = data;
    this.checkPasswordPwned(password).then(pwned => {
      if (!pwned) {
        this.props.setPasswordRequest(this.props.passwordResetCode, password);
      }
    })
  }

  render() {
    const schema = Yup.object().shape({
      password: Yup.string().required('Required'),
      passwordRepeat: Yup.string().required('Required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    let initialValues = {
      password: '',
      passwordRepeat: ''
    };

    const {settingPassword, setPasswordError} = this.props;

    const setPasswordText = settingPassword || this.state.checkingPasswordPwned ? 'Setting password...' : 'Set password';

    const setPasswordButtonDisabled = this.props.settingPassword || this.state.checkingPasswordPwned;

    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={this.onFormSubmit.bind(this)}
          render={({errors, status, touched, isSubmitting, values, isValid, dirty}) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Enter new password:</label>
                <Field type="password" name="password" className="form-control" id="password"/>
                {(touched.password || values.password) &&
                <PasswordStrengthBar password={values.password} minLength={8}
                                     onChangeScore={this.handlePasswordStrengthChange.bind(this)}
                                     scoreWords={['weak', 'weak', 'weak', 'good', 'strong']}
                />
                }
              </div>
              <div className="form-group">
                <label htmlFor="email">Repeat password:</label>
                <Field type="password" name="passwordRepeat" className="form-control" id="passwordRepeat"/>
                <ErrorMessage name="passwordRepeat" />
              </div>
              {setPasswordError &&
              <div className="alert alert-danger" role="alert">
                {setPasswordError}
              </div>
              }
              {this.state.checkPasswordPwnedError &&
              <div className="alert alert-danger" role="alert">
                {this.state.checkPasswordPwnedError}
              </div>
              }
              {this.state.passwordPwned &&
              <div className="alert alert-danger" role="alert">
                This password has previously appeared in a data breach and should never be used, please try another one.
              </div>
              }
              <Button variant="primary" disabled={setPasswordButtonDisabled} type="submit">
                {setPasswordText}
              </Button>
            </Form>
          )}
        >
        </Formik>
      </React.Fragment>
    )
  }
}

PasswordSetter.propTypes = {
  setPasswordRequest: PropTypes.func.isRequired,
  settingPassword: PropTypes.bool.isRequired,
  setPasswordError: PropTypes.string,
  passwordResetCode: PropTypes.string.isRequired
}

export default PasswordSetter;