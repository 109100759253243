import * as React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {dateToUveFormat} from '../../../lib/dateTimeUtils';
import {constants as productionDayConstants} from '../../../production-day';
import ProductionDayRowUnits from './ProductionDayRowUnits';
import * as constants from '../../constants';
import * as productionDaysGetters from '../../../production-day/getters';
import * as getters from "../../getters";
import ProductionDayRowActionsSync from "./ProductionDayRowActionsSync";
import ProductionDayRowActionsQrCode from "./ProductionDayRowActionsQrCode";

class ProductionDayRow extends React.Component {

  constructor(props) {
    super(props);
  }

  getStatusLabelClass = (status) => {
    switch (status) {
      case productionDayConstants.PRODUCTION_DAY_STATUS_ACTIVE:
        return 'text-success';
      case productionDayConstants.PRODUCTION_DAY_STATUS_INACTIVE:
        return 'text-secondary';
      case productionDayConstants.PRODUCTION_DAY_STATUS_LOCKED:
        return 'text-danger';
      case productionDayConstants.PRODUCTION_DAY_STATUS_WRAPPED_UP:
        return 'text-danger';
      default:
        return 'text-primary';
    }
  };

  syncProductionDay = (date) => {
    this.props.importProductionDaysRequest(
      this.props.production.id,
      date,
      null,
      constants.IMPORT_TYPE_SPECIFIC_DAY,
      `Day ${date} has been successfully synced`
    );
  };


  render = () => {
    const production = this.props.production;
    const day = this.props.day;

    const statusLabelClass = this.getStatusLabelClass(day.status);

    const syncDisabled = getters.isSyncDisabled(this.props.production);

    return (
      <React.Fragment>
        <tr style={{width: '20%'}}
            className={`production-days-table-date ${this.props.highlighted ? 'highlighted-row' : ''}`}
            ref={this.props.refProp}>
          <td style={{width: '20%'}} className="production-days-table-date">
            <Link to={`/productions/${production.id}/date/${day.date}`}>
              <div>
                {dateToUveFormat(day.date)}
              </div>
            </Link>
          </td>
          <td style={{width: '10%'}}>
            <div>
              {this.props.chitsTotalsByUnits.chitsTotal}
            </div>
          </td>
          <td style={{width: '40%'}}>
            <div>
              <ProductionDayRowUnits {...this.props} />
            </div>
          </td>
          <td style={{width: '20%'}}>
            <div>
                <span className={statusLabelClass}>
                  {productionDaysGetters.getProductionDayStatusLabel(this.props.day.status)}
                </span>
            </div>
          </td>
          <td style={{width: '10%'}}>
            <div style={{display: 'flex'}}>
              <div>
                <ProductionDayRowActionsSync day={day} syncDisabled={syncDisabled}
                                             syncProductionDay={this.syncProductionDay}/>
              </div>
              <div style={{marginLeft: '5px'}}>
                <ProductionDayRowActionsQrCode {...this.props} productionDay={day} action="CHECK_EXTRA_IN" />
              </div>
              <div style={{marginLeft: '5px'}}>
                <ProductionDayRowActionsQrCode {...this.props} productionDay={day} action="CHECK_EXTRA_OUT" />
              </div>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };
}

ProductionDayRow.propTypes = {
  production: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  chitsTotalsByUnits: PropTypes.object.isRequired,
  highlighted: PropTypes.bool,
  importProductionDaysRequest: PropTypes.func.isRequired,
  refProp: PropTypes.object
};

export default ProductionDayRow;
