import { arrayToMap } from '../lib/arrayUtils';
import * as actionTypes from './actionTypes';
import ProductionDayContainer from './components/ProductionDayContainer';
import * as constants from './constants';
import { updateCurrentProductionDayGiveNewState } from './setters';

const initialState = {
  //Call Details
  callDetailsType: undefined,
  callDetailsResponse: undefined,
  callDetailsDateSent: undefined,
  callDetailsTimeSent: undefined,
  callDetailsResponseDate: undefined,
  callDetailsResponseTime: undefined,
  callDetailsMessageModal: false,

  locations: null,
  location: null,
  isLoading: true,
  isLoaded: false,
  // Current production
  production: null,
  // Current production day id
  currentProductionDayId: null,
  // Production days map by production day id
  productionDays: {},
  // Current production days's rate card id
  currentProductionDayRateCardId: null,
  // AD id assigned to current production day
  adIdAssignedToCurrentProductionDay: null,
  // Production units of given production map by production unit id
  productionUnits: {},
  // Chits with related data map by chit id
  chitRecords: {},
  // Chits filter
  chitsFilter: {
    // example for keyword: "ho la"
    // logic:
    // (first name contains "ho" or last name contains "ho" or extra role name contains "ho")
    // AND
    // (first name contains "la" or last name contains "la" or extra role name contains "la")
    keywords: '',
    extraRoleIds: [],
    productionUnitIds: [],
    extraStatuses: [],
    activityTypes: [],
    productionDocumentsCompleted: null,
    healthSurveyStatuses: [],
    callTimeFrom: null,
    callTimeTo: null,
    travelValues: [],
    callDetails: null,
    transportationGroups: [],
    bulkActionResult: null
  },
  // chitId -> selected (not necessary includes all chits in case when they are not selected)
  idsOfSelectedChits: {},
  bulkActionsShowSetCallTimeWindow: false,
  bulkActionsShowAssignExtrasToProductionUnitWindow: false,
  bulkActionsShowAddSupplementaryFeeWindow: false,
  bulkActionsShowChangeDailyRateWindow: false,
  bulkActionsShowSetBookingDateDetailsWindow: false,
  bulkActionsShowCancelChitsWindow: false,
  bulkActionsShowSetExtrasStatusesWindow: false,
  bulkActionsShowRemoveSupplementaryFeesWindow: false,
  bulkActionsShowSendBreakdownEmailsWindow: false,
  bulkActionsShowSetNotes: false,
  bulkActionsShowSetTravelWindow: false,
  bulkSmsWindow: false,

  bulkActionsRunning: false,
  bulkActionsResults: {},
  latestBulkActionType: null,
  latestBulkActionParams: null,
  chitsActiveTab: constants.CHITS_TAB_BASE_INFO,
  selectedChitHealthSurveyResultDetails: null,
  extrasDocumentsLoaded: false,
  extrasDocuments: {},


  productionTranspotationGroupShowUpdateOrCreateWindow: false,
  productionTranspotationGroupUpdateOrCreateing: false,
  productionTranspotationGroupUpdateOrCreateError: false,
  productionTranspotationGroupUpdateOrCreateActiveId: 0,
  // Travel notes 
  productionDayShowHideTravelNotesChitId: 0,
  productionDayTravelNotesNewtworkCallRunning: false,
  productionDayTravelNotesError: null,
  // production day data export 
  productionDayDataExportRunning: false,
  productionDayDataExportError: null,
  // production day location set
  productionDayLocationShowUpdateOrCreateWindow: false,
  productionDaysetLocationRunning: false,
  productionDayLocationError: false,


  // Change current production day status
  productionDayChangeStatusShowHideWindow: false,
  productionDayChangeStatusChanging: false,
  productionDayChangeStatusError: false,

  // calldetailsupdateResponse

  productionDayshowCallDetailsResponseWindow: false,
  productionDayCallDetailsResponseNetworkCallRunning: false,
  ProductionDayCallDetailsResponseError: null,



};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CALL_DETAILS_MESSAGE_MODAL:
      return {
        ...state,
        callDetailsMessageModal: action.show,
      }
    case actionTypes.LOAD_CHIT_RECORDS_SUCCESS:
      return {
        ...state,
        chitRecords: action.chitRecords
      };
    case actionTypes.LOAD_PRODUCTION_DAY_AGGREGATED_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
        isLoading: false,
        isLoaded: true
      };
    case actionTypes.WRAP_UP_PRODUCTION_UNIT_ON_DAY_SUCCESS:
      const updatedDay = action.data;
      const updatedProductionDays = {
        ...state.productionDays,
        [updatedDay.id]: updatedDay
      };
      return {
        ...state,
        productionDays: updatedProductionDays
      };
    case actionTypes.SET_CHITS_FILTER_VALUE:
      return {
        ...state,
        chitsFilter: {
          ...state.chitsFilter,
          [action.key]: action.value
        }
      };
    case actionTypes.SET_CHIT_CALL_TIME_SUCCESS:
      const chitRecordWithUpdatedCallTime = action.chitRecord;
      const chitRecordsWithUpdatedCallTimeForSingleChit = {
        ...state.chitRecords,
        [chitRecordWithUpdatedCallTime.chit.id]: chitRecordWithUpdatedCallTime
      };
      return {
        ...state,
        chitRecords: chitRecordsWithUpdatedCallTimeForSingleChit
      };
    case actionTypes.ASSIGN_EXTRA_TO_PRODUCTION_UNIT_SUCCESS:
      const chitRecordWithUpdatedProductionUnit = action.chitRecord;
      const chitRecordsWithUpdatedProductionUnitForSingleChit = {
        ...state.chitRecords,
        [chitRecordWithUpdatedProductionUnit.chit.id]: chitRecordWithUpdatedProductionUnit
      };
      return {
        ...state,
        chitRecords: chitRecordsWithUpdatedProductionUnitForSingleChit
      };
    case actionTypes.RESET_HEALTH_SURVEY_RESULT_SUCCESS:
      const chitRecordWithUpdatedHealthSurvey = action.chitRecord;
      const chitRecordsWithUpdatedHealthSurveyForSingleChit = {
        ...state.chitRecords,
        [chitRecordWithUpdatedHealthSurvey.chit.id]: chitRecordWithUpdatedHealthSurvey
      };
      return {
        ...state,
        chitRecords: chitRecordsWithUpdatedHealthSurveyForSingleChit
      };
    case actionTypes.SET_CHITS_SELECTION:
      const updatedSelections = {
        ...state.idsOfSelectedChits,
      };
      action.chitIds.forEach(chitId => {
        updatedSelections[chitId] = action.selected
      });
      return {
        ...state,
        idsOfSelectedChits: updatedSelections
      };
    case actionTypes.DESELECT_ALL_CHITS:
      return {
        ...state,
        idsOfSelectedChits: {}
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_CALL_TIME_WINDOW:
      return {
        ...state,
        bulkActionsShowSetCallTimeWindow: action.show
      };

    case actionTypes.SHOW_HIDE_BULK_ACTIONS_ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_WINDOW:
      return {
        ...state,
        bulkActionsShowAssignExtrasToProductionUnitWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_ADD_SUPPLEMENTARY_FEE_WINDOW:
      return {
        ...state,
        bulkActionsShowAddSupplementaryFeeWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_CHANGE_DAILY_RATE_WINDOW:
      return {
        ...state,
        bulkActionsShowChangeDailyRateWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_BOOKING_DATE_DETAILS_WINDOW:
      return {
        ...state,
        bulkActionsShowSetBookingDateDetailsWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_CANCEL_CHITS_WINDOW:
      return {
        ...state,
        bulkActionsShowCancelChitsWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_EXTRAS_STATUSES_WINDOW:
      return {
        ...state,
        bulkActionsShowSetExtrasStatusesWindow: action.show
      };

    case actionTypes.SHOW_HIDE_BULK_ACTIONS_REMOVE_SUPPLEMENTARY_FEES_WINDOW:
      return {
        ...state,
        bulkActionsShowRemoveSupplementaryFeesWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SEND_BREAKDOWN_EMAILS_WINDOW:
      return {
        ...state,
        bulkActionsShowSendBreakdownEmailsWindow: action.show
      };
    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_NOTES: return {
      ...state,
      bulkActionsShowSetNotes: action.show
    }
    case actionTypes.SHOW_CHITS_BY_BULK_ACTION_RESULT:
      return {
        ...state,
        chitsByBulkActionResultFilter: action.result
      };
    case actionTypes.SET_CHITS_CALL_TIME_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_CALL_TIME,
        latestBulkActionParams: {
          callTime: action.callTime,
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.SET_CHITS_CALL_TIME_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowSetCallTimeWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.SET_CHITS_CALL_TIME_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowSetCallTimeWindow: false
      };
    case actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_PRODUCTION_UNIT,
        latestBulkActionParams: {
          productionUnitId: action.productionUnitId,
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowAssignExtrasToProductionUnitWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.ASSIGN_EXTRAS_TO_PRODUCTION_UNIT_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowAssignExtrasToProductionUnitWindow: false
      };
    case actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_SUPPLEMENTARY_FEE,
        latestBulkActionParams: {
          feeName: action.feeName,
          payRate: action.payRate,
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowAddSupplementaryFeeWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.ADD_SUPPLEMENTARY_FEE_TO_CHITS_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowAddSupplementaryFeeWindow: false
      };
    case actionTypes.CHANGE_DAILY_RATE_OF_CHITS_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_DAILY_RATE,
        latestBulkActionParams: {
          rateName: action.rateName,
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.CHANGE_DAILY_RATE_OF_CHITS_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowChangeDailyRateWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.CHANGE_DAILY_RATE_OF_CHITS_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowChangeDailyRateWindow: false
      };
    case actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_BOOKING_DATE_DETAILS,
        latestBulkActionParams: {
          bookingDateDetails: action.bookingDateDetails,
        }
      };
    case actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowSetBookingDateDetailsWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.SET_SHOW_HIDE_SMS_WINDOW: return {
      ...state,
      bulkSmsWindow: action.show,
    }
    case actionTypes.SEND_SMS: return {
      ...state,
       latestBulkActionType: constants.BULK_ACTION_TYPE_SEND_SMS,
       latestBulkActionParams: {
        productionId: action.payload.productionId,
        date: action.payload.date
      },
      bulkActionsRunning: true,

    }
    case actionTypes.SEND_SMS_SUCCESS: return {
      ...state,
      bulkActionsRunning: false,
      bulkActionsResults: action.results,
      chitRecords: action.chitRecords,
      bulkSmsWindow: false,
      idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))



    }
    case actionTypes.SEND_SMS_FAILURE: return {
      ...state,
      bulkActionsRunning: false,
    }
    case actionTypes.CANCEL_CHITS_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_CHITS_CANCELLATION,
        latestBulkActionParams: {
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.CANCEL_CHITS_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowCancelChitsWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.CANCEL_CHITS_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowCancelChitsWindow: false
      };
    case actionTypes.SET_EXTRAS_STATUSES_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_SET_EXTRAS_STATUSES,
        latestBulkActionParams: {
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.SET_EXTRAS_STATUSES_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowSetExtrasStatusesWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.SET_EXTRAS_STATUSES_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowSetExtrasStatusesWindow: false
      };
    case actionTypes.SET_BULK_CHIT_NOTES:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_SEND_CHIT_NOTES,
        latestBulkActionParams: {
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.SET_BULK_CHITS_NOTES_SUCCESS: return {
      ...state,
      bulkActionsRunning: false,
      bulkActionsShowSetNotes: false,
      bulkActionsResults: action.bulkActionsResults,
      chitRecords: {
        ...state.chitRecords,
        ...Object.keys(action.bulkActionsResults).reduce((acc, chitId) => {
          const generalNotes = action.bulkActionsResults[chitId].generalNotes || state.chitRecords[chitId].chit.generalNotes;
          acc[chitId] = {
            ...state.chitRecords[chitId],
            chit: {
              ...state.chitRecords[chitId].chit,
              generalNotes
            }
          }

          return acc;
        }, {})




        // latestBulkActionType: "",
        // latestBulkActionParams: {}
      }
    }
    case actionTypes.SET_BULK_CHITS_NOTES_FAILURE: return {
      ...state,
      bulkActionsRunning: false,
      bulkActionsShowSetNotes: false,
      bulkActionsResults: {},
    }

    case actionTypes.SET_EXTRAS_BOOKING_DATE_DETAILS_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowSetBookingDateDetailsWindow: false
      };
    case actionTypes.REMOVE_SUPPLEMENTARY_FEES_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_REMOVE_SUPPLEMENTARY_FEES,
        latestBulkActionParams: {
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.REMOVE_SUPPLEMENTARY_FEES_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowRemoveSupplementaryFeesWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.REMOVE_SUPPLEMENTARY_FEES_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowRemoveSupplementaryFeesWindow: false
      };

    case actionTypes.SEND_BREAKDOWN_EMAILS_REQUEST:
      return {
        ...state,
        bulkActionsRunning: true,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
        latestBulkActionType: constants.BULK_ACTION_TYPE_SEND_BREAKDOWN_EMAILS,
        latestBulkActionParams: {
          productionId: action.productionId,
          date: action.date
        }
      };
    case actionTypes.SEND_BREAKDOWN_EMAILS_SUCCESS:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: action.results,
        bulkActionsShowSendBreakdownEmailsWindow: false,
        chitRecords: action.chitRecords,
        idsOfSelectedChits: Object.fromEntries(Object.entries(action.results).map(([chitId, result]) => [chitId, true]))
      };
    case actionTypes.SEND_BREAKDOWN_EMAILS_FAILURE:
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowSendBreakdownEmailsWindow: false
      };
    case actionTypes.REMOVE_BULK_ACTIONS_FEEDBACK:
      return {
        ...state,
        bulkActionsResults: {},
        chitsFilter: {
          ...state.chitsFilter,
          bulkActionResult: null
        },
      };
    case actionTypes.SET_CHITS_ACTIVE_TAB:
      return {
        ...state,
        chitsActiveTab: action.tab
      }
    case actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_REQUEST:
      return {
        ...state,
        selectedChitHealthSurveyResultDetails: null
      }
    case actionTypes.GET_HEALTH_SURVEY_RESULT_DETAILS_SUCCESS:
      return {
        ...state,
        selectedChitHealthSurveyResultDetails: action.healthSurveyResultDetails
      };
    case actionTypes.GET_EXTRAS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        extrasDocumentsLoaded: true,
        extrasDocuments: action.extrasDocuments
      }

    case actionTypes.SET_PRODUCTION_DAY_NOTES_SUCCESS: return {
      ...state,
      productionDays: {
        ...state.productionDays,
        [action.productionDayId]: {
          ...state.productionDays[action.productionDayId],
          notes: action.notes
        }
      }
    }
    case actionTypes.SET_INDIVIDUAL_CHIT_NOTES_SUCCESS: return {
      ...state,

      chitRecords: {
        ...state.chitRecords,
        [action.chitId]: {
          ...state.chitRecords[action.chitId],
          chit: {
            ...state.chitRecords[action.chitId].chit,
            generalNotes: action.generalNotes
          }
        }

      }
    }

    case actionTypes.SET_INDIVIDUAL_CHIT_NOTES_FAILURE: return {
      ...state,
      bulkActionsRunning: false,
      bulkActionsShowSetNotes: false,
      bulkActionsResults: {},
    }

    case actionTypes.SHOW_HIDE_CHIT_LEVEL_TRAVEL_NOTES: {
      if (state.productionDayShowHideTravelNotesChitId != action.chitId) {
        return {
          ...state,
          productionDayShowHideTravelNotesChitId: action.chitId,
        }
      } return {
        ...state,
        productionDayShowHideTravelNotesChitId: 0
      }
    }
    case actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_REQUEST: {
      return {
        ...state,
        productionDayTravelNotesNewtworkCallRunning: true,
      }
    }
    case actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_SUCCESS: {
      return {
        ...state,
        productionDayTravelNotesNewtworkCallRunning: false,
        productionDayShowHideTravelNotesChitId: 0,
        chitRecords: {
          ...state.chitRecords,
          [action.chitTravelInfoResponse.id]: {
            ...state.chitRecords[action.chitTravelInfoResponse.id],
            chit: {
              ...action.chitTravelInfoResponse,

            }
          }
        }
      }
    }

    case actionTypes.SET_CHIT_LEVEL_TRAVEL_NOTES_FAILURE: {

      return {
        ...state,
        productionDayTravelNotesNewtworkCallRunning: false,

      }

    }

    case actionTypes.SHOW_HIDE_BULK_ACTIONS_SET_TRAVEL_NOTES: {
      return {
        ...state,
        bulkActionsShowSetTravelWindow: action.show
      }
    }

    case actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_REQUEST: {
      return {
        ...state,
        bulkActionsRunning: true
      }
    }
    case actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_SUCCESS: {
      const updatedselectedIds = {};
      const updatedBultActionResult = {};
      return {
        ...state,
        bulkActionsRunning: false,

        bulkActionsShowSetTravelWindow: false,

        chitRecords: {
          ...state.chitRecords,
          ...(action.results).reduce((acc, chitTravelInfo) => {
            updatedselectedIds[chitTravelInfo.chitId] = chitTravelInfo.result == constants.BULK_ACTION_RESULT_SUCCESS ? true : false;

            const travelInfo = chitTravelInfo.result == constants.BULK_ACTION_RESULT_SUCCESS && action.travelInfo || state.chitRecords[chitTravelInfo.chitId].chit.travelInfo;
            updatedBultActionResult[chitTravelInfo.chitId] = { ...chitTravelInfo, travelInfo }
            acc[chitTravelInfo.chitId] = {
              ...state.chitRecords[chitTravelInfo.chitId],
              chit: {
                ...state.chitRecords[chitTravelInfo.chitId].chit,
                travelInfo
              }
            }

            return acc;
          }, {})

        },
        bulkActionsResults: updatedBultActionResult,
        idsOfSelectedChits: updatedselectedIds,


      }
    }

    case actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_FAILURE: {
      return {
        ...state,
        bulkActionsRunning: false,
        bulkActionsResults: {},
        bulkActionsShowSetTravelWindow: false
      }
    }

    case actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_REQUEST: return {
      ...state,
      productionDayDataExportRunning: true
    }

    case actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_SUCCESS: return {
      ...state,
      productionDayDataExportRunning: false

    }
    case actionTypes.SET_BULK_ACTIONS_SET_TRAVEL_NOTES_FAILURE: return {
      productionDayDataExportError: action.error,
      productionDayDataExportRunning: false


    }

    case actionTypes.SET_HIDE_SHOW_PRODUCTION_DAY_LOCATION_WINDOW: return {

      ...state,
      productionDayLocationShowUpdateOrCreateWindow: action.status

    }
    case actionTypes.SET_PRODUCTION_DAY_LOCACTION_REQUEST: return {
      ...state,
      productionDaysetLocationRunning: true,
    }
    case actionTypes.SET_PRODCUTION_DAY_LOCATION_SUCCESS: return {
      ...state,
      productionDaysetLocationRunning: false,
      productionDayLocationShowUpdateOrCreateWindow: false
    }
    case actionTypes.SET_PRODCUTION_DAY_LOCATION_FAILURE: return {
      ...state,
      productionDaysetLocationRunning: false,
      productionDayLocationError: action.error

    }
    case actionTypes.SET_PRODUCTION_DAY_RELEASE_REQUEST: {


      return {
        ...state,
        productionDayChangeStatusChanging: true,
      }
    }
    case actionTypes.SET_PRODUCTION_DAY_RELEASE_SUCCESS: {
      return {
        ...updateCurrentProductionDayGiveNewState(state, action.updatedCurrentProductionDayData),
        productionDayChangeStatusChanging: false
      }
    }
    case actionTypes.SET_PRODUCTION_DAY_DATA_EXPORT_FAILURE: {
      return {
        ...state,
        productionDayChangeStatusChanging: false,
      }

    }
    case actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_WINDOW: {
      return {
        ...state,
        productionDayshowCallDetailsResponseWindow: action.show,


      }
    }
    case actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_REQUEST: {
      return {
        ...state,
        productionDayCallDetailsResponseNetworkCallRunning: true,

      }
    }

    case actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_SUCCESS: {
      return {
        ...state,
        productionDayCallDetailsResponseNetworkCallRunning: false,
        productionDayshowCallDetailsResponseWindow: false,
        chitRecords: {
          ...state.chitRecords,
          [action.updatedChit.id]: {
            ...state.chitRecords[action.updatedChit.id],
            chit: {
              ...action.updatedChit,

            }
          }
        }

      }
    }

    case actionTypes.SET_CHIT_CALL_DETAILS_RESPONSE_FAILURE: {
      return {
        ...state,
        productionDayCallDetailsResponseNetworkCallRunning: false,
      }
    }

    default:
      return state;
  }
};

export default reducer;