import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import FormField from './FormField';

function FormFields(props) {
  const { fields } = props;

  return <React.Fragment></React.Fragment >;
}

// {
//   /* {renderFeild(field, values[field.name], errors[field.name], handleChange, touched[field.name])} */
// }

FormFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default FormFields;
