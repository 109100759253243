import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import PaySchemeDetails from './PaySchemeDetails';
import './PaySchemeDetails.css';

class PaySchemeDetailsModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
        <Modal show={this.props.show} onHide={this.props.onClose.bind(this)} size="lg" backdrop="static" className="pay-scheme-details-modal">
          <Modal.Header closeButton>
            <Modal.Title>{this.props.payScheme.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PaySchemeDetails payScheme={this.props.payScheme}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.props.onClose.bind(this)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }
}

PaySchemeDetailsModal.propTypes = {
  payScheme: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PaySchemeDetailsModal;
