import React from 'react';
import PropTypes from 'prop-types';
import ChitRecord from './ChitRecord';
import { Col, Row } from 'react-bootstrap';
import ProductionUnitOnDayStatus from './ProductionUnitOnDayStatus';
import { Link } from 'react-router-dom';
import WrapUpProductionUnit from './WrapUpProductionUnit';
import GenerateProductionUnitCurrentReport from './GenerateProductionUnitCurrentReport';
import ProductionDayLocation from './ProductionDayLocation';

const ProductionUnitOnDay = (props) => {
  const chitRows = props.filteredChitRecords.map((chitRecord) => {
    const selected = chitRecord.chit.id in props.idsOfSelectedChits && props.idsOfSelectedChits[chitRecord.chit.id];
    const bulkActionResult = props.bulkActionsResults[chitRecord.chit.id];
    return (
      <ChitRecord
        key={chitRecord.chit.id}
        productionId={chitRecord.chit.productionId}
        productionDate={chitRecord.chit.date}
        record={chitRecord}
        productionUnits={props.productionUnits}
        setChitCallTimeRequest={props.setChitCallTimeRequest}
        assignExtraToProductionUnitRequest={props.assignExtraToProductionUnitRequest}
        setChitsSelection={props.setChitsSelection}
        selected={selected}
        bulkActionResult={bulkActionResult}
        {...props}
      />
    );
  });

  return (
    <div className="chits-by-production-unit">
      <div className="card" style={{ marginTop: '10px' }}>
        <div className="card-header">
          <Row>
            <Col md={2}>
              <Link to={`/productions/${props.productionId}/units/${props.productionUnit.id}`}>
                {props.productionUnit.name}
              </Link>
            </Col>
            <Col md={5}></Col>
            <Col md={5}>
              <div className="d-flex justify-content-end">
                <span className="px-1">
                  <ProductionDayLocation {...props} />
                </span>

                {props.closedProductionUnitOnDay && (
                  <div style={{ textAlign: 'right' }}>
                    <ProductionUnitOnDayStatus {...props} />
                  </div>
                )}
                {!props.closedProductionUnitOnDay && (
                  <div style={{ textAlign: 'right' }}>
                    <WrapUpProductionUnit
                      {...props}
                      chitRecordsOfProductionUnit={props.chitRecordByProductionUnit[props.productionUnit.id]}
                    />
                  </div>
                )}
                <span className="px-1">
                  <GenerateProductionUnitCurrentReport {...props} productionUnit={props.productionUnit} />
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body chits-table-card">
          <div className="table-responsive" style={{ overflow: 'visible' }}>
            <table className="table card-table table-striped chits-table">
              <tbody>{chitRows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductionUnitOnDay.propTypes = {
  productionId: PropTypes.number.isRequired,
  filteredChitRecords: PropTypes.array.isRequired,
  idsOfSelectedChits: PropTypes.object.isRequired,
  bulkActionsResults: PropTypes.object.isRequired,
  productionUnit: PropTypes.object.isRequired,
  closedProductionUnitOnDay: PropTypes.object,
  chitRecordByProductionUnit: PropTypes.object.isRequired,
  chitsActiveTab: PropTypes.string.isRequired,
};

export default ProductionUnitOnDay;
