import React from 'react';
import PropTypes from 'prop-types';

class ProductionDaysTableHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <tr>
        <th>Date</th>
        <th>Chits</th>
        <th>Units</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    )
  }
}

ProductionDaysTableHeader.propTypes = {
};

export default ProductionDaysTableHeader;

