import * as actionTypes from './actionTypes'

export const loadChitDataRequest = (chitId) => ({
  type: actionTypes.LOAD_CHIT_DATA_REQUEST,
  chitId
});

export const loadChitDataSuccess = (chitData, productionData, chitsOfExtraInProduction) => ({
  type: actionTypes.LOAD_CHIT_DATA_SUCCESS,
  chitData,
  productionData,
  chitsOfExtraInProduction
});

export const loadChitDataFailure = (error) => ({
  type: actionTypes.LOAD_CHIT_DATA_FAILURE,
  error
});

export const showHideSetExtraStatusWindow = show => ({
  type: actionTypes.SHOW_HIDE_SET_EXTRA_STATUS_WINDOW,
  show
});

export const showHideSetCheckOutTimeWindow = show => ({
  type: actionTypes.SHOW_HIDE_SET_CHECK_OUT_TIME_WINDOW,
  show
});

export const setExtraStatusRequest = (data) => ({
  type: actionTypes.SET_EXTRA_STATUS_REQUEST,
  data
});

export const setExtraStatusSuccess = (chitData) => ({
  type: actionTypes.SET_EXTRA_STATUS_SUCCESS,
  chitData,
});

export const setExtraStatusFailure = (error) => ({
  type: actionTypes.SET_EXTRA_STATUS_FAILURE,
  error
});

export const setCheckOutTimeRequest = (data) => ({
  type: actionTypes.SET_CHECK_OUT_TIME_REQUEST,
  data
});

export const setCheckOutTimeSuccess = (chitData) => ({
  type: actionTypes.SET_CHECK_OUT_TIME_SUCCESS,
  chitData,
});

export const setCheckOutTimeFailure = (error) => ({
  type: actionTypes.SET_CHECK_OUT_TIME_FAILURE,
  error
});

export const changeDailyRateRequest = (data) => ({
  type: actionTypes.CHANGE_DAILY_RATE_REQUEST,
  data
});

export const changeDailyRateSuccess = (chitData) => ({
  type: actionTypes.CHANGE_DAILY_RATE_SUCCESS,
  chitData,
});

export const changeDailyRateFailure = (error) => ({
  type: actionTypes.CHANGE_DAILY_RATE_FAILURE,
  error
});

export const addSupplementaryFeeRequest = (data) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_REQUEST,
  data
});

export const addSupplementaryFeeSuccess = (chitData) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_SUCCESS,
  chitData,
});

export const addSupplementaryFeeFailure = (error) => ({
  type: actionTypes.ADD_SUPPLEMENTARY_FEE_FAILURE,
  error
});

export const removeSupplementaryFeeRequest = (data) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEE_REQUEST,
  data
});

export const removeSupplementaryFeeSuccess = (chitData) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEE_SUCCESS,
  chitData,
});

export const removeSupplementaryFeeFailure = (error) => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_FEE_FAILURE,
  error
});

export const sendCheckInEmailRequest = (chitId) => ({
  type: actionTypes.SEND_CHECK_IN_EMAIL_REQUEST,
  chitId
});

export const sendCheckInEmailSuccess = () => ({
  type: actionTypes.SEND_CHECK_IN_EMAIL_SUCCESS
});

export const sendCheckInEmailFailure = (error) => ({
  type: actionTypes.SEND_CHECK_IN_EMAIL_FAILURE,
  error
});

export const sendCheckOutEmailRequest = (chitId) => ({
  type: actionTypes.SEND_CHECK_OUT_EMAIL_REQUEST,
  chitId
});

export const sendCheckOutEmailSuccess = () => ({
  type: actionTypes.SEND_CHECK_OUT_EMAIL_SUCCESS
});

export const sendCheckOutEmailFailure = (error) => ({
  type: actionTypes.SEND_CHECK_OUT_EMAIL_FAILURE,
  error
});

export const deleteChitRequest = (data, history) => ({
  type: actionTypes.DELETE_CHIT_REQUEST,
  data,
  history
});

export const deleteChitSuccess = () => ({
  type: actionTypes.DELETE_CHIT_SUCCESS
});

export const deleteChitFailure = (error) => ({
  type: actionTypes.DELETE_CHIT_FAILURE,
  error
});