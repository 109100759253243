import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Button, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import PasswordResetLayout from './PasswordResetLayout';

class SendPasswordResetLink extends React.Component {

  sendPasswordResetLink = (data) => {
    const {email} = data;
    this.props.sendPasswordResetLinkRequest(email, this.props.sourceApp);
  }

  render() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required('Required'),
    });

    let initialValues = {
      email: '',
    };

    const {sendingPasswordResetLink, sendPasswordResetLinkError} = this.props;
    const sendText = sendingPasswordResetLink ? 'Sending password reset link...' : 'Send password reset link';

    return (
      <PasswordResetLayout>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={this.sendPasswordResetLink.bind(this)}
          render={({errors, status, touched, isSubmitting}) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Enter your email</label>
                <Field type="email" name="email" className="form-control" id="email"/>
                <ErrorMessage name="email" component="div"/>
              </div>
              {sendPasswordResetLinkError &&
              <div className="alert alert-danger" role="alert">
                {sendPasswordResetLinkError}
              </div>
              }
              <Button variant="primary" disabled={this.props.sendingPasswordResetLink} type="submit">
                {sendText}
              </Button>
            </Form>
          )}
        >
        </Formik>
      </PasswordResetLayout>
    )
  }
}

SendPasswordResetLink.propTypes = {
  sendPasswordResetLinkRequest: PropTypes.func.isRequired,
  sendingPasswordResetLink: PropTypes.bool.isRequired,
  sendPasswordResetLinkError: PropTypes.string,
  sourceApp: PropTypes.string
}


export default SendPasswordResetLink;