import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Config from '../../../../config/config.default';
import {getAccessToken} from '../../../../lib/auth';
import {randomId} from '../../../../lib/randomUtils';
import {Button} from "react-bootstrap";
import Select from "react-select";
import {dateToUveFormat} from "../../../../lib/dateTimeUtils";

class DownloadDocumentsReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadOption: 'ALL'
    }
  }

  handleOnDownloadOptionChange = option => {
    this.setState({
      downloadOption: option.value
    })
  }

  render = () => {

    const productionDaysOptions = this.props.productionDays.map(d => ({
      value: d.date,
      label: dateToUveFormat(d.date)
    }));
    const downloadOptions = [{value: 'ALL', label: 'Of whole production'}, ...productionDaysOptions]
    const currentDownloadOption = this.state.downloadOption
      ? downloadOptions.find(option => option.value === this.state.downloadOption)
      : null;

    return (
      <div>
        <div style={{float: 'left'}}>
          <Button style={{width: '300px'}} variant="info" onClick={this.props.downloadDocumentsReportRequest.bind(this, this.props.production.id, this.props.production.name, currentDownloadOption.value === 'ALL' ? null : currentDownloadOption.value)}>Download Completed Documents Report</Button>
        </div>
        <div style={{float: 'left', marginLeft: '5px', width: '300px'}}>
          <Select
              options={downloadOptions}
              placeholder=""
              value={currentDownloadOption}
              onChange={this.handleOnDownloadOptionChange.bind(this)}
          />
        </div>
        <div style={{clear: 'both'}}/>
      </div>
    )
  }
}

DownloadDocumentsReport.propTypes = {
  production: PropTypes.object.isRequired,
  productionDays: PropTypes.array.isRequired,
  downloadDocumentsReportRequest: PropTypes.func.isRequired
};

export default DownloadDocumentsReport;
