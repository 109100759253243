import React from 'react';

const ChitsHeaderHealthSurvey = props => {
  return (
    <React.Fragment>
      <td className="chit-column-health-survey-type">Survey Type</td>
      <td className="text-center chit-column-health-survey-status">Status</td>
      <td className="chit-column-health-survey-recorded-at">Recorded At</td>
      <td className="chit-column-health-survey-actions">
        Actions
      </td>
    </React.Fragment>
  )
};

ChitsHeaderHealthSurvey.propTypes = {};

export default ChitsHeaderHealthSurvey;
