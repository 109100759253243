import * as React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

class UserRecordActionsEdit extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        <Button variant="outline-primary" size="sm"
                onClick={this.props.showHideEditUserWindow.bind(this, true, this.props.user)}>Edit</Button>
      </div>
    )
  };
}

UserRecordActionsEdit.propTypes = {
  showHideEditUserWindow: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default UserRecordActionsEdit;
