import * as constants from './constants';
import { groupBy } from '../lib/arrayUtils';
import {
  EXTRA_STATUS_BOOKED, EXTRA_STATUS_CANCELLED,
  EXTRA_STATUS_CHECKED_IN,
  EXTRA_STATUS_CHECKED_OUT,
  EXTRA_STATUS_NO_SHOW,
  EXTRA_STATUS_RELEASED,
} from "./constants";

export const getCurrentProductionDay = state =>
  state.productionDay.productionDays[state.productionDay.currentProductionDayId];

export const getAdAssignedToCurrentProductionDay = state =>
  state.app.users[state.productionDay.adIdAssignedToCurrentProductionDay];


export const getChitRecords = state => Object.values(state.productionDay.chitRecords);

export const getProductionDayChitsTotal = state=> state.productionDay.chitsTotal
export const getChitRecordsGroupedByProductionUnit =
  state => groupBy(getChitRecords(state), (chitRecord) => chitRecord.productionUnit.id);

export const getTranspotationGroups = state => state.production.production.transportGroups;

/**
 * Get distinct extra roles from chits in production day.
 */
export const getProductionDayExtraRoles = state => {
  const distinctExtraRoles = {};
  const chitRecords = getChitRecords(state);
  chitRecords.forEach(chitRecord => {
    const extraRoleId = chitRecord.chit.extraWorkDetails.extraRoleId;
    if (!distinctExtraRoles[extraRoleId]) {
      distinctExtraRoles[extraRoleId] = chitRecords.find(record => record.extraRole.id === extraRoleId).extraRole;
    }
  });

  return Object.values(distinctExtraRoles);
};

/**
 * Some chits might be selected, but user can apply additional filters after chits selection and chits that are not
 * included in thew new filter will be still selected.
 * This function returns chits that are selected and included in the filter.
 */
export const getIdsOfSelectedChitsCrossedByFiltered = (filteredChitRecordsByChitId, idsOfSelectedChits) => {
  const idsOfSelectedChitsCrossedByFiltered = {};
  Object.entries(idsOfSelectedChits).forEach(([chitId, selected]) => {
    if (selected && chitId in filteredChitRecordsByChitId) {
      idsOfSelectedChitsCrossedByFiltered[chitId] = true;
    }
  });

  return idsOfSelectedChitsCrossedByFiltered;
};

export const doesBulActionsContainFailedChits = bulkActionResults => (
  Object.values(bulkActionResults).filter(chitResult => chitResult.result === constants.BULK_ACTION_RESULT_FAILURE).length > 0
);

export const getBulkActionsFailedChits = (bulkActionsResults) => {
  return Object.fromEntries(
    Object.entries(bulkActionsResults)
      .filter(([chitId, chitResult]) => chitResult.result === constants.BULK_ACTION_RESULT_FAILURE)
  );
};

export const getSupplementaryFeesCardItems = rateCardItems => {
  rateCardItems.filter(item => item.rateType === constants.RATE_TYPE_SUPPLEMENTARY_FEE);
};

export const getCurrentProductionDayDate = state => {
  const currentProductionDay = getCurrentProductionDay(state);
  return currentProductionDay ? currentProductionDay.date : null;
};

export const getProductionUnits = state => Object.values(state.productionDay.productionUnits);

export const getProductionDayStatusLabel = status => {
  const statusToLabel = {
    [constants.PRODUCTION_DAY_STATUS_ACTIVE]: 'ACTIVE',
    [constants.PRODUCTION_DAY_STATUS_INACTIVE]: 'INACTIVE',
    // For some reason in the legacy the label is 'CLOSED', but in the backend is 'WRAPPED_UP'
    [constants.PRODUCTION_DAY_STATUS_WRAPPED_UP]: 'CLOSED',
    [constants.PRODUCTION_DAY_STATUS_LOCKED]: 'LOCKED'
  };

  return statusToLabel[status];
}

export const getProductionUnitOnDayStatusLabel = status => {
  const statusToLabel = {
    // For some reason in the legacy the label is 'CLOSED', but in the backend is 'WRAPPED_UP'
    [constants.PRODUCTION_UNIT_ON_DAY_STATUS_WRAPPED_UP]: 'CLOSED',
    [constants.PRODUCTION_UNIT_ON_DAY_STATUS_LOCKED]: 'LOCKED'
  };

  return statusToLabel[status];
}

/**
 * Get the pay scheme of the production unit.
 * If production unit doesn't have pay scheme, pay scheme of the production the unit is the member of is returned.
 */
export const getProductionUnitsPayScheme = (productionUnit, production, rateCards) => {
  const paySchemeId = productionUnit.paySchemeId || production.defaultPaySchemeId;
  return rateCards[paySchemeId];
}

export const getNumberOfExtrasWithNotCompletedDocuments = state => Object.values(state.productionDay.extrasDocuments).filter(d => {
  // Do not include documents of cancelled extras.
  const chitRecords = getChitRecords(state).filter(r => r.chit.extraId === d.extraId);
  if (chitRecords[0]) {
    return d.allCompleted === false && chitRecords[0].extraStatus !== EXTRA_STATUS_CANCELLED;
  } else {
    return 0;
  }
}).length;

export const doesProductionDayRequireDocuments = state => {
  // If at least one extras has document records, that's mean that production requires document.
  const firstExtra = Object.values(state.productionDay.extrasDocuments)[0];
  return firstExtra && firstExtra.documents.length > 0;
}

export const allCallDetailsDeliveryStatuses = [
  constants.CALL_DETAILS_STATUS_YES,constants.CALL_DETAILS_STATUS_NO,constants.CALL_DETAILS_STATUS_REVIEW,constants.CALL_DETAILS_STATUS_NOT_SENT
];

export const getDeliveryStatusOfCallDetails = (chit) => {
  const { callDetails } = chit;
  if (!callDetails || Object.keys(callDetails).length === 0) {
    return constants.CALL_DETAILS_STATUS_NOT_SENT;
  }
  if (callDetails.response?.result === constants.CALL_DETAILS_RESPONSE_ACTUAL_STATUS_POSITIVE) {
    return constants.CALL_DETAILS_STATUS_YES
  }
  if (callDetails.response?.result === constants.CALL_DETAILS_RESPONSE_ACTUAL_STATUS_NEGATIVE) {
    return constants.CALL_DETAILS_STATUS_REVIEW
  }
  return constants.CALL_DETAILS_STATUS_NO;

}

export const allExtraStatuses = [EXTRA_STATUS_RELEASED, EXTRA_STATUS_NO_SHOW, EXTRA_STATUS_BOOKED, EXTRA_STATUS_CHECKED_IN, EXTRA_STATUS_CHECKED_OUT, EXTRA_STATUS_CANCELLED]