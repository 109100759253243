import React from 'react';
import OnboardingLayout from './OnboardingLayout';
import PropTypes from 'prop-types';

class OnboardingVerifyPasswordResetCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordStrength: 0
    }
  }

  render() {
    const { error } = this.props
    return (
      <OnboardingLayout>
        {!error &&
        <div id="verifying-code-block" className="alert alert-primary" role="alert">
          Verifying code...
        </div>
        }
        {error &&
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        }
      </OnboardingLayout>
    )
  }
}


OnboardingVerifyPasswordResetCode.propTypes = {
  error: PropTypes.string
}

export default OnboardingVerifyPasswordResetCode;