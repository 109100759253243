import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import { connect } from 'react-redux';
class AdminPhoneNum extends React.Component {
    render = () => {
        const {
            production: { adminId },
            users,
        } = this.props;
        const phoneNumber = users[adminId]?.phoneNumber || '';
        return (
            <div>
                <div>
                    <div style={{ float: 'left', marginTop: '10px', width: '200px' }}>
                        Admin Phone Number:
                    </div>
                    <div style={{ float: 'left', marginLeft: '10px', width: '500px' }}>
                        <div
                            style={{
                                backgroundColor: 'hsl(0,0%,80%)',
                                borderColor: 'hsl(0,0%,80%)',
                                borderRadius: '4px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                display: 'flex',
                                minHeight: '38px',
                                padding: '2px 8px',
                                alignItems: 'center',
                            }}>
                            <div>{phoneNumber}</div>
                        </div>
                    </div>
                    <div style={{ clear: 'both' }} />
                </div>
            </div>
        );
    };
}

AdminPhoneNum.propTypes = {
    users: PropTypes.object.isRequired,
    production: PropTypes.shape({
        adminId: PropTypes.string.isRequired,
    }).isRequired,
};

export default AdminPhoneNum;
