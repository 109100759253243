import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import {
    currentDateTime,
    momentToDate,
    momentToUveDateFormat,
    parseFromNativeDatetime,
} from '../../../lib/dateTimeUtils';
import * as constants from '../../constants';
import * as getters from '../../getters';

class SyncActions extends React.Component {
    constructor(props) {
        super(props);
    }

    syncSpecificDay = (value) => {
        const date = momentToDate(parseFromNativeDatetime(value));
        this.props.importProductionDaysRequest(
            this.props.production.id,
            date,
            null,
            constants.IMPORT_TYPE_SPECIFIC_DAY,
            `Day ${date} has been successfully synced`,
        );
    };

    syncCurrentDay = () => {
        const currentDay = momentToDate(currentDateTime());
        this.props.importProductionDaysRequest(
            this.props.production.id,
            currentDay,
            null,
            constants.IMPORT_TYPE_CURRENT_DAY,
            `Current day ${currentDay} has been successfully synced`,
        );
    };

    sync4Days = () => {
        this.props.importProductionDaysRequest(
            this.props.production.id,
            null,
            null,
            constants.IMPORT_TYPE_4_DAYS,
            '4 days has been successfully synced',
        );
    };

    render = () => {
        const now = currentDateTime();
        const nowUVEFormat = momentToUveDateFormat(now);

        const syncDisabled = getters.isSyncDisabled(this.props.production);

        // Plus 3 since we need to include current day so in the end we will get 4 days
        const nowPlus3DaysUVEFormat = momentToUveDateFormat(now.add(3, 'd'));

        const syncDisabledMessage =
            "Production's Pay Scheme and Primary AD must be set to enable sync";
        const sync4DaysTooltipMessage = syncDisabled ? (
            syncDisabledMessage
        ) : (
            <div>
                {' '}
                This will run a 4 day sync from today's date, from <strong>
                    {nowUVEFormat}
                </strong>{' '}
                to <strong>{nowPlus3DaysUVEFormat}</strong>
            </div>
        );

        const syncCurrentDayTooltipMessage = syncDisabled
            ? syncDisabledMessage
            : 'Sync current day';

        const syncSpecificDayTooltipMessage = syncDisabled
            ? syncDisabledMessage
            : 'Sync specific day...!!!';

        return (
            <div className="sync-action-btn-group">
                <div style={{ float: 'right' }}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                <div className="tooltip-content">{sync4DaysTooltipMessage}</div>
                            </Tooltip>
                        }>
                        <div className="d-inline-block">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.sync4Days}
                                disabled={this.props.importingProductionDays || syncDisabled}
                                // Enable tooltip when button is disabled
                                style={{ pointerEvents: syncDisabled ? 'none' : 'auto' }}>
                                {this.props.importType === constants.IMPORT_TYPE_4_DAYS && (
                                    <span>Syncing 4 days...</span>
                                )}
                                {this.props.importType !== constants.IMPORT_TYPE_4_DAYS && (
                                    <span>Sync 4 days</span>
                                )}
                            </button>
                        </div>
                    </OverlayTrigger>
                </div>
                <div style={{ float: 'right' }}>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{syncCurrentDayTooltipMessage}</Tooltip>}>
                        <div className="d-inline-block">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.syncCurrentDay}
                                disabled={this.props.importingProductionDays || syncDisabled}
                                // Enable tooltip when button is disabled
                                style={{ pointerEvents: syncDisabled ? 'none' : 'auto' }}>
                                {this.props.importType === constants.IMPORT_TYPE_CURRENT_DAY && (
                                    <span>Syncing {this.props.specificDayBeingImported}... </span>
                                )}
                                {this.props.importType !== constants.IMPORT_TYPE_CURRENT_DAY && (
                                    <span>Sync current day</span>
                                )}
                            </button>
                        </div>
                    </OverlayTrigger>
                </div>
                <div style={{ float: 'right' }}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                <div className="tooltip-content">
                                    {syncSpecificDayTooltipMessage}
                                </div>
                            </Tooltip>
                        }>
                        <div className="d-inline-block">
                            <DatePicker
                                selected={new Date()}
                                onChange={this.syncSpecificDay}
                                disabled={this.props.importingProductionDays || syncDisabled}
                                customInput={
                                    <div className="d-inline-block">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={
                                                this.props.importingProductionDays || syncDisabled
                                            }
                                            // Enable tooltip when button is disabled
                                            style={{
                                                pointerEvents: syncDisabled ? 'none' : 'auto',
                                            }}>
                                            {this.props.importType ===
                                                constants.IMPORT_TYPE_SPECIFIC_DAY && (
                                                <span>
                                                    Syncing {this.props.specificDayBeingImported}...
                                                </span>
                                            )}
                                            {this.props.importType !==
                                                constants.IMPORT_TYPE_SPECIFIC_DAY && (
                                                <span>Sync specific day...</span>
                                            )}
                                        </button>
                                    </div>
                                }
                            />
                        </div>
                    </OverlayTrigger>
                </div>
                <div style={{ clear: 'both' }} />
            </div>
        );
    };
}

SyncActions.propTypes = {
    production: PropTypes.object.isRequired,
    importProductionDaysRequest: PropTypes.func.isRequired,
    importingProductionDays: PropTypes.bool.isRequired,
    specificDayBeingImported: PropTypes.string,
    importType: PropTypes.string,
};

export default SyncActions;
