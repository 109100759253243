import React from 'react'
import {Slide, ToastContainer} from 'react-toastify';
import {LoadIndicatorContainer} from '../../common/load-indicator';
import {GlobalErrorContainer} from '../../common/global-error';
import 'react-toastify/dist/ReactToastify.css';
import SideNav from './SideNav';
import Header from './Header';
import Footer from './Footer';
import './App.css';

const App = (props) => (
  <div>
    <LoadIndicatorContainer/>
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        <SideNav/>
        <div className="layout-container">
          <Header  {...props} />
          <div className="layout-content">
            <ToastContainer transition={Slide}/>
            <GlobalErrorContainer/>
            <div className="container-fluid flex-grow-1 container-p-y">
              {props.children}
            </div>
            <Footer/>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-sidenav-toggle"/>
    </div>
  </div>
);

export default App;