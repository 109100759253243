import * as actionTypes from "./actionTypes";

export const loadUsersRequest = () => ({
  type: actionTypes.LOAD_USERS_REQUEST
});

export const loadUsersSuccess = (users) => ({
  type: actionTypes.LOAD_USERS_SUCCESS,
  users
});

export const loadUsersFailure = (error) => ({
  type: actionTypes.LOAD_USERS_FAILURE,
  error
});

export const showHideCreateUserWindow = (show) => ({
  type: actionTypes.SHOW_HIDE_CREATE_USER_WINDOW,
  show
});


export const showHideEditUserWindow = (show, user) => ({
  type: actionTypes.SHOW_HIDE_EDIT_USER_WINDOW,
  show,
  user
});

export const createUserRequest = (data) => ({
  type: actionTypes.CREATE_USER_REQUEST,
  data
});

export const createUserSuccess = (user) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  user
});

export const createUserFailure = (error) => ({
  type: actionTypes.CREATE_USER_FAILURE,
  error
});

export const updateUserRequest = (data) => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  data
});

export const updateUserSuccess = (user) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  user
});

export const updateUserFailure = (error) => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  error
});

export const activateUserRequest = (data) => ({
  type: actionTypes.ACTIVATE_USER_REQUEST,
  data
});

export const activateUserSuccess = (user) => ({
  type: actionTypes.ACTIVATE_USER_SUCCESS,
  user
});

export const activateUserFailure = (error) => ({
  type: actionTypes.ACTIVATE_USER_FAILURE,
  error
});

export const deactivateUserRequest = (data) => ({
  type: actionTypes.DEACTIVATE_USER_REQUEST,
  data
});

export const deactivateUserSuccess = (user) => ({
  type: actionTypes.DEACTIVATE_USER_SUCCESS,
  user
});

export const deactivateUserFailure = (error) => ({
  type: actionTypes.DEACTIVATE_USER_FAILURE,
  error
});

export const unEnrollUserFromMfaRequest = (data) => ({
  type: actionTypes.UN_ENROLL_USER_FROM_MFA_REQUEST,
  data
});

export const unEnrollUserFromMfaSuccess = (user) => ({
  type: actionTypes.UN_ENROLL_USER_FROM_MFA_SUCCESS,
  user
});

export const unEnrollUserFromMfaFailure = (error) => ({
  type: actionTypes.UN_ENROLL_USER_FROM_MFA_FAILURE,
  error
});

export const sendVerificationEmailRequest = (data) => ({
  type: actionTypes.SEND_VERIFICATION_EMAIL_REQUEST,
  data
});

export const sendVerificationEmailSuccess = () => ({
  type: actionTypes.SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailFailure = (error) => ({
  type: actionTypes.SEND_VERIFICATION_EMAIL_FAILURE,
  error
});