import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';

class QrCodeSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      requiresQrCodes: this.props.production.requiresQrCodes,
    };
  }

  showHideConfirmationDialog = (show) => {
    this.setState({
      showConfirmationDialog: show
    });
  };

  getOptions = () => ([
    {
      value: false,
      label: 'No'
    },
    {
      value: true,
      label: 'Yes'
    }]
  );

  setQrCodesRequirement = () => {
    this.showHideConfirmationDialog(false);
    this.props.setQrCodesRequirementRequest(this.props.production.id, this.state.requiresQrCodes);
  };

  cancelQrCodeSettingsChange = () => {
    this.showHideConfirmationDialog(false);
    this.setState({
      requiresQrCodes: this.props.production.requiresQrCodes
    });
  };

  handleOnSelectChange = option => {
    this.setState({
      requiresQrCodes: option.value
    });
    this.showHideConfirmationDialog(true);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.production.requiresQrCodes !== this.props.production.requiresQrCodes) {
      this.setState({
        requiresQrCodes: this.props.production.requiresQrCodes
      });
    }
  }

  render = () => {
    const options = this.getOptions();
    const currentOption = options.find(option => option.value === this.state.requiresQrCodes);

    return (
      <div>
        <div>
          <div style={{float: 'left', marginTop: '10px', width: '200px'}}>
            Production uses QR codes:
          </div>
          <div style={{float: 'left', marginLeft: '10px', width: '500px'}}>
            <Select
              options={options}
              placeholder="Please select..."
              value={currentOption}
              onChange={this.handleOnSelectChange.bind(this)}
            />
          </div>
          <div style={{clear: 'both'}}/>
        </div>
        <ConfirmationDialog
          show={this.state.showConfirmationDialog}
          title="Change QR code settings"
          message={
            <div>
              <span>Are you sure you want to change QR code settings?</span>
            </div>
          }
          onCancel={this.cancelQrCodeSettingsChange.bind(this)}
          onConfirm={this.setQrCodesRequirement.bind(this)}
        />
      </div>
    );
  };
}

QrCodeSettings.propTypes = {
  production: PropTypes.object.isRequired,
  setQrCodesRequirementRequest: PropTypes.func.isRequired
}

export default QrCodeSettings;