import React from 'react'
import PropTypes from 'prop-types';
import CentredLayout from '../../app/components/CentredLayout';
import {Button} from 'react-bootstrap';
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {STEP_SIGN_IN} from '../constants';
import firebase from "firebase/compat/app";

class Mfa extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }

    this.recaptchaRef = React.createRef();
  }

  confirmVerificationCode = (data) => {
    const { code } = data;
    this.props.confirmVerificationCodeRequest(this.props.mfaResolver, this.props.verificationId, code);
  }

  getPhoneInfoOptions = () => {
    return {
      multiFactorHint: this.props.mfaResolver.hints[0],
      session: this.props.mfaResolver.session
    };
  }

  componentDidMount() {
    const that = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptchaRef.current, {
      'size': 'invisible',
      'callback': function (response) {
        that.setState({
          recaptchaVerified: true,
          verifyingRecaptcha: false
        })
      },
      'expired-callback': function () {
        console.log('EXPIRED');
      }
    });

    this.sendMfaVerificationCode();
  }

  sendMfaVerificationCode = () => {
    const phoneInfoOptions = this.getPhoneInfoOptions();
    this.props.sendMfaVerificationCodeRequest(phoneInfoOptions, window.recaptchaVerifier);
  }

  render() {
    const schema = Yup.object().shape({
      code: Yup.string().required('Required')
    });

    let initialValues = {
      code: '',
    };

    const {confirmingVerificationCode, error, mfaResolver, sendingMfaVerificationCode, mfaVerificationCodeSent} = this.props;
    const phoneNumber = mfaResolver.hints[0].phoneNumber;

    return (
      <CentredLayout title="Sign In - MFA">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={this.confirmVerificationCode.bind(this)}
          render={({errors, status, touched, isSubmitting, values, isValid, dirty}) => (
            <Form>
              <p>
                {sendingMfaVerificationCode &&
                  <span>Sending verification code...</span>
                }
              </p>
              {!mfaVerificationCodeSent && error &&
              <div className="alert alert-danger" style={{marginTop: '10px'}} role="alert">
                {error}
              </div>
              }
              {mfaVerificationCodeSent &&
              <React.Fragment>
                <p>
                  <span>We sent the code to {phoneNumber}, please enter it below.</span>
                </p>
                <div className="form-group">
                  <Field type="text" name="code" className="form-control" id="code"
                         placeholder="Enter verification code"/>
                  <ErrorMessage name="code"/>
                </div>
                {error &&
                <div className="alert alert-danger" style={{marginTop: '10px'}} role="alert">
                  {error}
                </div>
                }
                <div style={{marginTop: '15px'}}>
                  <div style={{float: 'left'}}>
                    <Button variant="primary" disabled={confirmingVerificationCode} type="submit">
                      {confirmingVerificationCode &&
                      <span>Confirming Code...</span>
                      }
                      {!confirmingVerificationCode &&
                      <span>Confirm Code</span>
                      }
                    </Button>
                  </div>
                  <div style={{float: 'right'}}>
                    <Button variant="secondary" disabled={sendingMfaVerificationCode}
                            onClick={() => this.props.setStep(STEP_SIGN_IN)}>
                      Back
                    </Button>
                  </div>
                  <div style={{clear: 'both'}}/>
                  <div style={{marginTop: '10px'}}>
                    {!sendingMfaVerificationCode &&
                    <span>Didn't receive the code? <a
                      onClick={() => this.sendMfaVerificationCode()}><strong>Send again</strong></a></span>
                    }
                  </div>
                </div>
              </React.Fragment>
              }
            </Form>
          )}
        >
        </Formik>
        <div ref={this.recaptchaRef}></div>
      </CentredLayout>
    );
  }
}

Mfa.propTypes = {
  firebaseUser: PropTypes.object.isRequired,
  confirmVerificationCodeRequest: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorCode: PropTypes.string,
  confirmingVerificationCode: PropTypes.bool.isRequired,
  sendingMfaVerificationCode: PropTypes.bool.isRequired,
  sendMfaVerificationCodeRequest: PropTypes.func.isRequired,
  mfaResolver: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  verificationId: PropTypes.string,
  mfaVerificationCodeSent: PropTypes.bool.isRequired
}

export default Mfa;