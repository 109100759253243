import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../../constants';
import ProductionExternalSurvey from './ProductionExternalSurvey';
import {deepEqual} from '../../../../lib/objectUtils';

class Surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalHealthSurveySettings: props.production.healthSurveySettings,
      healthSurveySettings: props.production.healthSurveySettings
    }
  }

  setHealthSurveyType = type => {
    this.setState(prevState => {
      let data = null;
      if (type === constants.HEALTH_SURVEY_TYPE_UVE_SURVEY) {
        data = {questionnaireId: constants.DEFAULT_UVE_HEALTH_SURVEY_QUESTIONNAIRE_ID}
      }

      return {
        ...prevState,
        healthSurveySettings: {
          ...prevState.healthSurveySettings,
          type,
          data
        }
      }
    });
  }

  handleProductionExternalSurveyChange = data => {
    this.setState(prevState => ({
      ...prevState,
      healthSurveySettings: {
        ...prevState.healthSurveySettings,
        data
    }}))
  }

  setHealthSurveySettings = () => {
    this.props.setHealthSurveySettingsRequest(this.props.production.id, this.state.healthSurveySettings);
    this.setState(prevState => ({
      originalHealthSurveySettings: prevState.healthSurveySettings
    }));
  };

  cancelHealthSurveyChanges = () => {
    this.setState(prevState => ({
      healthSurveySettings: prevState.originalHealthSurveySettings
    }));
  }

  render = () => {
    const healthSurveyType = this.state.healthSurveySettings.type;
    // const dataChanged = deepEqual(this.state.originalHealthSurveySettings, this.state.healthSurveySettings);
    const dataChanged = !deepEqual(this.state.originalHealthSurveySettings, this.state.healthSurveySettings);

    return (
      <div className="card">
        <div className="card-header">
          Survey
        </div>
        <div className="card-body">
          <div className="form-check">
            <input className="form-check-input" type="radio" name="surveyType" id="surveyNoSurvey"
                   value={constants.HEALTH_SURVEY_TYPE_NO_SURVEY}
                   checked={healthSurveyType === constants.HEALTH_SURVEY_TYPE_NO_SURVEY}
                   onChange={this.setHealthSurveyType.bind(this, constants.HEALTH_SURVEY_TYPE_NO_SURVEY)}
            />
            <label className="form-check-label" htmlFor="surveyNoSurvey">No Survey</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="surveyType" id="surveyUveSurvey"
                   value={constants.HEALTH_SURVEY_TYPE_UVE_SURVEY}
                   checked={healthSurveyType === constants.HEALTH_SURVEY_TYPE_UVE_SURVEY}
                   onChange={this.setHealthSurveyType.bind(this, constants.HEALTH_SURVEY_TYPE_UVE_SURVEY)}
            />
            <label className="form-check-label" htmlFor="surveyUveSurvey">Uve Standard Survey</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="surveyType" id="surveyProductionExternalSurvey"
                   checked={healthSurveyType === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY}
                   value={constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY}
                   onChange={this.setHealthSurveyType.bind(this, constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY)}
            />
            <label className="form-check-label" htmlFor="surveyProductionExternalSurvey">Production's External
              Survey</label>
            {healthSurveyType === constants.HEALTH_SURVEY_TYPE_PRODUCTION_EXTERNAL_SURVEY &&
            <ProductionExternalSurvey productionExternalSurvey={this.state.healthSurveySettings.data}
                                      productionId={this.props.production.id}
                                      onProductionExternalSurveyChange={this.handleProductionExternalSurveyChange.bind(this)}
            />
            }
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary" disabled={!dataChanged} onClick={this.setHealthSurveySettings.bind(this)}>Save</button>
          <button className="btn btn-default" disabled={!dataChanged} style={{marginLeft: '10px'}} onClick={this.cancelHealthSurveyChanges.bind(this)}>Cancel</button>
        </div>
      </div>
    )
  }
}

Surveys.propTypes = {
  production: PropTypes.object.isRequired,
  setHealthSurveySettingsRequest: PropTypes.func.isRequired
};

export default Surveys;