import React from 'react';
import {connect} from 'react-redux';
import Ads from './Ads';
import {
  addAdsToProductionRequest, assignPrimaryAdRequest,
  assignSecondaryAdsToProductionUnitRequest,
  removeAdFromProductionRequest,
  setProductionUnitPaySchemeRequest,
  unassignSecondaryAdFromAllProductionUnitsRequest,
  unassignSecondaryAdFromProductionUnitRequest
} from '../../actions';
import * as getters from '../../getters';
import { getters as appGetters } from '../../../app';

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    production: productionState.production,
    usersById: state.app.users,
    productionAds: getters.getProductionAds(state),
    allAds: appGetters.getAds(state),
    productionUnitsBySecondaryAds: getters.getProductionUnitsBySecondaryAds(state),
    productionUnits: getters.getProductionUnits(state),
    highlightedProductionAdId: ownProps.currentProductionAdId
  };
};

const actionCreators = {
  setProductionUnitPaySchemeRequest,
  addAdsToProductionRequest,
  removeAdFromProductionRequest,
  assignPrimaryAdRequest,
  unassignSecondaryAdFromAllProductionUnitsRequest,
  assignSecondaryAdsToProductionUnitRequest,
  unassignSecondaryAdFromProductionUnitRequest,
};

export default connect(mapStateToProps, actionCreators)(Ads);


