import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import ConfirmationDialog from '../../../common/confimration-dialog/ConfirmationDialog';
import Select from 'react-select';
import {Link} from 'react-router-dom';

class AdRowProductionUnits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUnassignSecondaryAdConfirmationDialog: false
    };
  }

  showHideUnassignSecondaryAdConfirmationDialog = show => {
    this.setState({
      showUnassignSecondaryAdConfirmationDialog: show
    });
  }

  unassignSecondaryAd = productionUnitId => {
    this.showHideUnassignSecondaryAdConfirmationDialog(false);
    this.props.unassignSecondaryAdFromProductionUnitRequest(productionUnitId, this.props.ad.id);
  }

  isAdPrimary = () => this.props.ad && this.props.ad.id === this.props.production.primaryAdId

  /**
   * Get all units of production except of units which are already assigned to given AD.
   */
  geProductionUnitsOptions = () => {
    const assignedProductionUnites = this.props.productionUnitsBySecondaryAds[this.props.ad.id] || [];
    const assignedProductionUnitesById = Object.fromEntries(assignedProductionUnites.map(productionUnit => [productionUnit.id, true]))
    const nonAssignedProductionUnits = this.props.productionUnits
      .filter(productionUnit => !(productionUnit.id in assignedProductionUnitesById || productionUnit.isDefault));
    return nonAssignedProductionUnits.map(productionUnit => ({
      value: productionUnit.id,
      label: productionUnit.name
    }));
  };


  handleOnSelectChange = option => {
    this.props.assignSecondaryAdsToProductionUnitRequest(option.value, [this.props.ad.id]);
  };

  render = () => {
    if (this.isAdPrimary()) {
      return (
        <div>
          Primary AD has access to all units
        </div>
      )
    }
    const productionUnitsOfAd = this.props.productionUnitsBySecondaryAds[this.props.ad.id] || [];
    const units = productionUnitsOfAd.map(productionUnit => {
      return (
        <Row key={productionUnit.id} className="in-row-row">
          <Col md={9} className="in-row-row-col">
            <Link to={`/productions/${this.props.production.id}/units/${productionUnit.id}`}>
              {productionUnit.name}
            </Link>
          </Col>
          <Col md={3} className="in-row-row-col">
            <div className="in-row-row-actions">
              <Button onClick={this.showHideUnassignSecondaryAdConfirmationDialog.bind(this, true)}
                      variant="outline-danger" size="xs">
                Unassign
              </Button>
            </div>
          </Col>
          <ConfirmationDialog
            show={this.state.showUnassignSecondaryAdConfirmationDialog}
            onCancel={this.showHideUnassignSecondaryAdConfirmationDialog.bind(this, false)}
            title="Unassign Secondary AD"
            message={
              <span>Are you sure you want to unassign secondary AD <strong>{this.props.ad.firstName} {this.props.ad.lastName}</strong> from
                production unit <strong>{productionUnit.name}</strong>?
              </span>
            }
            onConfirm={this.unassignSecondaryAd.bind(this, productionUnit.id)}
          />
        </Row>
      )
    });

    const productionUnitsOptions = this.geProductionUnitsOptions();
    const addUnitsSelectStyle = productionUnitsOfAd.length > 0 ? {marginTop: '15px'} : {};
    return (
      <div>
        {productionUnitsOfAd.length > 0 &&
        <div>
          {units}
        </div>
        }
        <div className="add-units-select" style={addUnitsSelectStyle}>
          <Select
            options={productionUnitsOptions}
            placeholder="Select unit to assign to..."
            value={null}
            onChange={this.handleOnSelectChange.bind(this)}
          />
        </div>
      </div>
    )
  }
}

AdRowProductionUnits.propTypes = {
  production: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  productionUnits: PropTypes.array.isRequired,
  productionUnitsBySecondaryAds: PropTypes.object.isRequired,
  assignSecondaryAdsToProductionUnitRequest: PropTypes.func.isRequired,
  unassignSecondaryAdFromProductionUnitRequest: PropTypes.func.isRequired
};

export default AdRowProductionUnits;
