import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Dropdown, DropdownButton } from 'react-bootstrap';
import AddSupplementaryFee from './AddSupplementaryFee';
import { dateTimeToHoursAndMinutes } from '../../../../lib/dateTimeUtils';
import ChangeDailyRate from './ChangeDailyRate';
import SetCallTime from './SetCallTime';
import * as constants from '../../../constants';
import AssignExtrasToProductionUnit from './AssignExtrasToProductionUnit';
import SetExtrasBookingDateDetails from './SetExtrasBookingDateDetails';
import CancelChits from './CancelChits';
import RemoveSupplementaryFees from './RemoveSupplementaryFees';
import SendBreakdownEmails from './SendBreakdownEmails';
import SetExtrasStatuses from './SetExtrasStatuses';
import SetChitNotes from './SetChitNotes';
import SetBulkTravelNotes from './SetBulkTravelNotes';
import SendMessageModal from './SendMessageModal';
class BulkActions extends React.Component {
  render = () => {
    const disabled = !Object.values(this.props.idsOfSelectedChitsCrossedByFiltered).includes(true);
    const btnClass = disabled ? 'btn-outline-default' : 'btn-outline-secondary';

    const bulkActionsTotalResultsCount = Object.keys(this.props.bulkActionsResults).length;
    const chitsWord = bulkActionsTotalResultsCount === 1 ? 'chit' : 'chits';

    const failedChitsCount = Object.keys(this.props.bulkActionsFailedChits).length;
    const succeedChitsCount = bulkActionsTotalResultsCount - failedChitsCount;

    let bulkActionsSucceedMessage = `Successfully applied bulk actions for ${bulkActionsTotalResultsCount} ${chitsWord}`;

    let bulkActionsFailedWindowErrorMessage = `Some actions failed for ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}`;

    const allChitsBtnClass = !this.props.chitsFilter.bulkActionResult ? 'btn-info' : 'btn-outline-info';
    const succeedChitsBtnClass =
      this.props.chitsFilter.bulkActionResult === constants.BULK_ACTION_RESULT_SUCCESS
        ? 'btn-success'
        : 'btn-outline-success';
    const failedChitsBtnClass =
      this.props.chitsFilter.bulkActionResult === constants.BULK_ACTION_RESULT_FAILURE ? 'btn-danger' : 'btn-outline-danger';

    if (failedChitsCount > 0) {
      switch (this.props.latestBulkActionType) {
        case constants.BULK_ACTION_TYPE_CALL_TIME:
          const callTime = dateTimeToHoursAndMinutes(this.props.latestBulkActionParams.callTime);
          bulkActionsFailedWindowErrorMessage = `Could not set call time ${callTime} in ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_PRODUCTION_UNIT:
          const productionUnitName = this.props.productionUnitsById[this.props.latestBulkActionParams.productionUnitId].name;
          bulkActionsFailedWindowErrorMessage = `Could not assign extra to production unit "${productionUnitName}" in ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SUPPLEMENTARY_FEE:
          const feeName = this.props.latestBulkActionParams.feeName;
          const payRate = this.props.latestBulkActionParams.payRate;
          bulkActionsFailedWindowErrorMessage = `Could not add supplementary fee "${feeName} - £${payRate}" to ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_DAILY_RATE:
          const rateName = this.props.latestBulkActionParams.rateName;
          bulkActionsFailedWindowErrorMessage = `Could not change primary rate "${rateName}" in ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_BOOKING_DATE_DETAILS:
          bulkActionsFailedWindowErrorMessage = `Could not set call details in ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_CHITS_CANCELLATION:
          bulkActionsFailedWindowErrorMessage = `Could not cancel ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SET_EXTRAS_STATUSES:
          bulkActionsFailedWindowErrorMessage = `Could not set extras statuses for ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_REMOVE_SUPPLEMENTARY_FEES:
          bulkActionsFailedWindowErrorMessage = `Could not remove supplementary fees for  ${failedChitsCount} of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SEND_BREAKDOWN_EMAILS:
          bulkActionsFailedWindowErrorMessage = `Could not send breakdown emails to ${failedChitsCount}  out of ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SEND_CHIT_NOTES:
          bulkActionsSucceedMessage = `Could not set notes for chits  ${failedChitsCount} of ${bulkActionsTotalResultsCount} `;
          case constants.BULK_ACTION_TYPE_SEND_SMS :
            bulkActionsSucceedMessage = `Could not send SMS for selected chits ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
      }
    } else {
      switch (this.props.latestBulkActionType) {
        case constants.BULK_ACTION_TYPE_CALL_TIME:
          const callTime = dateTimeToHoursAndMinutes(this.props.latestBulkActionParams.callTime);
          bulkActionsSucceedMessage = `Successfully set call time to ${callTime} in ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_PRODUCTION_UNIT:
          const productionUnitName = this.props.productionUnitsById[this.props.latestBulkActionParams.productionUnitId].name;
          bulkActionsSucceedMessage = `Successfully assigned extra to production unit "${productionUnitName}" in ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SUPPLEMENTARY_FEE:
          const feeName = this.props.latestBulkActionParams.feeName;
          const payRate = this.props.latestBulkActionParams.payRate;
          bulkActionsSucceedMessage = `Successfully added supplementary fee "${feeName} - £${payRate}" to ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_DAILY_RATE:
          const rateName = this.props.latestBulkActionParams.rateName;
          bulkActionsSucceedMessage = `Successfully changed primary rate to "${rateName}" in ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_BOOKING_DATE_DETAILS:
          bulkActionsSucceedMessage = `Successfully set call details in ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_CHITS_CANCELLATION:
          bulkActionsSucceedMessage = `Successfully cancelled ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SET_EXTRAS_STATUSES:
          bulkActionsSucceedMessage = `Successfully set extras statuses for ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_REMOVE_SUPPLEMENTARY_FEES:
          bulkActionsSucceedMessage = `Successfully removed supplementary fees for ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SEND_BREAKDOWN_EMAILS:
          bulkActionsSucceedMessage = `Successfully sent breakdown emails to ${bulkActionsTotalResultsCount} ${chitsWord}.`;
          break;
        case constants.BULK_ACTION_TYPE_SEND_CHIT_NOTES:
          bulkActionsSucceedMessage = `Successfully added notes for selected chits ${bulkActionsTotalResultsCount} ${chitsWord}.`;
        case constants.BULK_ACTION_TYPE_SEND_CHIT_LEVEL_TRAVEL_NOTES:
          bulkActionsSucceedMessage = `Successfully added travelnotes for selected chits ${bulkActionsTotalResultsCount} ${chitsWord}.`;
        case constants.BULK_ACTION_TYPE_SEND_SMS :
          bulkActionsSucceedMessage = `Successfully sent SMS for selected chits ${bulkActionsTotalResultsCount} ${chitsWord}.`;



          break;
      }
    }
    const createBulActions = (actionName, onClick) => ({ actionName, onClick });
    const bulkActionInfo = [
      createBulActions('Set Call Time', this.props.showHideBulkActionsSetCallTimeWindow.bind(this, true)),
      createBulActions('Assign Unit', this.props.showHideBulkActionsAssignExtrasToProductionUnitWindow.bind(this, true)),
      createBulActions('Add Fee',this.props.showHideBulkActionsAddSupplementaryFeeWindow.bind(this, true)),
      createBulActions('Remove Fee', this.props.showHideBulkActionsRemoveSupplementaryFeesWindow.bind(this, true)),
      createBulActions('Change Primary Rate', this.props.showHideBulkActionsChangeDailyRateWindow.bind(this, true)),
      createBulActions('Set Call Details', this.props.showHideBulkActionsSetBookingDateDetailsWindow.bind(this, true)),
      createBulActions('Set Notes', this.props.showHideBulkActionsSetNote.bind(this, true)),
      createBulActions('Set Travel', this.props.showHideBulkActionsSetTravelNotes.bind(this, true)),
      createBulActions('Set Extras Statuses', this.props.showHideBulkActionsSetExtrasStatusesWindow.bind(this, true)),
      createBulActions('Send Breakdown Emails', this.props.showHideBulkActionsSendBreakdownEmailsWindow.bind(this, true)),
      createBulActions('Send Call Details', this.props.setShowHideBulkSmsModal.bind(this,true))
         // createBulActions('All filtered chits',this.props.setChitsFilterValue.bind(this, 'bulkActionResult')),
      // createBulActions('Succeeded chits',this.props.setChitsFilterValue.bind(this, 'bulkActionResult', constants.BULK_ACTION_RESULT_SUCCESS)),
      // createBulActions('Failed chits',this.props.setChitsFilterValue.bind(this, 'bulkActionResult', constants.BULK_ACTION_RESULT_FAILURE)),
      // createBulActions('Remove feedback',this.props.removeBulkActionsFeedback.bind(this)),
    ];

    const idsOfSelectedChitsCrossedByFilteredAsArray = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    const idOfFirstChit = idsOfSelectedChitsCrossedByFilteredAsArray[0];

    const firstSelectedChitRecord = idOfFirstChit ? this.props.filteredChitRecordsByChitId[idOfFirstChit] : null;

    return (
      <div>
        {bulkActionsTotalResultsCount > 0 && failedChitsCount === 0 && (
          <Alert variant="success" style={{ marginRight: '10px' }}>
            {bulkActionsSucceedMessage} <span></span>
          </Alert>
        )}
        {failedChitsCount > 0 && (
          <Alert variant="danger" style={{ marginRight: '10px' }}>
            {bulkActionsFailedWindowErrorMessage} <span></span>
            Please see highlighted rows for mode details.
          </Alert>
        )}

        <div>
          {/* 
        <div class="dropdown">
  <button aria-haspopup="true" aria-expanded="false" className={`dropdown-toggle ${`btn ${btnClass} btn-sm`}`}>Bulk Actions</button>
  {bulkActionInfo.map(({actionName,onClick})=> <Dropdown.Item eventKey={actionName} key={actionName} onClick={onClick}>{actionName}</Dropdown.Item>)}
</div> */}
          <DropdownButton
            style={{ position: 'revert' }}
            disabled={disabled}
            title={'Bulk Actions'}
            variant={`btn ${btnClass} btn-sm`}
            drop="down"
          >
            {bulkActionInfo.map(({ actionName, onClick }) => (
              <Dropdown.Item eventKey={actionName} key={actionName} onClick={onClick}>
                {actionName}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          {/* 
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  onClick={this.props.showHideBulkActionsSetCallTimeWindow.bind(this, true)}>
           
          </button>
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsAssignExtrasToProductionUnitWindow.bind(this, true)}>
            Assign To Production Unit...
          </button>
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsAddSupplementaryFeeWindow.bind(this, true)}>
            Add Supplementary Fee...
          </button>
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsChangeDailyRateWindow.bind(this, true)}>
            Change Primary Rate...
          </button>
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsSetBookingDateDetailsWindow.bind(this, true)}>
            Set Booking Date Details...
          </button>
          {/*<button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}*/}
          {/*        style={{marginLeft: '5px'}}*/}
          {/*        onClick={this.props.showHideBulkActionsCancelChitsWindow.bind(this, true)}>*/}
          {/*  Cancel Chits...*/}
          {/*</button>*/}
          {/* <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsSetExtrasStatusesWindow.bind(this, true)}>
            Set Extras Statuses...
          </button>

          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsRemoveSupplementaryFeesWindow.bind(this, true)}>
            Remove Supplementary Fees...
          </button>
          <button type="button" className={`btn ${btnClass} btn-sm`} disabled={disabled}
                  style={{marginLeft: '5px'}}
                  onClick={this.props.showHideBulkActionsSendBreakdownEmailsWindow.bind(this, true)}>
            Send Breakdown emails...
          </button> */}

          {/* {bulkActionsTotalResultsCount > 0 &&
          <div className="btn-group" role="group" aria-label="Basic example" style={{marginLeft: '15px'}}>
            <button type="button" className={`btn ${allChitsBtnClass} btn-sm`}
                    onClick={this.props.setChitsFilterValue.bind(this, 'bulkActionResult', null)}>All filtered chits
            </button>
            {succeedChitsCount > 0 &&
            <button type="button" className={`btn ${succeedChitsBtnClass} btn-sm`}
                    onClick={this.props.setChitsFilterValue.bind(this, 'bulkActionResult', constants.BULK_ACTION_RESULT_SUCCESS)}>Succeeded
              chits</button>
            }
            {failedChitsCount > 0 &&
            <button type="button" className={`btn ${failedChitsBtnClass} btn-sm`}
                    onClick={this.props.setChitsFilterValue.bind(this, 'bulkActionResult', constants.BULK_ACTION_RESULT_FAILURE)}>Failed
              chits</button>
            }
          </div>
          }
          {bulkActionsTotalResultsCount > 0 &&
          <button type="button" className={`btn btn-outline-secondary btn-sm`} style={{marginLeft: '5px'}}
                  onClick={this.props.removeBulkActionsFeedback.bind(this)}>
            Remove feedback
          </button> */}
        </div>
        {this.props.bulkSmsWindow && <SendMessageModal {...this.props}/>}
        {this.props.bulkActionsShowSetCallTimeWindow && <SetCallTime {...this.props} />}

        {this.props.bulkActionsShowAssignExtrasToProductionUnitWindow && <AssignExtrasToProductionUnit {...this.props} />}

        {this.props.bulkActionsShowAddSupplementaryFeeWindow && <AddSupplementaryFee {...this.props} />}

        {this.props.bulkActionsShowChangeDailyRateWindow && <ChangeDailyRate {...this.props} />}
        {this.props.bulkActionsShowSetBookingDateDetailsWindow && (
          <SetExtrasBookingDateDetails
            {...this.props}
            initialBookingDateDetails={firstSelectedChitRecord ? firstSelectedChitRecord.chit.bookingDateDetails : null}
          />
        )}
        {this.props.bulkActionsShowCancelChitsWindow && <CancelChits {...this.props} />}
        {this.props.bulkActionsShowSetExtrasStatusesWindow && <SetExtrasStatuses {...this.props} />}

        {this.props.bulkActionsShowSetNotes && <SetChitNotes {...this.props} />}
        {this.props.bulkActionsShowSetTravelWindow && <SetBulkTravelNotes {...this.props} />}

        {this.props.bulkActionsShowRemoveSupplementaryFeesWindow && <RemoveSupplementaryFees {...this.props} />}
        {this.props.bulkActionsShowSendBreakdownEmailsWindow && <SendBreakdownEmails {...this.props} />}
      </div>
    );
  };
}

BulkActions.propTypes = {
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  bulkActionsShowSetCallTimeWindow: PropTypes.bool.isRequired,
  bulkActionsShowAssignExtrasToProductionUnitWindow: PropTypes.bool.isRequired,
  bulkActionsShowAddSupplementaryFeeWindow: PropTypes.bool.isRequired,
  bulkActionsShowChangeDailyRateWindow: PropTypes.bool.isRequired,
  bulkActionsShowSetBookingDateDetailsWindow: PropTypes.bool.isRequired,
  bulkActionsShowCancelChitsWindow: PropTypes.bool.isRequired,
  bulkActionsShowSetExtrasStatusesWindow: PropTypes.bool.isRequired,
  bulkActionsShowRemoveSupplementaryFeesWindow: PropTypes.bool.isRequired,
  bulkActionsShowSendBreakdownEmailsWindow: PropTypes.bool.isRequired,
  bulkActionsShowSetNotes: PropTypes.bool.isRequired,
  bulkActionsShowSetTravelWindow: PropTypes.bool.isRequired,
  showHideBulkActionsSetTravelNotes: PropTypes.func.isRequired,
  showHideBulkActionsSetCallTimeWindow: PropTypes.func.isRequired,
  showHideBulkActionsAssignExtrasToProductionUnitWindow: PropTypes.func.isRequired,
  showHideBulkActionsAddSupplementaryFeeWindow: PropTypes.func.isRequired,
  showHideBulkActionsChangeDailyRateWindow: PropTypes.func.isRequired,
  showHideBulkActionsSetBookingDateDetailsWindow: PropTypes.func.isRequired,
  showHideBulkActionsCancelChitsWindow: PropTypes.func.isRequired,
  showHideBulkActionsSetExtrasStatusesWindow: PropTypes.func.isRequired,
  showHideBulkActionsRemoveSupplementaryFeesWindow: PropTypes.func.isRequired,
  showHideBulkActionsSendBreakdownEmailsWindow: PropTypes.func.isRequired,
  showHideBulkActionsSetNote: PropTypes.func.isRequired,
  bulkActionsResults: PropTypes.object.isRequired,
  bulkActionsFailedChits: PropTypes.object.isRequired,
  latestBulkActionType: PropTypes.string,
  latestBulkActionParams: PropTypes.object,
  productionUnits: PropTypes.array.isRequired,
  productionUnitsById: PropTypes.object.isRequired,
  setChitsFilterValue: PropTypes.func.isRequired,
  chitsFilter: PropTypes.object.isRequired,
  removeBulkActionsFeedback: PropTypes.func.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  setShowHideBulkSmsModal : PropTypes.func.isRequired,
  bulkSmsWindow : PropTypes.func.isRequired,
};

export default BulkActions;
