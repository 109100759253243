export const LOAD_PRODUCTION_REQUEST = 'LOAD_PRODUCTION_REQUEST';
export const LOAD_PRODUCTION_SUCCESS = 'LOAD_PRODUCTION_SUCCESS';
export const LOAD_PRODUCTION_FAILURE = 'LOAD_PRODUCTION_FAILURE';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const ASSIGN_PRIMARY_AD_REQUEST = 'ASSIGN_PRIMARY_AD_REQUEST';
export const ASSIGN_PRIMARY_AD_SUCCESS = 'ASSIGN_PRIMARY_AD_SUCCESS';
export const ASSIGN_PRIMARY_AD_FAILURE = 'ASSIGN_PRIMARY_AD_FAILURE';

export const ASSIGN_ADMIN_REQUEST = 'ASSIGN_ADMIN_REQUEST';
export const ASSIGN_ADMIN_SUCCESS = 'ASSIGN_ADMIN_SUCCESS';
export const ASSIGN_ADMIN_FAILURE = 'ASSIGN_ADMIN_FAILURE';


export const SET_DEFAULT_PAY_SCHEME_REQUEST = 'SET_DEFAULT_PAY_SCHEME_REQUEST';
export const SET_DEFAULT_PAY_SCHEME_SUCCESS = 'SET_DEFAULT_PAY_SCHEME_SUCCESS';
export const SET_DEFAULT_PAY_SCHEME_FAILURE = 'SET_DEFAULT_PAY_SCHEME_FAILURE';

export const IMPORT_PRODUCTION_DAYS_REQUEST = 'IMPORT_PRODUCTION_DAYS_REQUEST';
export const IMPORT_PRODUCTION_DAYS_SUCCESS = 'IMPORT_PRODUCTION_DAYS_SUCCESS';
export const IMPORT_PRODUCTION_DAYS_FAILURE = 'IMPORT_PRODUCTION_DAYS_FAILURE';

export const SHOW_HIDE_CREATE_PRODUCTION_UNIT_WINDOW = 'SHOW_HIDE_CREATE_PRODUCTION_UNIT_WINDOW';
export const CREATE_PRODUCTION_UNIT_REQUEST = 'CREATE_PRODUCTION_UNIT_REQUEST';
export const CREATE_PRODUCTION_UNIT_SUCCESS = 'CREATE_PRODUCTION_UNIT_SUCCESS';
export const CREATE_PRODUCTION_UNIT_FAILURE = 'CREATE_PRODUCTION_UNIT_FAILURE';

export const SHOW_HIDE_UPDATE_PRODUCTION_UNIT_BASIC_INFO_WINDOW = 'SHOW_HIDE_UPDATE_PRODUCTION_UNIT_BASIC_INFO_WINDOW';
export const UPDATE_PRODUCTION_UNIT_BASIC_INFO_REQUEST = 'UPDATE_PRODUCTION_UNIT_BASIC_INFO_REQUEST';
export const UPDATE_PRODUCTION_UNIT_BASIC_INFO_SUCCESS = 'UPDATE_PRODUCTION_UNIT_BASIC_INFO_SUCCESS';
export const UPDATE_PRODUCTION_UNIT_BASIC_INFO_FAILURE = 'UPDATE_PRODUCTION_UNIT_BASIC_INFO_FAILURE';

export const DELETE_PRODUCTION_UNIT_REQUEST = 'DELETE_PRODUCTION_UNIT_REQUEST';
export const DELETE_PRODUCTION_UNIT_SUCCESS = 'DELETE_PRODUCTION_UNIT_SUCCESS';
export const DELETE_PRODUCTION_UNIT_FAILURE = 'DELETE_PRODUCTION_UNIT_FAILURE';

export const SET_PRODUCTION_UNIT_PAY_SCHEME_REQUEST = 'SET_PRODUCTION_UNIT_PAY_SCHEME_REQUEST';
export const SET_PRODUCTION_UNIT_PAY_SCHEME_SUCCESS = 'SET_PRODUCTION_UNIT_PAY_SCHEME_SUCCESS';
export const SET_PRODUCTION_UNIT_PAY_SCHEME_FAILURE = 'SET_PRODUCTION_UNIT_PAY_SCHEME_FAILURE';

export const ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_REQUEST = 'ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_REQUEST';
export const ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_SUCCESS = 'ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_SUCCESS';
export const ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_FAILURE = 'ASSIGN_SECONDARY_ADS_TO_PRODUCTION_UNIT_FAILURE';

export const UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_REQUEST = 'UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_REQUEST';
export const UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_SUCCESS = 'UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_SUCCESS';
export const UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_FAILURE = 'UNASSIGN_SECONDARY_AD_FROM_PRODUCTION_UNIT_FAILURE';

export const UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_REQUEST = 'UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_REQUEST';
export const UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_SUCCESS = 'UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_SUCCESS';
export const UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_FAILURE = 'UNASSIGN_SECONDARY_AD_FROM_ALL_PRODUCTION_UNITS_FAILURE';

export const ADD_ADS_TO_PRODUCTION_REQUEST = 'ADD_ADS_TO_PRODUCTION_REQUEST';
export const ADD_ADS_TO_PRODUCTION_SUCCESS = 'ADD_ADS_TO_PRODUCTION_SUCCESS';
export const ADD_ADS_TO_PRODUCTION_FAILURE = 'ADD_ADS_TO_PRODUCTION_FAILURE';

export const REMOVE_AD_FROM_PRODUCTION_REQUEST = 'REMOVE_AD_FROM_PRODUCTION_REQUEST';
export const REMOVE_AD_FROM_PRODUCTION_SUCCESS = 'REMOVE_AD_FROM_PRODUCTION_SUCCESS';
export const REMOVE_AD_FROM_PRODUCTION_FAILURE = 'REMOVE_AD_FROM_PRODUCTION_FAILURE';

export const SHOW_HIDE_PRODUCTION_DAY_QR_CODE_WINDOW = 'SHOW_HIDE_PRODUCTION_DAY_QR_CODE_WINDOW';
export const GET_ACTION_TOKEN_QR_CODE_REQUEST = 'GET_ACTION_TOKEN_QR_CODE_REQUEST';
export const GET_ACTION_TOKEN_QR_CODE_SUCCESS = 'GET_ACTION_TOKEN_QR_CODE_SUCCESS';
export const GET_ACTION_TOKEN_QR_CODE_FAILURE = 'GET_ACTION_TOKEN_QR_CODE_FAILURE';

export const SET_HEALTH_SURVEY_SETTINGS_REQUEST = 'SET_HEALTH_SURVEY_SETTINGS_REQUEST';
export const SET_HEALTH_SURVEY_SETTINGS_SUCCESS = 'SET_HEALTH_SURVEY_SETTINGS_SUCCESS';
export const SET_HEALTH_SURVEY_SETTINGS_FAILURE = 'SET_HEALTH_SURVEY_SETTINGS_FAILURE';

export const SHOW_HIDE_CREATE_PRODUCTION_DOCUMENT_WINDOW = 'SHOW_HIDE_CREATE_PRODUCTION_DOCUMENT_WINDOW';
export const SHOW_HIDE_UPDATE_PRODUCTION_DOCUMENT_WINDOW = 'SHOW_HIDE_UPDATE_PRODUCTION_DOCUMENT_WINDOW';

export const CREATE_PRODUCTION_DOCUMENT_REQUEST = 'CREATE_PRODUCTION_DOCUMENT_REQUEST';
export const CREATE_PRODUCTION_DOCUMENT_SUCCESS = 'CREATE_PRODUCTION_DOCUMENT_SUCCESS';
export const CREATE_PRODUCTION_DOCUMENT_FAILURE = 'CREATE_PRODUCTION_DOCUMENT_FAILURE';

export const UPDATE_PRODUCTION_DOCUMENT_REQUEST = 'UPDATE_PRODUCTION_DOCUMENT_REQUEST';
export const UPDATE_PRODUCTION_DOCUMENT_SUCCESS = 'UPDATE_PRODUCTION_DOCUMENT_SUCCESS';
export const UPDATE_PRODUCTION_DOCUMENT_FAILURE = 'UPDATE_PRODUCTION_DOCUMENT_FAILURE';

export const DELETE_PRODUCTION_DOCUMENT_REQUEST = 'DELETE_PRODUCTION_DOCUMENT_REQUEST';
export const DELETE_PRODUCTION_DOCUMENT_SUCCESS = 'DELETE_PRODUCTION_DOCUMENT_SUCCESS';
export const DELETE_PRODUCTION_DOCUMENT_FAILURE = 'DELETE_PRODUCTION_DOCUMENT_FAILURE';

export const SET_QR_CODES_REQUIREMENT_REQUEST = 'SET_QR_CODES_REQUIREMENT_REQUEST';
export const SET_QR_CODES_REQUIREMENT_SUCCESS = 'SET_QR_CODES_REQUIREMENT_SUCCESS';
export const SET_QR_CODES_REQUIREMENT_FAILURE = 'SET_QR_CODES_REQUIREMENT_FAILURE';

export const DOWNLOAD_DOCUMENTS_REQUEST = 'DOWNLOAD_DOCUMENTS_REQUEST';
export const DOWNLOAD_DOCUMENTS_SUCCESS = 'DOWNLOAD_DOCUMENTS_SUCCESS';
export const DOWNLOAD_DOCUMENTS_FAILURE = 'DOWNLOAD_DOCUMENTS_FAILURE';

export const DOWNLOAD_DOCUMENTS_REPORT_REQUEST = 'DOWNLOAD_DOCUMENTS_REPORT_REQUEST';
export const DOWNLOAD_DOCUMENTS_REPORT_SUCCESS = 'DOWNLOAD_DOCUMENTS_REPORT_SUCCESS';
export const DOWNLOAD_DOCUMENTS_REPORT_FAILURE = 'DOWNLOAD_DOCUMENTS_REPORT_FAILURE';

// transportation Group actions types
export const SET_TRANSPOTATION_GROUP_CREATE_UPDATE_MODAL_SHOW_HIDE = 'SET_TRANSPOTATION_GROUP_CREATE_UPDATE_MODAL_SHOW_HIDE';
export const SET_TRANSPOTATION_GROUP_CREATEING = 'SET_TRANSPOTATION_GROUP_CREATEING';
export const SET_TRANSPOTATION_GROUP_CREATEING_SUCCESS='SET_TRANSPOTATION_GROUP_CREATEING_SUCCESS';
export const SET_TRANSPOTATION_GROUP_CREATEING_FAILTURE='SET_TRANSPOTATION_GROUP_CREATEING_FAILTURE';

export const SET_TRANSPOTATION_GROUP_UPDATE_REQUEST = 'SET_TRANSPOTATION_GROUP_UPDATE_REQUEST';
export const SET_TRANSPOTATION_GROUP_UPDATE_SUCCESS = 'SET_TRANSPOTATION_GROUP_UPDATE_SUCCESS';
export const SET_TRANSPOTATION_GROUP_UPDATE_FAILURE = 'SET_TRANSPOTATION_GROUP_UPDATE_FAILURE'

export const SET_TRANSPOTATION_GROUP_DELETE_WINDOW = 'SET_TRANSPOTATION_GROUP_DELETE_WINDOW';
export const SET_TRANSPOTATION_GROUP_DELETE_REQUEST = 'SET_TRANSPOTATION_GROUP_DELETE_REQUEST';
export const SET_TRANSPOTATION_GROUP_DELETE_SUCCESS= 'SET_TRANSPOTATION_GROUP_DELETE_SUCCESS';
export const SET_TRANSPOTATION_GROUP_DELETE_FAILURE = 'SET_TRANSPOTATION_GROUP_DELETE_FAILURE';
// locations
export const LOAD_LOCATION_DATA_REQUEST = 'LOAD_LOCATION_DATA_REQUEST';
export const LOAD_LOCATION_DATA_SUCCESS = 'LOAD_LOCATION_DATA_SUCCESS';
export const LOAD_LOCATION_DATA_FAILURE = 'LOAD_LOCATION_DATA_FAILURE'
export const SET_LOCATION_CREATE_MODAL_SHOW_HIDE = `SET_LOCATION_CREATE_MODAL_SHOW_HIDE`;
export const SET_LOCATION_EDIT_MODAL_SHOW_HIDE = `SET_LOCATION_EDIT_MODAL_SHOW_HIDE`;
export const SET_LOCATION_CREATEING = 'SET_LOCATION_CREATEING';
export const SET_LOCATION_CREATEING_SUCCESS='SET_LOCATION_CREATEING_SUCCESS';
export const SET_LOCATION_CREATEING_FAILTURE='SET_LOCATION_CREATEING_FAILTURE';
export const SET_LOCATION_EDIT_REQUEST='SET_LOCATION_EDIT_REQUEST';
export const SET_LOCATION_EDIT_SUCCESS='SET_LOCATION_EDIT_SUCCESS';
export const SET_LOCATION_EDIT_FAILTURE='SET_LOCATION_EDIT_FAILTURE';
export const SET_LOCATION_DELETE_REQUEST= 'SET_LOCATION_DELETE_REQUEST';
export const SET_LOCATION_DELETE_SUCCESS='SET_LOCATION_DELETE_SUCCESS';
export const SET_LOCATION_DELETE_FAILTURE='SET_LOCATION_DELETE_FAILTURE';





