import {Link} from "react-router-dom";
import React from "react";
import UsersTable from "./UsersTable";
import "./Users.css";
import UsersHeader from "./UsersHeader";

class Users extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div>
        <UsersHeader {...this.props} />
        <UsersTable {...this.props} />
      </div>
    );
  }
}

export default Users;