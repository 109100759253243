import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../constants';
import { dateToTimestamp } from '../../../lib/dateTimeUtils';
import ChitRecordBaseInfo from './ChitRecordBaseInfo';
import ChitRecordCallDetails from './ChitRecordCallDetails';
import ChitRecordBookingDateDetails from './ChitRecordBookingDateDetails';
import ChitRecordHealthSurvey from './ChitRecordHealthSurvey';
import ChitRecordDocuments from './ChitRecordDocuments';
import { Link } from 'react-router-dom';

class ChitRecord extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChitSelection = (event) => {
        this.props.setChitsSelection([this.props.record.chit.id], event.target.checked);
    };

    render = () => {
        const { record } = this.props;

        let trClass = '';
        if (this.props.bulkActionResult) {
            trClass =
                this.props.bulkActionResult.result === constants.BULK_ACTION_RESULT_SUCCESS
                    ? 'table-success chit-bulk-action-success'
                    : 'table-danger chit-bulk-action-failure';
        }
        const productionDateAsTimestamp = dateToTimestamp(this.props.productionDate);

        const chitDetailsUrl = `/production/${this.props.productionId}/extra/${record.chit.extraId}/chits?chitdate=${productionDateAsTimestamp}`;

        return (
            <React.Fragment>
                <tr className={`chit-row ${trClass}`}>
                    <td className="chit-column-checkbox">
                        <input
                            type="checkbox"
                            onChange={this.handleChitSelection}
                            checked={this.props.selected}
                        />
                    </td>
                    {this.props.chitsActiveTab === constants.CHITS_TAB_CALL_DETAILS ? (
                        <td className="chit-column-extra-mobile">{record.extra.mobileNumber}</td>
                    ) : (
                        <td className="chit-column-extra-id">{record.extra.id}</td>
                    )}
                    <td className="chit-column-first-name">
                        <span>{record.extra.firstName}</span>
                    </td>
                    <td className="chit-column-last-name">
                        <span>{record.extra.lastName}</span>
                    </td>

                    {this.props.chitsActiveTab === constants.CHITS_TAB_BASE_INFO && (
                        <ChitRecordBaseInfo {...this.props} />
                    )}

                    {this.props.chitsActiveTab === constants.CHITS_TAB_CALL_DETAILS && (
                        <ChitRecordCallDetails {...this.props} />
                    )}

                    {this.props.chitsActiveTab === constants.CHITS_TAB_BOOKING_DATE_DETAILS && (
                        <ChitRecordBookingDateDetails {...this.props} />
                    )}
                    {this.props.chitsActiveTab === constants.CHITS_TAB_HEALTH_SURVEY && (
                        <ChitRecordHealthSurvey {...this.props} />
                    )}

                    {this.props.chitsActiveTab === constants.CHITS_TAB_DOCUMENTS && (
                        <ChitRecordDocuments
                            {...this.props}
                            documents={
                                this.props.extrasDocuments[record.chit.extraId]
                                    ? this.props.extrasDocuments[record.chit.extraId].documents
                                    : []
                            }
                        />
                    )}

                    <td className="text-center chit-column-chit">
                        <Link
                            to={`/chits/${record.chit.id}`}
                            className="btn btn-xs btn-outline-primary">
                            View
                        </Link>
                    </td>
                </tr>

                {this.props.bulkActionResult && this.props.bulkActionResult.result === 'failure' && (
                    <tr className="table-danger chit-bulk-action-failure-message ">
                        <td colSpan={13}>{this.props.bulkActionResult.message}</td>
                    </tr>
                )}
            </React.Fragment>
        );
    };
}

ChitRecord.propTypes = {
    productionId: PropTypes.number.isRequired,
    productionDate: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    setChitsSelection: PropTypes.func.isRequired,
    bulkActionResult: PropTypes.object,
    chitsActiveTab: PropTypes.string.isRequired,
    extrasDocuments: PropTypes.object.isRequired,
};

export default ChitRecord;
