import React from 'react';
import ChitSection3Left from './ChitSection3Left';
import ChitSection3Right from './ChitSection3Right';

class ChitSection3 extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <ChitSection3Left {...this.props} />
        </div>
        <div className="col-md-4">
          <ChitSection3Right {...this.props} />
        </div>
      </div>
    )
  }
}

export default ChitSection3;