import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal} from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import * as constants from '../../../constants';
import * as getters from "../../../getters";

/**
 * Daily Rate = Primary Rate, in most of places Daily Rate name is used, so stick with it (comes form legacy)
 */
class ChangeDailyRate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rateName: null
    };
  };

  multipleProductionUnitsAreSelected = () => {
    const productionUnits = {};
    for (const chitId in this.props.idsOfSelectedChitsCrossedByFiltered) {
      productionUnits[this.props.filteredChitRecordsByChitId[chitId].productionUnit.id] = true;
    }

    return Object.keys(productionUnits).length > 1;
  };

  getDailyRateOptions = (rateCardItems) => {
    return rateCardItems
      .filter(item => item.rateType === constants.RATE_TYPE_DAILY_RATE)
      .map(item => ({
        value: item.title,
        label: `${item.title} - ${item.payRate}`
      }));
  };

  handleDailyRateChange = (option) => {
    this.setState({
      rateName: option.value,
    })
  };

  handleChangeDailyRate = (paySchemeId) => {
    const chitIds = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered);
    this.props.changeDailyRateOfChitsRequest(chitIds, paySchemeId, this.state.rateName, this.props.productionId, this.props.productionDate);
  };

  getSelectedProductionUnitsPayScheme = () => {
    const firstChitId = Object.keys(this.props.idsOfSelectedChitsCrossedByFiltered)[0];
    if (!firstChitId) {
      return null;
    }

    const firstChitRecord = this.props.filteredChitRecordsByChitId[firstChitId];
    const productionUnit = firstChitRecord.productionUnit;
    return getters.getProductionUnitsPayScheme(productionUnit, this.props.production, this.props.rateCards);
  }

  render = () => {
    const modalBackdrop = this.props.bulkActionsRunning ? 'static' : true;
    const changeDailyRateButtonLabel = this.props.bulkActionsRunning ? 'Change Primary Rate...' : 'Changing Primary Rate';
    const isMultipleProductionUnitsAreSelected  = this.multipleProductionUnitsAreSelected();
    const selectedProductionUnitsPayScheme = this.getSelectedProductionUnitsPayScheme();

    return (
      <Modal show={true} onHide={this.props.showHideBulkActionsChangeDailyRateWindow.bind(this, false)} keyboard={!this.props.bulkActionsRunning} backdrop={modalBackdrop}>
        <Modal.Header closeButton={!this.props.bulkActionsRunning}>
          <Modal.Title>Change primary rate of selected chits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isMultipleProductionUnitsAreSelected &&
          <Alert variant='warning'>
            Chits of multiple production units are selected, please select chits of single production unit.
          </Alert>
          }
          {!isMultipleProductionUnitsAreSelected &&
          <Select
            options={this.getDailyRateOptions(selectedProductionUnitsPayScheme.items)}
            placeholder="Primary Rate..."
            className="basic-multi-select"
            onChange={this.handleDailyRateChange.bind(this)}
          />
          }
        </Modal.Body>
        <Modal.Footer>
          {isMultipleProductionUnitsAreSelected &&
          <Button variant="default" onClick={this.props.showHideBulkActionsChangeDailyRateWindow.bind(this, false)}>
            Ok
          </Button>
          }

          {!isMultipleProductionUnitsAreSelected && this.props.bulkActionsRunning &&
            <Loader type="ThreeDots" color="#2BAD60" height={50} width={50}/>
          }
          {!isMultipleProductionUnitsAreSelected &&
          <Button variant="default" onClick={this.props.showHideBulkActionsChangeDailyRateWindow.bind(this, false)} disabled={this.props.bulkActionsRunning}>
            Cancel
          </Button>
          }
          {!isMultipleProductionUnitsAreSelected &&
          <Button variant="primary" onClick={this.handleChangeDailyRate.bind(this, selectedProductionUnitsPayScheme.id)} disabled={this.props.bulkActionsRunning || this.state.rateName == null}>
            {changeDailyRateButtonLabel}
          </Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

ChangeDailyRate.propTypes = {
  production: PropTypes.object.isRequired,
  rateCards: PropTypes.object.isRequired,
  idsOfSelectedChitsCrossedByFiltered: PropTypes.object.isRequired,
  filteredChitRecordsByChitId: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
  productionDate: PropTypes.string.isRequired,
  showHideBulkActionsChangeDailyRateWindow: PropTypes.func.isRequired,
  bulkActionsRunning: PropTypes.bool.isRequired,
  changeDailyRateOfChitsRequest: PropTypes.func.isRequired,
};

export default ChangeDailyRate;