import { axiosDefault } from '../lib/network';
import axios from 'axios'
import fileDownload from "js-file-download";


export const getProductionDayAggregateData = (productionId, date) => {
  return axiosDefault.get(`/v2/production-days/${productionId}/date/${date}/aggregated`).then(function (response) {
    return response.data;
  });
};

export const wrapUpProductionUnitOnDay = (productionDayId, productionUnitId) => {
  return axiosDefault.post(`/v2/production-days/wrap-up-production-unit`, {
    productionDayId,
    productionUnitId
  }).then(function (response) {
    return response.data;
  });
};

export const generateProductionUnitCurrentReport = (productionDayId, productionUnitId) => {
  return axiosDefault.post(`/v2/reports/production-unit-on-day-current-report`, {
    productionDayId,
    productionUnitId
  }).then(function (response) {
    return response.data;
  });
};

export const generateCurrentReport = (productionDayId) => {
  return axiosDefault.post(`/v2/reports/production-day-current-report`, {
    productionDayId
  }).then(function (response) {
    return response.data;
  });
};

export const setChitCallTime = (chitId, callTime) => {
  return axiosDefault.post(`/v2/chits/set-call-time`, { chitId, callTime }).then(function (response) {
    return response;
  });
};

export const setChitsCallTime = (chitIds, callTime) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/set-call-time`, { chitIds, callTime }).then(function (response) {
    return response.data;
  })
};

export const assignExtraToProductionUnit = (chitId, productionUnitId) => {
  return axiosDefault.post(`/v2/chits/assign-extra-to-production-unit`, {
    chitId,
    productionUnitId
  }).then(function (response) {
    return response;
  });
};

export const assignExtrasToProductionUnit = (chitIds, productionUnitId) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/assign-extras-to-production-unit`, {
    chitIds,
    productionUnitId
  }).then(function (response) {
    return response.data;
  });
};

export const addSupplementaryFeeToChits = (chitIds, paySchemeId, feeName, payRate) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/add-supplementary-fee`, {
    chitIds,
    paySchemeId,
    feeName,
    payRate
  }).then(function (response) {
    return response.data;
  });
};

export const changeDailyRateOfChits = (chitIds, paySchemeId, rateName) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/change-daily-rate`, { chitIds, paySchemeId, rateName }).then(function (response) {
    return response.data;
  });
};

export const setExtrasBookingDateDetails = (chitIds, bookingDateDetails) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/set-extras-booking-date-details`, { chitIds, bookingDateDetails }).then(function (response) {
    return response.data;
  })
};

export const cancelChits = (chitIds, cancellationFeeType) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/cancel`, { chitIds, cancellationFeeType }).then(function (response) {
    return response.data;
  })
};

export const setExtrasStatus = (chitIds, extraStatus, details) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/set-extras-statuses`, { chitIds, status: extraStatus, ...details }).then(function (response) {
    return response.data;
  })
};

export const removeSupplementaryFeesChits = (chitIds) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/remove-supplementary-fees`, { chitIds }).then(function (response) {
    return response.data;
  })
};

export const sendBreakdownEmails = (chitIds) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/send-breakdown-emails`, { chitIds }).then(function (response) {
    return response.data;
  })
};


export const getChitRecords = (productionId, date) => {
  return axiosDefault.get(`/v2/chits/aggregated?productionId=${productionId}&date=${date}`).then(function (response) {
    return response.data;
  })
};


export const getChitRecord = (chitId) => {
  return axiosDefault.get(`/v2/chits/${chitId}/aggregated`).then(function (response) {
    return response.data;
  })
};

export const resetHealthSurveyResult = chitId => {
  return axiosDefault.post(`/v2/chits/reset-health-survey`, { chitId }).then(function (response) {
    return response.data;
  })
};

export const getHealthSurveyResultDetails = chitId => {
  return axiosDefault.get(`/v2/chits/${chitId}/health-survey-result-details`).then(function (response) {
    return response.data;
  })
};

export const getExtrasDocuments = (productionId, date) => {
  return axiosDefault.get(`/v2/production-days/${productionId}/date/${date}/extra-documents`).then(function (response) {
    return response.data;
  })
};

export const downloadProductionDocument = (url, docTitle) => {
  return axiosDefault.get(url, { responseType: 'blob' }).then(function (response) {
    fileDownload(response.data, `${docTitle}.pdf`);
    return response.data;
  })
};

//  api's-for notes handling 

export const setProductionNotes = (productionId, notes) => {
  return axiosDefault.post(`/v2/productions/set-notes`, { productionId, notes }).then(function (response) {
    return response.data
  })
}

export const setProductionDayNotes = (productionDayId, notes) => {
  return axiosDefault.post(`/v2/production-days/set-notes`, { productionDayId, notes }).then(function (response) {
    return response.data
  })
}

export const setIndividualChitNotes = (chitId, generalNotes) => {
  return axiosDefault.post(`/v2/chits/set-general-notes`, { chitId, generalNotes }).then(function (response) {
    return response.data
  })
}

export const setBulkChitNotesRequest = (chitIds, generalNotes) => {
  return axiosDefault.post(`/v2/chits/bulk-actions/set-general-notes`, { chitIds, generalNotes }).then(function (response) {
    return response.data
  })
}


export const setChitLevelTravelNotes = (chitId, travelInfo) => {
  return axiosDefault.post('/v2/chits/set-travel-info', { chitId, travelInfo }).then(function (response) {
    return response.data
  })
}

export const setChitLevelTravelBulkNotes = (chitIds, travelInfo) => {
  return axiosDefault.post('/v2/chits/bulk-actions/set-travel-info', { chitIds, travelInfo }).then((response) => response.data)
}


export const setProductionDayDataExportRequest = (payload) => {
  return axiosDefault.post('/v2/booking-forms/send', payload).then(res => res.data)
}

export const setProductionDayRelease = (productionDayId) => {
  return axiosDefault.post(`/v2/production-days/send`, {
    productionDayId
  })

}

export const sendingSMS = (payload) => {
  return axiosDefault.post('/v2/chits/bulk-actions/send-call-details', payload).then(res => res.data)
}

export const setCallDetailsResponse = (chitId,result)=> {
  return axiosDefault.post('/v2/chits/record-call-details-response ', {
    chitId,
    result
  }).then(res=>res.data);
}