/**
 * Convert array of objects to map (object) with keys from element of array.
 * @param array to convert to map
 * @param key of array element which should be set as field of map or function which extracts key from the element
 */
import {isFunction} from 'formik';

export const arrayToMap = (array, key) => {
    const isKeyFunction = key && {}.toString.call(key) === '[object Function]';
    const obj = {};
    array.forEach(element => {
        const computedKey = isKeyFunction ? key(element) : element[key];
        obj[computedKey] = element;
    });

    return obj;
};

/**
 * Group array by propertyGetter.
 * if propertyGetter is of type string, tries to get property of element as key, otherwise, if propertyGetter is
 * function, tries to get key by function which access the element of the array.
 */
export const groupBy = (arr, propertyGetter) => {
    return arr.reduce((acc, obj) => {
        let key;
        if (typeof propertyGetter === 'string') {
            key = obj[propertyGetter];
        } else if (isFunction(propertyGetter)) {
            key = propertyGetter(obj)
        }
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export const arraysEqual = (arr1, arr2) =>
    arr1.length === arr2.length &&
    arr1.every(function (element) {
        return arr2.includes(element);
    });
