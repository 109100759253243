import React from 'react';
import {connect} from 'react-redux';
import ProductionDays from './ProductionDays';
import * as getters from '../../getters';
import {getters as appGetters} from '../../../app';
import {
  setDefaultPaySchemeRequest,
  importProductionDaysRequest,
  showHideProductionDayQrCodeWindow
} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const productionState = state.production;
  return {
    production: productionState.production,
    productionDays: getters.getProductionDays(state),
    productionUnits: getters.getProductionUnits(state),
    paySchemes: appGetters.getRateCards(state),
    ads: appGetters.getAds(state),
    chitsTotalsByUnitsInDate: getters.getChitsTotalsByUnitsInDate(state),
    primaryAd: getters.getPrimaryAd(state),
    importingProductionDays: productionState.importingProductionDays,
    specificDayBeingImported: productionState.specificDayBeingImported,
    importType: productionState.importType,
    adsById: appGetters.getAdsGroupedById(state),
    paySchemesById: state.app.rateCards,
    productionUnitsById: state.production.productionUnits,
    highlightedProductionDayDate: ownProps.currentProductionDayDate,
    productionDayQrCode: state.production.currentProductionDayQrCode,
    productionDayShowQrCodeWindow: state.production.productionDayShowQrCodeWindow,
    productionDayProductionQrCodeBeingShown: state.production.productionDayProductionQrCodeBeingShown,
    productionDayQrCodeBeingShown: state.production.productionDayQrCodeBeingShown,
    currentProductionDayQrCode: state.production.currentProductionDayQrCode
    // currentProductionDayQrCodeAction: state.production.currentProductionDayQrCodeAction
  };
};

const actionCreators = {
  setDefaultPaySchemeRequest,
  importProductionDaysRequest,
  showHideProductionDayQrCodeWindow,
};

export default connect(mapStateToProps, actionCreators)(ProductionDays);


